import Data from "./Data";

export const voteRoute = "/Vote";

export default abstract class Vote {

    public static async Upvote(contentId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Get(`${voteRoute}/${contentId}/Up`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async Downvote(contentId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Get(`${voteRoute}/${contentId}/Down`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async ClearVote(contentId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Get(`${voteRoute}/${contentId}/Clear`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }
}