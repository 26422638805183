import { Box, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';

import FeedbackIcon from '@mui/icons-material/Feedback';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';

const listItemStyle:React.CSSProperties = {
    padding: "0px 16px"
};

export default class CommentDisclaimer extends React.Component {
    render() {
        return(
            <Box>
                <Typography variant="body2">
                    Please keep our commenting guidelines in mind when writing a comment.
                </Typography>
                <List>
                    <ListItem sx={listItemStyle}>
                        <ListItemIcon>
                            <FeedbackIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText primary="Keep it relevant" secondary="Off-topic comments may be removed."/>
                    </ListItem>
                    <ListItem sx={listItemStyle}>
                        <ListItemIcon>
                            <ConnectWithoutContactIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText primary="Keep it respectful" secondary="Criticise ideas, not people. Avoid offensive language (this includes profanity)"/>
                    </ListItem>
                    <ListItem sx={listItemStyle}>
                        <ListItemIcon>
                            <CleaningServicesIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText primary="Keep it neat" secondary="Avoid excessive use of bold type and capital letters."/>
                    </ListItem>
                </List>
            </Box>
        )
    }
}