import Data from "./Data";
import { AnswerDTO, QuestionDTO } from "./models/datatransferobjects";
import { CreateAnswerRequest, CreateQuestionRequest, UpdateAnswerRequest, UpdateQuestionRequest, ViewQuestionResult } from "./models/question";

export const questionRoute = "/Question";

export default abstract class Question {

    public static async GetQuestion(pollId:number) : Promise<QuestionDTO> {
        return new Promise<QuestionDTO>((resolve, reject) => {
            Data.Get<QuestionDTO>(`${questionRoute}/${pollId}`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        })
    }

    public static async ViewQuestion(identifier:string, seoFriendlyUrl:string) : Promise<ViewQuestionResult> {
        return new Promise<ViewQuestionResult>((resolve, reject) => {
            Data.Get<ViewQuestionResult>(`${questionRoute}/${identifier}/${seoFriendlyUrl}`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        })
    }

    public static async CreateQuestion(title:string, content:string, contentPreview:string, tags:string[]) : Promise<QuestionDTO> {
        return new Promise<QuestionDTO>((resolve, reject) => {
            Data.Post<CreateQuestionRequest, QuestionDTO>(questionRoute, {title:title, content:content, contentPreview:contentPreview, tags:tags}).then(post => {
                resolve(post);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async CreateAnswer(questionId:string, content:string, contentPreview:string) : Promise<AnswerDTO> {
        return new Promise<AnswerDTO>((resolve, reject) => {
            Data.Post<CreateAnswerRequest, AnswerDTO>(`${questionRoute}/${questionId}/Answer`, {content: content, contentPreview: contentPreview}).then(answer => {
                resolve(answer);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async UpdateAnswer(questionId:string, answerId:string, content:string, contentPreview:string) : Promise<AnswerDTO> {
        return new Promise<AnswerDTO>((resolve, reject) => {
            Data.Put<UpdateAnswerRequest, AnswerDTO>(`${questionRoute}/${questionId}/Answer/${answerId}`, {content: content, contentPreview: contentPreview}).then(answer => {
                resolve(answer);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async UpdateQuestion(questionId:string, title:string, content:string, contentPreview:string, tags:string[]) : Promise<QuestionDTO> {
        return new Promise<QuestionDTO>((resolve, reject) => {
            Data.Put<UpdateQuestionRequest, QuestionDTO>(`${questionRoute}/${questionId}`, {title:title, content:content, contentPreview:contentPreview, tags:tags}).then(post => {
                resolve(post);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async AcceptAnswer(questionId:string, answerId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Get(`${questionRoute}/${questionId}/Answer/${answerId}/Accept`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async DeleteAnswer(questionId:string, answerId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Delete(`${questionRoute}/${questionId}/Answer/${answerId}`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async DeleteQuestion(questionId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Delete(`${questionRoute}/${questionId}`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }
}