import { Box, Dialog, DialogContent, Stack, Typography, Autocomplete, TextField, Alert, Button } from '@mui/material';
import React from 'react';
import {EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import FeedController from '../../../API/FeedController';
import CircularProgress from '@mui/material/CircularProgress';
import FeedItemCard from '../../Feed/FeedItemCard';


export default class FetchEditorPostEmbedBlock extends React.Component {
    data;
    editorRef;
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            editorState:  EditorState.createEmpty(),
            hasPostId: false,
            postId: "",
            postDTOs: [],
            postDTO: null,
            post: null,
            error: undefined,
            posts: [],
            query: ""
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSelectedPostChange = this.onSelectedPostChange.bind(this);
        this.renderInput = this.renderInput.bind(this);
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        FeedController.GetPostPreviews().then(response => {
            const postPreviews = [];
            for(let preview of response) {
                
                postPreviews.push({
                    label: preview.title,
                    identifier: preview.identifier
                });
            }

            this.setState({...this.state, posts: postPreviews, postDTOs: response});
        });
    }

    onInputChange(event, newValue) {
        this.setState({...this.state, query: newValue});
    }

    onSelectedPostChange(event, newValue) {
        this.setState({...this.state, post: newValue});
    }

    renderInput(params) {
        const disabled = this.state.posts.length === 0;
        return(
            <Stack direction={'row'} spacing={2}>
                {disabled && <CircularProgress />}
                <TextField {...params} label="Select post" disabled={disabled}></TextField>
            </Stack>
            
        )
    }

    getData() {
        return {
            "identifer": this.state.postId
        };
    }

    compareOptions(option, value) {
        return option.identifier === value.identifier;
    }

    onSubmit() {
        if(this.state.post != null) {
            const postDTO = this.state.postDTOs.filter(dto => dto.identifier === this.state.post.identifier)[0];
            this.setState({...this.state, postId: this.state.post.identifier, hasPostId: true, postDTO: postDTO});
        }
    }

    onCancel() {
        this.props.onDelete();
        this.setState({...this.state, hasLink: true});
    }

    handleFocus() {
        
    }

    render() {
        return(
            <Box>
                {this.state.hasPostId ? <FeedItemCard item={this.state.postDTO} /> : <div>No post Id selected</div>}
                <Dialog fullScreen open={!this.state.hasPostId}>
                    <DialogContent>
                        <Stack gap={"12px"}>
                            <Typography variant='h5'>Embed a post</Typography>
                            <Typography variant='body1'>Select a post to embed into this one.</Typography>
                            <Autocomplete
                                disablePortal
                                id="select-post"
                                options={this.state.posts}
                                sx={{ width: "100%" }}
                                value={this.state.post}
                                onChange={this.onSelectedPostChange}
                                inputValue={this.state.query}
                                onInputChange={this.onInputChange}
                                renderInput={this.renderInput}
                                isOptionEqualToValue={this.compareOptions}
                            />
                            {this.state.error && (
                                <Alert severity="error">{this.state.error}</Alert>
                            )}
                            <Stack direction="row" gap={"8px"}>
                                <Button onClick={this.onSubmit} color='primary' variant='contained' fullWidth>Insert</Button>
                                <Button onClick={this.onCancel} color='secondary' variant='contained' fullWidth>Cancel</Button>
                            </Stack>
                        </Stack>
                    </DialogContent>
                </Dialog>
            </Box>
        );
    }
}