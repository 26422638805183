import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

type TagProps = {
    title:string
}

type TagsProps = {
    tags:string[]
}

const tagStyle:React.CSSProperties = {
    borderRadius: 8,
    backgroundColor: "#e8def8",
    textAlign: "center",
    padding: "6px 12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}

export class Tag extends React.Component<TagProps> {
    render() {
        return(
            <Box style={tagStyle}>
                <Typography>{this.props.title}</Typography>
            </Box>
        );
    }
}

export default class Tags extends React.Component<TagsProps> {
    render() {
        return(
            <Stack direction="row" gap={"16px"} justifyContent={"center"} alignItems={"center"} sx={{marginTop: "4px", marginRight: "8px"}}>
                {this.props.tags.map((tagTitle, i) => {
                    return(<Tag key={i} title={tagTitle}/>)
                })}
            </Stack>
        )
    }
}