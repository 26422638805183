import React from 'react';
import CookieConsent from "react-cookie-consent";

export default class CookieAlert extends React.Component {
    render(): React.ReactNode {
        return(
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="consentCookie"
                enableDeclineButton
                declineButtonStyle={{
                    background: "#fefefe",
                    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "1.75",
                    letterSpacing: "0.02857em",
                    textTransform: "uppercase",
                    color: "#fff",
                    backgroundColor: "#aab0bc",
                    borderRadius: "100px"
                }}
                style={{ 
                    background: "#fff"
                }}

                buttonStyle={{
                    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "1.75",
                    letterSpacing: "0.02857em",
                    textTransform: "uppercase",
                    color: "#fff",
                    backgroundColor: "#df37a5",
                    borderRadius: "100px"
                }}
            >
                <span style={{color: "#000"}}>Fetch uses cookies in order to deliver the best experience to our users. <a href='/terms'>Privacy statement</a></span>
            </CookieConsent>
        )
    }
}