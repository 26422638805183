import React from 'react';

import { Box, Button, Card, Divider, Stack, Link } from '@mui/material';


import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import { ViewAnswerResult, ViewQuestionResult } from '../../API/models/question';
import AuthorReputation from '../Author/AuthorReputation';

import {FormatDate} from '../../Helpers/Formatters';
import FetchViewer from '../Viewer/FetchViewer';
import CreateComment from '../Comment/CreateComment';
import { ViewCommentResult } from '../../API/models/comments';
import Question from '../../API/Question';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CommentTag from '../../Controls/CommentTag';
import VoteTag from '../../Controls/VoteTag';
import ChildComment from './ChildComment';
import Spacer from '../../Helpers/Spacer';

import {leftSidebarStyle} from './ViewQuestion';
import FeedItemCardMenu from '../Feed/FeedItemCardMenu';
import Vote from '../../API/Vote';
import AuthorAvatar from '../../Account/AuthorAvatar';
import { WhoAmIResponse } from '../../API/models/account';

type ViewAnswerProps = {
    whoAmI: WhoAmIResponse,
    answer:ViewAnswerResult,
    question:ViewQuestionResult,
    onAccepted:Function
}

type ViewAnswerState = {
    answer: ViewAnswerResult,
    isCommentBoxOpen: boolean,
    showMoreComments: boolean,
    menuAnchor?:Element,
    isMenuOpen:boolean,
    isAuthor:boolean,
    isAccepted:boolean
}

const cardStyle: React.CSSProperties = {
    width: "100%"
}

export default class ViewAnswer extends React.Component<ViewAnswerProps, ViewAnswerState> {
    viewerRef:React.RefObject<FetchViewer>;

    constructor(props:ViewAnswerProps) {
        super(props);

        this.state = {
            isCommentBoxOpen: false,
            answer: this.props.answer,
            showMoreComments: false,
            isMenuOpen: false,
            isAuthor: false,
            isAccepted: false
        };

        this.viewerRef = React.createRef();

        this.openCommentBox = this.openCommentBox.bind(this);
        this.onCommentPosted = this.onCommentPosted.bind(this);
        this.toggleComments = this.toggleComments.bind(this);
        this.showComments = this.showComments.bind(this);

        this.openContextMenu = this.openContextMenu.bind(this);
        this.closeContextMenu = this.closeContextMenu.bind(this);
        this.acceptAnswer = this.acceptAnswer.bind(this);
        this.setAcceptation = this.setAcceptation.bind(this);
        this.upVoteAnswer = this.upVoteAnswer.bind(this);
        this.downVoteAnswer = this.downVoteAnswer.bind(this);
    }

    componentDidMount(): void {
        const isAuthor = this.props.question.author.id === this.props.whoAmI.id;
            this.setState({...this.state, isAuthor: isAuthor, isAccepted: this.props.answer.accepted}, () => {
                if(this.viewerRef.current) {
                    this.viewerRef.current.setContent(JSON.parse(this.state.answer.content));
                }
            });
    }

    openCommentBox() {
        this.setState({...this.state, isCommentBoxOpen: true});
    }

    closeCommentBox() {
        this.setState({...this.state, isCommentBoxOpen: false});
    }

    openContextMenu(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            ...this.state,
            isMenuOpen: true,
            menuAnchor: event.currentTarget
        });
    }

    closeContextMenu() {
        this.setState({
            ...this.state,
            isMenuOpen: false
        });
    }

    onCommentPosted(comment:ViewCommentResult) {
        let answer = this.state.answer;
        answer.numberOfComments += 1;
        answer.comments.unshift(comment);
        this.setState({...this.state, answer: answer, isCommentBoxOpen: false});
    }

    toggleComments() {
        this.setState({...this.state, showMoreComments: !this.state.showMoreComments});
    }

    showComments() {
        const elements:JSX.Element[] = [];
        const limit = this.state.showMoreComments 
            ? this.state.answer.comments.length 
            : this.state.answer.comments.length < 5 
                ? this.state.answer.comments.length 
                : 5;

        for(let i = 0; i < limit; i++) {
            elements.push(<ChildComment key={this.state.answer.comments[i].commentId} comment={this.state.answer.comments[i]} />)
        }

        return elements;
    }

    acceptAnswer() {
        Question.AcceptAnswer(this.props.question.id, this.props.answer.answerId).then(() => {
            this.props.onAccepted(this.props.answer.answerId);
        });
    }

    setAcceptation(accepted:boolean) {
        this.setState({...this.state, isAccepted: accepted});
    }

    upVoteAnswer() {
        Vote.Upvote(this.state.answer.answerId).then(() => {
            let answer = this.state.answer;
            answer.votes += 1;
            this.setState({...this.state, answer: answer});
        }).catch(error => {
            console.log(error);
        });
    }

    downVoteAnswer() {
        Vote.Downvote(this.state.answer.answerId).then(() => {
            let answer = this.state.answer;
            answer.votes -= 1;
            this.setState({...this.state, answer: answer});
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        return (
            <Stack gap={"24px"} sx={{width: "100%"}}>
                <Card style={cardStyle}>
                    <CardContent style={{textAlign: "left"}}>
                        <Stack sx={{marginLeft: "-12px"}} direction="row">
                            <Stack gap={"6px"} alignItems="center" justifyItems="center" alignContent="center" style={leftSidebarStyle}>
                                {this.state.isAuthor && 
                                    <Box sx={{height: 64}}></Box>
                                }
                                {!this.state.isAuthor && 
                                    <IconButton onClick={this.upVoteAnswer}>
                                        <KeyboardArrowUpIcon />
                                    </IconButton>
                                }
                                <Typography>{this.state.answer.votes}</Typography>
                                {!this.state.isAuthor && 
                                    <IconButton onClick={this.downVoteAnswer}>
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                }

                                
                                
                                {
                                    this.state.isAccepted &&
                                        <CheckIcon style={{color: "green", fontWeight: "bold"}} />
                                }
                                {
                                    this.state.isAuthor && !this.state.isAccepted &&
                                        <IconButton onClick={this.acceptAnswer}>
                                            <CheckIcon />
                                        </IconButton>
                                }
                            </Stack>
                            <Stack gap={"18px"} style={{flexGrow: 1}}>
                                <Stack gap={"12px"} style={{width: "100%"}} direction="row">
                                    <AuthorAvatar author={this.state.answer.author} />
                                    <Box>
                                        <div style={{textAlign: "left", fontWeight: "bold"}}>{this.state.answer.author.name}</div>
                                        <div style={{textAlign: "left"}}><AuthorReputation author={this.state.answer.author}/></div>
                                    </Box>
                                    <Spacer />
                                    <Box>
                                        <IconButton onClick={this.openContextMenu} sx={{marginLeft: "auto", flexGrow: 1}}><MoreVertIcon/></IconButton>
                                    </Box>
                                </Stack>
                                <Stack spacing={2}>
                                    <Stack direction="row" spacing={2}>
                                        <Typography variant="body2" color="text.secondary">
                                            {FormatDate(new Date(this.state.answer.date))}
                                        </Typography>
                                    </Stack>
                                    <FetchViewer ref={this.viewerRef} />
                                </Stack>
                                {
                                    this.state.isCommentBoxOpen && (
                                        <div>
                                            <CreateComment whoAmI={this.props.whoAmI} onCommentPosted={this.onCommentPosted} contentId={this.state.answer.answerId}/>
                                        </div>
                                    )
                                }
                                {
                                    this.state.answer.comments.length > 0 && (
                                        <Stack spacing={1}>
                                            <Divider />
                                            <Stack style={{flexGrow: 1}} spacing={1} divider={<Divider />}>
                                                {this.showComments()}
                                            </Stack>
                                            <Divider />
                                            {this.state.answer.comments.length > 5 && (
                                                <Box>
                                                    <Link onClick={this.toggleComments}><Typography variant="body2" style={{fontSize: "80%", display: "inline"}}>{this.state.showMoreComments ? "Hide" : "Show"} {this.state.answer.comments.length - 5} more comments</Typography></Link>
                                                </Box>
                                            )}
                                        </Stack>
                                    )
                                }
                                <Stack direction="row" gap={"12px"} style={{alignItems: "center"}}>
                                    <VoteTag votes={this.state.answer.votes} />
                                    <CommentTag comments={this.state.answer.numberOfComments} />
                                    <Spacer />
                                    <Button onClick={this.openCommentBox} variant='outlined' style={{marginLeft: "auto"}}>Comment</Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
                <FeedItemCardMenu whoAmI={this.props.whoAmI} hasFavorited={this.state.answer.hasFavorited} contentId={this.state.answer.answerId} author={this.state.answer.author} isHidden={false} onClose={this.closeContextMenu} open={this.state.isMenuOpen} anchor={this.state.menuAnchor} />

                {/* <CreateComment onCommentPosted={this.onCommentPosted} contentId={this.props.answer.answerId} />
                <BasicCommentList comments={this.props.answer.comments} contentId={this.props.answer.answerId} /> */}
            </Stack>
        ); 
    }
}