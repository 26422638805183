import React from 'react';
import {RichUtils} from 'draft-js';
import 'draft-js/dist/Draft.css';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';


import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TimelineIcon from '@mui/icons-material/Timeline';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ArticleIcon from '@mui/icons-material/Article';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import FetchEditorTextFormat from './FetchEditorTextFormat';
import FetchEditorBlockType from './FetchEditorBlockType';
import FetchEditorBlock from './FetchEditorBlock';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import {generateRandomUUID} from "../../Helpers/IdHelper";

export class EditorBlock {
    index;
    id;
    type;
    data;
    reference

    constructor(index, type, data, reference) {
        this.index = index;
        this.id = generateRandomUUID();
        this.type = type;
        this.data = data;
        this.reference = reference;
    }
}

export default class FetchEditor extends React.Component {
    constructor(props) {
        super(props);
        let blocks = [];
        
        this.state = {
            blocks : blocks,
            currentBlockRef: null,
            showAddBlockItem: blocks.length > 1 ? 0 : 1,
            activeBlock : 0,
            token: ""
        };

        this.editorChange = this.editorChange.bind(this);
        this.openChartEditor = this.openChartEditor.bind(this);
        this.closeChartEditor = this.closeChartEditor.bind(this);
        this.handleFormatChange = this.handleFormatChange.bind(this);
        this.handleBlockChange = this.handleBlockChange.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.showAddBlockButton = this.showAddBlockButton.bind(this);
        this.hideAddBlockButton = this.hideAddBlockButton.bind(this);
        this.insertText = this.insertText.bind(this);
        this.insertChart = this.insertChart.bind(this);
        this.deleteBlock = this.deleteBlock.bind(this);
        this.save = this.save.bind(this);
        this.load = this.load.bind(this);
        this.getBlockPosition = this.getBlockPosition.bind(this);
        this.getExcerpt = this.getExcerpt.bind(this);
        this.onPaste = this.onPaste.bind(this);

        this.insertImage = this.insertImage.bind(this);
        this.insertImageBase64 = this.insertImageBase64.bind(this);
    }

    componentDidMount() {
        const blocks = [new EditorBlock(0, 'text', null, React.createRef())];
        this.setState({...this.state, blocks : blocks, blockReference: blocks[0].reference});

    }

    clear() {
        const blocks = [new EditorBlock(0, 'text', null, React.createRef())];
        this.setState({...this.state, blocks : blocks, blockReference: blocks[0].reference});
    }

    getExcerpt() {
        for(const block of this.state.blocks) {
            if(block.type === "text" && block.reference.current) {
                const rawData = block.reference.current?.getRawData();
                const text = rawData.substring(0, 100);
                const words = text.split(" ");
                if(words.length === 1) {
                    return words;
                }

                const joinedWords = words.join(" ");
                return joinedWords;
            }
        }
    }

    //ba534c60-23be-4645-b5a8-525045ff5312
    //a24e9885-bae8-45f4-bdd8-251a64e1a0ce
    async load(id) {
        return new Promise((resolve, reject) => {
            fetch('https://localhost:7233/Post/' + id, {
                method: "GET",
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.state.token
                },
            }).then(response => {
                response.json().then(json => {
                    resolve(JSON.parse(json.content));
                });
            });
        });
    }

    async save() {
        const blocks = [];
        for(const block of this.state.blocks) {
            const blockData = await block.reference.current.getData();
            blocks.push({"index": block.index, "id": block.id, "type": block.type, "data": JSON.stringify(blockData)});
        }
        
        return blocks;
    }

    async insertText(blockId) {
        const blocks = this.state.blocks;
        for(let block of blocks) {
            const blockData = await block.reference.current.getData();
            block.data = blockData;
        }

        const index = this.getBlockPosition(blockId);
        blocks.splice(index + 1, 0, new EditorBlock(index, 'text', null, React.createRef()));
        this.setState({...this.state, blocks: blocks});
    }

    insertChart(blockId) {
        const blocks = this.state.blocks;
        const index = this.getBlockPosition(blockId);
        blocks.splice(index + 1, 0, new EditorBlock(index, 'chart', null, React.createRef()));
        this.setState({...this.state, blocks: blocks});
    }

    insertYoutube(blockId) {
        const blocks = this.state.blocks;
        const index = this.getBlockPosition(blockId);
        blocks.splice(index + 1, 0, new EditorBlock(index, 'youtube', null, React.createRef()));
        this.setState({...this.state, blocks: blocks});
    }

    insertPostEmbed(blockId) {
        const blocks = this.state.blocks;
        const index = this.getBlockPosition(blockId);
        blocks.splice(index + 1, 0, new EditorBlock(index, 'postembed', null, React.createRef()));
        this.setState({...this.state, blocks: blocks});
    }

    insertImage(blockId) {
        const blocks = this.state.blocks;
        const index = this.getBlockPosition(blockId);
        blocks.splice(index + 1, 0, new EditorBlock(index, 'image', null, React.createRef()));
        this.setState({...this.state, blocks: blocks});
    }

    insertImageBase64(imageBase64) {
        const blocks = this.state.blocks;
        const index = this.getBlockPosition(this.state.activeBlock);
        blocks.splice(index + 1, 0, new EditorBlock(index, 'image', {src: imageBase64}, React.createRef()));
        this.setState({...this.state, blocks: blocks});
    }

    insertChartJs(blockId) {
        const blocks = this.state.blocks;
        const index = this.getBlockPosition(blockId);
        blocks.splice(index + 1, 0, new EditorBlock(index, 'chartjs', null, React.createRef()));
        this.setState({...this.state, blocks: blocks});
    }

    getBlockPosition(blockId) {
        for (let i = 0; i < this.state.blocks.length; i++) {
            if (this.state.blocks[i].id === blockId) {
                return i;
            }
        }
        return -1;
    }

    handleEditorChange(editor, id) {
        let reference = this.state.blocks.filter(block => {
            return block.id === id;
        })[0].reference;

        this.setState({...this.state, currentBlockRef: reference, activeBlock: id});
    }

    openChartEditor() {
        this.setState({...this.state, isEditorModalOpen: true});
    }

    closeChartEditor() {
        this.setState({...this.state, isEditorModalOpen: false});
    }

    editorChange(editorState) {
        this.state.currentBlockRef.current.updateStateObject(editorState);
    }

    handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            this.onChange(newState);
            return 'handled';
        }

        return 'not-handled';
    }

    handleBlockChange(block) {
        let editor = this.state.currentBlockRef.current.getStateObject();
        this.editorChange(RichUtils.toggleBlockType(editor, block));
    }

    handleFormatChange(format) {
        let editor = this.state.currentBlockRef.current.getStateObject();
        this.editorChange(RichUtils.toggleInlineStyle(editor, format));
    }

    handleColorChange(rgba) {
        let editor = this.state.currentBlockRef.current.getStateObject();
        const colorString = `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
        this.editorChange(RichUtils.toggleInlineStyle(editor, colorString));
    }

    showAddBlockButton(id) {
        this.setState({...this.state, showAddBlockItem: id});
    }

    hideAddBlockButton() {
        this.setState({...this.state, showAddBlockItem: 0})
    }

    deleteBlock(id) {
        const blocks = this.state.blocks.filter(function(block) {
            return block.id !== id;
        });

        this.setState({...this.state, blocks: blocks});
    }

    onPaste(event) {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items;
        for (let item of items) {
            if (item.kind === 'file') {
              var blob = item.getAsFile();
              var reader = new FileReader();
              reader.onload = (event) => {
                this.insertImageBase64(event.target.result);
            }; // data url!
              reader.readAsDataURL(blob);
            }
        }
    }

    getBlocks() {
        var list = [];
        let i = 0;

        for (const block of this.state.blocks) {
            var element =  <Stack key={block.id} direction="column" spacing={0}>
                                <FetchEditorBlock onDelete={this.deleteBlock} id={block.id} type={block.type} data={block.data} firstBlock={(i === 0)} onFocus={this.handleEditorChange} ref={block.reference}/>
                                <Box onMouseEnter={() => {this.showAddBlockButton(block.id)}} onMouseLeave={this.hideAddBlockButton} style={{margin: "4px 0"}}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'left',
                                        flexDirection: 'column',
                                        paddingLeft: '12px'
                                    }}>
                                    <SpeedDial FabProps={{"size" : "small"}} ariaLabel='add block' direction='right' icon={<SpeedDialIcon />} sx={{transition: "all ease 0.5s", opacity: this.state.showAddBlockItem === block.id ? 1 : 0.15 }}>
                                        <SpeedDialAction onClick={() => {this.insertText(block.id);} } key={"text"} icon={<TextSnippetIcon />} tooltipTitle={"Insert a text block below"}/>
                                        <SpeedDialAction onClick={() => {this.insertImage(block.id);} } key={"image"} icon={<InsertPhotoIcon />} tooltipTitle={"Insert an image below"}/>
                                        <SpeedDialAction onClick={() => {this.insertChart(block.id);} } key={"chart"} icon={<WaterfallChartIcon />} tooltipTitle={"Insert a TradingView chart block below"}/>
                                        <SpeedDialAction onClick={() => {this.insertChartJs(block.id);} } key={"chartJS"} icon={<TimelineIcon />} tooltipTitle={"Insert a chartJS block below"}/>
                                        <SpeedDialAction onClick={() => {this.insertYoutube(block.id);} } key={"youtube"} icon={<YouTubeIcon />} tooltipTitle={"Insert a YouTube video below"}/>
                                        <SpeedDialAction onClick={() => {this.insertPostEmbed(block.id);} } key={"embed"} icon={<ArticleIcon />} tooltipTitle={"Embed a post reference below"}/>
                                    </SpeedDial>
                                </Box>
                            </Stack>

            list.push(element);
            i++;
        }
        return list;
    }
  
    render() {
      return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack spacing={0}>
                    <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" style={{margin: "18px 0 0 0"}}>
                        <FetchEditorBlockType onChange={this.handleBlockChange}/>
                        <FetchEditorTextFormat onChange={this.handleFormatChange}/>
                    </Stack>
                    <Box onPaste={this.onPaste} sx={{height: '100%', overflow: 'auto'}}>
                        { this.getBlocks() }
                    </Box>
                </Stack>
            </Grid>
        </Grid>
      );
    }
}