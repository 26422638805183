import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import { tagStyle } from "../Theme/Theme"

import BallotIcon from '@mui/icons-material/Ballot';

type VoteTagProps = {
    votes:number
}

export default class VoteTag extends React.Component<VoteTagProps> {
    render() {
        return(
            <Box style={tagStyle}>
                <Stack direction="row" spacing={1}>
                    <BallotIcon fontSize='small' color='primary'/>
                    <Typography variant="body2" color="text.primary">
                        {this.props.votes}
                    </Typography>
                </Stack>
            </Box>
        )
    }
}