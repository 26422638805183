import { Stack, Typography, Card, Box, Button, Avatar, List, ListItem, ListItemButton, ListItemAvatar, Icon, ListItemText, Paper } from '@mui/material';
import React from 'react';
import { NotificationDTO } from '../../API/models/datatransferobjects';

import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import NotificationController from '../../API/NotificationController';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const notificationBoxStyle: React.CSSProperties = {
    alignItems: "center",
    padding: "16px",
}

const notificationStackStyle: React.CSSProperties = {
    alignItems: "center",
}

const avatarStyle: React.CSSProperties = {
    // justifyContent: "center",
    // alignItems: "center",
    // padding: "24px",
    // display: "flex"
}

const columns: GridColDef[] = [
    { field: 'messageHtml', headerName: 'Notification', flex: 1 }
];

type NotificationsProps = {}

type NotificationState = {
    notifications:NotificationDTO[],
    loadingIsExhausted: boolean,
    currentPage:number
}

export default class Notifications extends React.Component<NotificationsProps, NotificationState> {

    constructor(props:NotificationsProps) {
        super(props);
        this.state = {
            notifications: [],
            loadingIsExhausted: false,
            currentPage: 0
        };

        this.loadMore = this.loadMore.bind(this);
    }

    componentDidMount(): void {
        this.loadMore();
    }

    loadMore() {
        NotificationController.GetNotifications(this.state.currentPage).then(notifications => {
            const currentNotifications = this.state.notifications;
            if(notifications.length === 0) {
                this.setState({...this.state, loadingIsExhausted: true});
                return;
            }
            let page = this.state.currentPage;
            for(const notification of notifications) {
                currentNotifications.push(notification)
            }

            page++;
            this.setState({...this.state, notifications: currentNotifications, currentPage: page});
        });
    }

    render() {
        return(
            <Stack spacing={2} style={{flexGrow: 1}}>
                <Paper elevation={1}>
                    <List>
                        {this.state.notifications.map((notification, i) => {
                            return <Notification notification={notification} />
                        })}
                    </List>
                </Paper>
                {this.state.loadingIsExhausted
                    ? <Typography variant='body1'>There are no more nofitications.</Typography>
                    : <Button onClick={this.loadMore} variant='contained'>Load more</Button>
                }
            </Stack>
        );
    }
}

type NotificationProps = {
    notification: NotificationDTO
}

export class Notification extends React.Component<NotificationProps> {
    render() {
        return(
            <ListItem 
                key={this.props.notification.id}
                secondaryAction={
                    <Button 
                        href={this.props.notification.actionButtonUrl}
                        variant='outlined'
                    >{this.props.notification.actionButtonMessage}</Button>
                }
            >
                <ListItemButton>
                    <ListItemAvatar>
                        {this.props.notification.isRead 
                            ? <MarkChatReadIcon />
                            : <MarkChatUnreadIcon />
                        }
                    </ListItemAvatar>
                    <ListItemText primary={this.props.notification.messageHtml} />
                </ListItemButton>
            </ListItem> 
        )
    }
}