import { AppBar, Toolbar, Typography, Box, Paper, Stack, Alert } from '@mui/material';
import { IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import React from 'react';
import { Link } from 'react-router-dom';
import Post from '../API/Post';
import Editor from '../Content/Editor/Editor';
import { sidebarPaddingStyle } from '../Content/Editor/Sidebar/EditorSideBar';
import EditorSideBar from '../Content/Editor/Sidebar/EditorSideBar';
import Spacer from "../Helpers/Spacer";
import Question from '../API/Question';
import Theory from '../API/Theory';
import Poll from '../API/Poll';
import { router } from '../App';
import { ParseErrors } from '../Helpers/ErrorHelper';
import UserMenu from './Header/UserMenu';
import { ContentType } from './EditorLayout';
import { WhoAmIResponse } from '../API/models/account';

type DesktopEditorProps = {
    contentType:ContentType,
    whoAmI: WhoAmIResponse
}

type DesktopEditorState = {
    errors: string[]
}

const containerStyle: React.CSSProperties = {
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    gap: "24px",
    display: "flex",
    alignItems: "flex-start"
}

const toolbarStyle: React.CSSProperties = {
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50vw",
}

const editorStyle: React.CSSProperties = {
    flexGrow: 1,
    maxWidth: "50vw",
    minHeight: "calc(100vh - 64px - 24px - 48px)",
}

const sidebarStyle: React.CSSProperties = {
    width: "300px",
}

const fakeSidebarStyle: React.CSSProperties = {
    width: "300px"
}

export default class DesktopEditorLayout extends React.Component<DesktopEditorProps, DesktopEditorState> {
    editorRef:React.RefObject<Editor>
    metdataRef:React.RefObject<EditorSideBar>
    
    constructor(props:DesktopEditorProps) {
        super(props);
        this.save = this.save.bind(this);
        this.savePost = this.savePost.bind(this);
        this.savePoll = this.savePoll.bind(this);
        this.saveQuestion = this.saveQuestion.bind(this);
        this.saveTheory = this.saveTheory.bind(this);
        this.editorRef = React.createRef();
        this.metdataRef = React.createRef();

        this.state = {
            errors: []
        };
    }

    async save(metadata:any, callBack:Function) {
        let excerpt = this.editorRef.current?.getExcerpt();
        this.editorRef.current?.save().then(editorData => {
            if(metadata.contentType === "post") {
                this.savePost(metadata, editorData, excerpt ?? metadata.contentType).then(() => {
                    callBack();
                });
            }

            else if(metadata.contentType === "question") {
                this.saveQuestion(metadata, editorData, excerpt ?? metadata.contentType).then(() => {
                    callBack();
                });
            }

            else if(metadata.contentType === "theory") {
                this.saveTheory(metadata, editorData, excerpt ?? metadata.contentType).then(() => {
                    callBack();
                });
            }

            else if(metadata.contentType === "poll") {
                this.savePoll(metadata, editorData, excerpt ?? metadata.contentType).then(() => {
                    callBack();
                });
            }
        });
    }

    async savePost(metadata:any, editorData:any, excerpt:string) {
        await Post.CreatePost(metadata.title, JSON.stringify(editorData), excerpt, metadata.tags, metadata.image)
            .then(post => {
                router.navigate(`/post/${post.identifier}/${post.title.replace(" ", "-")}`);
            })
            .catch(errors => {
                const errorList = ParseErrors(errors);
                this.setState({...this.state, errors: errorList});
            });
    }

    async saveQuestion(metadata:any, editorData:any, excerpt:string) {
        Question.CreateQuestion(metadata.title, JSON.stringify(editorData), excerpt, metadata.tags)
            .then(question => {
                router.navigate(`/question/${question.identifier}/${question.title.replace(" ", "-")}`);
            })
            .catch(errors => {
                const errorList = ParseErrors(errors);
                this.setState({...this.state, errors: errorList});
            });
    }

    async saveTheory(metadata:any, editorData:any, excerpt:string) {
        await Theory.CreateTheory(metadata.title, JSON.stringify(editorData), excerpt, metadata.tags)
            .then(theory => {
                router.navigate(`/theory/${theory.identifier}/${theory.title.replace(" ", "-")}`);
            })
            .catch(errors => {
                const errorList = ParseErrors(errors);
                this.setState({...this.state, errors: errorList});
            });
        
    }

    async savePoll(metadata:any, editorData:any, excerpt: string) {
        await Poll.CreatePoll(metadata.title, JSON.stringify(editorData), excerpt, metadata.tags, 
        metadata.question, metadata.option1, metadata.option2, metadata.option3, metadata.option4)
            .then(poll => {
                router.navigate(`/poll/${poll.identifier}/${poll.title.replace(" ", "-")}`);
            })
            .catch(errors => {
                const errorList = ParseErrors(errors);
                this.setState({...this.state, errors: errorList});
            });
    }
    
    render() {
        return(
            <Box>
                <Stack spacing="24px">
                    <AppBar position="static" color='primary'>
                        <Toolbar sx={toolbarStyle}>
                            <Box>
                                <Link to="/" style={{color: "inherit"}}>
                                    <IconButton color="inherit">
                                        <ArrowBackIosNewIcon />
                                    </IconButton>
                                </Link>
                            </Box>
                            <Spacer />
                            <Box>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                    Fetch
                                </Typography>
                            </Box>
                            <Spacer />
                            <Box>
                                <UserMenu whoAmI={this.props.whoAmI} />
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Stack style={containerStyle}>
                        <div style={fakeSidebarStyle}></div>
                        <Paper style={editorStyle}>
                            <Editor ref={this.editorRef}/>
                        </Paper>
                        <Paper style={sidebarStyle}>
                            <EditorSideBar contentType={this.props.contentType}  onSave={this.save} ref={this.metdataRef}/>
                            
                            {this.state.errors.length > 0 && (
                                <Box style={sidebarPaddingStyle}>
                                    <Stack>
                                        {this.state.errors.map((error, i) => {
                                            return(
                                                <Alert key={i} severity='error'>{error}</Alert>
                                            )
                                        })}
                                    </Stack>
                                </Box>
                            )}
                        </Paper>
                    </Stack>
                </Stack>
            </Box>
        );
    }
}
