import * as React from 'react';
import Account from '../API/Account';
import Authentication from '../API/Authentication';
import { router } from '../App';
import ga4events from "../events";


import { Typography, Grid, Button, TextField} from '@mui/material';
import { styled, } from '@mui/material/styles';

type RegisterProps = {

}

type RegisterState = {
    email:string,
    error?:string
}

const StepsSection = styled('section')(({ theme }) => ({
    padding: theme.spacing(4),
    backgroundColor: '#f3edf7',
    maxHeight: '100vh',
    [theme.breakpoints.up('sm')]: {
        MaxHeight: '100vh',
    },
    '& .step': {
      textAlign: 'center',
      '& img': {
        maxWidth: '80px',
        marginBottom: theme.spacing(2),
      },
      '& h2': {
        fontSize: '1.5rem',
        marginBottom: theme.spacing(1),
      },
      '& p': {
        color: '#666',
      },
    },
    '& form': {
        marginTop: '3vh !important', // Push form to the bottom
        positition: ' relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        backgroundColor: 'rgba(255, 255, 255, 0.9)', // Semi-transparent background
        borderRadius: theme.spacing(2),
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Box shadow for depth
        maxWidth: '80%',
        marginLeft: 'auto !important',
        [theme.breakpoints.up('sm')]: {
          marginTop: '10vh !important',
          maxWidth: '400px', // Reduce the max width of the form on larger screens
          padding: theme.spacing(4),
          marginLeft: 'auto !important',
          marginRight: 'auto !important',
        },
        '& .MuiTextField-root': {
          width: '100%',
          marginBottom: theme.spacing(2),
        },
        '& .MuiButton-root': {
          width: '100%',
          maxWidth: '300px',
          padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
          borderRadius: theme.spacing(3),
          fontSize: '1rem',
          fontWeight: 'bold',
        },
      },
  }));

export default class  LandingPageOnboardStepSection extends React.Component<RegisterProps, RegisterState> {
    constructor(props:RegisterProps) {
        super(props);

        this.state = {
            email: "",
        }
        this.onEmailChanged = this.onEmailChanged.bind(this);
        this.onRegister = this.onRegister.bind(this);
    }

    onEmailChanged(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, email: event.currentTarget.value});
    }
    
    onRegister() {
        ga4events.buttonClick('register_at_landing_page_hero_section');
        Account.Register(this.state.email).then(() => {
            try { Authentication.Logout() } catch { }
            router.navigate("/register/confirm");
        });
    }

    render(){
        return (
            <StepsSection>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4} className="step">
                        <div><img src="/pages/assets/img/icons/study.png" className="icon-svg icon-svg-md text-yellow me-4" alt="" /></div>
                        <Typography variant="h2">Step 1</Typography>
                        <Typography variant="h4">Obtain knowledge</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} className="step">
                    <div><img src="/pages/assets/img/icons/physics.png" className="icon-svg icon-svg-md text-yellow me-4" alt="" /></div>
                        <Typography variant="h2">Step 2</Typography>
                        <Typography variant="h4">Formulate your own strategy</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} className="step">
                        <div><img src="/pages/assets/img/icons/cash.png" className="icon-svg icon-svg-md text-yellow me-4" alt="" /></div>
                        <Typography variant="h2">Step 3</Typography>
                        <Typography variant="h4">Be a profitable trader</Typography>
                    </Grid>
                    <form>
                        <TextField onChange={this.onEmailChanged} label="Enter your email" variant="outlined" />
                        <Button id={'step-register-button'} onClick={this.onRegister} variant="contained" style={{backgroundColor: "#1a73e8"}}>
                            Register for free
                        </Button>
                    </form>
                </Grid>
            </StepsSection>
        )
    }
        
    
}