import { Stack, Typography, Card, Box, Divider, Button, Switch, Avatar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Alert, Snackbar } from '@mui/material';
import Spacer from '../../Helpers/Spacer'
import React, { ChangeEvent } from 'react';


import NakedTextInput from '../../Controls/NakedTextInput';
import Account from '../../API/Account';
import HiddenImageInput from '../../Controls/HiddenImageInput';
import Subscription from '../../API/Subscription';
import { router } from '../../App';
import { WhoAmIResponse } from '../../API/models/account';
import { withParams } from '../../Helpers/ReactRouterHelper';

const containerStyle: React.CSSProperties = {
    width: "100%",
    textAlign: "left"
}

const headerStackStyle: React.CSSProperties = {
    height: "60px",
    padding: "8px 16px",
    justifyContent: "center",
}

const optionStackStyle: React.CSSProperties = {
    alignItems: "center",
    height: "66px",
    padding: "0 16px",
}

const titleBigStyle: React.CSSProperties = {
    fontWeight: "bold",
    fontSize: "100"
}

const titleMediumStyle: React.CSSProperties = {
    fontSize: "11pt",
    fontWeight: "500"
}

const captionStyle: React.CSSProperties = {
    fontSize: "10pt"
}
 
const bioStyle: React.CSSProperties = {
    height: "120px",
    padding: "0 16px",
}

const bioStackStyle: React.CSSProperties = {
    height: "100%",
    // justifyContent: "center",
    alignItems: "center"
}

const avatarStyle: React.CSSProperties = {
    justifyContent: "center",
    alignItems: "center",
    padding: "24px",
    display: "flex"
}

type AccountSettingsProps = {
    loaderData: WhoAmIResponse
}

type AccountSettingsState = {
    isSettingsUpdatedSuccesVisible: boolean,
    isUpdatePasswordDialogOpen:boolean,
    isChangeEmailDialogOpen:boolean,
    isUpdatePasswordSuccesVisible:boolean,
    isChangeEmailSuccesVisisble:boolean,
    currentPassword:string,
    newPassword:string,
    newEmail:string,
    confirmNewPassword:string,
    bio: string,
    darkMode: boolean,
    allowNewLikeNotifications: boolean,
    allowNewCommentNotifications: boolean,
    allowNewPostNotifications: boolean,
    allowNewQuestionNotifications: boolean,
    allowNewTheoryNotifications:boolean,
    allowNewPollNotifications: boolean,
    profileImageUrl:string,
    subscriptionValidUntilDate:Date,

    username:string,
    updatePasswordError?:string,
    changeEmailError?:string,
    emailChangeSuccess: boolean,
    isStripeManageable:boolean
};

class AccountSettings extends React.Component<AccountSettingsProps, AccountSettingsState> {
    bioReference:React.RefObject<NakedTextInput>
    fileReference:React.RefObject<HiddenImageInput>
    constructor(props:AccountSettingsProps) {
        super(props);

        this.state = {
            isSettingsUpdatedSuccesVisible: false,
            isUpdatePasswordDialogOpen: false,
            isChangeEmailDialogOpen: false,
            isChangeEmailSuccesVisisble: false,
            isUpdatePasswordSuccesVisible: false,
            currentPassword: "",
            newEmail: "",
            newPassword: "",
            confirmNewPassword: "",
            bio: "",
            darkMode: false,
            allowNewLikeNotifications: false,
            allowNewCommentNotifications: false,
            allowNewPostNotifications: false,
            allowNewQuestionNotifications: false,
            allowNewTheoryNotifications: false,
            allowNewPollNotifications: false,
            subscriptionValidUntilDate: new Date(),

            username: "",
            profileImageUrl: "",
            emailChangeSuccess: false,
            isStripeManageable: false
        };

        this.bioReference = React.createRef();
        this.fileReference = React.createRef();

        this.openPasswordDialog = this.openPasswordDialog.bind(this);
        this.handlePasswordDialogClose = this.handlePasswordDialogClose.bind(this);
        this.onConfirmNewPasswordChanged = this.onConfirmNewPasswordChanged.bind(this);
        this.onNewPasswordChanged = this.onNewPasswordChanged.bind(this);
        this.onCurrentPasswordChanged = this.onCurrentPasswordChanged.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onNewEmailChange = this.onNewEmailChange.bind(this);

        this.openChangeEmailDialog = this.openChangeEmailDialog.bind(this);
        this.handleChangeEmailDialogClose = this.handleChangeEmailDialogClose.bind(this);

        this.onBioChange = this.onBioChange.bind(this);
        this.onAllowNewCommentNotificationsChanged = this.onAllowNewCommentNotificationsChanged.bind(this);
        this.onAllowNewQuestionNotificationsChanged = this.onAllowNewQuestionNotificationsChanged.bind(this);
        this.onAllowNewPollNotificationsChanged = this.onAllowNewPollNotificationsChanged.bind(this);
        this.onAllowNewPostNotificationsChanged = this.onAllowNewPostNotificationsChanged.bind(this);
        this.onAllowNewTheoryNotificationsChanged = this.onAllowNewTheoryNotificationsChanged.bind(this);
        this.onAllowNewLikeNotificationsChanged = this.onAllowNewLikeNotificationsChanged.bind(this);
        this.onToggleAll = this.onToggleAll.bind(this);

        this.openImageFile = this.openImageFile.bind(this);
        this.onImageFileChange = this.onImageFileChange.bind(this);
        this.manageSubscription = this.manageSubscription.bind(this);

        this.save = this.save.bind(this);
    }

    openPasswordDialog() {
        this.setState({...this.state, isUpdatePasswordDialogOpen: true});
    }

    handlePasswordDialogClose() {
        this.setState({...this.state, isUpdatePasswordDialogOpen: false, updatePasswordError: undefined});
    }

    onNewPasswordChanged(event: ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, newPassword: event.target.value});
    }

    onConfirmNewPasswordChanged(event: ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, confirmNewPassword: event.target.value});
    }

    onCurrentPasswordChanged(event: ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, currentPassword: event.target.value});
    }

    onPasswordChange() {
        this.setState({...this.state, updatePasswordError: undefined}, () => {
            Account.UpdatePassword(this.state.currentPassword, this.state.newPassword, this.state.confirmNewPassword).then(() => {
                this.setState({...this.state, isUpdatePasswordSuccesVisible: true}, () => {
                    this.handlePasswordDialogClose();
                });
            }).catch(error => {
                this.setState({...this.state, updatePasswordError: error.title});
            });
        });
    }

    onEmailChange() {
        this.setState({...this.state, changeEmailError: undefined}, () => {
            Account.ChangeEmail(this.state.newEmail).then(() => {
                this.setState({...this.state, emailChangeSuccess: true}, () => {
                    setTimeout(() => {
                        this.setState({...this.state, isChangeEmailDialogOpen: false});
                    }, 2000);
                });
            }).catch(error => {
                this.setState({...this.state, changeEmailError: error});
            });
        });
    }

    openChangeEmailDialog() {
        this.setState({...this.state, isChangeEmailDialogOpen: true});
    }

    handleChangeEmailDialogClose() {
        this.setState({...this.state, isChangeEmailDialogOpen: false, changeEmailError: undefined});
    }

    componentDidMount() {
        Promise.all([
            Account.GetAccountSettings(),
            Subscription.CheckStripe()
        ]).then(promises => {
            this.setState({...this.state, ...promises[0], username: this.props.loaderData.username, isStripeManageable: promises[1]}, () => {
                this.bioReference.current?.update();
            });
        })
    }

    save() {
        Account.UpdateAccountSettings({
            bio: this.state.bio,
            darkMode: this.state.darkMode,
            allowNewLikeNotifications: this.state.allowNewLikeNotifications,
            allowNewCommentNotifications: this.state.allowNewCommentNotifications,
            allowNewPostNotifications: this.state.allowNewPostNotifications,
            allowNewQuestionNotifications: this.state.allowNewQuestionNotifications,
            allowNewPollNotifications: this.state.allowNewPollNotifications,
            allowNewTheoryNotifications: this.state.allowNewTheoryNotifications
        }).then(() => {
            this.setState({...this.state, isSettingsUpdatedSuccesVisible: true});
        }).catch(error => {
            console.error(error);
        });
    }

    onBioChange(bio:string) {
        this.setState({...this.state, bio: bio});
    }

    onAllowNewCommentNotificationsChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, allowNewCommentNotifications: event.target.checked});
    }

    onAllowNewPostNotificationsChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, allowNewPostNotifications: event.target.checked});
    }

    onAllowNewPollNotificationsChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, allowNewPollNotifications: event.target.checked});
    }

    onAllowNewQuestionNotificationsChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, allowNewQuestionNotifications: event.target.checked});
    }

    onAllowNewTheoryNotificationsChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, allowNewTheoryNotifications: event.target.checked});
    }

    onAllowNewLikeNotificationsChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, allowNewLikeNotifications: event.target.checked});
    }

    onToggleAll(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            ...this.state,
            allowNewCommentNotifications: event.target.checked,
            allowNewLikeNotifications: event.target.checked,
            allowNewPollNotifications: event.target.checked,
            allowNewPostNotifications: event.target.checked,
            allowNewQuestionNotifications: event.target.checked,
            allowNewTheoryNotifications: event.target.checked
        });
    }

    openImageFile() {
        this.fileReference.current?.open();
    }

    onImageFileChange(imageBase64:string) {
        Account.UploadProfileImage(imageBase64).then(imageUrl => {
            this.setState({...this.state, profileImageUrl: imageUrl});
        }).catch(error => {
            console.error(error);
        });
    }

    onNewEmailChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, newEmail: event.target.value});
    }

    manageSubscription() {
        Subscription.CreatePortalSession();
    }

    getSubscription() {
        router.navigate("/register/subscription");
    }

    render() {
        return(
            <Box style={containerStyle}>
                <Snackbar open={this.state.isSettingsUpdatedSuccesVisible} autoHideDuration={5000}>
                    <Alert severity="success">Succesfully updated settings.</Alert>
                </Snackbar>
                <Snackbar open={this.state.isUpdatePasswordSuccesVisible} autoHideDuration={5000}>
                    <Alert severity="success">Succesfully updated password</Alert>
                </Snackbar>
                <Card elevation={2}>

                    <Box>
                        <Stack direction="row" style={optionStackStyle}>
                            <Typography>Account settings</Typography>
                            <Spacer />
                            <Button onClick={this.save} variant="contained" color="primary">Save</Button>
                        </Stack>
                    </Box>
                    <Divider />
              
                    <Box style={avatarStyle}>
                        <Stack gap={"12px"} justifyContent="center" alignItems="center">
                            <Avatar src={this.state.profileImageUrl.length > 0 ? this.state.profileImageUrl : undefined} sx={{ bgcolor: "#DF37A5", width: 128, height: 128, fontSize: "36pt" }}>{this.state.profileImageUrl.length === 0 && (
                                this.state.username[0]
                            )}</Avatar>
                            <Button onClick={this.openImageFile} variant='outlined' fullWidth>Change Profile Picture</Button>
                            <HiddenImageInput onChange={this.onImageFileChange} ref={this.fileReference} />
                        </Stack>
                    </Box>
                    <Divider />
                    <Box style={bioStyle}>
                        <Stack direction="row" style={bioStackStyle}>
                            <Typography style={titleMediumStyle}>Bio</Typography>
                            <Box sx={{paddingLeft: "22px", flexGrow: 1}}>
                                <NakedTextInput ref={this.bioReference} onChange={this.onBioChange} value={this.state.bio} title={"Bio"} placeholder={"Tell us something about yourself..."} />
                            </Box>
                        </Stack>
                    </Box>
                    <Divider />
                    <Box>
                        <Stack>

                            <Stack style={headerStackStyle}>
                                <Typography style={titleBigStyle}>Account settings</Typography>
                                <Typography style={captionStyle}>Manage your account settings.</Typography>
                            </Stack>

                            <Stack direction="row" style={optionStackStyle}>
                                <Typography style={titleMediumStyle}>Password</Typography>
                                <Spacer />
                                <Button onClick={this.openPasswordDialog} variant="outlined">Change password</Button>
                            </Stack>

                            <Stack direction="row" style={optionStackStyle}>
                                <Typography style={titleMediumStyle}>E-mail</Typography>
                                <Spacer />
                                <Button onClick={this.openChangeEmailDialog} variant="outlined">Change e-mail</Button>
                            </Stack>
                            {this.state.isStripeManageable ? 
                                <Stack direction="row" style={optionStackStyle}>
                                    <Stack>
                                        <Typography style={titleMediumStyle}>Subscription</Typography>
                                        <Typography style={captionStyle}>{`Valid until ${this.state.subscriptionValidUntilDate.toString().split("T")[0]}`}</Typography>
                                    </Stack>
                                    <Spacer />
                                    <Button onClick={this.manageSubscription} variant="outlined">Manage subscription</Button>
                                </Stack>
                            :
                                <Stack direction="row" style={optionStackStyle}>
                                    <Stack>
                                        <Typography style={titleMediumStyle}>Subscription</Typography>
                                        <Typography style={captionStyle}>You have no subscription.</Typography>
                                    </Stack>
                                    <Spacer />
                                    <Button onClick={this.getSubscription} variant="outlined">Get Fetch Premium</Button>
                                </Stack>
                            }
                            

                        </Stack>
                    </Box>
                    <Divider />
                    <Box>
                        <Stack>
                            <Stack direction="row" style={optionStackStyle}>
                                <Stack>
                                    <Typography style={titleBigStyle}><b>Notifications</b></Typography>
                                    <Typography style={captionStyle}>Change when you are receiving notifications.</Typography>
                                </Stack>
                                <Spacer />
                                <Switch onChange={this.onToggleAll} checked={
                                    this.state.allowNewLikeNotifications &&
                                    this.state.allowNewCommentNotifications &&
                                    this.state.allowNewPollNotifications &&
                                    this.state.allowNewPostNotifications &&
                                    this.state.allowNewQuestionNotifications &&
                                    this.state.allowNewTheoryNotifications} value={this.state.allowNewLikeNotifications &&
                                        this.state.allowNewCommentNotifications &&
                                        this.state.allowNewPollNotifications &&
                                        this.state.allowNewPostNotifications &&
                                        this.state.allowNewQuestionNotifications &&
                                        this.state.allowNewTheoryNotifications} />
                            </Stack>

                            <Stack direction="row" style={optionStackStyle}>
                                <Stack>
                                    <Typography style={titleMediumStyle}>Likes</Typography>
                                    <Typography style={captionStyle}>Your post or comment is liked.</Typography>
                                </Stack>
                                <Spacer />
                                <Switch onChange={this.onAllowNewLikeNotificationsChanged} checked={this.state.allowNewLikeNotifications} value={this.state.allowNewLikeNotifications} />
                            </Stack>

                            <Stack direction="row" style={optionStackStyle}>
                                <Stack>
                                    <Typography style={titleMediumStyle}>Comments</Typography>
                                    <Typography style={captionStyle}>A reaction on your post or comment.</Typography>
                                </Stack>
                                <Spacer />
                                <Switch onChange={this.onAllowNewCommentNotificationsChanged} checked={this.state.allowNewCommentNotifications}  value={this.state.allowNewCommentNotifications} />
                            </Stack>

                            <Stack direction="row" style={optionStackStyle}>
                                <Stack>
                                    <Typography style={titleMediumStyle}>New post</Typography>
                                    <Typography style={captionStyle}>A new post is placed.</Typography>
                                </Stack>
                                <Spacer />
                                <Switch onChange={this.onAllowNewPostNotificationsChanged} checked={this.state.allowNewPostNotifications} value={this.state.allowNewPostNotifications} />
                            </Stack>

                            <Stack direction="row" style={optionStackStyle}>
                                <Stack>
                                    <Typography style={titleMediumStyle}>New question</Typography>
                                    <Typography style={captionStyle}>A new question is placed.</Typography>
                                </Stack>
                                <Spacer />
                                <Switch onChange={this.onAllowNewQuestionNotificationsChanged} checked={this.state.allowNewQuestionNotifications} value={this.state.allowNewQuestionNotifications} />
                            </Stack>

                            <Stack direction="row" style={optionStackStyle}>
                                <Stack>
                                    <Typography style={titleMediumStyle}>New theory</Typography>
                                    <Typography style={captionStyle}>A new theory is placed.</Typography>
                                </Stack>
                                <Spacer />
                                <Switch onChange={this.onAllowNewTheoryNotificationsChanged} checked={this.state.allowNewTheoryNotifications} value={this.state.allowNewTheoryNotifications} />
                            </Stack>

                            <Stack direction="row" style={optionStackStyle}>
                                <Stack>
                                    <Typography style={titleMediumStyle}>New poll</Typography>
                                    <Typography style={captionStyle}>A new poll is placed.</Typography>
                                </Stack>
                                <Spacer />
                                <Switch onChange={this.onAllowNewPollNotificationsChanged} checked={this.state.allowNewPollNotifications} value={this.state.allowNewPollNotifications} />
                            </Stack>
                        </Stack>
                    </Box>
                    {/* <Divider />
                    <Box>
                        <Stack>

                            <Stack style={headerStackStyle}>
                                <Typography style={titleBigStyle}>Other</Typography>
                                <Typography style={captionStyle}>Other settings that are not grouped.</Typography>
                            </Stack>

                            <Stack direction="row" style={optionStackStyle}>
                                <Stack>
                                    <Typography style={titleMediumStyle}>Dark mode</Typography>
                                    <Typography style={captionStyle}>A theme that is easier on the eyes.</Typography>
                                </Stack>
                                <Spacer />
                                <Switch defaultChecked />
                            </Stack>

                            <Stack direction="row" style={optionStackStyle}>
                                <Stack>
                                    <Typography style={titleMediumStyle}>Delete account</Typography>
                                    <Typography style={captionStyle}>Use with caution. This can't be undone!</Typography>
                                </Stack>
                                <Spacer />
                                <Button variant="outlined" color="error">Delete account</Button>
                            </Stack>

                        </Stack>
                    </Box> */}

                    <Divider />
                    <Box>
                        <Stack direction="row" style={optionStackStyle}>
                            <Spacer />
                            <Button variant="contained" color="primary" onClick={() => this.save()}>Save</Button>
                        </Stack>
                    </Box>
                    
                </Card>
                <Dialog open={this.state.isUpdatePasswordDialogOpen} onClose={this.handlePasswordDialogClose}>
                    <DialogTitle>Change password</DialogTitle>
                    <DialogContent>
                        {(() => {
                            if(this.state.updatePasswordError) {
                                return(
                                    <Alert severity="error">{this.state.updatePasswordError}</Alert>
                                );
                            }
                        })()}
                        <DialogContentText>
                            Changing your password may require you to re-authenticate your account.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="current-password"
                            label="Current password"
                            type="password"
                            fullWidth
                            variant="standard"
                            onChange={this.onCurrentPasswordChanged}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="new-password"
                            label="New password"
                            type="password"
                            fullWidth
                            variant="standard"
                            onChange={this.onNewPasswordChanged}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="confirm-password"
                            label="Confirm password"
                            type="password"
                            fullWidth
                            variant="standard"
                            onChange={this.onConfirmNewPasswordChanged}
                        />
                    </DialogContent>
                    <DialogActions>
                    <Button color="inherit" onClick={this.handlePasswordDialogClose}>Cancel</Button>
                    <Button onClick={this.onPasswordChange}>Change</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.isChangeEmailDialogOpen} onClose={this.handleChangeEmailDialogClose}>
                    <DialogTitle>Change e-mail</DialogTitle>
                    <DialogContent>
                        {
                            this.state.changeEmailError 
                                && <Alert severity="error">{this.state.changeEmailError}</Alert>
                        }
                        {
                            this.state.emailChangeSuccess 
                                && <Alert severity='success'>You will receive a link in you new mail address</Alert>
                        }
                        <DialogContentText>
                            Enter the new e-mail address that you want to use. 
                            We will send you a code to verify that the e-mail address belongs to you.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="new-mail"
                            label="New e-mail address"
                            value={this.state.newEmail}
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={this.onNewEmailChange}
                        />
                    </DialogContent>
                    <DialogActions>
                    <Button color="inherit" onClick={this.handleChangeEmailDialogClose}>Cancel</Button>
                    <Button onClick={this.onEmailChange}>Send code</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        )
    }
}

export default withParams(AccountSettings);