import Data from "./Data";
import { ArgumentDTO, TheoryDTO } from "./models/datatransferobjects";
import { UpdateArgumentRequest } from "./models/theory";
import { CreateArgumentRequest, CreateTheoryRequest, UpdateTheoryRequest, ViewTheoryResult } from "./models/theory";

export const theoryRoute = "/Theory";

export default abstract class Theory {
    public static async GetTheory(pollId:number) : Promise<TheoryDTO> {
        return new Promise<TheoryDTO>((resolve, reject) => {
            Data.Get<TheoryDTO>(`${theoryRoute}/${pollId}`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        })
    }

    public static async ViewTheory(identifier:string, seoFriendlyUrl:string) : Promise<ViewTheoryResult> {
        return new Promise<ViewTheoryResult>((resolve, reject) => {
            Data.Get<ViewTheoryResult>(`${theoryRoute}/${identifier}/${seoFriendlyUrl}`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        })
    }

    public static async CreateTheory(title:string, content:string, contentPreview:string, tags:string[]) : Promise<TheoryDTO> {
        return new Promise<TheoryDTO>((resolve, reject) => {
            Data.Post<CreateTheoryRequest, TheoryDTO>(theoryRoute, {title:title, content:content, contentPreview:contentPreview, tags:tags}).then(post => {
                resolve(post);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async CreateArgument(theoryId:string, content:string, contentPreview:string) : Promise<ArgumentDTO> {
        return new Promise<ArgumentDTO>((resolve, reject) => {
            Data.Post<CreateArgumentRequest, ArgumentDTO>(`${theoryRoute}/${theoryId}`, {content: content, contentPreview: contentPreview}).then(answer => {
                resolve(answer);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async UpdateArgument(theoryId:string, argumentId:string, content:string, contentPreview:string) : Promise<ArgumentDTO> {
        return new Promise<ArgumentDTO>((resolve, reject) => {
            Data.Put<UpdateArgumentRequest, ArgumentDTO>(`${theoryRoute}/${theoryId}/Argument/${argumentId}`, {content: content, contentPreview: contentPreview}).then(answer => {
                resolve(answer);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async UpdateTheory(theoryId:string, title:string, content:string, contentPreview:string, tags:string[]) : Promise<TheoryDTO> {
        return new Promise<TheoryDTO>((resolve, reject) => {
            Data.Put<UpdateTheoryRequest, TheoryDTO>(`${theoryRoute}/${theoryId}`, {title:title, content:content, contentPreview:contentPreview, tags:tags}).then(post => {
                resolve(post);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async DeleteArgument(theoryId:string, argumentId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Delete(`${theoryRoute}/${theoryId}/Argument/${argumentId}`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async DeleteTheory(theoryId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Delete(`${theoryRoute}/${theoryId}`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }
}