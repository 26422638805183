import Data from "./Data";
import { PostDTO, ContentDTO } from "./models/datatransferobjects";
import { CreatePostRequest, UpdatePostRequest, ViewPostResult } from "./models/post";

export const contentRoute = "/Content";

export default abstract class Content {

    public static async Flag(contentId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Get(`${contentRoute}/${contentId}/Flag`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async Pin(contentId:string, pinnedUntil:Date) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Get(`${contentRoute}/${contentId}/Pin/${pinnedUntil}`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async Unpin(contentId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Get(`${contentRoute}/${contentId}/Unpin`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async Favorite(contentId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Get(`${contentRoute}/${contentId}/Favorite`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async Unfavorite(contentId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Get(`${contentRoute}/${contentId}/Unfavorite`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async Hide(contentId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Get(`${contentRoute}/${contentId}/Hide`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async Unhide(contentId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Get(`${contentRoute}/${contentId}/Unhide`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async Identifier(contentId:string) : Promise<ContentDTO> {
        return new Promise((resolve, reject) => {
            Data.Get<ContentDTO>(`${contentRoute}/${contentId}`).then(content => {
                resolve(content)
            }).catch(error => {
                reject(error);
            });
        });
    }
}