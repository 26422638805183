import { Button, Card, CardActions, CardContent, Stack, TextField, Typography } from '@mui/material';
import React from 'react'
import Subscription from '../API/Subscription';
import { ROLES_MANAGE_APP, router } from '../App';
import DatePicker from '../Controls/DatePicker';
import { hasRole } from '../Helpers/AuthHelper';
import { withParams } from '../Helpers/ReactRouterHelper';
import { WhoAmIResponse } from '../API/models/account';

type UpgradeUserProps = {
    loaderData: WhoAmIResponse
}

type UpgradeUserState = {
    email:string,
    date: Date
}

class UpgradeUser extends React.Component<UpgradeUserProps, UpgradeUserState> {
    constructor(props:UpgradeUserProps) {
        super(props);
        this.state = {
            email: "",
            date: new Date()
        };

        this.onEmailChanged = this.onEmailChanged.bind(this);
        this.onDateChanged = this.onDateChanged.bind(this);
        this.onUpgrade = this.onUpgrade.bind(this);
    }

    onEmailChanged(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, email: event.currentTarget.value});
    }

    onDateChanged(date:Date) {
        this.setState({...this.state, date: date});
    }

    onUpgrade() {
        Subscription.AddSubscription(this.state.email, this.state.date).then(() => {

        });
    }

    componentDidMount(): void {
        if(!hasRole(this.props.loaderData, ROLES_MANAGE_APP)) {
            router.navigate("/");
        }
    }

    render() {
        return (
            <Card sx={{wdith: "100%", flexGrow: "1", p: 2}}>
                <CardContent>
                    <Stack gap={"12px"}>
                        <Typography variant='h5'>Upgrade a user</Typography>
                        <Typography variant='body1'>
                            Enter an e-mail address and end of subscription date to upgrade a user to premium.
                        </Typography>
                        <TextField label="User e-mail" variant="outlined" margin='dense' value={this.state.email} onChange={this.onEmailChanged}></TextField>
                        <DatePicker label='Subscription end date' onChange={this.onDateChanged}/>
                    </Stack>
                </CardContent>
                <CardActions>
                    <Button fullWidth variant='contained' onClick={this.onUpgrade}>Upgrade</Button>
                </CardActions>
            </Card>
            
        )
        
    }
}

export default withParams(UpgradeUser);