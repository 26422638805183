/* eslint-disable */
// export function generateRandomUUID(): string {
//     return (Math.random() * 1488944457420413255478064584723979).toString();
// }

export function generateRandomUUID(): string {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < 12; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}