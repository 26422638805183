import { LoginRequest, LoginResponse } from "./models/authentication";
import { getCookie, setCookie } from "./resources/cookies";
import { router } from "../App";

const urlFallback = require('../fallbackUrls.json');


export function _PROTOCOL() {
    return process.env.REACT_APP_PROTOCOL ?? urlFallback.protocol;
}

export function _HOST() {
    return process.env.REACT_APP_HOST ?? urlFallback.host;
}

export function _PORT() {
    return process.env.REACT_APP_PORT ?? urlFallback.port;
}

export default abstract class Data {
    public static async Authenticate(email:string, password:string) : Promise<LoginResponse> {
        return new Promise<LoginResponse>((resolve, reject) => {
            this.Post<LoginRequest, LoginResponse>('/login', {email: email, password: password}).then(response => {
                setCookie("auth", JSON.stringify(response), response.expiresOn);
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static GetAccessToken() : string | undefined  {
        const cookie = getCookie("auth");
        if(cookie) {
            const cookieObject = JSON.parse(cookie);
            if(cookieObject.accessToken) {
                return cookieObject.accessToken;
            }
        }
    }

    public static async Post<Request, Response>(url:string, body:Request | undefined) : Promise<Response> {
        const token = this.GetAccessToken();
        return new Promise<Response>((resolve, reject) => {
            const payload = body ? JSON.stringify(body) : "";
            const urlString = `${_PROTOCOL()}${_HOST()}:${_PORT()}${url}`;
            fetch(urlString, {
                method: "POST",
                headers: {
                    'content-length': payload.length.toString(),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    ...token && { "Authorization": `Bearer ${token}`}
                },
                body: payload
            }).then(response => {
                const contentType = response.headers.get("content-type");
                if(contentType && contentType.indexOf("application/json") !== -1) {
                    response.json().then(json => {
                        if(response.ok) {
                            resolve(json as Response);
                        }
                        else if(response.status == 401) {
                            if(typeof json === 'string') {
                                router.navigate(`/login/${json}`);
                            }
                            else {
                                router.navigate(`/login/${json.title}`);
                            }
                        }
                        else if(response.status == 402) {
                            router.navigate("/upgrade-now");
                        }
                        else {
                            reject(json);
                        }
                    }).catch(error => {
                        reject(error);
                    });
                }
                else {
                    if(response.ok) {
                        resolve({} as Response);
                    }
                    else if(response.status == 401) {
                        router.navigate("/login/Something%20went%20wrong");
                    }
                    else if(response.status == 402) {
                        router.navigate("/upgrade-now");
                    }
                    else {
                        reject();
                    }                    
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            });
        });
    }

    public static async Put<Request, Response>(url:string, body:Request) : Promise<Response> {
        const token = this.GetAccessToken();
        return new Promise<Response>((resolve, reject) => {
            const payload = body ? JSON.stringify(body) : "";
            const urlString = `${_PROTOCOL()}${_HOST()}:${_PORT()}${url}`;
            fetch(urlString, {
                method: "PUT",
                headers: {
                    'content-length': payload.length.toString(),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    ...token && { "Authorization": `Bearer ${token}`}
                },
                body: payload
            }).then(response => {
                const contentType = response.headers.get("content-type");
                if(contentType && contentType.indexOf("application/json") !== -1) {
                    response.json().then(json => {
                        if(response.ok) {
                            resolve(json as Response);
                        }
                        else if(response.status == 401) {
                            if(typeof json === 'string') {
                                router.navigate(`/login/${json}`);
                            }
                            else {
                                router.navigate(`/login/${json.title}`);
                            }
                        }
                        else if(response.status == 402) {
                            router.navigate("/upgrade-now");
                        }
                        else {
                            reject(json);
                        }
                    }).catch(error => {
                        reject(error);
                    });
                }

                else {
                    if(response.ok) {
                        resolve({} as Response);
                    }
                    else if(response.status == 401) {
                        router.navigate("/login/Something%20went%20wrong");
                    }
                    else if(response.status == 402) {
                        router.navigate("/upgrade-now");
                    }
                    else {
                        reject();
                    }                    
                }
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async Get<Type>(url:string) : Promise<Type> {
        const token = this.GetAccessToken();
        return new Promise<Type>((resolve, reject) => {
            const urlString = `${_PROTOCOL()}${_HOST()}:${_PORT()}${url}`;
            fetch(urlString, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    ...token && { "Authorization": `Bearer ${token}`}
                }
            }).then(response => {
                const contentType = response.headers.get("content-type");
                if(contentType && contentType.indexOf("application/json") !== -1) {
                    response.json().then(json => {
                        if(response.ok) {
                            resolve(json as Type);
                        }
                        else if(response.status == 401) {
                            router.navigate("/login");
                        }
                        else if(response.status == 402) {
                            router.navigate("/upgrade-now");
                        }
                        else {
                            reject(json);
                        }
                    }).catch(error => {
                        reject(error);
                    });
                }
                else {
                    if(response.ok) {
                        resolve({} as Type);
                    }
                    else if(response.status == 401) {
                        router.navigate("/login");
                    }
                    else if(response.status == 402) {
                        router.navigate("/upgrade-now");
                    }
                    else {
                        reject();
                    }                    
                }
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async Delete<Type>(url:string) : Promise<Type> {
        const token = this.GetAccessToken();
        return new Promise<Type>((resolve, reject) => {
            const urlString = `${_PROTOCOL()}${_HOST()}:${_PORT()}${url}`;
            const requestUrl = new URL(urlString);
            fetch(urlString, {
                method: "DELETE",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {
                response.json().then(json => {
                    if(response.ok) {
                        resolve(json as Type);
                    }
                    else if(response.status == 401) {
                        router.navigate("/login");
                    }
                    else if(response.status == 402) {
                        router.navigate("/upgrade-now");
                    }
                    else {
                        reject(json);
                    }
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        });
    }
}