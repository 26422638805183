import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import EditorSideBarPoll from './EditorSideBarPoll';
import EditorSideBarPost from './EditorSideBarPost';
import EditorSideBarQuestion from './EditorSideBarQuestion';
import EditorSideBarTheory from './EditorSideBarTheory';
import { ContentType } from '../../../Layout/EditorLayout';

type EditorSideBarState = {
    loading:boolean
}

type EditorSideBarProps = {
    onSave:Function,
    contentType: ContentType
}

export interface ISideBar {
    save():any
};

export const sidebarPaddingStyle: React.CSSProperties = {
    padding: "8px 16px",
    textAlign: "left"
}

export default class EditorSideBar extends React.Component<EditorSideBarProps, EditorSideBarState> {
    metdataRef:React.RefObject<any>;
    constructor(props:EditorSideBarProps) {
        super(props);
        this.state = {loading: false};
        this.save = this.save.bind(this);
        this.metdataRef = React.createRef();
    }

    save() {
        this.setState({...this.state, loading: true}, () => {
            if(this.metdataRef.current.save) {
                const metadata = this.metdataRef.current.save();
                const contentType = this.props.contentType
                this.props.onSave({...metadata, contentType: contentType }, () => {
                    this.setState({...this.state, loading: false});
                });
            }
        })
    }

    render() {

        let sideBarWidget = <EditorSideBarPost />

        if(this.props.contentType === "post") {
            sideBarWidget = <EditorSideBarPost ref={this.metdataRef} />;
        }

        else if(this.props.contentType === "poll") {
            sideBarWidget = <EditorSideBarPoll ref={this.metdataRef} />;
        }

        else if(this.props.contentType === "question") {
            sideBarWidget = <EditorSideBarQuestion ref={this.metdataRef} />;
        }

        else if(this.props.contentType === "theory") {
            sideBarWidget = <EditorSideBarTheory ref={this.metdataRef} />;
        }

        return(
            <Stack>
                <Stack gap={3} style={sidebarPaddingStyle}>
                    <Typography style={{textAlign: "center"}}>Create a {this.props.contentType}</Typography>
                </Stack>
                {sideBarWidget}
                <Box style={{padding: "8px 16px 16px 16px"}}>
                    <Button disabled={this.state.loading} startIcon={this.state.loading ? <CircularProgress size={20} color="inherit" /> : undefined}  fullWidth variant="contained" onClick={this.save}>Publish</Button>
                </Box>
            </Stack>
        )
    }
}