import Box from '@mui/material/Box';
import React from 'react';
import TVChart from '../../Charts/TVChart';

export default class FetchEditorChartBlock extends React.Component {
    constructor(props) {
        super(props);
        this.reference = React.createRef();
        this.getData = this.getData.bind(this);
        if(this.props.data) {
            this.data = JSON.parse(this.props.data);
        }
    }

    componentDidMount() {
        if(this.data) {
            this.reference.current.loadChart(this.data);
        }
    }

    async getData() {
        const data = await this.reference.current.getData();
        return data;
    }

    render() {
        return(
            <Box>
                <TVChart ref={this.reference}/>
            </Box>
        )
    }
}