import { Box } from "@mui/system";
import React from "react";
import FeedItemCard from "../../Feed/FeedItemCard";
import FeedController from "../../../API/FeedController";

export default class FetchViewerPostEmbedBlock extends React.Component {
    constructor(props) {
        super(props);
        this.data = JSON.parse(this.props.data);
        this.state = {
            isLoaded: false,
            DTO: null
        };
    }

    componentDidMount() {
        FeedController.GetPostPreview(this.data.identifer).then(postPreview => {
            this.setState({...this.state, DTO: postPreview, isLoaded: true});
        });
    }

    render() {
        return(
            <Box>
                {this.state.isLoaded 
                ? <FeedItemCard item={this.state.DTO} />
                : <div>Loading...</div> }
            </Box>
        )
    }
}