import { LibrarySymbolInfo, ResolutionString, IDatafeedChartApi, HistoryCallback, PeriodParams, ErrorCallback, SubscribeBarsCallback } from "../../public/charting_library/datafeed-api";
import {configurationDataExample} from "./configurationData";
import DataFeedAPI from "../API/DataFeedAPI";
import { isNumber } from "../Helpers/Formatters";

export type SimpleSymbolInfo = {
    symbol: string,
    full_name: string,
    description: string,
    exchange: string,
    type: string,
    id: number
}

export default class DataFeed implements IDatafeedChartApi {

    hasCalledHistory:boolean;

    constructor() {
        this.hasCalledHistory = false;
    }

    onReady(callback: Function) {
        setTimeout(() => {
            callback(configurationDataExample);
        }, 0);
        
    }

    async getAllSymbols(): Promise<SimpleSymbolInfo[]> {
        return new Promise<SimpleSymbolInfo[]>((resolve, reject) => {
            DataFeedAPI.GetSymbols().then(symbols => {
                const result: SimpleSymbolInfo[] = [];
                for(const symbol of symbols) {
                    result.push({
                        symbol: symbol.name,
                        full_name: symbol.fullName,
                        description: symbol.description,
                        exchange: symbol.exchange,
                        type: symbol.type,
                        id: symbol.id
                    });
                }

                resolve(result);
            });
        });
    }

    async searchSymbols(userInput: string, exchange: string, symbolType: string, onResultReadyCallback: Function): Promise<void> {
        const symbols = await this.getAllSymbols();
        const newSymbols = symbols.filter(symbol => {
            const isExchangeValid = exchange === '' || symbol.exchange === exchange;
            const isFullSymbolContainsInput = symbol.full_name
                .toLowerCase()
                .indexOf(userInput.toLowerCase()) !== -1;
            return isExchangeValid && isFullSymbolContainsInput;
        });
        onResultReadyCallback(newSymbols);
        return;
    }

    async resolveSymbol(symbolName: string, onSymbolResolvedCallback: Function, onResolveErrorCallback: Function, extension: any): Promise<void> {
        this.getAllSymbols().then(symbols => {
            const symbolItem = symbols.find(({
                full_name,
                id
            }) => isNumber(symbolName) ? parseInt(symbolName) === id : full_name.toLowerCase() === symbolName.toLowerCase());
            if (!symbolItem) {
                onResolveErrorCallback('cannot resolve symbol');
                return;
            }
            const symbolInfo: LibrarySymbolInfo = {
                ticker: symbolItem.id.toString(),
                name: symbolItem.symbol,
                full_name: symbolItem.full_name,
                listed_exchange: "fetch",
                format: "price",
                description: symbolItem.description,
                type: symbolItem.type,
                session: '24x7',
                timezone: 'Etc/UTC',
                exchange: symbolItem.exchange,
                minmov: 1,
                pricescale: 100,
                has_intraday: false,
                has_no_volume: true,
                has_weekly_and_monthly: false,
                supported_resolutions: ["1D" as ResolutionString],
                volume_precision: 2,
                data_status: "streaming",
            };
            onSymbolResolvedCallback(symbolInfo);
        });
    }

    getBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, periodParams: PeriodParams, onResult: HistoryCallback, onError: ErrorCallback): void {
        if(symbolInfo.ticker) {
            DataFeedAPI.GetBars2(+symbolInfo.ticker, periodParams).then(bars => {
                for(let bar of bars.bars) {
                    bar.time = bar.time * 1000;
                }

                if(bars.bars.length === 0 && bars.nextTime > 0) {
                    onResult(bars.bars, {noData: true, nextTime: bars.nextTime * 1000});
                }

                else if(bars.bars.length === 0) {
                    onResult(bars.bars, {noData: true});
                }

                else {
                    onResult(bars.bars);
                }
            });
        }
    }

    subscribeBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, onTick: SubscribeBarsCallback, listenerGuid: string, onResetCacheNeededCallback: () => void): void {

    }


	unsubscribeBars(listenerGuid: string): void {

    }
}