/*
 * General utils for managing cookies in Typescript.
 */
export function setCookie(name: string, val: string, expirery:string | undefined) {
    let date = new Date();
    const value = val;

    // Set it expire in 7 days
    if(expirery) {
        date = new Date(expirery);
    }
    else {
        date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));
    }

    // Set it
    if(document) {
        const cookie = `${name}=${value}; expires${date.toUTCString()}; path=/; domain=${window.location.hostname}`;
        document.cookie = cookie;
    }
}

export function getCookie(name: string): string | undefined {
    if(document) {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        

        if (parts && parts.length == 2) {
            let part = parts.pop();
            if(part) {
                return part.split(";").shift();
            }
        }
    }
}

export function deleteCookie(name: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    if(document) {
        const cookie = `${name}=; expires=${date.toUTCString()}; path=/; domain=${window.location.hostname}`;
        document.cookie = cookie;
    }
}