import React from 'react';

import EmailIcon from '@mui/icons-material/Email';
import {Card, Stack, Typography, Box, Button} from "@mui/material";
import { router } from '../App';

export default class ConfirmEmail extends React.Component {

    goToHome() {
        router.navigate("/");
    }

    render(): React.ReactNode {
        return(
            <Card style={{width: "100%"}}>
                <Stack style={{padding: "64px 32px"}} spacing={3}>
                    <Stack direction="row" style={{justifyContent: "center", alignItems: "center"}}>
                        <EmailIcon sx={{fontSize: "72pt", color: "var(--secondary-color)"}}  />
                    </Stack>
                    <Typography variant='h4'>Great!</Typography>
                    <Typography variant='body1'>
                        Your registration was successful please continue by confirming your e-mail address.
                    </Typography>
                    <Box>
                        <Button onClick={this.goToHome}>Go back to the feed</Button>
                    </Box>
                </Stack>
            </Card>
        )
    }
}