import Box from '@mui/material/Box';
import React from 'react';
import FetchViewerTextBlock from './FetchViewerTextBlock';
import FetchViewerChartBlock from './FetchViewerChartBlock';
import FetchViewerYoutubeBlock from './FetchViewYoutubeBlock';
import FetchViewerImageBlock from './FetchViewerImageBlock';
import FetchViewerChartJsBlock from './FetchViewerChartJsBlock';
import FetchViewerPostEmbedBlock from './FetchViewerPostEmbedBlock';

export default class FetchViewerBlock extends React.Component {
    render() {
        let block = null;

        if(this.props.type === 'text') {
            block = <FetchViewerTextBlock id={this.props.id} data={this.props.data} />
        }

        else if(this.props.type === 'chart') {
            block = <FetchViewerChartBlock id={this.props.id} data={this.props.data} />
        }

        else if(this.props.type === "youtube") {
            block = <FetchViewerYoutubeBlock id={this.props.id} data={this.props.data} />
        }

        else if(this.props.type === "image") {
            block = <FetchViewerImageBlock id={this.props.id} data={this.props.data} />
        }

        else if(this.props.type === "chartjs") {
            block = <FetchViewerChartJsBlock id={this.props.id} data={this.props.data} />
        }

        else if(this.props.type === "postembed") {
            block = <FetchViewerPostEmbedBlock id={this.props.id} data={this.props.data} />
        }

        return (<Box>{block}</Box>);
    }
}