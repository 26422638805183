import { Box, Card, Stack, Skeleton } from '@mui/material';
import React from 'react';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Spacer from '../../Helpers/Spacer';
import {defaultElevation} from "../../Theme/Theme";

export default class PostCardShadow extends React.Component {
    render() {
        return(
            <Card style={{ flexGrow: 1}} elevation={defaultElevation}>
                <Box sx={{padding: "16px"}}>
                    <Stack direction="row" spacing={2} sx={{alignItems: "center"}}>
                        <Skeleton animation="wave" variant="circular" width={40} height={40} />
                        <Stack>
                            <Skeleton key={1} animation="wave" height={18} width="100px" />   
                            <Skeleton key={2} animation="wave" height={18} width="150px" />
                        </Stack>
                    </Stack>
                </Box>
                <Skeleton sx={{ height: 300 }} animation="wave" variant="rectangular" />
                <CardContent style={{textAlign: "left"}}>
                    <Stack spacing={2}>
                        <Stack>
                            <Skeleton key={1} animation="wave" height={30} width="200px" />     
                            <Skeleton key={2} animation="wave" height={18} width="150px" />    
                        </Stack>
                        <Stack>
                            <Skeleton key={1} animation="wave" height={18} width="100%" />  
                            <Skeleton key={2} animation="wave" height={18} width="100%" />  
                            <Skeleton key={3} animation="wave" height={18} width="80%" />  
                        </Stack>
                    </Stack>
                </CardContent>
                <CardActions sx={{p: "0 16px 16px 16px", gap: "8px"}}>
                    <Skeleton animation="wave" height={50} width={60} />      
                    <Skeleton animation="wave" height={50} width={60} />      
                    <Spacer />
                </CardActions>
            </Card>
        )
    }
}
