import { Box, Button, Card, Stack } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FeedItemDTO } from '../../API/models/datatransferobjects';
import {router} from "../../App";
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AuthorReputation from '../Author/AuthorReputation';
import Tags from './Tag';
import Spacer from '../../Helpers/Spacer';

import {defaultElevation} from "../../Theme/Theme";
import {FormatDate} from '../../Helpers/Formatters';
import LikeTag from '../../Controls/LikeTag';
import CommentTag from '../../Controls/CommentTag';
import FeedItemCardMenu from './FeedItemCardMenu';
import PollVote from '../Poll/PollVote';
import AuthorPopover from '../Author/AuthorPopover';
import Content from '../../API/Content';
import { WhoAmIResponse } from '../../API/models/account';

import NewspaperIcon from '@mui/icons-material/Newspaper';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import PollIcon from '@mui/icons-material/Poll';
import QuizIcon from '@mui/icons-material/Quiz';
import CheckIcon from '@mui/icons-material/Check';

type PostCardProps = {
    item:FeedItemDTO,
    hasHover?:boolean,
    whoAmI: WhoAmIResponse
}

type PostCardState = {
    isMenuOpen:boolean,
    menuAnchor?:Element,
    hasHover:boolean
}

export default class FeedItemCard extends React.Component<PostCardProps, PostCardState> {
    static defaultProps = {
        hasHover: true
    }

    constructor(props:PostCardProps) {
        super(props);
        this.state = {
            isMenuOpen: false,
            hasHover: this.props.hasHover ?? false
        };

        this.openContextMenu = this.openContextMenu.bind(this);
        this.closeContextMenu = this.closeContextMenu.bind(this);
        this.gotoItem = this.gotoItem.bind(this);
        this.gotoProfile = this.gotoProfile.bind(this);
        this.getTopTag = this.getTopTag.bind(this);
    }

    openContextMenu(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            ...this.state,
            isMenuOpen: true,
            menuAnchor: event.currentTarget
        });
    }

    closeContextMenu() {
        this.setState({
            ...this.state,
            isMenuOpen: false
        });
    }

    gotoItem() {
        if(this.props.item.type !== "comment") {
            router.navigate(`/${this.props.item.type}/${this.props.item.identifier}/${this.props.item.title.replace(" ", "-")}`)
        }
        else {
            Content.Identifier(this.props.item.identifier).then(content => {
                router.navigate(`/${content.type}/${content.id}/${content.title.replace(" ", "-")}`)
            })
        }
    }

    gotoProfile() {
        router.navigate(`/profile/${this.props.item.author.name}`);
    }

    onDelete() {
        window.location.reload();
    }

    getTopTag() : JSX.Element {
        switch(this.props.item.type) {
            case 'theory':
                return(
                    <Box style={{color: "#ffffff", backgroundColor: "#241468", display: "flex", justifyContent: "center", alignItems: "center", height: "32px", textAlign: "center"}}>
                        <Stack spacing={2} direction={'row'} alignItems={'center'}>
                            <AutoStoriesIcon />
                            <Typography variant="caption">THEORY</Typography>
                        </Stack>
                    </Box>
                )
            case 'poll':
                return(
                    <Box style={{color: "#ffffff", backgroundColor: "#9F0D7F", display: "flex", justifyContent: "center", alignItems: "center", height: "32px", textAlign: "center"}}>
                        <Stack spacing={2} direction={'row'} alignItems={'center'}>
                            <PollIcon />
                            <Typography variant="caption">POLL</Typography>
                        </Stack>
                    </Box>
                )
            case 'question':
                return(
                    <Box style={{color: "#ffffff", backgroundColor: "#F79BD3", display: "flex", justifyContent: "center", alignItems: "center", height: "32px", textAlign: "center"}}>
                        <Stack spacing={2} direction={'row'} alignItems={'center'}>
                            <QuizIcon />
                            <Typography variant="caption">QUESTION</Typography>
                        </Stack>
                    </Box>
                )
            default:
                return(
                    <Box style={{color: "#ffffff", backgroundColor: "var(--primary-color)", display: "flex", justifyContent: "center", alignItems: "center", height: "32px", textAlign: "center"}}>
                        <Stack spacing={2} direction={'row'} alignItems={'center'}>
                            <NewspaperIcon />
                            <Typography variant="caption">POST</Typography>
                        </Stack>
                        
                    </Box>
                )
        }        
    }
    
    render() {
        const isPremium = this.props.item.display === "premium";
        const isHidden = this.props.item.display === "hidden";
        console.log(isPremium);
        const blurStyle:React.CSSProperties = {
            filter: "blur(4px)"
        };

        const opacity = isHidden ? 0.325 : 1;

        return(
            <Card className={isHidden ? 'feed-item-card hidden' : 'feed-item-card'} style={{ flexGrow: 1, opacity: opacity }} elevation={defaultElevation}>
                {isPremium && (
                    <Box style={{color: "#ffffff", backgroundColor: "var(--primary-color)", display: "flex", justifyContent: "center", alignItems: "center", height: "32px", textAlign: "center"}}>
                        <Typography variant="caption">PREMIUM</Typography>
                    </Box>
                )}
                {isHidden && (
                    <Box style={{color: "#ffffff", backgroundColor: "#343434", display: "flex", justifyContent: "center", alignItems: "center", height: "32px", textAlign: "center"}}>
                        <Typography variant="caption">HIDDEN</Typography>
                    </Box>
                )}
                {!isHidden && !isPremium && this.getTopTag()}
                <CardHeader
                    className='clickable-header'
                    avatar={
                        this.state.hasHover ? 
                        <AuthorPopover whoAmI={this.props.whoAmI} author={this.props.item.author}>
                            <Avatar src={this.props.item.author.profileImageUrl.length > 0 ? this.props.item.author.profileImageUrl : undefined} onClick={this.gotoProfile} sx={{ bgcolor: red[500] }} aria-label="recipe">
                                {this.props.item.author.name[0]}
                            </Avatar>
                        </AuthorPopover>

                        : 
                        <Avatar src={this.props.item.author.profileImageUrl.length > 0 ? this.props.item.author.profileImageUrl : undefined} onClick={this.gotoProfile} sx={{ bgcolor: red[500] }} aria-label="recipe">
                            {this.props.item.author.name[0]}
                        </Avatar>
                    }
                    title={<div onClick={this.gotoProfile} style={{textAlign: "left", fontWeight: "bold"}}>{this.props.item.author.name}</div>}
                    subheader={<div style={{textAlign: "left"}}><AuthorReputation author={this.props.item.author}/></div>}
                    action={
                        <Stack direction="row">
                            <Tags tags={this.props.item.tags}/>
                            {!isPremium && (<IconButton onClick={this.openContextMenu}>
                                <MoreVertIcon />
                            </IconButton>)}
                        </Stack>
                    }
                />
                {
                    this.props.item.thumbnail && 
                        <CardMedia
                            component="img"
                            height="300"
                            image={this.props.item.thumbnail}
                            alt="Image"
                            style={isPremium ? blurStyle : {}}
                        />
                }
                <CardContent style={{textAlign: "left", ...(isPremium && blurStyle)}}>
                    <Stack alignItems={"center"} direction={"row"} spacing={2}>
                        {this.props.item.isCompleted && (
                            <CheckIcon color='success' fontSize='large' />
                        )}
                        <Box style={{flexGrow: 1}}>
                            <Typography variant="h5" color="text.secondary">
                                <NavLink style={{color: 'rgba(0, 0, 0, 0.87)'}} to={`/${this.props.item.type}/${this.props.item.identifier}/${this.props.item.title.replace(" ", "-")}`}>
                                    {this.props.item.title}
                                </NavLink>
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                <Typography variant="body2" color="text.secondary">
                                    {FormatDate(new Date(this.props.item.date))}
                                </Typography>
                            </Stack>
                            <Typography sx={{marginTop:1}} variant="body2" color="text.secondary">
                                {this.props.item.preview}...
                            </Typography>
                            {this.props.item.type === "poll" && (
                                <PollVote pollId={this.props.item.id} />
                            )}
                        </Box>
                    </Stack>
                </CardContent>
                <CardActions sx={{p: "0 16px 16px 16px", gap: "8px"}}>
                    {this.props.item.type !== "question" && this.props.item.type !== "theory" && this.props.item.type !== "comment" && (this.props.item.type === "post" && !isPremium) &&
                        <LikeTag likes={this.props.item.likes} hasLiked={this.props.item.hasLiked} contentId={this.props.item.id}  />
                    }
                    {this.props.item.type === "comment" &&
                        <LikeTag likes={this.props.item.likes} hasLiked={this.props.item.hasLiked} commentId={this.props.item.id}  />
                    }
                    {this.props.item.type !== "comment" && 
                        <CommentTag comments={this.props.item.comments} onClick={this.gotoItem} />
                    }
                    
                    <Spacer />
                    <Button className='read-more' onClick={this.gotoItem} variant='contained'>Read more</Button>
                </CardActions>
                <FeedItemCardMenu whoAmI={this.props.whoAmI} onDelete={this.onDelete} hasFavorited={this.props.item.hasFavorited} contentId={this.props.item.id} author={this.props.item.author} isHidden={this.props.item.display === "hidden"} isMine={this.props.item.isMine} onClose={this.closeContextMenu} open={this.state.isMenuOpen} anchor={this.state.menuAnchor} />
            </Card>
        )
    }
}
