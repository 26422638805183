import React from 'react';
import {ethers} from 'ethers';

import { Button, Stack, TextField, Box, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
interface LinearProgressWithLabelProps extends LinearProgressProps {
    count: number;
    total: number;
  }
  
  function LinearProgressWithLabel(props: LinearProgressWithLabelProps) {
    const { count, total, ...linearProgressProps } = props;
    const percentage = (count / total) * 100;
  
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" value={percentage} {...linearProgressProps} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(percentage)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
  interface LinearWithValueLabelProps {
    count: number;
    total: number;
  }
  
  function LinearWithValueLabel(props: LinearWithValueLabelProps) {
    const { count, total } = props;
    const [progress, setProgress] = React.useState((count / total) * 100);
  
    React.useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? (count / total) * 100 : prevProgress + 10));
      }, 800);
  
      return () => {
        clearInterval(timer);
      };
    }, [count, total]);
  
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgressWithLabel count={count} total={total} value={progress} />
      </Box>
    );
  }


import { Helmet, HelmetProvider } from 'react-helmet-async';
import { error } from 'console';

type PortfolioState = {
    walletAddress: string,
    balance: number,
    ethWallet: Array<object>,
    plsWallet: Array<object>,
    network: String,
    nativeCoinBalance: number,
    count: number,
    total: number

}

const ERC20_ABI = [
    {
        "constant": true,
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "name": "",
                "type": "string"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_spender",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_from",
                "type": "address"
            },
            {
                "name": "_to",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "decimals",
        "outputs": [
            {
                "name": "",
                "type": "uint8"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "_owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "name": "balance",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "name": "",
                "type": "string"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_to",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "_owner",
                "type": "address"
            },
            {
                "name": "_spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "payable": true,
        "stateMutability": "payable",
        "type": "fallback"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "name": "spender",
                "type": "address"
            },
            {
                "indexed": false,
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    }
]


class Portfolio extends React.Component <{}, PortfolioState> {
    constructor(props:any) {
        super(props);
        this.state = {
            walletAddress: "undefined",
            balance: 0 ,
            ethWallet: [],
            plsWallet: [],
            network: "Ethereum",
            nativeCoinBalance: 0,
            count: 0,
            total: 0
        }
        this.onEthWalletChange = this.onEthWalletChange.bind(this);
        this.onPlsWalletChange = this.onPlsWalletChange.bind(this);
        this.connectWallet = this.connectWallet.bind(this);
        this.getUserNativeCoinBalance = this.getUserNativeCoinBalance.bind(this);
        this.getNetworkFromMetaMask = this.getNetworkFromMetaMask.bind(this);
        this.handleWalletChange = this.handleWalletChange.bind(this);
    }

    async componentDidMount() { 
        await this.connectWallet()
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<PortfolioState>, snapshot?: any): void {
        if (prevState.network !== this.state.network) {
            if (this.state.network === 'Ethereum') {
                this.onEthWalletChange(this.state.walletAddress, this.state.network)
            } else {
                this.onPlsWalletChange(this.state.walletAddress)
            }
        }
        
    }

    async getBalanceOfTokenByAddress(address:string, provider:any, walletAddress:string) {
        try {
            const contract = new ethers.Contract(address, ERC20_ABI, provider)
            return await contract.balanceOf(walletAddress) // your own address
        }
            
        catch(err) {
            console.log(err)
        }

    }

    async getAssetPriceByAddress(address:string, network:string='ethereum') {
        const response = await fetch(`https://api.dexscreener.com/latest/dex/tokens/${address}`, {'method': 'GET'});
        const data = await response.json();
        if (!data?.pairs) return {}
        // get the first element of the array where chainId is network
        return data.pairs.filter((pair:any) => pair.chainId === network)[0]
    }

    async getAssetPriceByTicker(token:string, network:string='ethereum') {
        const response = await fetch(`https://api.dexscreener.com/latest/dex/search?q=${token}%20USDC`, {'method': 'GET'});
        const data = await response.json();
        if (!data?.pairs) return {}
        // get the first element of the array where chainId is network
        return data.pairs.filter((pair:any) => pair.chainId === network)[0]
    }

    async onEthWalletChange(walletAddress:string, network:any) {
        if (network !== '1') return;
        let tokenData = require('./ethcoins.json')
        
        // retrieve last updated time from local storage and convert it to an int
        const lastUpdated = parseInt(localStorage.getItem('lastUpdatedETH') || '0')
        // check if last updated is less than 1 hour ago
        if (lastUpdated && lastUpdated > (new Date().getTime() - 3600000 * 7)) {
            // if it is, retrieve data from local storage
            const ethWallet = JSON.parse(localStorage.getItem('ethWallet') || '[]')
            tokenData = {'tokens': ethWallet}
        }
        let foundTokens = [];
        let total = 0;
        const provider = new ethers.BrowserProvider(window.ethereum)
        this.setState({'total': tokenData.tokens.length})
        let count = 0;
        for (const token of tokenData.tokens) {
            count += 1
            this.setState({'count': count})
            if (token.address === '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2') continue; // ethereum will be handled below

            const res = await this.getBalanceOfTokenByAddress(token.address, provider, walletAddress)
            if (!res) continue
            const priceData = await this.getAssetPriceByAddress(token.address, 'ethereum')
            if (!priceData) continue;
            const usdValue = res.toString() / 10 ** token.decimals * priceData.priceUsd
            total += usdValue
            foundTokens.push(
                {
                    name: token.name, 
                    symbol: token.symbol, 
                    balance: res.toString() / 10 ** token.decimals, 
                    img: token.logoURI || token.img, 
                    address: token.address,
                    assetPrice: priceData.priceUsd,
                    priceChange: priceData?.priceChange?.h24,
                    decimals: token.decimals,
                    
                }
            )
        }
        const nativeCoinBalance = await this.getUserNativeCoinBalance(walletAddress)
        const nativeCoinPrice = await this.getAssetPriceByTicker('ETH', 'ethereum')
        foundTokens.push(
            {
                name: 'Ethereum', 
                symbol: 'ETH', 
                balance: nativeCoinBalance, 
                img: 'https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628', 
                address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', // Technically speaking Weth
                assetPrice: nativeCoinPrice.priceUsd,
                priceChange: nativeCoinPrice?.priceChange?.h24
            }
        )
        total += nativeCoinBalance  * nativeCoinPrice.priceUsd
        
        // empty ethWallet and Ethbalance before updating
        localStorage.removeItem('ethWallet')
        localStorage.removeItem('ethBalance')
        localStorage.removeItem('lastUpdatedETH')

        localStorage.setItem('ethWallet', JSON.stringify(foundTokens))
        localStorage.setItem('ethBalance', JSON.stringify(total))
         // get current time in ms
        localStorage.setItem('lastUpdatedETH', JSON.stringify(new Date().getTime()))

        // new array with unique items only from foundTokens. filter by token.name in foundtokens
        const uniqueArray = foundTokens.filter((obj, index, array) => 
        array.findIndex(o => o.name === obj.name) === index
        );

        this.setState({'balance': total})
        this.setState({'ethWallet': uniqueArray})
    }

    async onPlsWalletChange(walletAddress:string) {
        // retrieve last updated time from local storage and convert it to an int
        const lastUpdated = parseInt(localStorage.getItem('lastUpdatedPLS') || '0')
        // check if last updated is less than 1 hour ago
        if (lastUpdated && lastUpdated > (new Date().getTime() - 3600000)) {
            // if it is, retrieve data from local storage
            const plsWallet = JSON.parse(localStorage.getItem('plsWallet') || '[]')
            const balance = JSON.parse(localStorage.getItem('plsBalance') || '0')
            this.setState({'balance': balance})
            this.setState({'plsWallet': plsWallet})
            return
        }

        // TODO skip nan values
        // TODO get images somehow
        let pulseTokens = []
        const response = await fetch(`https://api.scan.pulsechain.com/api/v2/addresses/${walletAddress}/token-balances`, {'method': 'GET'});
        let total = 0;
        const data = await response.json();
        const imageData = require('./plscoins.json')
        for (const token of data){
            const priceData = await this.getAssetPriceByAddress(token.token.address, 'pulsechain')
            // check for Nan values
            if (!priceData?.priceUsd) continue;
            total += Number(priceData.priceUsd * (token.value / 10 ** token.token.decimals))
            let img = imageData[token.token.name.toUpperCase()] ? imageData[token.token.name.toUpperCase()] : imageData[token.token.symbol.toUpperCase()]
            if (!img) img = 'https://upload.wikimedia.org/wikipedia/commons/5/5a/Black_question_mark.png'
            pulseTokens.push(
                {
                    name: token.token.name, 
                    symbol: token.token.symbol, 
                    balance:(token.value / 10 ** token.token.decimals), 
                    address: token.token.address,
                    assetPrice: priceData.priceUsd,
                    priceChange: priceData?.priceChange?.h24,
                    img: img
                }
            )
        }
        const nativeCoinBalance = await this.getUserNativeCoinBalance(walletAddress)
        const nativeCoinPrice = await this.getAssetPriceByTicker('PLS', 'pulsechain')
        pulseTokens.push(
            {
                name: 'PulseChain', 
                symbol: 'PLS', 
                balance: nativeCoinBalance, 
                img: 'https://assets.coingecko.com/coins/images/25666/standard/11145.png?1696524795', 
                address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', 
                assetPrice: nativeCoinPrice.priceUsd,
                priceChange: nativeCoinPrice?.priceChange?.h24
            }
        )
      
        total += nativeCoinBalance  * nativeCoinPrice.priceUsd

        localStorage.setItem('plsWallet', JSON.stringify(pulseTokens))
        localStorage.setItem('plsBalance', JSON.stringify(total))
        // get current time in ms
        localStorage.setItem('lastUpdatedPLS', JSON.stringify(new Date().getTime()))


        this.setState({'balance': total})
        this.setState({'plsWallet': pulseTokens})
    }

    async connectWallet() {
        if (!window.ethereum) alert('Please install metamask to use this feature');
        window.ethereum.request({'method': 'eth_requestAccounts'})
        .then(async (result: any) => {
            await this.handleWalletChange(result[0])
            const network = await this.getNetworkFromMetaMask();
            if (this.state.network === 'Ethereum') {
                this.onEthWalletChange(result[0], network)
            } else {
                this.onPlsWalletChange(result[0])
            }

        })
    }

    async handleWalletChange(address:string) {
        this.setState({'walletAddress': address})
    }

    async getNetworkFromMetaMask() {

        return window.ethereum.request({'method': 'net_version'})
        .then((network:any) => {
            if (network === '1') this.setState({'network': 'Ethereum'})
            if (network === '369') this.setState({'network': 'PulseChain'})
            return network;
        })
    }

    async getUserNativeCoinBalance(address:string) {
        if (this.state.walletAddress === 'undefined') return;
        const r = await window.ethereum.request({'method': 'eth_getBalance', 'params': [address, 'latest']})
        .then((balance:any) => {
            this.setState({'nativeCoinBalance': parseFloat(ethers.formatEther(balance))})
            return parseFloat(ethers.formatEther(balance))
        })
        return r
    }

    getSortedArray(arr: Array<object>) {
        
        // the array is sorted by the balance * usdValue  of the token
        return arr.sort((a:any, b:any) => {
            return (b.balance * b.assetPrice) - (a.balance * a.assetPrice)
        })
        
    }

    formatNumber(num:number) {
        if(!num) return;
        // format number in usd value
        return num.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

    }        
    
    render() {        
        return (
            <HelmetProvider>
                <Helmet>
                    <meta name='title' property="og:title" content='portfolio'/>
                    <meta name='type' property="og:type" content="portfolio"/>
                    {/* <meta name='image' property="og:image" content={this.state.post.thumbnail} /> */}
                    <meta name='description' property="og:description" content="View this post on Fetch.team!" />
                    <meta name='url' property="og:url" content={window.location.href} />
                    <meta name="twitter:card" property='twitter:card' content="summary_large_image" />
                    <meta name='section' property='article:section ' content='Financial education'/>
                    <meta name='sitename' property='og:site_name ' content='Fetch.team'/>
                </Helmet>
                <Stack gap={"24px"} sx={{width: "100%"}}>
                    <div className='portfolio-display-container'>
                        <div className='portfolio-display'>

                            <div className='portfolio-display-header'>
                                <div className='portfolio-display-header-balance'>
                                    <Typography variant='h5'>Total Balance</Typography>
                                    <Typography variant='h3'>{this.formatNumber(this.state.balance)}</Typography>
                                </div>
                                <div className='portfolio-display-header-address'>
                                    <TextField variant="filled" size="small" fullWidth id='walletAddress' placeholder={this.state.walletAddress} disabled/>
                                </div>
                                <div className='portfolio-display-header-network'>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={this.state.network}
                                    exclusive
                                    aria-label="Platform"
                                >
                                    <ToggleButton disabled value="Ethereum">Ethereum</ToggleButton>
                                    <ToggleButton disabled value="PulseChain">PulseChain</ToggleButton>
                                </ToggleButtonGroup>
                                </div>
                            </div>
                            <div className='portfolio-display-content'>
                                {this.state.network === 'Ethereum'?
                                
                                    <div className='portfolio-display-content-inner'>
                                         <Box sx={{ width: '100%' }}>
                                            <LinearProgressWithLabel count={this.state.count} total={this.state.total} />
                                        </Box>
                                        {this.getSortedArray(this.state.ethWallet).map((token:any) => {
                                            return (
                                                <div key={token.symbol} className='portfolio-display-content-inner-token'>
                                                    <div className='portfolio-display-content-inner-token-info'>
                                                        <img src={token.img} alt={token.symbol}/>
                                                        <div className='portfolio-display-content-inner-token-info-description'>
                                                            <p className='token-name'>{token.name}</p>
                                                            <p className='token-description'>{token.symbol}</p>
                                                        </div>
                                                    </div>
                                                    <div className='portfolio-display-content-inner-token-tokens portfolio-column'>
                                                        <p className='token-description'>{token.balance}</p>
                                                    </div>
                                                    <div className='portfolio-display-content-inner-token-price portfolio-column'>
                                                        <p className='token-description'>{this.formatNumber(token.assetPrice)}</p>
                                                    </div>
                                                    <div className='portfolio-display-content-inner-token-balance portfolio-column'>
                                                        <p className='token-description'>{token.priceChange}</p>
                                                    </div>
                                                    <div className='portfolio-display-content-inner-token-balance portfolio-column'>
                                                        <p className='token-description'>${Math.round(((token.assetPrice * token.balance) + Number.EPSILON) * 100) / 100}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                :
                                <div className='portfolio-display-content-inner'>
                                {this.getSortedArray(this.state.plsWallet).map((token:any) => {
                                    return (
                                        <div className='portfolio-display-content-inner-token'>
                                            <div className='portfolio-display-content-inner-token-info'>
                                                <img src={token.img} alt={token.symbol}/>
                                                <div className='portfolio-display-content-inner-token-info-description'>
                                                    <p className='token-name'>{token.name}</p>
                                                    <p className='token-description'>{token.symbol}</p>
                                                </div>
                                            </div>
                                            <div className='portfolio-display-content-inner-token-tokens portfolio-column'>
                                                <p className='token-description'>{token.balance}</p>
                                            </div>
                                            <div className='portfolio-display-content-inner-token-price portfolio-column'>
                                                <p className='token-description'>{token.assetPrice}</p>
                                            </div>
                                            <div className='portfolio-display-content-inner-token-balance portfolio-column'>
                                                <p className='token-description'>{token.priceChange}</p>
                                            </div>
                                            <div className='portfolio-display-content-inner-token-balance portfolio-column'>
                                                <p className='token-description'>${Math.round(((token.assetPrice * token.balance) + Number.EPSILON) * 100) / 100}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                </Stack>
            </HelmetProvider>
        ); 
    }
}


export default Portfolio;