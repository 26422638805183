import { Box, Card, Typography, AppBar, Toolbar, Grid, IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react'; // we need this to make JSX compile
import MainMenu from './MainMenu';
import {defaultElevation, itemSpacing} from "../Theme/Theme";
import Spacer from "../Helpers/Spacer";
import {router} from "../App";
import { WhoAmIResponse } from '../API/models/account';
import UserMenu from './Header/UserMenu';
import { Link } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';

type DesktopLayoutState = {

}

type DesktopLayoutProps = {
    children? : JSX.Element | JSX.Element[],
    whoAmI: WhoAmIResponse
}

const superContainerStyle:React.CSSProperties = {
    alignItems: "center",
    justifyContent: "center",
    display: "flex"
}

const containerStyle:React.CSSProperties = {
    gap: itemSpacing,
    display: "flex",
    flexDirection: "row",
    padding: itemSpacing,
    flexGrow: 1,
    maxWidth: "1100px",
    alignItems: "flex-start"
}

const toolbarStyle: React.CSSProperties = {
    maxWidth: "1100px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    flexGrow: 1,
    width: "calc(100% - 48px)"
}

const sideBarStyle:React.CSSProperties = {
    display: "block",
    flexGrow: 0,
    position: "sticky",
    top: itemSpacing,
}

const mainContentStyle:React.CSSProperties = {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row"
}

export default class DesktopLayout extends React.Component<DesktopLayoutProps, DesktopLayoutState> {    
    constructor(props:DesktopLayoutProps) {
        super(props);
        this.login = this.login.bind(this);
        this.goToHome = this.goToHome.bind(this);
    }

    login() {
        router.navigate("/login");
    }

    goToHome() {
        router.navigate("/");
    }

    goToNew() {
        router.navigate("/new");
    }
    
    render() {
        return(
            <div>
                <AppBar position="static" color='primary'>
                    <Toolbar sx={toolbarStyle}>
                        <Box sx={{"&:hover": {cursor: "pointer", userSelect: "none"}}} onClick={this.goToHome}>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className="fetch-logo">
                                FETCH
                            </Typography>
                        </Box>
                        <Spacer />
                        <Box>
                            <Stack spacing={2} direction={'row'} alignItems={'center'}>
                                <IconButton onClick={this.goToNew}>
                                    <AddIcon style={{color: "#fff"}}/>
                                </IconButton>
                                <UserMenu whoAmI={this.props.whoAmI} />
                            </Stack>
                        </Box>
                    </Toolbar>
                </AppBar>
                <div style={{padding: "16px"}}>
                    <Grid container spacing={2}>
                        <Grid item sm={3} md={3} lg={2} style={{position: 'sticky', alignSelf: 'flex-start', height: 'auto', top: 0}}>
                            <Card elevation={defaultElevation}>
                                <MainMenu whoAmI={this.props.whoAmI}></MainMenu>
                            </Card>
                        </Grid>
                        <Grid item sm={9} md={9} lg={10}>
                            {this.props.children}
                        </Grid>
                        <Grid item xs={12}>
                            <footer style={{padding: "48px 0"}}>
                                <Stack spacing={2}>
                                    <Typography variant="caption"><Link to={"/terms#terms-conditions"}>Terms & conditions</Link> &nbsp;•&nbsp; <Link to={"/terms#privacy-policy"}>Privacy disclaimer</Link></Typography>
                                    <Typography variant="caption">&copy; {(new Date()).getFullYear()} Fetch.team<br/>All right reserved</Typography>
                                </Stack>
                            </footer>
                        </Grid>
                    </Grid>
                </div>
                
                
            </div>
        );
    }
}