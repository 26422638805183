import React from 'react';
import { Box, Stack, Card, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

export default class PremiumRequired extends React.Component {
    render() {
        return(
            <Card style={{width: "100%"}}>
                <Stack style={{padding: "64px 0"}} spacing={3}>
                    <Stack direction="row" style={{justifyContent: "center", alignItems: "center"}}>
                        <WorkspacePremiumIcon sx={{fontSize: "72pt", color: "var(--secondary-color)"}}  />
                    </Stack>
                    <Typography>
                        A premium subscription to Fetch is required to view this content. <br/>Premium will allow you to view and create on Fetch to your hearts content!
                    </Typography>
                    <Box>
                        <Button component={Link} to="/register/subscription" variant="contained">Subscribe now</Button>
                    </Box>
                </Stack>
            </Card>
        )
    }
}