import { Stack } from '@mui/material';
import React from 'react';
import { ViewCommentResult } from '../../API/models/comments';
import BasicComment from './BasicComment';
import { WhoAmIResponse } from '../../API/models/account';


type BasicCommentListProps = {
    comments: ViewCommentResult[],
    contentId: string,
    whoAmI: WhoAmIResponse
};

export default class BasicCommentList extends React.Component<BasicCommentListProps> {
    render() {
        return(
            <Stack gap={"24px"}>
                {this.props.comments.map((comment, i) => {
                    return(
                        <BasicComment whoAmI={this.props.whoAmI} commentDepth={1} key={comment.commentId} comment={comment} contentId={this.props.contentId} />
                    );
                })}
            </Stack>
        )
    }
}