import Data from "./Data";
import { Settings, CompleteRegistrationRequest, CompleteResetPasswordRequest, RegistrationRequest, ResetPasswordRequest, UpdatePasswordRequest, UpdateUserPermissionsRequest, UploadUserProfileImageRequest, UserPermissionType, WhoAmIResponse, UpdateSettingsRequest, ValidTokenResponse, ChangeEmailTokenRequest, ChangeEmailRequest, GoogleLoginRequest, GoogleLoginPreflightRequest } from "./models/account";
import { LoginResponse } from "./models/authentication";

export default abstract class Account {
    public static async Register(email:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Post<RegistrationRequest, void>("/Register", {email: email}).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async CompleteRegistration(username: string, password: string, confirmPassword: string, registrationToken: string) {
        return new Promise<LoginResponse>((resolve, reject) => {
            Data.Post<CompleteRegistrationRequest, LoginResponse>("/CompleteRegistration", {username: username, password: password, confirmPassword: confirmPassword, registrationToken: registrationToken}).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async GoogleSignInPreflight(token:string) : Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            Data.Post<GoogleLoginPreflightRequest, boolean>("/GoogleAuthPreflight", {token: token}).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async GoogleSignIn(token:string, username?: string | undefined) {
        return new Promise<LoginResponse>((resolve, reject) => {
            Data.Post<GoogleLoginRequest, LoginResponse>("/LoginGoogle", {token: token, username}).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async ResetPassword(email:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Post<ResetPasswordRequest, void>("/ResetPassword", {email: email}).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async CompletePasswordReset(password: string, confirmPassword:string, passwordResetToken:string): Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Post<CompleteResetPasswordRequest, void>("/CompleteResetPassword", {password: password, confirmPassword: confirmPassword, passwordResetToken: passwordResetToken}).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async ChangeEmail(email:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Post<ChangeEmailTokenRequest, void>("/GenerateChangeEmailToken", {email: email}).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    ///TODO THIS NEEDS TO BE FIXED
    public static async CompleteEmailChange(email:string, token:string): Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Post<ChangeEmailRequest, void>("/ChangeEmail", {email: email, token: token}).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async UpdatePassword(currentPassword: string, newPassword:string, confirmPassword:string): Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Post<UpdatePasswordRequest, void>("/UpdatePassword", {currentPassword: currentPassword, newPassword: newPassword, confirmPassword: confirmPassword}).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async WhoAmI() : Promise<WhoAmIResponse> {
        return new Promise<WhoAmIResponse>((resolve, reject) => {
            Data.Get<WhoAmIResponse>("/WhoAmI").then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async UploadProfileImage(imageBase64:string) : Promise<string> {
        return new Promise<string>((resolve, reject) => {
            Data.Post<UploadUserProfileImageRequest, string>("/ChangeProfileImage", {imageBase64: imageBase64}).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async ChangeUserPermission(userId:string, permissionType:UserPermissionType) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Put<UpdateUserPermissionsRequest, null>(`/User/${userId}`, {userId: userId, permissionType: permissionType}).then(response => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async GetAccountSettings(): Promise<Settings> {
        return new Promise((resolve, reject) => {
            Data.Get<Settings>(`/Settings`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });   
    }

    public static async UpdateAccountSettings(accountSettings: UpdateSettingsRequest): Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Put<UpdateSettingsRequest, void>('/Settings', accountSettings).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async IsPasswordResetTokenValid(token:string) : Promise<boolean> {
        return new Promise((resolve, reject) => {
            Data.Get<ValidTokenResponse>(`/IsValidPasswordResetToken/${token}`).then(tokenResponse => {
                resolve(tokenResponse.isValid);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async IsChangeMailTokenValid(token:string) : Promise<boolean> {
        return new Promise((resolve, reject) => {
            Data.Get<ValidTokenResponse>(`/IsValidChangeEmailToken/${token}`).then(tokenResponse => {
                resolve(tokenResponse.isValid);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async GetProfileImageUrl() : Promise<string> {
        return new Promise((resolve, reject) => {
            Data.Get<string>(`/ProfileImageUrl`).then(imageUrl => {
                resolve(imageUrl);
            }).catch(error => {
                reject(error);
            })
        })
    }

    public static async RefreshToken() : Promise<LoginResponse> {
        return new Promise((resolve, reject) => {
            Data.Post<void, LoginResponse>("/RefreshToken", undefined).then(loginResponse => {
                resolve(loginResponse);
            }).catch(error => {
                reject(error);
            });
        });
    }
}