import { Box, Menu, MenuItem, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContent, Stack, Button, Typography } from '@mui/material';
import React from 'react';


import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import FlagIcon from '@mui/icons-material/Flag';
import Comment from '../../API/Comment';
import { ViewCommentResult } from '../../API/models/comments';

type CommentContextMenuProps = {
    open: boolean,
    anchor?:Element,
    onClose:Function,
    content:ViewCommentResult,
};

type CommentContextMenuState = {
    isFlagDialogOpen:boolean,
    flagSucceeded: boolean
}

export default class CommentContextMenu extends React.Component<CommentContextMenuProps, CommentContextMenuState> {
    constructor(props:CommentContextMenuProps) {
        super(props);

        this.state = {
            isFlagDialogOpen: false,
            flagSucceeded: false
        };
    }

    flag() {
        Comment.Flag(this.props.content.commentId).then(() => {
            this.setState({...this.state, flagSucceeded: true}, () => {
                setTimeout(this.closeFlagDialog, 750);
            });
        });
    }

    openFlagDialog() {
        this.setState({...this.state, isFlagDialogOpen: true});
    }

    closeFlagDialog() {
        this.setState({...this.state, isFlagDialogOpen: false, flagSucceeded: false});
    }

    render() {
        return(
            <Box>
                <Menu open={this.props.open} anchorEl={this.props.anchor} onClose={() => this.props.onClose()}>
                    <MenuItem onClick={this.openFlagDialog}>
                        <ListItemIcon>
                            <FlagIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Flag</ListItemText>
                    </MenuItem>
                </Menu>
                <Dialog onClose={this.closeFlagDialog} open={this.state.isFlagDialogOpen}>
                    <DialogTitle>Flag this item</DialogTitle>
                    <DialogContent>
                        <Stack gap="12px">
                            <Typography variant='h3'>Are you sure?</Typography>
                            <Typography variant='body1'>
                                Flagging the content of another user will alert the moderation team to a possible transgression of the community guidelines.
                            </Typography>
                            <Typography variant='body1'>
                                If this content is not in infringement of the community guidelines or if there is no feasible reasons for flagging this content you may risk a ban from the platform.
                            </Typography>
                            <Button 
                                style={ this.state.flagSucceeded ? {backgroundColor: "var(--success-color)"} : {backgroundColor: "var(--error-color)"}} variant='contained' onClick={this.flag} 
                                fullWidth 
                                endIcon={this.state.flagSucceeded ? <CheckIcon/> : <WarningIcon/>}>
                                    Flag
                            </Button>
                        </Stack>
                    </DialogContent>
                </Dialog>
            </Box>
        )
    }
}