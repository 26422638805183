import React from 'react';
import {ethers} from 'ethers';

import { Button, Stack, TextField, Box, Typography } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';

import {mangoFusionPalette,} from '@mui/x-charts/colorPalettes';

import { Helmet, HelmetProvider } from 'react-helmet-async';

type WhaleTrackerState = {
    walletAddress: string,
    plsWallet: Array<object>,
    whales: Array<string>,
    transactions: Array<object>,
    selectedWhale: string,
    whaleTransactionsLoading: boolean,
    whalePortfolioLoading: boolean, 
}

class WhaleTracker extends React.Component <{}, WhaleTrackerState> {
    constructor(props:any) {
        super(props);
        this.state = {
            walletAddress: "undefined",
            plsWallet: [],
            whales: [
                '0x19cac87bb9aa7715d6e7cf06a85ecadc1c9373d7',
                '0x79bD0cb5E9a3379B8BB8134926505032399BAC76',
                '0x29b7EfDA7c08773f9EADBc7443A23758e35Eac2d',
                '0xAfa64C228CB623CA2e0f1b9121DA704815cEB21B',
                
            ],
            transactions: [],
            selectedWhale: 'undefined',
            whaleTransactionsLoading: false,
            whalePortfolioLoading: false,
        }
        this.onPlsWalletChange = this.onPlsWalletChange.bind(this);
        this.getPieChartData = this.getPieChartData.bind(this);
        this.getSortedArray = this.getSortedArray.bind(this);
        this.formatNumber = this.formatNumber.bind(this);
        this.getTotalWhaleBalance = this.getTotalWhaleBalance.bind(this);
        this.getAssetPriceByAddress = this.getAssetPriceByAddress.bind(this);
        this.getWhaleTransactions = this.getWhaleTransactions.bind(this);
        this.getDeligatedTransactions = this.getDeligatedTransactions.bind(this);
        this._handleFromTokenSwap = this._handleFromTokenSwap.bind(this);
        this._handleToTokenSwap = this._handleToTokenSwap.bind(this);
        this.getExactTokensForEthTransactions = this.getExactTokensForEthTransactions.bind(this);
        this.getExactEthForTokensTransactions = this.getExactEthForTokensTransactions.bind(this);
        this.getExactTokensForTokensTransactions = this.getExactTokensForTokensTransactions.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<WhaleTrackerState>, snapshot?: any): void {
       
    }

    async getAssetPriceByTicker(token:string, network:string='ethereum') {
        const response = await fetch(`https://api.dexscreener.com/latest/dex/search?q=${token}%20USDC`, {'method': 'GET'});
        const data = await response.json();
        if (!data?.pairs) return {}
        // get the first element of the array where chainId is network
        return data.pairs.filter((pair:any) => pair.chainId === network)[0]
    }

    async getAssetPriceByAddress(address:string, network:string='ethereum') {
        let priceData:any = {}
        let value:any= {}
        const imageData = require('../Portfolio/plscoins.json')

        const currentTime = new Date().getTime()
        const cacheResult = localStorage.getItem(address)
        // check if the cache is older than 1 hour
        if (cacheResult && (currentTime - JSON.parse(cacheResult).timestamp) < 3600000) return JSON.parse(cacheResult) 

        if (address !== '0x0000000000000000000000000000000000000000') { //!==pls
            const response = await fetch(`https://api.dexscreener.com/latest/dex/tokens/${address}`, {'method': 'GET'});
            const data = await response.json();
            if (!data?.pairs) return {}
            priceData = data.pairs.filter((pair:any) => pair.chainId === network)[0]
            const tokenResponse = await fetch(`https://api.scan.pulsechain.com/api/v2/addresses/${address}`, {'method': 'GET'}); //first element is the latest transaction
            const tokenData = await tokenResponse.json();


            // get the first element of the array where chainId is network
            if (!priceData?.priceUsd) return;

            // cache results in local storage
            value = {
                priceUsd: priceData.priceUsd, 
                priceChange: priceData.priceChange,
                timestamp: currentTime,
                name: tokenData.token.name,
                symbol: tokenData.token.symbol,
                decimals: tokenData.token.decimals,
                img: imageData[tokenData.token.name.toUpperCase()] ? imageData[tokenData.token.name.toUpperCase()] : imageData[tokenData.token.symbol.toUpperCase()]
            }
        } else {
            priceData = await this.getAssetPriceByTicker('wpls', network)
            value = {
                priceUsd: priceData.priceUsd, 
                priceChange: priceData.priceChange,
                timestamp: currentTime,
                name: 'Pulsechain',
                symbol: 'PLS',
                decimals: 18,
                img: imageData['PLS']
            }
        }


        
        localStorage.setItem(address, JSON.stringify(value))
        return value
    }

    async _handleFromTokenSwap(transfer:any, swap:any){
        const fromAddress = transfer.token.address
        const fromAmount = transfer.total.value 
        const fromPriceData = await this.getAssetPriceByAddress(fromAddress, 'pulsechain')

        swap['fromAddress'] = fromAddress
        swap['fromAmount'] = fromAmount
        swap['fromPriceData'] = fromPriceData
        swap['fromTokenImage'] = fromPriceData?.img? fromPriceData?.img : 'https://upload.wikimedia.org/wikipedia/commons/5/5a/Black_question_mark.png'



        const r = Math.round(((Number(swap.fromPriceData.priceUsd) * (swap.fromAmount / 10 ** Number(swap.fromPriceData.decimals))) + Number.EPSILON) * 100) / 100        
        
    }

    async _handleToTokenSwap(transfer:any, swap:any){
        const toAddress = transfer.token.address
        const toAmount = transfer.total.value
        const toPriceData = await this.getAssetPriceByAddress(toAddress, 'pulsechain')
        swap['toAddress'] = toAddress
        swap['toAmount'] = toAmount
        swap['toPriceData'] = toPriceData
        swap['toTokenImage'] = toPriceData?.img? toPriceData?.img : 'https://upload.wikimedia.org/wikipedia/commons/5/5a/Black_question_mark.png'
    
    }

    async getDeligatedTransactions(data:any) {
        // order of transaction is 'coin_transfer', 'contract_call', 'token_transfer'
        let needsProcessing = []
        let swaps = []

        const multicalls = data.items.filter((item: any) => item.method === 'multicall')
        for (const multicall of multicalls) {
            // verify the multicall is a swap
            if (multicall.decoded_input.method_id !== '5ae401dc') continue;

            // just support 'coin_transfer', 'contract_call', 'token_transfer'. Maybe the check above already ensures that
            const tx_types = multicall.tx_types
            if (tx_types.length !== 3) continue;
            if (tx_types[0] !== 'coin_transfer' || tx_types[1] !== 'contract_call' || tx_types[2] !== 'token_transfer') continue;


            needsProcessing.push(multicall)
        }

        for (const transaction of needsProcessing) {
            const response = await fetch(`https://api.scan.pulsechain.com/api/v2/transactions/${transaction.hash}`, {'method': 'GET'})
                .then(response => response.json())
            
            const token_transfers = response.token_transfers
            let swap = {}
            
            for(let i=0; i < token_transfers.length; i++) {
                const transfer = token_transfers[i]

                if (i % 3 === 0) {
                    this._handleFromTokenSwap(transfer, swap);
                    
                }
                if (i % 3 === 2) {
                    this._handleToTokenSwap(transfer, swap);
                    swaps.push(swap);
                    swap = {}
                }
            
            }

        }
        return swaps;

    }

    async getExactTokensForTokensTransactions(data:any) {
        // TODO swapExactTokensForTokens
        let swaps:any = []
        const tSwaps = data.items.filter((item: any) => item.method === 'swapExactTokensForTokens')
        for (const tSwap of tSwaps) {

            const fromTokenAddress = tSwap.decoded_input?.parameters['2'].value[0]
            const fromTokenAmount =  tSwap.decoded_input?.parameters['0'].value
            
            const toTokenAddress = tSwap.decoded_input?.parameters['2'].value[1]
            const toTokenAmount = tSwap.decoded_input?.parameters['1'].value

            const fromPriceData = await this.getAssetPriceByAddress(fromTokenAddress, 'pulsechain')
            const toPriceData = await this.getAssetPriceByAddress(toTokenAddress, 'pulsechain')
            
            // skip if price data is undefined
            if (!fromPriceData || !toPriceData) continue;

            swaps.push({
                fromAddress: fromTokenAddress,
                toAddress: toTokenAddress,
                fromAmount: fromTokenAmount,
                toAmount: toTokenAmount,
                fromPriceData: fromPriceData,
                toPriceData: toPriceData,
                fromTokenImage: fromPriceData?.img? fromPriceData?.img : 'https://upload.wikimedia.org/wikipedia/commons/5/5a/Black_question_mark.png',
                toTokenImage: toPriceData?.img ? toPriceData?.img : 'https://upload.wikimedia.org/wikipedia/commons/5/5a/Black_question_mark.png',
            })
            
        }

        return swaps
    }

    async getExactEthForTokensTransactions(data:any) {
        // TODO swapExactTokensForTokens
        let swaps:any = []
        const tSwaps = data.items.filter((item: any) => item.method === 'swapExactETHForTokens')
        for (const tSwap of tSwaps) {

            const fromTokenAddress = '0x0000000000000000000000000000000000000000'
            const fromTokenAmount =  tSwap.value
            
            const toTokenAddress = tSwap.decoded_input?.parameters['1'].value[1]
            const toTokenAmount = tSwap.decoded_input?.parameters['0'].value

            const fromPriceData = await this.getAssetPriceByAddress(fromTokenAddress, 'pulsechain')
            const toPriceData = await this.getAssetPriceByAddress(toTokenAddress, 'pulsechain')
            
            // skip if price data is undefined
            if (!fromPriceData || !toPriceData) continue;

            swaps.push({
                fromAddress: fromTokenAddress,
                toAddress: toTokenAddress,
                fromAmount: fromTokenAmount,
                toAmount: toTokenAmount,
                fromPriceData: fromPriceData,
                toPriceData: toPriceData,
                fromTokenImage: fromPriceData?.img? fromPriceData?.img : 'https://upload.wikimedia.org/wikipedia/commons/5/5a/Black_question_mark.png',
                toTokenImage: toPriceData?.img ? toPriceData?.img : 'https://upload.wikimedia.org/wikipedia/commons/5/5a/Black_question_mark.png',
            })
            
        }

        return swaps
    }

    async getExactTokensForEthTransactions(data:any) {
        // also take a look at swapExactEthForTokens
        let swaps:any = []
        const eSwaps = data.items.filter((item: any) => item.method === 'swapExactTokensForETH')
        
        for (const eSwap of eSwaps) {
            const fromTokenAddress = eSwap.decoded_input?.parameters['2'].value[0]
            const fromTokenAmount =  eSwap.decoded_input?.parameters['0'].value
            
            const toTokenAddress = eSwap.decoded_input?.parameters['2'].value[1]
            const toTokenAmount = eSwap.decoded_input?.parameters['1'].value

            const fromPriceData = await this.getAssetPriceByAddress(fromTokenAddress, 'pulsechain')
            const toPriceData = await this.getAssetPriceByAddress(toTokenAddress, 'pulsechain')
            
            // skip if price data is undefined
            if (!fromPriceData || !toPriceData) continue;

            swaps.push({
                fromAddress: fromTokenAddress,
                toAddress: toTokenAddress,
                fromAmount: fromTokenAmount,
                toAmount: toTokenAmount,
                fromPriceData: fromPriceData,
                toPriceData: toPriceData,
                fromTokenImage: fromPriceData?.img? fromPriceData?.img : 'https://upload.wikimedia.org/wikipedia/commons/5/5a/Black_question_mark.png',
                toTokenImage: toPriceData?.img ? toPriceData?.img : 'https://upload.wikimedia.org/wikipedia/commons/5/5a/Black_question_mark.png',
            })
            
        }

        return swaps
    }

    async getWhaleTransactions(address:string) {
        this.setState({whaleTransactionsLoading: true})
        let swaps:Array<object> = []
        const response = await fetch(`https://api.scan.pulsechain.com/api/v2/addresses/${address}/transactions?filter=to%20%7C%20from`, {'method': 'GET'});
        const data = await response.json(); //first element is the latest transaction
        if (!data) return;
        // get all swaps in data.items
        const rawSwaps = data.items.filter((item: any) => item.method === 'swap')
        if (rawSwaps.length === 0) {
            const deligatedSwaps = await this.getDeligatedTransactions(data)
            const exactTokensForEthSwaps = await this.getExactTokensForEthTransactions(data)
            const exactEthForTokensSwaps = await this.getExactEthForTokensTransactions(data)
            const exactTokensForTokensSwaps = await this.getExactTokensForTokensTransactions(data)
            // extend swaps
            swaps.push(...deligatedSwaps)
            swaps.push(...exactTokensForEthSwaps)
            swaps.push(...exactEthForTokensSwaps)
            swaps.push(...exactTokensForTokensSwaps)
        }
        
        for (const transaction of rawSwaps) {

            const values = transaction.decoded_input?.parameters['0'].value
            const fromAddress = values[0]
            const toAddress = values[1]
            const fromAmount = values[3]
            const toAmount = values[4]
            const fromPriceData = await this.getAssetPriceByAddress(fromAddress, 'pulsechain')
            const toPriceData = await this.getAssetPriceByAddress(toAddress, 'pulsechain')
            
            // skip if price data is undefined
            if (!fromPriceData || !toPriceData) continue;

            swaps.push({
                values: values,
                fromAddress: fromAddress,
                toAddress: toAddress,
                fromAmount: fromAmount,
                toAmount: toAmount,
                fromPriceData: fromPriceData,
                toPriceData: toPriceData,
                fromTokenImage: fromPriceData?.img? fromPriceData?.img : 'https://upload.wikimedia.org/wikipedia/commons/5/5a/Black_question_mark.png',
                toTokenImage: toPriceData?.img ? toPriceData?.img : 'https://upload.wikimedia.org/wikipedia/commons/5/5a/Black_question_mark.png',
            })
            
        }
        this.setState({transactions: swaps})
        this.setState({whaleTransactionsLoading: false})
    }


    async onPlsWalletChange(walletAddress:string) {
        this.setState({selectedWhale: walletAddress})
        this.setState({whalePortfolioLoading: true})
        this.getWhaleTransactions(walletAddress)
        let pulseTokens = []
        const response = await fetch(`https://api.scan.pulsechain.com/api/v2/addresses/${walletAddress}/token-balances`, {'method': 'GET'});
        let total = 0;
        const data = await response.json();
        const imageData = require('../Portfolio/plscoins.json')
        for (const token of data){
            const priceData = await this.getAssetPriceByAddress(token.token.address, 'pulsechain')
        
            // check for Nan values
            if (!priceData?.priceUsd) continue;
            total += Number(priceData.priceUsd * (token.value / 10 ** token.token.decimals))
            let img = imageData[token.token.name.toUpperCase()] ? imageData[token.token.name.toUpperCase()] : imageData[token.token.symbol.toUpperCase()]
            if (!img) img = 'https://upload.wikimedia.org/wikipedia/commons/5/5a/Black_question_mark.png'
            
            pulseTokens.push(
                {
                    name: token.token.name, 
                    symbol: token.token.symbol, 
                    balance:(token.value / 10 ** token.token.decimals), 
                    address: token.token.address,
                    assetPrice: priceData.priceUsd,
                    priceChange: priceData?.priceChange?.h24,
                    totalUSDValue: Math.round((( priceData.priceUsd * (token.value / 10 ** token.token.decimals)) + Number.EPSILON) * 100) / 100,
                    img: img
                }
            )
        }
        this.setState({plsWallet: pulseTokens})
        this.setState({whalePortfolioLoading: false})
    }
    
    getPieChartData(data:any) {
        let pieChartData = []
        for (const token of data) {
            if (token.totalUSDValue < 1) continue;
            pieChartData.push({label: token.name, value: token.totalUSDValue})
        }
        return pieChartData;

    }

    getSortedArray(arr: Array<object>) {

        // the array is sorted by the balance * usdValue  of the token only if the token is worth more than 1 usd
        return arr.filter((token:any) => token.totalUSDValue > 1).sort((a:any, b:any) => {
            return b.totalUSDValue - a.totalUSDValue
        })
        // return arr.sort((a:any, b:any) => {
        //     return (b.balance * b.assetPrice) - (a.balance * a.assetPrice) 
        // })
        
    }

    formatNumber(num:any) {
        // format number in usd value
        return num.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

    }  

    getTotalWhaleBalance(data:any) {
        let total = 0;
        if (data.length === 0) return 0;
        for (const token of data) {
            total += token.totalUSDValue
        }
        return this.formatNumber(total);
    }
    
    render() {        
        return (
            <HelmetProvider>
                <Helmet>
                    <meta name='title' property="og:title" content='portfolio'/>
                    <meta name='type' property="og:type" content="portfolio"/>
                    {/* <meta name='image' property="og:image" content={this.state.post.thumbnail} /> */}
                    <meta name='description' property="og:description" content="View this post on Fetch.team!" />
                    <meta name='url' property="og:url" content={window.location.href} />
                    <meta name="twitter:card" property='twitter:card' content="summary_large_image" />
                    <meta name='section' property='article:section ' content='Financial education'/>
                    <meta name='sitename' property='og:site_name ' content='Fetch.team'/>
                </Helmet>
                <Stack gap={"24px"} sx={{width: "100%"}}>
                    <div className='blur-container'>
                        <div className="whale-container-inner">
                            <div className="whale-stats-row-1">
                                <div className='whale-list whale-blur'>
                                {this.state.whales.map((whale:string) => {
                                    return (
                                        <div className='whale' onClick={() =>this.onPlsWalletChange(whale)}>
                                            <p className='whale-address'> {whale}</p>
                                        </div>
                                    )
                                })}
                                </div>                       
                                <div className="whale-coin-summary whale-blur">
                                {this.state.whalePortfolioLoading ? (<div className="loader-container"><span className="loader"></span></div>) : <PieChart
                                        colors={mangoFusionPalette}
                                        series={[
                                            {
                                                data: this.getPieChartData(this.state.plsWallet),
                                                innerRadius: 50,
                                                outerRadius: 120,
                                                paddingAngle: 5,
                                                cornerRadius: 5,
                                                startAngle: -100,
                                                endAngle: 180,
                                                cx: 120,
                                                cy: 225,

                                            }
                                        ]}        
                                    />}
                                </div>
                                
                                <div className="whale-coin-extra">
                                    <div className='coin-table whale-blur'>
                                    {this.state.whalePortfolioLoading ? (<div className="loader-container"><span className="loader"></span></div>) : null}
                                    {this.getSortedArray(this.state.plsWallet).map((token:any) => {
                                            return (
                                                <div key={token?.symbol} className='portfolio-display-content-inner-token'>
                                                    <div className='portfolio-display-content-inner-token-info'>
                                                        <img src={token?.img} alt={token?.symbol}/>
                                                        <div className='portfolio-display-content-inner-token-info-description'>
                                                            <p className='token-description'>{token?.symbol}</p>
                                                            <p className='token-name'>{token?.name}</p>
                                                        </div>
                                                    </div>
                                                    <div className='portfolio-display-content-inner-token-balance portfolio-column'>
                                                        <p className='token-description'>${Math.round(((token?.assetPrice * token?.balance) + Number.EPSILON) * 100) / 100}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="whale-stats-row-2">
                                <div className="whale-transaction-history whale-blur">
                                    <div className="whale-transaction-history-table">
                                    {this.state.whaleTransactionsLoading ? (<div className="loader-container"><span className="loader"></span></div>) : null}

                                        {/* for transaction in transactions */}
                                        {this.state.transactions.map((transaction:any) => {
                                            if (transaction.fromPriceData && transaction.toPriceData) {
                                                return (
                                                    <div className='whale-transaction-history-row'>
                                                        <div className='whale-transaction-history-row-item'>
                                                            <div className='portfolio-display-content-inner-token-info'>
                                                                <img src={transaction?.fromPriceData?.img} alt={transaction?.fromPriceData?.img}/>
                                                                <div className='portfolio-display-content-inner-token-info-description'>
                                                                    <p className='token-name'>{transaction.fromPriceData.symbol}</p>
                                                                    <p className='token-description'>{transaction.fromPriceData.name}</p>
                                                                </div>
                                                            </div>
                                                            
    
                                                            <p className='token-description'>${Math.round(((transaction.fromPriceData.priceUsd * (transaction.fromAmount / 10 ** transaction.fromPriceData.decimals)) + Number.EPSILON) * 100) / 100}</p>
                                                        </div>
                                                        <div className='whale-transaction-history-row-item'>
                                                            <div className='portfolio-display-content-inner-token-info'>
                                                                <img src={transaction?.toPriceData?.img} alt={transaction?.toPriceData?.img}/>
                                                                <div className='portfolio-display-content-inner-token-info-description'>
                                                                    <p className='token-name'>{transaction.toPriceData.symbol}</p>
                                                                    <p className='token-description'>{transaction.toPriceData.name}</p>
                                                                </div>
                                                            </div>
                                                            <p className='token-description'>${Math.round(((transaction.toPriceData.priceUsd * (transaction.toAmount / 10 ** transaction.toPriceData.decimals)) + Number.EPSILON) * 100) / 100}</p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            return null;
                                            
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Stack>
            </HelmetProvider>
        ); 
    }
}


export default WhaleTracker;