import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import DataFeedAPI from '../../API/DataFeedAPI';
import { Symbol } from '../../API/models/market';
import { router } from '../../App';
import { withParams } from '../../Helpers/ReactRouterHelper';

type BrowseSymbolsProps = {

}

type BrowseSymbolsState = {
    symbols:Symbol[]
}

class BrowseSymbols extends React.Component<BrowseSymbolsProps, BrowseSymbolsState> {
    constructor(props:BrowseSymbolsProps) {
        super(props);
        this.state = {
            symbols: []
        }
    }

    componentDidMount(): void {
        DataFeedAPI.GetSymbols().then(symbols => {
            this.setState({...this.state, symbols: symbols});
        });
    }

    navigateToSymbol(symbolId:string) {
        router.navigate(`/symbol/${symbolId}`);
    }
    
    render() {
        return(
            <Box style={{padding : "12px", width: "100%"}}>
                <Stack gap="12px">
                    {this.state.symbols.map((symbol, id) => {
                        return(
                            <Paper sx={{width: "100%", padding: "8px"}}>
                                <Stack direction="row">
                                    <Box>
                                        <Typography style={{textAlign: "left"}} variant='h6'>{symbol.fullName}</Typography>
                                        <Typography style={{textAlign: "left"}} variant='body1'>{symbol.description}</Typography>
                                    </Box>
                                    <Box sx={{flexGrow: 1}}/>
                                    <Button href={`/symbol/${symbol.fullName}`}>Explore</Button>
                                </Stack>
                            </Paper>
                        )
                    })}
                </Stack>
            </Box>
        );
    }
}

export default withParams(BrowseSymbols);