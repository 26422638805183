import Data from "./Data";
import { FeedItemDTO, PollDataDTO, PollDTO } from "./models/datatransferobjects";
import { CreatePollRequest, UpdatePollRequest, ViewPollResult } from "./models/poll";

export const pollRoute = "/Poll";

export default abstract class Poll {
    public static async GetPoll(pollId:number) : Promise<PollDataDTO> {
        return new Promise<PollDataDTO>((resolve, reject) => {
            Data.Get<PollDataDTO>(`${pollRoute}/${pollId}`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        })
    }

    public static async ViewPoll(identifier:string, seoFriendlyUrl:string) : Promise<ViewPollResult> {
        return new Promise<ViewPollResult>((resolve, reject) => {
            Data.Get<ViewPollResult>(`${pollRoute}/${identifier}/${seoFriendlyUrl}`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        })
    }

    public static async GetPollData(pollId:string) : Promise<PollDataDTO> {
        return new Promise<PollDataDTO>((resolve, reject) => {
            Data.Get<PollDataDTO>(`${pollRoute}/${pollId}/data`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        })
    }

    public static async CreatePoll(title:string, content:string, contentPreview:string, tags:string[], 
        question: string, option1:string, option2:string, option3:string, option4:string) : Promise<PollDTO> {
            return new Promise<PollDTO>((resolve, reject) => {
                const body = {
                    title: title,
                    content:content,
                    contentPreview: contentPreview,
                    tags: tags,
                    question: question,
                    option1: option1,
                    option2: option2,
                    option3: option3,
                    option4: option4
                };

                Data.Post<CreatePollRequest, PollDTO>(pollRoute, body).then(poll => {
                    resolve(poll);
                }).catch(error => {
                    reject(error);
                });
            });
    }

    public static async UpdatePoll(pollId:string, title:string, content:string, contentPreview:string, tags:string[], 
        option1:string, option2:string, option3:string, option4:string) : Promise<PollDataDTO> {
            return new Promise<PollDataDTO>((resolve, reject) => {
                Data.Put<UpdatePollRequest, PollDataDTO>(`${pollRoute}/${pollId}`, {
                    title: title,
                    content:content,
                    contentPreview: contentPreview,
                    tags: tags,
                    option1: option1,
                    option2: option2,
                    option3: option3,
                    option4: option4
                }).then(poll => {
                    resolve(poll);
                }).catch(error => {
                    reject(error);
                });
            });
    }

    public static async DeletePoll(pollId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Delete(`${pollRoute}/${pollId}`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async FlagPoll(pollId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Get(`${pollRoute}/${pollId}/Flag`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async VoteOnPoll(pollId:string, option:number) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Get(`${pollRoute}/${pollId}/SelectOption/${option}`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }
}