import React from 'react';
import DesktopEditorLayout from './DesktopEditorLayout';
import MobileEditorLayout from './MobileEditorLayout';
import { withParams } from '../Helpers/ReactRouterHelper';
import { ROLES_CREATE_POST, router } from '../App';
import { WhoAmIResponse } from '../API/models/account';

export type ContentType = "post" | "poll" | "question" | "theory";

type EditorLayoutState = {
    isMobile:boolean
};

type EditorLayoutProps = {
    params: EditorLayoutParams,
    loaderData: WhoAmIResponse
}

type EditorLayoutParams = {
    contentType:ContentType
}

class EditorLayout extends React.Component<EditorLayoutProps, EditorLayoutState> {
    constructor(props:EditorLayoutProps) {
        super(props);
        this.state = {isMobile: window.innerWidth < 768}
        this.resize = this.resize.bind(this);

        window.addEventListener('resize', this.resize);
    }

    resize() {
        const width = window.innerWidth;
        if(width < 768 && !this.state.isMobile) {
            this.setState({...this.state, isMobile: true});
        }

        else if(width > 768 && this.state.isMobile) {
            this.setState({...this.setState, isMobile: false});
        }
    }

    componentDidMount(): void {
        if(this.props.params.contentType === "post") {
            const filteredArray = ROLES_CREATE_POST.filter(value => this.props.loaderData.roles.includes(value));
            if(filteredArray.length === 0) {
                router.navigate("/upgrade-now");
            }
        }
    }

    render() {
        if(this.state.isMobile) {
            return(<MobileEditorLayout contentType={this.props.params.contentType} />);
        }
        else {
            return(<DesktopEditorLayout whoAmI={this.props.loaderData} contentType={this.props.params.contentType} />);
        }
    }
}

export default withParams(EditorLayout);