import { Box, Stack, Card, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';
import { FeedItemDTO } from '../../API/models/datatransferobjects';
import FeedItemCard from './FeedItemCard';
import PostCardShadow from '../Post/PostCardShadow';
import FeedController from '../../API/FeedController';
import CircularProgress from '@mui/material/CircularProgress';
import StarIcon from '@mui/icons-material/Star';
import { WhoAmIResponse } from '../../API/models/account';
import { withParams } from '../../Helpers/ReactRouterHelper';


type FavoriteProps = {
    params?: any,
    loaderData: WhoAmIResponse
}

type FavoriteState = {
    items: FeedItemDTO[],
    currentPage:number,
    loadFavoriteItems:boolean,
    infiniteScrollEnabled: boolean
}

const PAGE_SIZE: number = 10;

class Favorites extends React.Component<FavoriteProps, FavoriteState> {  
    constructor(props:FavoriteProps) {
        super(props);
        this.state = {
            items: [],
            currentPage: 0,
            loadFavoriteItems: true,
            infiniteScrollEnabled: true
        }

        this.onScroll = this.onScroll.bind(this);
    }
    componentDidMount(): void {
        FeedController.GetFavorites(this.state.currentPage).then(response => {
            this.setState({...this.state, items: response}, () => {
                if (response.length > 0) {
                    window.addEventListener('scroll', this.onScroll);
                }
                this.setState({...this.state, loadFavoriteItems: false, infiniteScrollEnabled: response.length === PAGE_SIZE});
            });
        });
    }

    onScroll() {
        if(this.state.infiniteScrollEnabled && !this.state.loadFavoriteItems) {
            const rect = document.getElementsByTagName('body')[0].getBoundingClientRect();
            const scrollPositionBottomOffScreen = window.scrollY + window.innerHeight;
            const heightRemainingUntilEndOfPage = rect.height - scrollPositionBottomOffScreen;
            const loadThresholdInPixels = 600;
            if(heightRemainingUntilEndOfPage < loadThresholdInPixels) {
                this.setState({...this.state, loadFavoriteItems: true}, () => {
                    const newPage = this.state.currentPage + 1;
                    FeedController.GetFavorites(newPage).then(response => {
                        const items = this.state.items;
                        for(let item of response) {
                            items.push(item);
                        }
                        this.setState({...this.state, currentPage: newPage, items: items}, () => {
                            this.setState({...this.state, loadFavoriteItems: false, infiniteScrollEnabled: response.length === PAGE_SIZE});
                        });
                    });
                });
            }
        }
    }

    renderLoading() {
        return [0, 1, 2].map(i => {
            return <PostCardShadow key={i} />
        });
    }

    renderFeed() {
        return this.state.items.map((post, i) => {
            return <FeedItemCard whoAmI={this.props.loaderData} key={i} item={post} />
        })
    }

    renderEmpty(): React.ReactNode {
        return (
            <Card>
                <Stack style={{padding: "64px 0"}} spacing={3}>
                    <Stack direction="row" style={{justifyContent: "center", alignItems: "center"}}>
                        <StarIcon sx={{fontSize: "50pt", color: "var(--secondary-color)"}}  />
                        <StarIcon sx={{fontSize: "72pt", color: "var(--secondary-color)"}}  />
                        <StarIcon sx={{fontSize: "50pt", color: "var(--secondary-color)"}}  />
                    </Stack>
                    <Typography>
                        You do not have any favorite content yet. <br/>
                        Use the favorite button on the feed to have the content show up here.
                    </Typography>
                    <Box>
                        <Button component={Link} to="/" variant="contained">Go back to the feed</Button>
                    </Box>
                </Stack>
            </Card>
        )
    }

    render() {
        return (
            <Stack gap={"24px"} flexGrow={1} alignContent="center" justifyContent="center">
                {
                    this.state.items
                        ? this.renderFeed()
                        : this.renderLoading()
                }
                {
                    !this.state.loadFavoriteItems && this.state.items.length === 0 && (
                        this.renderEmpty()
                    )
                }
                {
                    this.state.loadFavoriteItems && (
                        this.renderLoading()
                    )
                }
                {
                    this.state.loadFavoriteItems && (
                        <Box sx={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
                            <CircularProgress />
                        </Box>
                    )
                }
            </Stack>
        )
    }
}

export default withParams(Favorites);