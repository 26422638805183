import React from 'react';
import {router} from "../App";
import { withParams } from '../Helpers/ReactRouterHelper';
import { WhoAmIResponse } from '../API/models/account';

type AuthorizeProps = {
    roles:string[],
    redirectToLogin:boolean,
    children: JSX.Element | JSX.Element[],
    loaderData: WhoAmIResponse
};

class Authorize extends React.Component<AuthorizeProps> {
    componentDidMount(): void {
        console.log(this.props);
        const filteredArray = this.props.roles.filter(value => this.props.loaderData.roles.includes(value));
        if(filteredArray.length === 0) {
            if(this.props.redirectToLogin) {
                router.navigate("/login");
            }
            else {
                router.navigate("/upgrade-now");
            }
        }
    }
    
    render() {
        return(
            <div>{this.props.children}</div>
        )
    }
}

export default withParams(Authorize);