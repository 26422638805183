import Data from "./Data";
import { CreateCommentRequest, UpdateCommentRequest, ViewCommentResult } from "./models/comments";
import { CommentDTO } from "./models/datatransferobjects";

export const commentRoute = "/Comment";

export default abstract class Comment {
    
    public static async Create(comment:string, contentId:string, parentCommentId?:string) : Promise<CommentDTO> {
        return new Promise<CommentDTO>((resolve, reject) => {
            const payload:CreateCommentRequest = parentCommentId ? {
                comment: comment,
                parentCommentId: parentCommentId
            } : {
                comment: comment
            }

            Data.Post<CreateCommentRequest, CommentDTO>(`${commentRoute}/${contentId}`, payload).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async View(commentId:string) : Promise<ViewCommentResult> {
        return new Promise<ViewCommentResult>((resolve, reject) => {
            const requestUrl = `${commentRoute}/${commentId}`;
            Data.Get<ViewCommentResult>(requestUrl).then(comment => {
                resolve(comment);
            }).catch(error => {
                reject(error);
            })
        })
    }

    public static async Update(commentId:string, comment:string) : Promise<CommentDTO> {
        return new Promise<CommentDTO>((resolve, reject) => {
            Data.Put<UpdateCommentRequest, CommentDTO>(`${commentRoute}/${commentId}`, {comment: comment}).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async Delete(commentId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Put<null, null>(`${commentRoute}/${commentId}`, null).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async Like(commentId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Get<null>(`${commentRoute}/${commentId}/Like`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async Unlike(commentId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Get<null>(`${commentRoute}/${commentId}/Unlike`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async Flag(commentId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            //Do something.
        })
    }
}