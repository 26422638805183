import Box from '@mui/material/Box';
import React from 'react';
import ResponsiveChart from '../../Charts/ResponsiveChart';

export default class FetchViewerChartJsBlock extends React.Component {
    constructor(props) {
        super(props);
        this.reference = React.createRef();
        this.data = JSON.parse(this.props.data);
        this.state = {

        };
    }

    componentDidMount() {
        if(this.props.data) {
            this.setState({...this.state, title: this.data.title, plotData: this.data.chartData, height: this.data.height});
        }
    }

    render() {
        return(
            <Box style={{borderRadius: "8px", overflow: "hidden"}}>
                <ResponsiveChart height={this.state.height} data={this.state.plotData} title={this.state.title} />
            </Box>
        )
    }
}