import React from 'react';
import DataFeedAPI from '../../API/DataFeedAPI';
import { Symbol } from '../../API/models/market';
import { FormControl, Select, SelectChangeEvent, MenuItem, InputLabel, Autocomplete, Paper, Grid, Typography, Chip, Card, Tab, Tabs, TextField } from '@mui/material';
import { DictionayArray } from '../../Structs/Dictionary';
import TabPanel from '../../Controls/Tabs';
import { FeedFilterValues } from './Feed';


type FeedFilterProps = {
    OnFeedFilterChange(filter:Filter) : void
}

type FeedFilterState = {
    symbols: Symbol[],
    filter:Filter,
    tagFilter: string
}

export type Filter = {
    symbols: Symbol[] | undefined,
    type: string
}

export default class FeedFilter extends React.Component<FeedFilterProps, FeedFilterState> {
    constructor(props:FeedFilterProps) {
        super(props);
        this.state = {
            symbols: [],
            filter: { 
                symbols: [],
                type: "All"
            },
            tagFilter: ""
        }

        this.onFilterAssetChange = this.onFilterAssetChange.bind(this);
        this.onFilterTypeChange = this.onFilterTypeChange.bind(this);
    }

    componentDidMount(): void {
        DataFeedAPI.GetSymbols().then(symbols => {
            this.setState({...this.state, symbols: symbols});
        });
    }

    onFilterAssetChange(event:any, value:Symbol[]) {
        let filter = this.state.filter;
        filter.symbols = value;

        this.setState({...this.state, filter: filter}, () => {
            this.props.OnFeedFilterChange(filter);
        });
    }

    onFilterTypeChange(event:SelectChangeEvent) {
        let filter = this.state.filter;
        filter.type = event.target.value;
        this.setState({...this.state, filter: filter}, () => {
            this.props.OnFeedFilterChange(filter);
        });
    }

    render(): React.ReactNode {
        return (
            <Card style={{padding: "16px"}} elevation={1}>
                <Grid container spacing={1}>
                    <Grid item sm={12}>
                        <Typography variant='body1'>Filter</Typography>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Autocomplete
                                multiple
                                options={this.state.symbols}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField
                                     {...params} 
                                     label={"Assets"}
                                     InputLabelProps={{
                                        shrink: true,
                                      }}
                                     />}
                                value={this.state.filter.symbols}
                                groupBy={(option) => option.type}
                                onChange={this.onFilterAssetChange}
                            />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Type</InputLabel>
                                <Select
                                    value={this.state.filter.type}
                                    onChange={this.onFilterTypeChange}
                                    label={"Type"}
                                >
                                {Object.keys(FeedFilterValues).filter(k => isNaN(Number(k))).map((value, i) => {
                                    return <MenuItem key={`${value}${i}`} value={value}>{value}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Card>
        )
    }
}