import React from 'react';
import { withParams } from '../../Helpers/ReactRouterHelper';

import { Box, Card, Stack } from '@mui/material';


import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AuthorReputation from '../Author/AuthorReputation';
import Tags from '../Feed/Tag';

import {InlineShareButtons} from 'sharethis-reactjs';



import {defaultElevation} from "../../Theme/Theme";
import {FormatDate} from '../../Helpers/Formatters';
import FetchViewer from '../Viewer/FetchViewer';
import CreateComment from '../Comment/CreateComment';
import BasicCommentList from '../Comment/BasicCommentList';
import { ViewCommentResult } from '../../API/models/comments';
import ViewContentShadow from '../Post/ViewContentShadow';
import Poll from '../../API/Poll';
import { ViewPollResult } from '../../API/models/poll';
import Alert from '@mui/material/Alert';
import FeedItemCardMenu from '../Feed/FeedItemCardMenu';
import PollVote from './PollVote';
import LikeTag from '../../Controls/LikeTag';
import { router } from '../../App';
import { WhoAmIResponse } from '../../API/models/account';
import ga4events from '../../events';
import { Helmet, HelmetProvider } from 'react-helmet-async';


type ViewPollParams = {
    identifier:string,
    seoFriendlyUrl:string
}

type ViewPollProps = {
    params: ViewPollParams,
    loaderData: WhoAmIResponse
}

type ViewPollState = {
    poll: ViewPollResult,
    content: any,
    loading: boolean,
    error?:string,
    menuAnchor?:Element,
    isMenuOpen:boolean
}

const cardStyle: React.CSSProperties = {
    width: "100%"
}

class ViewPoll extends React.Component<ViewPollProps, ViewPollState> {
    editorRef:React.RefObject<FetchViewer>;

    constructor(props:ViewPollProps) {
        super(props);
        this.state = {
            poll: { 
                id: "", 
                title: "", 
                author: {
                    id: "",
                    name: "", 
                    reputation: 0, 
                    achievements: { 
                        gold: 0, 
                        silver: 0, 
                        bronze: 0 
                    },
                    profileImageUrl:  ""
                }, 
                date: new Date(), 
                content: "{}", 
                tags: [],
                likes: 0,
                numberOfComments: 0, 
                comments: [] ,

                question: "",
                option1: "",
                option2: "",
                option3: "",
                option4: "",
                option1Votes:0,
                option2Votes:0,
                option3Votes:0,
                option4Votes:0,

                option1Percentage: 0,
                option2Percentage: 0,
                option3Percentage: 0,
                option4Percentage: 0,
                numberOfOptions: 0,
                userVotedOnOption: 0,
                hasLiked: false,
                hasFavorited: false,
                pollEndsOn: new Date()
            },
            content: {},
            loading: true,
            isMenuOpen: false
        };

        this.editorRef = React.createRef();

        this.openContextMenu = this.openContextMenu.bind(this);
        this.closeContextMenu = this.closeContextMenu.bind(this);
        this.onCommentPosted = this.onCommentPosted.bind(this);
    }

    componentDidMount(): void {
        ga4events.sendPageView(window.location.pathname, 'View Poll');
        Poll.ViewPoll(this.props.params.identifier, this.props.params.seoFriendlyUrl).then(poll => {
            this.setState({...this.state, loading: false}, () => {
                const content = JSON.parse(poll.content);
                if(this.editorRef.current) {
                    this.setState({...this.state, poll: poll, content: content}, () => {
                        if(this.editorRef.current) {
                            this.editorRef.current.setContent(content);
                            this.setState({...this.state, loading: false});
                        }
                    });
                }
            });
            
        });
    }

    onCommentPosted(comment:ViewCommentResult) {
        /*
            Look up how to load a complete comment from the generated DTO. 
            Currently the DTO is lacking in properties to display it in the comment.
        */
        const post = this.state.poll;
        post.comments.unshift(comment);
        this.setState({...this.state, poll: post});
    } 

    openContextMenu(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            ...this.state,
            isMenuOpen: true,
            menuAnchor: event.currentTarget
        });
    }

    closeContextMenu() {
        this.setState({
            ...this.state,
            isMenuOpen: false
        });
    }

    onPostDeleted() {
        router.navigate("/");
    }
    
    render() {

        

        if (this.state.loading)
        {
            return <ViewContentShadow />
        }
        else 
        {
            return (
                <HelmetProvider>
                    <Helmet>
                        <meta name='title' property="og:title" content={this.state.poll.title}/>
                        <meta name='type' property="og:type" content="article"/>
                        <meta name='description' property="og:description" content="View this poll on Fetch.team!" />
                        <meta name='url' property="og:url" content={window.location.href} />
                        <meta name="twitter:card" property='twitter:card' content="summary_large_image" />
                        <meta name='published' property='article:published_time' content={this.state.poll.date.toString()}/>
                        <meta name='modified' property='article:modified_time' content={this.state.poll.date.toString()}/>
                        <meta name='author' property='article:author' content={this.state.poll.author.name}/>
                        <meta name='tags' property='article:tag ' content={this.state.poll.tags.toString()}/>
                        <meta name='section' property='article:section ' content='Financial education'/>
                        <meta name='sitename' property='og:site_name ' content='Fetch.team'/>
                        <script type="text/javascript" src="https://platform-api.sharethis.com/js/sharethis.js#property=6554b0907a115e0012964612&product=inline-share-buttons&source=platform"></script>
                    </Helmet>
                    <Stack gap={"24px"} sx={{width: "100%"}}>
                        <Card style={cardStyle}>
                            <CardHeader
                                avatar={
                                <Avatar src={this.state.poll.author.profileImageUrl.length > 0 ? this.state.poll.author.profileImageUrl : undefined} sx={{ bgcolor: red[500] }} aria-label="recipe">
                                    {this.state.poll.author.name[0]}
                                </Avatar>
                                }
                                title={<div style={{textAlign: "left", fontWeight: "bold"}}>{this.state.poll.author.name}</div>}
                                subheader={<div style={{textAlign: "left"}}><AuthorReputation author={this.state.poll.author}/></div>}
                                action={
                                <Box>
                                    <Stack direction="row">
                                        <Tags tags={this.state.poll.tags}/>
                                        <IconButton onClick={this.openContextMenu}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Stack>
                                </Box>}
                            />
                            <CardContent style={{textAlign: "left"}}>
                                <Typography variant="h5" color="text.secondary">
                                    {this.state.poll.title}
                                </Typography>
                                <Stack spacing={2}>
                                    <Stack direction="row" spacing={2}>
                                        <Typography variant="body2" color="text.secondary">
                                            {FormatDate(new Date(this.state.poll.date))}
                                        </Typography>
                                    </Stack>
                                    <FetchViewer ref={this.editorRef} />                                    
                                    {this.state.error && <Alert sx={{margin: "12px"}} severity="info">{this.state.error}</Alert>}
                                    
                                    <Divider />
                                    {this.state.poll.id && (
                                        <PollVote pollId={this.state.poll.id} />
                                    )}
                                </Stack>
                            </CardContent>
                            <CardActions sx={{p: "0 16px 16px 16px", gap: "8px"}}>
                                    {this.state.poll.id && (
                                        <LikeTag likes={this.state.poll.likes} contentId={this.state.poll.id} hasLiked={this.state.poll.hasLiked}/>
                                    )}
                                    
                            </CardActions>
                            <div className="sharethis-container">
                                <InlineShareButtons
                                    config={{
                                        alignment: 'center',  // alignment of buttons (left, center, right)
                                        color: 'social',      // set the color of buttons (social, white)
                                        enabled: true,        // show/hide buttons (true, false)
                                        font_size: 16,        // font size for the buttons
                                        labels: 'cta',        // button labels (cta, counts, null)
                                        language: 'en',       // which language to use (see LANGUAGES)
                                        networks: [           // which networks to include (see SHARING NETWORKS)
                                        'whatsapp',
                                        'reddit',
                                        'twitter',
                                        'facebook',
                                        'messenger',
                                        ],
                                        padding: 12,          // padding within buttons (INTEGER)
                                        radius: 4,            // the corner radius on each button (INTEGER)
                                        show_total: true,
                                        size: 40,             // the size of each button (INTEGER)


                                        // OPTIONAL PARAMETERS

                                        // min_count: 10,                    // (threshold for total share count to be displayed)
                                        // url: 'https://www.sharethis.com', // (defaults to current url)
                                        // image: 'https://bit.ly/2CMhCMC',  // (defaults to og:image or twitter:image)
                                        // description: 'custom text',       // (defaults to og:description or twitter:description)
                                        // title: 'custom title',            // (defaults to og:title or twitter:title)
                                        // message: 'custom email text',     // (only for email sharing)
                                        // subject: 'custom email subject',  // (only for email sharing)
                                        // username: 'custom twitter handle' // (only for twitter sharing)
                                    }}
                                />
                            </div>
                            
                        </Card>
                        <Card style={{ flexGrow: 1}} elevation={defaultElevation}>
                            <CardContent style={{textAlign: "left"}}>
                                <CreateComment whoAmI={this.props.loaderData} onCommentPosted={this.onCommentPosted} contentId={this.state.poll.id} />
                            </CardContent>
                        </Card>
                        <BasicCommentList whoAmI={this.props.loaderData} comments={this.state.poll.comments} contentId={this.state.poll.id} />
                        <FeedItemCardMenu whoAmI={this.props.loaderData} onDelete={this.onPostDeleted} hasFavorited={this.state.poll.hasFavorited} contentId={this.state.poll.id} author={this.state.poll.author} isHidden={false} onClose={this.closeContextMenu} open={this.state.isMenuOpen} anchor={this.state.menuAnchor} />
                    </Stack>
                </HelmetProvider>
                
            ); 
        }
    }
}

export default withParams(ViewPoll);