import { Drawer, Stack, SxProps } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react'; // we need this to make JSX compile
import MainMenu from './MainMenu';

import MobileLayoutNavigation from "./MobileLayoutNavigation";
import { router } from '../App';
import { WhoAmIResponse } from '../API/models/account';
import UserMenu from './Header/UserMenu';
import { withParams } from '../Helpers/ReactRouterHelper';

import AddIcon from '@mui/icons-material/Add';

type MobileLayoutState = {
    isDrawerOpen:boolean
}

type MobileLayoutProps = {
    title:string,
    children? : JSX.Element | JSX.Element[],
    whoAmI: WhoAmIResponse
}

const MainContentStyle:SxProps = {
    m: 2,
    alignContent: "center",
    marginBottom: "78px"
};

class MobileLayout extends React.Component<MobileLayoutProps, MobileLayoutState> {
    constructor(props:MobileLayoutProps) {
        super(props);

        this.state = {
            isDrawerOpen: false
        };

        this.closeDrawer = this.closeDrawer.bind(this);
        this.openDrawer = this.openDrawer.bind(this);
        this.login = this.login.bind(this);
        this.goToHome = this.goToHome.bind(this);
    }

    closeDrawer() {
        this.setState({...this.state, isDrawerOpen: false});
    }

    openDrawer() {
        this.setState({...this.state, isDrawerOpen: true});
    }

    login() {
        router.navigate("/login");
    }

    goToHome() {
        router.navigate("/feed")
    }

    goToNew() {
        router.navigate("new");
    }
    
    render() {
        return(
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" color='primary'>
                    <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={this.openDrawer}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography onClick={this.goToHome} variant="h6" component="div" sx={{ flexGrow: 1 }} className="fetch-logo">
                        Fetch
                    </Typography>
                    <UserMenu whoAmI={this.props.whoAmI} />
                    </Toolbar>
                </AppBar>
                <Box sx={MainContentStyle}>
                    {this.props.children}
                </Box>
                <Drawer
                    anchor="left"
                    open={this.state.isDrawerOpen}
                    onClose={this.closeDrawer}
                >
                    <MainMenu whoAmI={this.props.whoAmI} />
                </Drawer>
                <MobileLayoutNavigation whoAmI={this.props.whoAmI} />
            </Box>
        );
    }
}

export default withParams(MobileLayout);