import { DatafeedConfiguration, ResolutionString } from "../../public/charting_library/datafeed-api";


export const configurationDataExample: DatafeedConfiguration = {
    exchanges: [], // No exchange filtering.
    symbols_types: [{ name: "Crypto", value: "crypto" }],
    supported_resolutions: ["D" as ResolutionString],
    currency_codes: [{id: "USD", code: "USD", description: "$"}],
    units: {},
    supports_marks: false,
    supports_timescale_marks: false,
    supports_time: true
}
