import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import { tagStyle } from "../Theme/Theme"

import ForumIcon from '@mui/icons-material/Forum';

type CommentButtonProps = {
    comments: number,
    onClick?: any
}

export default class CommentTag extends React.Component<CommentButtonProps> {

    constructor(props: CommentButtonProps) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        return(
            <Box style={tagStyle} onClick={this.onClick}>
                <Stack direction="row" spacing={1}>
                    <ForumIcon fontSize='small' color='primary'/>
                    <Typography variant="body2" color="text.primary">
                        {this.props.comments}
                    </Typography>
                </Stack>
            </Box>
        )
    }
}