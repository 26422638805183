import React from 'react';
import Plot from 'react-plotly.js';

export default class ResponsiveChart extends React.Component {
    boxRef;
    
    constructor(props) {
        super(props);
        this.state = {
            width: 400
        }

        this.boxRef = React.createRef();
        this.handleResize = this.handleResize.bind(this);
        window.addEventListener('resize', this.handleResize);
    }

    static defaultProps = {
        height: 400
    }

    handleResize() {
        if(this.state && this.boxRef.current) {
            const width = this.boxRef.current.clientWidth;
            this.setState({...this.state, width: width});
        }
    }

    componentDidMount() {
        const width = this.boxRef.current.clientWidth;
        this.setState({...this.state, width: width});
    }

    render() {
        return(
            <div ref={this.boxRef} className='responsive-chart' style={{width: "100%"}}>
                <Plot data={this.props.data} layout={ { title: this.props.title, height: this.props.height, width: this.state.width} } useResizeHandler/>
            </div>
        )
    }
}