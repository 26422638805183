import React from 'react';
import Post from '../../API/Post';
import ga4events from '../../events';
import { withParams } from '../../Helpers/ReactRouterHelper';

import { Box, Card, Stack } from '@mui/material';

import {InlineShareButtons} from 'sharethis-reactjs';

import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ViewTheoryResult } from '../../API/models/theory';
import AuthorReputation from '../Author/AuthorReputation';
import Tags from '../Feed/Tag';


import {FormatDate} from '../../Helpers/Formatters';
import FetchViewer from '../Viewer/FetchViewer';
import ViewContentShadow from '../Post/ViewContentShadow';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Editor from '../Editor/Editor';
import ViewArgument from './ViewArgument';
import Vote from '../../API/Vote';
import Theory from '../../API/Theory';
import FeedItemCardMenu from '../Feed/FeedItemCardMenu';
import AuthorPopover from '../Author/AuthorPopover';
import LoadableButton from '../../Controls/LoadableButton';
import AuthorizeView from '../../Layout/AuthorizeView';
import { ROLES_FREE, router } from '../../App';
import { WhoAmIResponse } from '../../API/models/account';
import { Helmet, HelmetProvider } from 'react-helmet-async';


type ViewTheoryParams = {
    identifier:string,
    seoFriendlyUrl:string
}

type ViewTheoryProps = {
    params: ViewTheoryParams,
    loaderData: WhoAmIResponse
}

type ViewTheoryState = {
    theory: ViewTheoryResult,
    content: any,
    loading: boolean,
    menuAnchor?:Element,
    isMenuOpen:boolean,
    isCommentPosting: boolean,
    isCommentPosted: boolean
}

const cardStyle: React.CSSProperties = {
    width: "100%"
}

export const leftSidebarStyle: React.CSSProperties = {
    padding: "0 4px 0 6px",
    marginTop: "-14px",
}

export const cardHeaderStyle: React.CSSProperties = {

}

export const cardBottomStyle: React.CSSProperties = {
    padding: "18px 38px 0px 38px",
    alignContent: "center",
    alignItems: "center",
    width: "100%"
}

class ViewTheory extends React.Component<ViewTheoryProps, ViewTheoryState> {
    viewerRef:React.RefObject<FetchViewer>;
    editorRef: React.RefObject<Editor>

    constructor(props:ViewTheoryProps) {
        super(props);
        this.state = {
            theory: { 
                id: "", 
                title: "", 
                author: {
                    id: "",
                    name: "", 
                    reputation: 0, 
                    achievements: { 
                        gold: 0, 
                        silver: 0, 
                        bronze: 0 
                    },
                    profileImageUrl:  ""
                }, 
                date: new Date(), 
                content: "{}", 
                tags: [],
                votes: 0,
                arguments: [],
                userVote: 0,
                hasFavorited: false,
            },
            content: {},
            loading: true,
            isMenuOpen: false,
            isCommentPosted: false,
            isCommentPosting: false
        }

        this.viewerRef = React.createRef();
        this.editorRef = React.createRef();

        this.openContextMenu = this.openContextMenu.bind(this);
        this.closeContextMenu = this.closeContextMenu.bind(this);

        this.postArgument = this.postArgument.bind(this);
        this.upVote = this.upVote.bind(this);
        this.downVote = this.downVote.bind(this);
    }

    componentDidMount(): void {
        ga4events.sendPageView(window.location.pathname, 'View theory');
        Theory.ViewTheory(this.props.params.identifier, this.props.params.seoFriendlyUrl).then(theory => {
            this.setState({...this.state, loading: false}, () => {
                const content = JSON.parse(theory.content);
                if(this.viewerRef.current) {
                    this.setState({...this.state, theory: theory, content: content}, () => {
                        if(this.viewerRef.current) {
                            this.viewerRef.current.setContent(content);
                            this.setState({...this.state, loading: false});
                        }
                    });
                }
            });
        });
    }

    openContextMenu(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            ...this.state,
            isMenuOpen: true,
            menuAnchor: event.currentTarget
        });
    }

    closeContextMenu() {
        this.setState({
            ...this.state,
            isMenuOpen: false
        });
    }

    postArgument() {
        this.setState({...this.state, isCommentPosting: true}, () => {
            this.editorRef.current?.save().then(content => {
                Theory.CreateArgument(this.state.theory.id, JSON.stringify(content), "Answer preview").then(argument => {
                    Theory.ViewTheory(this.props.params.identifier, this.props.params.seoFriendlyUrl).then(theory => {
                        const content = JSON.parse(theory.content);
                        if(this.viewerRef.current) {
                            this.setState({...this.state, theory: theory, content: content}, () => {
                                if(this.viewerRef.current) {
                                    this.viewerRef.current.setContent(content);
                                    this.setState({...this.state, loading: false, isCommentPosting: false, isCommentPosted: true}, () => {
                                        this.editorRef.current?.clear();
                                        setTimeout(() => {
                                            this.setState({...this.state, isCommentPosted: false});
                                        }, 2200);
                                    });
                                }
                            });
                        }
                    });
                });
            }).catch(error => {
                console.log(error);
            });
        });
    }

    upVote() {
        Vote.Upvote(this.state.theory.id).then(() => {
            const votes = this.state.theory.userVote === 0 ? this.state.theory.votes + 1 : this.state.theory.votes;
            this.setState({...this.state, theory: {...this.state.theory, userVote: 1, votes:votes}})
        });
    }

    downVote() {
        Vote.Downvote(this.state.theory.id).then(() => {
            const votes = this.state.theory.userVote === 0 ? this.state.theory.votes - 1 : this.state.theory.votes;

            this.setState({...this.state, theory: {...this.state.theory, userVote: -1, votes: votes}})
        });
    }

    onPostDeleted() {
        router.navigate("/");
    }

    render() {
        if (this.state.loading)
        {
            return <ViewContentShadow />
        }
        else 
        {
            return (
                <HelmetProvider>
                    <Helmet>
                        <meta name='title' property="og:title" content={this.state.theory.title}/>
                        <meta name='type' property="og:type" content="article"/>
                        <meta name='description' property="og:description" content="View this question on Fetch.team!" />
                        <meta name='url' property="og:url" content={window.location.href} />
                        <meta name="twitter:card" property='twitter:card' content="summary_large_image" />
                        <meta name='published' property='article:published_time' content={this.state.theory.date.toString()}/>
                        <meta name='modified' property='article:modified_time' content={this.state.theory.date.toString()}/>
                        <meta name='author' property='article:author' content={this.state.theory.author.name}/>
                        <meta name='tags' property='article:tag ' content={this.state.theory.tags.toString()}/>
                        <meta name='section' property='article:section ' content='Financial education'/>
                        <meta name='sitename' property='og:site_name ' content='Fetch.team'/>
                        <script type="text/javascript" src="https://platform-api.sharethis.com/js/sharethis.js#property=6554b0907a115e0012964612&product=inline-share-buttons&source=platform"></script>
                    </Helmet>
                    <Stack gap={"24px"} sx={{width: "100%"}}>
                        <div className="sharethis-sticky-share-buttons"></div>
                        <Card style={cardStyle}>
                            <CardContent style={{textAlign: "left"}}>
                                <Stack sx={{marginLeft: "-12px"}} direction="row">
                                    <Stack gap={"6px"} alignItems="center" justifyItems="center" alignContent="center" style={leftSidebarStyle}>
                                        <IconButton onClick={this.upVote}>
                                            <KeyboardArrowUpIcon style={{...(this.state.theory.userVote === 1) && {color: "var(--primary-color)"}}} />
                                        </IconButton>
                                        <Typography>{this.state.theory.votes}</Typography>
                                        <IconButton onClick={this.downVote}>
                                            <KeyboardArrowDownIcon style={{...(this.state.theory.userVote === -1) && {color: "var(--primary-color)"}}} />
                                        </IconButton>
                                    </Stack>
                                    <Stack gap={"18px"}>
                                        <Stack gap={"12px"} direction="row" style={cardHeaderStyle}>
                                            <AuthorPopover whoAmI={this.props.loaderData} author={this.state.theory.author}>
                                                <Avatar src={this.state.theory.author.profileImageUrl.length > 0 ? this.state.theory.author.profileImageUrl : undefined} sx={{ bgcolor: red[500] }} aria-label="recipe">
                                                    {this.state.theory.author.name[0]}
                                                </Avatar>
                                            </AuthorPopover>
                                            <Box>
                                                <div style={{textAlign: "left", fontWeight: "bold"}}>{this.state.theory.author.name}</div>
                                                <div style={{textAlign: "left"}}><AuthorReputation author={this.state.theory.author}/></div>
                                            </Box>
                                            <Box sx={{flexGrow: 1}}></Box>
                                            <Box>
                                                <Stack direction="row">
                                                    <Tags tags={this.state.theory.tags}/>
                                                    <IconButton onClick={this.openContextMenu}>
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                </Stack>
                                            </Box>
                                        </Stack>
                                        <Stack>
                                            <Typography variant="h5" color="text.secondary">
                                                {this.state.theory.title}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {FormatDate(new Date(this.state.theory.date))}
                                            </Typography>
                                        </Stack>
                                        <FetchViewer ref={this.viewerRef} />
                                        <div className="sharethis-container">
                                        <InlineShareButtons
                                            config={{
                                                alignment: 'center',  // alignment of buttons (left, center, right)
                                                color: 'social',      // set the color of buttons (social, white)
                                                enabled: true,        // show/hide buttons (true, false)
                                                font_size: 16,        // font size for the buttons
                                                labels: 'cta',        // button labels (cta, counts, null)
                                                language: 'en',       // which language to use (see LANGUAGES)
                                                networks: [           // which networks to include (see SHARING NETWORKS)
                                                'whatsapp',
                                                'reddit',
                                                'twitter',
                                                'facebook',
                                                'messenger',
                                                ],
                                                padding: 12,          // padding within buttons (INTEGER)
                                                radius: 4,            // the corner radius on each button (INTEGER)
                                                show_total: false,
                                                size: 40,             // the size of each button (INTEGER)


                                                // OPTIONAL PARAMETERS

                                                // min_count: 10,                    // (threshold for total share count to be displayed)
                                                // url: 'https://www.sharethis.com', // (defaults to current url)
                                                // image: 'https://bit.ly/2CMhCMC',  // (defaults to og:image or twitter:image)
                                                // description: 'custom text',       // (defaults to og:description or twitter:description)
                                                // title: 'custom title',            // (defaults to og:title or twitter:title)
                                                // message: 'custom email text',     // (only for email sharing)
                                                // subject: 'custom email subject',  // (only for email sharing)
                                                // username: 'custom twitter handle' // (only for twitter sharing)
                                            }}
                                        />
                                    </div>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>
                        <AuthorizeView whoAmI={this.props.loaderData} roles={ROLES_FREE}>
                            <Card>
                                <CardHeader subheader={<div style={{textAlign: "left"}}>Add an argument</div>}></CardHeader>
                                <CardContent>
                                    <Editor ref={this.editorRef} />
                                </CardContent>
                                <CardActions sx={{margin: "12px"}}>
                                    <LoadableButton hasConfetti loading={this.state.isCommentPosting} onClick={this.postArgument} style={{marginLeft: "auto"}} variant='contained'>
                                        Post argument
                                    </LoadableButton>
                                </CardActions>
                            </Card>
                        </AuthorizeView>
                        {this.state.theory.arguments.map((argument, i) => {
                            return (
                                <ViewArgument theory={this.state.theory} key={argument.argumentId} argument={argument} />
                            )
                        })}
                        <FeedItemCardMenu whoAmI={this.props.loaderData} onDelete={this.onPostDeleted} hasFavorited={this.state.theory.hasFavorited} contentId={this.state.theory.id} author={this.state.theory.author} isHidden={false} onClose={this.closeContextMenu} open={this.state.isMenuOpen} anchor={this.state.menuAnchor} />
                    </Stack>
                </HelmetProvider>
            ); 
        }
    }
}

export default withParams(ViewTheory);