import { Box } from '@mui/material';
import React from 'react';
import FetchEditorTextBlock from './Blocks/FetchEditorTextBlock';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';
import FetchEditorChartBlock from './Blocks/FetchEditorChartBlock';
import { generateRandomUUID } from '../../Helpers/IdHelper';
import FetchEditorYoutubeBlock from './Blocks/FetchEditorYoutubeBlock';
import FetchEditorImageBlock from './Blocks/FetchEditorImageBlock';
import FetchEditorChartJsBlock from './Blocks/FetchEditorChartJsBlock';
import FetchEditorPostEmbedBlock from './Blocks/FetchEditorPostEmbedBlock';

export default class FetchEditorBlock extends React.Component {
    blockId;
    
    constructor(props) {
        super(props);
        this.focus = this.focus.bind(this);
        this.reference = React.createRef();
        this.updateStateObject = this.updateStateObject.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.blockId = generateRandomUUID();
        this.getRawData = this.getRawData.bind(this);
    }

    focus(object, id) {
        this.props.onFocus(object, id);
    }

    updateStateObject(object) {
        this.reference.current.editorChange(object);
    }

    getStateObject() {
        return this.reference.current.getEditor();
    }

    onDelete() {
        if(this.props.onDelete) {
            this.props.onDelete(this.props.id);
        }
    }

    async getData() {
        return await this.reference.current.getData();
    }

    getRawData() {
        if(this.props.type === "text") {
            return this.reference.current?.getRawText();
        }

        else {
            return "";
        }
    }

    render() {
        let block = null
        if(this.props.type === 'text') {
            block = <FetchEditorTextBlock ref={this.reference} id={this.props.id} data={this.props.data} onFocus={this.focus}/>;
        }
        else if(this.props.type === 'chart') {
            block = <FetchEditorChartBlock ref={this.reference} id={this.props.id} data={this.props.data} onFocus={this.focus} />;
        }

        else if(this.props.type === 'chartjs') {
            block = <FetchEditorChartJsBlock ref={this.reference} id={this.props.id} data={this.props.data} onFocus={this.focus} onDelete={this.onDelete} />;
        }

        else if(this.props.type === 'youtube') {
            block = <FetchEditorYoutubeBlock ref={this.reference} id={this.props.id} data={this.props.data} onFocus={this.focus} onDelete={this.onDelete} />;
        }

        else if(this.props.type === 'image') {
            block = <FetchEditorImageBlock ref={this.reference} id={this.props.id} data={this.props.data} onFocus={this.focus} onDelete={this.onDelete} />;
        }

        else if(this.props.type === 'postembed') {
            block = <FetchEditorPostEmbedBlock ref={this.reference} id={this.props.id} data={this.props.data} onFocus={this.focus} onDelete={this.onDelete} />;
        }

        return(
            <Stack direction="row">
                <div style={{backgroundColor: "var(--primary-color)", minWidth: "4px", marginLeft: "14px", borderRadius: "40px", display: "block"}}>
                    
                </div>
                <Box sx={{
                    padding: '24px',
                    width: '100%'}}>

                    {block}

                </Box>
                {
                    (!this.props.firstBlock && 
                        <Box style={{display: "flex", justifyContent: "center", margin: "0 14px"}}>
                            <IconButton onClick={this.onDelete} sx={{margin: "auto 0", transition: "all 0.2s ease", border: "1px #bbb solid", borderRadius: '100%', "&:hover": {color: "var(--error-color)", borderColor: "var(--error-color)"}}} size="small">
                                <DeleteIcon/>
                            </IconButton>
                        </Box>
                    )
                }
            </Stack>
        );
    }
}