import { Button, Stack, TextField, Typography, Box, Alert, Dialog, DialogTitle, DialogActions } from '@mui/material';
import { Link } from "react-router-dom";
import React, { FormEventHandler } from 'react';
import Account from '../API/Account';
import Data from '../API/Data';
import {router} from "../App";

import { CredentialResponse, GoogleLogin } from '@react-oauth/google';


import { withParams } from '../Helpers/ReactRouterHelper';
import { setCookie } from '../API/resources/cookies';
import GoogleTapSignIn from '../Static/googleTapSignIn';

type LoginParams = {
    error?:string
}

type LoginProps = {
    params: LoginParams
}

type LoginState = {
    username:string,
    password:string,
    error?:string,
    tokenCache?: string | undefined,
    enterUsername: boolean,
    newUsername: string
}

class Login extends React.Component<LoginProps, LoginState> {
    constructor(props:LoginProps) {
        super(props);

        this.state = {
            username:"",
            password: "",
            newUsername: "",
            enterUsername: false
        }

        this.onPasswordChanged = this.onPasswordChanged.bind(this);
        this.onUsernameChanged = this.onUsernameChanged.bind(this);
        this.onNewUsernameChanged = this.onNewUsernameChanged.bind(this);
        this.onUsernameDialogClose = this.onUsernameDialogClose.bind(this);
        this.onLogin = this.onLogin.bind(this);

        this.onGoogleLoginError = this.onGoogleLoginError.bind(this);
        this.onGoogleLoginSuccess = this.onGoogleLoginSuccess.bind(this);
        this.signInGoogle = this.signInGoogle.bind(this);
    }

    componentDidMount(): void {
        const token = Data.GetAccessToken();
        if(token) {
            router.navigate("/");
        }
    }

    onUsernameChanged(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, username: event.currentTarget.value});
    }

    onPasswordChanged(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, password: event.currentTarget.value});
    }

    onNewUsernameChanged(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, newUsername: event.currentTarget.value});
    }

    onUsernameDialogClose() {
        this.setState({...this.state, enterUsername: false, newUsername: ""});
    }
    
    onLogin(event:any) {
        event.preventDefault();
        Data.Authenticate(this.state.username, this.state.password).then(response => {
            router.navigate("/");
        }).catch(error => {
            console.log(error);
            this.setState({...this.state, error: error.title});
        })
    }

    onGoogleLoginSuccess(response:CredentialResponse) {
        if(!response.credential) return;
        Account.GoogleSignInPreflight(response.credential).then(accountExists => {
            console.log(accountExists);
            if(accountExists) {
                if(!response.credential) return;
                Account.GoogleSignIn(response.credential).then(response => {
                    setCookie("auth", JSON.stringify(response), response.expiresOn);
                    router.navigate("/");
                });
            }

            else {
                this.setState({...this.state, tokenCache: response.credential, enterUsername: true});
            }
        });
    }

    onGoogleLoginError() {

    }

    signInGoogle() {
        if(this.state.tokenCache == null) return;

        Account.GoogleSignIn(this.state.tokenCache, this.state.newUsername).then(response => {
            setCookie("auth", JSON.stringify(response), response.expiresOn);
            router.navigate("/");
        });
    }

    render() {
        return(
            <Box>
                <form action='#' onSubmit={this.onLogin}>
                    {this.state.error && (
                        <Alert severity="error">{this.state.error}</Alert>
                    )}
                    <Stack gap={5}>
                        <Box style={{textAlign: "center"}}>
                            <Typography variant="h6">Log in to your account</Typography>
                        </Box>
                        <Stack gap={2}>
                            <TextField size="small" onChange={this.onUsernameChanged} variant="filled" fullWidth id='username' placeholder='E-mail' label="E-mail"/>
                            <TextField size="small" onChange={this.onPasswordChanged} variant="filled" fullWidth id='password' placeholder='Password' label='Password' type="password" />
                            {this.props.params.error && (
                                <Alert severity='warning'>{this.props.params.error}</Alert>
                            )}
                        </Stack>
                        <Stack gap={2}>
                            <Button type="submit" variant='contained' fullWidth>Login</Button>
                            <GoogleTapSignIn button />
                            <Button component={Link} to={"/"} variant='outlined' fullWidth>Browse app</Button>
                            <Button component={Link} to={"/register"} variant='outlined' fullWidth>Don't have an account? Sign up</Button>
                            <Button component={Link} to={"/forgot/password"} variant='outlined' fullWidth>Forgot your password?</Button>
                        </Stack>
                    </Stack>
                </form>
                <Dialog open={this.state.enterUsername}>
                    <Stack style={{padding: "16px"}} spacing={2}>
                        <Typography variant='body1'>Please provide a username</Typography>
                        <Typography variant='caption'>In order to finalize your account please provide a username</Typography>
                        <TextField size="small" onChange={this.onNewUsernameChanged} variant="filled" fullWidth id='new-username' placeholder='Username' label="Username"/>
                        <DialogActions>
                            <Button autoFocus onClick={this.onUsernameDialogClose}>
                                Cancel
                            </Button>
                            <Button onClick={this.signInGoogle}>Create</Button>
                        </DialogActions>
                    </Stack>
                </Dialog>
            </Box>
        )
    }
}

export default withParams(Login);