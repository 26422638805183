import Box from '@mui/material/Box';
import React from 'react';
import TVChart from '../../Charts/TVChart';


export default class FetchViewerChartBlock extends React.Component {
    constructor(props) {
        super(props);
        this.reference = React.createRef();
        this.getData = this.getData.bind(this);
        this.data = JSON.parse(this.props.data);
    }

    componentDidMount() {
        if(this.props.data) {
            this.reference.current.loadChart(this.data.state, this.data.range);
        }
    }

    async getData() {
        const data = await this.reference.current.getData();
        return data;
    }

    render() {
        return(
            <Box style={{borderRadius: "8px", overflow: "hidden"}}>
                <TVChart ref={this.reference} disabledFeatures={[
                    "header_widget",
                    "edit_buttons_in_legend",
                    "context_menus",
                    "left_toolbar",
                    "control_bar",
                    "timeframes_toolbar"
                ]}/>
            </Box>
        )
    }
}