export function ParseErrors(errors:any): string[] {

    console.log(errors);

    if (errors.title) {
        return [errors.title];
    }

    const errorList:string[] = [];
    if(Array.isArray(errors)) {
        for(const error in errors) {
            errorList.push(error);
        }
    }
    else if(errors.Errors) {
        for(const error of errors.Errors) {
            errorList.push(error);
        }
    }

    return errorList;
}