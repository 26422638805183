import React from 'react';
import Button from '@mui/material/Button';
import { Box, CircularProgress } from '@mui/material';
import ConfettiExplosion from 'react-confetti-explosion';

type LoadableButtonProps = {
    children: React.ReactNode,
    variant: "contained" | "outlined" | "text" | undefined,
    color: "inherit" | "error" | "primary" | "secondary" | "info" | "success" | "warning" | undefined,
    onClick: Function,
    loading: boolean,
    hasConfetti?: boolean,
    style?: React.CSSProperties
}

type LoadableButtonState = {
    showConfetti: boolean
}

export default class LoadableButton extends React.Component<LoadableButtonProps, LoadableButtonState> {
    static defaultProps = {
        loading: false,
        hasConfetti: false,
        color: "primary",
        variant: "contained"
     };
     constructor(props:LoadableButtonProps) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.state = {
            showConfetti: false
        };
     }
     onClick() {
        this.props.onClick();
     }

     componentDidUpdate(prevProps: Readonly<LoadableButtonProps>, prevState: Readonly<{}>, snapshot?: any): void {
         if(this.props.hasConfetti) {
            if(prevProps.loading && !this.props.loading) {
                this.setState({...this.state, showConfetti: true}, () => {
                    setTimeout(() => {
                        this.setState({...this.state, showConfetti: false})
                    }, 2200);
                })
            }
         }
     }

     render() {
        return(
            <Box style={this.props.style}>
                {this.state.showConfetti && 
                    <ConfettiExplosion 
                        force={0.4}
                        duration={2200}
                        width={300}
                        particleCount={30}
                        colors={[
                            "#df37a5",
                            "#2d46b9",
                            "#1e3163"
                        ]}
                    />}
                <Button 
                    disabled={this.props.loading}
                    color={this.props.color}
                    variant={this.props.variant}
                    startIcon={this.props.loading ? <CircularProgress size={20} /> : undefined}
                    onClick={this.onClick}
                >{this.props.children}</Button>
            </Box>
        )
    }
}