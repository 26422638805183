import React from 'react';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LoginIcon from '@mui/icons-material/Login';

import SettingsIcon from '@mui/icons-material/Settings';
import AppsIcon from '@mui/icons-material/Apps';
import EditIcon from '@mui/icons-material/Edit';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { router } from '../App';
import { Badge, Paper } from '@mui/material';
import { WhoAmIResponse } from '../API/models/account';
import NotificationController from '../API/NotificationController';

type MobileLayoutNavigationProps = {
    whoAmI:WhoAmIResponse
}

type MobileLayoutNavigationState = {
    navValue:any,
    isLoggedIn: boolean ,
    notificationCount:number
}

export default class MobileLayoutNavigation extends React.Component<MobileLayoutNavigationProps, MobileLayoutNavigationState> {
    constructor(props:MobileLayoutNavigationProps) {
        super(props);
        this.state = {navValue: null, isLoggedIn: this.props.whoAmI.roles.length > 0, notificationCount: 0};

        this.navigationValueChanged = this.navigationValueChanged.bind(this);
    }

    componentDidMount(): void {
        if(this.state.isLoggedIn) {
            NotificationController.GetCount().then(notificationCount => {
                this.setState({...this.state, notificationCount: notificationCount});
            });
        }
    }

    navigationValueChanged(event:any, value:any) {
        this.setState({...this.state, navValue: value});
    }

    gotoNew() {
        router.navigate("/new");
    }

    gotoHome() {
        router.navigate("/");
    }

    gotoSettings() {
        router.navigate("/settings");
    }

    goToProfile() {
        router.navigate("/profile");
    }

    goToNotifications() {
        router.navigate("/notifications");
    }

    goToLogin() {
        router.navigate("/login");
    }

    getNotificationIcon() : JSX.Element {
        if(this.state.notificationCount > 0) {
            return <Badge badgeContent={this.state.notificationCount} color="primary"><NotificationsActiveIcon/></Badge>
        }

        else {
            return <NotificationsIcon />
        }
    }

    render() {
        return(
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation
                showLabels
                value={this.state.navValue}
                onChange={this.navigationValueChanged}
            >                
                <BottomNavigationAction onClick={this.gotoHome} label="Feed" icon={<AppsIcon />} />
                {this.state.isLoggedIn && <BottomNavigationAction onClick={this.gotoNew} label="New" icon={<EditIcon />} />}
                {this.state.isLoggedIn && <BottomNavigationAction onClick={this.goToProfile} label="Profile" icon={<AccountCircleIcon />} />}
                {this.state.isLoggedIn && <BottomNavigationAction onClick={this.goToNotifications} label="Notifications" icon={this.getNotificationIcon()} />}
                {!this.state.isLoggedIn && <BottomNavigationAction onClick={this.goToLogin} label="Login" icon={<LoginIcon />} />}
            </BottomNavigation>
        </Paper>
        );
    }
}