import { Box } from "@mui/system";
import React from "react";

export default class FetchViewerImageBlock extends React.Component {
    constructor(props) {
        super(props);
        this.data = JSON.parse(this.props.data);
    }

    render() {
        return(
            <Box>
                <img src={this.data.src} alt="view block" style={{width: "100%"}} />
            </Box>
        )
    }
}