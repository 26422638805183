import { createTheme, ThemeOptions } from "@mui/material";

// declare module '@mui/material/styles' {
//     interface Theme {
//         status: {
//         danger: string;
//         };
//     }
//     // allow configuration using `createTheme`
//     interface ThemeOptions {
//         palette:PaletteOptions
//     }
// }

export const themeOptions: ThemeOptions = {
    palette: {
      primary: {
        main: '#df37a5',
        light: '#fffbfe',
        dark: '#49454f',
      },
      secondary: {
        main: '#f50057',
      },
      background: {
        default: '#f3edf7',
        paper: '#f7f2f9',
      },
      text: {
        secondary: '#49454f',
      },
      divider: '#ccc',
    },
    // typography: {
    //   allVariants: {
    //     fontFamily: "Inter",
    //     fontWeight: 400,
    //   },
    // },
    components: {
      // MuiDivider: {
      //   // defaultProps: {
      //   //   background: "red"
      //   // },
      //   styleOverrides: {
      //     root: {
      //       // borderColor: '#ccc'
      //     }
      //   }
      // }

      // MuiTypography: {
      //   variants: [
      //     {
      //       props: { variant: "title-medium" },
      //       style: {
      //         fontSize: "10pt",
      //         fontWeight: "bold"
      //       }
      //     }
      //   ]
      // },

      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '100px',
            boxShadow: "none"
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "12px"
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer"
            }
          },
        }
      }
    }
  };

export const theme = createTheme(themeOptions);

export const tagStyle:React.CSSProperties = {
  borderRadius: 8,
  backgroundColor: "#e8def8",
  textAlign: "center",
  padding: "6px 12px",
  fontWeight: "bold"
}

export const itemSpacing = "24px";
export const defaultElevation = 2;