import { Box, Stack } from '@mui/material';
import React from 'react';

type SimpleLayoutProps = {
    showLogo? : boolean,
    hideOverflow?: boolean,
    children? : JSX.Element | JSX.Element[]
}

const pageStyle: React.CSSProperties = {
    background: "radial-gradient(55.87%55.87%at 35.49%-18.37%,#dd45d3 0%,rgba(255,255,255,0)100%),radial-gradient(91.61%92.58%at 104.86%-43.36%,#fd9d52 0%,rgba(255,255,255,0)100%),radial-gradient(50.59%55.55%at -2.99%-8.69%,#dd45d3 9.06%,rgba(255,255,255,0)100%)",
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // background: "yellow"
}

const boxStyle: React.CSSProperties = {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
}

export default class SimpleLayout extends React.Component<SimpleLayoutProps> {
    render() {
        return(
            <Stack style={{...pageStyle, overflow : this.props.hideOverflow !== false ? "hidden" : "visible"}} >
                {/* <Box style={{minWidth: "min(100vw, 800px)"}}>
                    <Stack direction="row" style={{padding: "18px 18px 0 18px", alignItems: "center"}}>
                        <img title="logo" src="/logo-header.png" height={80} />   
                        <Spacer />
                        <Typography>Don't have an account? <Link>Sign up.</Link></Typography>
                    </Stack>
                </Box> */}
                <Box style={boxStyle}>
                    <Stack style={{minWidth: "min(360px, 100vw)", flexGrow: 1}} gap={4}>
                        {this.props.showLogo !== false &&
                        <Box>
                            <img alt='logo' title="logo" src="/logo.png" height={200} className="simple-layout-logo" />
                        </Box>}
                        <Box style={{flexGrow: 1, padding: "0 18px 18px 18px"}}>
                            {this.props.children}
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        )
    }
}