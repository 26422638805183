import { Stack, Typography } from '@mui/material';
import React from 'react';

type PollBarProps = {
    name:string,
    option:number,
    percentage:number,
    votes:number,
    hasVoted:boolean,
}

const barStyle:React.CSSProperties = {
    width: "100%",
    borderRadius: "6px",
    // margin: "4px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // padding: "0px 12px"

    padding: "0 12px",
    height: "36.5px"
}

const secondaryColor = "#e8def8";
const secondaryBorderColor = "#9993a4";

export default class PollBar extends React.Component<PollBarProps> {
    render() {
        const color = this.props.hasVoted ? "var(--primary-color)" : secondaryColor;
        const borderColor = this.props.hasVoted ? "var(--primary-color)" : secondaryBorderColor;
        const gradient = `linear-gradient(90deg, ${color} ${this.props.percentage}%, ${color} ${this.props.percentage}%, rgba(255,255,255,0) ${this.props.percentage}%, rgba(255,255,255,0) 100%)`
        return(
            <Stack direction="row" style={{alignItems: "center"}}>
                {/* <Radio onClick={this.onChange} checked={this.props.hasVoted} sx={{ '& .MuiSvgIcon-root': { fontSize: "28pt" } }}/> */}
                <div style={{...barStyle, background: gradient, borderColor: borderColor}}>
                    <Typography style={{width: "50px", fontWeight: "bold"}}>{this.props.percentage.toFixed(0)}%</Typography>
                    <Typography>{this.props.name}</Typography>
                </div>
            </Stack>
        )
    }
}
