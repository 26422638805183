import { ViewCommentResult } from "./comments"
import { AuthorDTO } from "./datatransferobjects"

export interface CreateTheoryRequest {
    title:string,
    content:string, 
    contentPreview:string,
    tags:string[]
}

export interface UpdateTheoryRequest {
    title:string,
    content:string, 
    contentPreview:string,
    tags:string[]
}

export interface CreateArgumentRequest {
    content:string,
    contentPreview:string
}

export interface UpdateArgumentRequest {
    content:string,
    contentPreview:string
}

export interface ViewTheoryResult {
    id:string,
    title:string,
    author:AuthorDTO,
    date:Date,
    content:string,
    tags:string[],
    userVote: number,
    hasFavorited: boolean,
    votes:number,
    arguments:ViewArgumentResult[]
}

export interface ViewArgumentResult {
    argumentId:string,
    title:string,
    author:AuthorDTO,
    date:Date,
    content:string,
    state:ArgumentState,
    votes:number,
    numberOfComments: number,
    hasFavorited: boolean,
    comments:ViewCommentResult[]
}

export enum ArgumentState {
    Default,
    ConsensusAgreed,
    ConsensusDisagreed
}