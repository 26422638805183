import { Bar } from "../Content/Charts/charting_library/charting_library";
import Data from "./Data";
import { GetBarsRequest, GetBarsResponse, InsertBarsRequest, Symbol } from '../API/models/market';
import { Bar as ModelBar} from "../API/models/market";
import { PeriodParams } from "../../public/charting_library/datafeed-api";
export const dataFeedRoute = "/DataFeed";


export default abstract class DataFeedAPI {

    public static async GetSymbols() : Promise<Symbol[]> {
        return new Promise((resolve, reject) => {
            Data.Get<Symbol[]>(`${dataFeedRoute}/Symbols`).then(symbols => {
                resolve(symbols);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async CreateSymbol(symbol:Symbol) : Promise<Symbol> {
        return new Promise<Symbol>((resolve, reject) => {
            Data.Post<Symbol, Symbol>(`${dataFeedRoute}/Symbols`, symbol).then(symbol => {
                resolve(symbol);
            }).catch(error => {
                reject(error);
            })
        });
    }

    public static async GetBars(symbolId: number, start: number, end: number) : Promise<Bar[]> {
        return new Promise<Bar[]>((resolve, reject) => {
            Data.Get<Bar[]>(`${dataFeedRoute}/Bars/${symbolId}/${start}/${end}`).then((bars) => {
                resolve(bars);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async GetBars2(symbolId:number, params:PeriodParams) : Promise<GetBarsResponse> {
        return new Promise<GetBarsResponse>((resolve, reject) => {
            Data.Post<GetBarsRequest, GetBarsResponse>(`${dataFeedRoute}/GetBars`, {...params, symbolId: symbolId, resolutionId: 1}).then(bars => {
                resolve(bars);
            }).catch(error => {
                reject(error);
            })
        });
    }

    public static async InsertBars(symbolId:number, resolutionId:number, bars:ModelBar[]) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Post<InsertBarsRequest, void>(`${dataFeedRoute}/Bars`,
                {symbolId: symbolId, resolutionId: resolutionId, bars: bars})
            .then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }
}