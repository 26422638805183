import Data from "./Data";
import { LoginRequest, LoginResponse } from "./models/authentication";
import { deleteCookie } from "./resources/cookies";

export default abstract class Authentication {
    public static async Login(loginRequest:LoginRequest) : Promise<LoginResponse> {
        return new Promise<LoginResponse>((resolve, reject) => {
            Data.Authenticate(loginRequest.email, loginRequest.password).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static Logout() {
        deleteCookie("auth");
    }
}