import { WhoAmIResponse } from "../API/models/account";

export function hasRole(whoAmI:WhoAmIResponse, roles:string[]) {
    for(const role of roles) {
        for(const userRole of whoAmI.roles) {
            if(role === userRole) {
                return true;
            }
        }
    }

    return false;
}
    