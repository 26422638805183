import React from 'react';

import { Button, Stack } from '@mui/material';

import { PollDataDTO } from '../../API/models/datatransferobjects';

import Typography from '@mui/material/Typography';


import Poll from '../../API/Poll';
import PollBar from './PollBar';
import moment from 'moment';
import { momentDurationToString } from "../../Helpers/Formatters";

type PollVoteProps = {
    pollId:string
}

type PollVoteState = {
    poll:PollDataDTO,
    error?:string
}

export default class PollVote extends React.Component<PollVoteProps, PollVoteState> {

    constructor(props:PollVoteProps) {
        super(props);
        this.state = {
            poll: {
                question:"",
                option1:"",
                option2:"",
                option3:"",
                option4:"", 
                option1Votes:0,
                option2Votes:0,
                option3Votes:0,
                option4Votes:0,
                option1Percentage:0, 
                option2Percentage:0,
                option3Percentage:0,
                option4Percentage:0,
                numberOfOptions:0, 
                userVotedOnOption:0,
                pollEndsOn: new Date()
            }
        };

        this.onVote = this.onVote.bind(this);
    }

    componentDidMount(): void {
        if(this.props.pollId.length > 0) {
            Poll.GetPollData(this.props.pollId).then(pollData => {
                this.setState({...this.state, poll: pollData});
            });
        }
    }
    
    onVote(option:number) {
        Poll.VoteOnPoll(this.props.pollId, option).then(() => {
            Poll.GetPollData(this.props.pollId).then(poll => {
                this.setState({...this.state, poll:poll });
            });
        }).catch(error => {
            this.setState({...this.state, error: error.title})
        });
    }

    render() {
        let totalVotes = this.state.poll.option1Votes + 
            this.state.poll.option2Votes + 
            this.state.poll.option3Votes + 
            this.state.poll.option4Votes;

        let end = moment(this.state.poll.pollEndsOn);
        let duration = moment.duration(end.diff(moment()));
        let pollEnded = (new Date(this.state.poll.pollEndsOn)).getTime() < (new Date()).getTime();
        let pollEndsIn = !pollEnded ? momentDurationToString(duration) : "Final results";

        return(
        <Stack spacing={2}>
            <Typography variant="body2">{this.state.poll.question}</Typography>
            {
                (this.state.poll.userVotedOnOption < 0 && !pollEnded) &&
                    <Stack spacing={1}>
                        <Button variant="outlined" onClick={() => this.onVote(0)}>{this.state.poll.option1}</Button>
                        <Button variant="outlined" onClick={() => this.onVote(1)}>{this.state.poll.option2}</Button>
                        {
                            this.state.poll.option3 &&
                                <Button variant="outlined" onClick={() => this.onVote(2)}>{this.state.poll.option3}</Button>
                        }
                        {
                            this.state.poll.option4 && 
                                <Button variant="outlined" onClick={() => this.onVote(3)}>{this.state.poll.option4}</Button>
                        }
                    </Stack>
            }
            {
                (this.state.poll.userVotedOnOption >= 0 || pollEnded) &&
                    <Stack spacing={1} className={"poll-animation"}>
                        <PollBar option={0} name={this.state.poll.option1} percentage={this.state.poll.option1Percentage * 100} votes={this.state.poll.option1Votes} hasVoted={this.state.poll.userVotedOnOption === 0} />
                        <PollBar option={1} name={this.state.poll.option2} percentage={this.state.poll.option2Percentage * 100} votes={this.state.poll.option2Votes} hasVoted={this.state.poll.userVotedOnOption === 1} />
                        {
                            this.state.poll.option3 &&
                                <PollBar option={2} name={this.state.poll.option3} percentage={this.state.poll.option3Percentage * 100} votes={this.state.poll.option3Votes} hasVoted={this.state.poll.userVotedOnOption === 2} />
                        }
                        {
                            this.state.poll.option4 &&
                                <PollBar option={3} name={this.state.poll.option4} percentage={this.state.poll.option4Percentage * 100} votes={this.state.poll.option4Votes} hasVoted={this.state.poll.userVotedOnOption === 3} />
                        }                                            
                    </Stack>
            }
            <Stack direction="row" spacing={1}>
                <Typography variant='body2'>
                    {totalVotes} vote{ totalVotes !== 1 && 's' /* jsuwu */ }
                </Typography>
                <Typography variant='body2'>
                    •
                </Typography>
                <Typography variant='body2'>
                    {pollEndsIn}
                </Typography>
            </Stack>
        </Stack>)     
    }
}