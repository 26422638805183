import { Alert, Box, Button, Stack, TextField } from '@mui/material';
import React from 'react';
import Account from '../API/Account';
import { router } from '../App';
import { withParams } from '../Helpers/ReactRouterHelper';

type ConfirmEmailChangeParams = {
    token: string
}

type ConfirmEmailProps = {
    params:ConfirmEmailChangeParams
}

type ConfirmEmailState = {
    email:string,
    error?:string,
    succeeded: boolean
}

class ConfirmEmailChange extends React.Component<ConfirmEmailProps, ConfirmEmailState> {
    constructor(props:ConfirmEmailProps) {
        super(props);
        this.state = {
            email: "",
            succeeded: false
        }

        this.onEmailChange = this.onEmailChange.bind(this);
        this.onEmailConfirm = this.onEmailConfirm.bind(this);
    }

    componentDidMount(): void {
        Account.IsChangeMailTokenValid(this.props.params.token).then(isValid => {
            if(!isValid) {
                // Do something
            }
        });
    }

    onEmailChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, email: event.currentTarget.value});
    }

    onEmailConfirm() {
        Account.CompleteEmailChange(this.state.email, this.props.params.token).then(() => {
            this.setState({...this.state, succeeded: true},  () => {
                setTimeout(() => {
                    router.navigate("/login");
                }, 2000);
            })
        });
    }

    render(): React.ReactNode {
        return(
            <Stack gap="12px">
                {this.state.succeeded && 
                    <Alert severity="success">We've succesfuly updated your e-mail address</Alert>
                }
                <TextField label="Confirm new e-mail" placeholder='new e-mail' variant='outlined' value={this.state.email} onChange={this.onEmailChange} fullWidth/>
                <Button variant="contained" onClick={this.onEmailConfirm} fullWidth>Confirm</Button>
            </Stack>
        )
    }
}

export default withParams(ConfirmEmailChange);