import React from 'react';
import { CallBackProps, Step, ACTIONS, EVENTS, STATUS, BeaconRenderProps, Locale, Placement, Styles, TooltipRenderProps } from 'react-joyride';
import Joyride from 'react-joyride';
import ReactGA from 'react-ga4';
import { Props } from 'react-floater';
import { Box, Stack, Typography } from '@mui/material';

type OnboardingProps = {

}

type OnboardingState = {
    steps: Step[]
    progress: OnboardingProgress
}

type OnboardingProgress = {
    step: number,
    hasSkipped: boolean,
    isFinished: boolean
}

const localStorageItem = 'onboarding-progress';

export interface OnboardingStep extends Step {
    content: React.ReactNode;
    disableBeacon?: boolean | undefined;
    event?: string | undefined;
    floaterProps?: Props | undefined;
    hideFooter?: boolean | undefined;
    isFixed?: boolean | undefined;
    offset?: number | undefined;
    placement?: 'center' | 'auto' | Placement | undefined;
    placementBeacon?: Placement | undefined;
    target: string | HTMLElement;
    title?: React.ReactNode;
    beaconComponent?: React.ElementType<BeaconRenderProps> | undefined;
    disableCloseOnEsc?: boolean | undefined;
    disableOverlay?: boolean | undefined;
    disableOverlayClose?: boolean | undefined;
    disableScrolling?: boolean | undefined;
    disableScrollParentFix?: boolean | undefined;
    hideBackButton?: boolean | undefined;
    hideCloseButton?: boolean | undefined;
    locale?: Locale | undefined;
    nonce?: string | undefined;
    showProgress?: boolean | undefined;
    showSkipButton?: boolean | undefined;
    spotlightClicks?: boolean | undefined;
    spotlightPadding?: number | undefined;
    styles?: Styles | undefined;
    tooltipComponent?: React.ElementType<TooltipRenderProps> | undefined;
    onCompleted?: Function
}

type OnboardingContentProps = {
    heading: string,
    text: string
}

export class OnboardingContent extends React.Component<OnboardingContentProps> {
    constructor(props:OnboardingContentProps) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            <Box>
                <Stack spacing={2}>
                    <Typography variant='body1'>{this.props.heading}</Typography>
                    <Typography variant='caption'>{this.props.text}</Typography>
                </Stack>
            </Box>
        );
    }
}

export const steps: OnboardingStep[] = [
    {
        target: '.onboarding-start',
        content: (
            <OnboardingContent
                heading='Welcome to fetch!'
                text="We're very happy to have you here! Let's get you familiar with the app!"
            />
        )
    },
    {
        target: '.feed-item-card:first-of-type:not(.hidden)',
        content: (
            <OnboardingContent
                heading='The first post'
                text="This is your first post, posts can contain market predictions, theories, questions and polls. The tags at the top will tell you more about its contents"
            />
        )
    },
    {
        target: '.feed-item-card:first-of-type:not(.hidden) .read-more',
        content: (
            <OnboardingContent
                heading='Read more'
                text="Click read more to check the contents for this post"
            />
        ),
        onCompleted: () => {
            const element = document.querySelector('.feed-item-card:first-of-type:not(.hidden) .read-more') as HTMLElement;
            if(element) {
                element.click()
            }
        }
    },
    {
        target: '.comment-box',
        content: (
            <OnboardingContent
                heading='Share your thoughts'
                text="Let us know what you think. Start discussions, answer questions and provide argument to theories using the comment box."
            />
        )
    },
];

export default class Onboarding extends React.Component<OnboardingProps, OnboardingState> {
    constructor(props:OnboardingProps) {
        super(props);

        if(localStorage) {
            const progressString = localStorage.getItem(localStorageItem);
            const progress: OnboardingProgress = progressString 
                ? JSON.parse(progressString)
                : {
                    hasSkipped: false,
                    isFinished: false,
                    step: 0
                }

            this.state = {
                steps: [],
                progress: progress
            }

            localStorage.setItem(localStorageItem, JSON.stringify(progress));
        }

        this.handleJoyrideCallback = this.handleJoyrideCallback.bind(this);
        this.initializeOnboarding = this.initializeOnboarding.bind(this);
        this.incrementStep = this.incrementStep.bind(this);
        this.finishOnboarding = this.finishOnboarding.bind(this);
        this.skipOnboarding = this.skipOnboarding.bind(this);
        this.handleLoad = this.handleLoad.bind(this);
    }

    componentDidMount(): void {
        window.addEventListener('load', this.handleLoad);
    }

    componentWillUnmount(): void {
        window.removeEventListener('load', this.handleLoad)  
    }

    handleLoad() {
        this.setState({...this.state, steps: steps});
    }

    handleJoyrideCallback(data: CallBackProps) {
        const { action, index, status, type, step } = data;

        console.log(data);
        
        if(type === EVENTS.TOOLTIP && index === 0 && step.event === 'click' && action === ACTIONS.UPDATE) {
            this.initializeOnboarding();
        }
        else if (type === EVENTS.STEP_AFTER || type == EVENTS.TARGET_NOT_FOUND) {
            this.incrementStep(index, step);
        }
        else if (status === STATUS.FINISHED) {
            this.finishOnboarding();
        }
        else if(status === STATUS.SKIPPED) {
            this.skipOnboarding();
        }
    }

    initializeOnboarding() {
        ReactGA.event({
            category: "Onboarding",
            action: "tutorial_begin"
        });
    }

    incrementStep(index:number, step:OnboardingStep) {
        ReactGA.event({
            category: "Onboarding",
            action: "tutorial_step_completed",
            value: index
        });

        let progress = this.state.progress;
        const nextStep = index + 1;
        progress.step = nextStep;
        this.setState({...this.state, progress: progress}, () => {
            //this.storeProgress(progress)
            if(step.onCompleted) {
                step.onCompleted();
            }
        });
    }

    finishOnboarding() {
        ReactGA.event({
            category: "Onboarding",
            action: "tutorial_complete"
        });

        let progress = this.state.progress;
        progress.isFinished = true;

        this.setState({...this.state, progress}, () => {
            //this.storeProgress(progress)
        });
    }

    skipOnboarding() {
        ReactGA.event({
            category: "Onboarding",
            action: "tutorial_skipped"
        });

        let progress = this.state.progress;
        progress.hasSkipped = true;

        this.setState({...this.state, progress}, () => {
            //this.storeProgress(progress)
        });
    }

    storeProgress(progress:OnboardingProgress) {
        if(localStorage) {
            localStorage.setItem(localStorageItem, JSON.stringify(progress));
        }
    }

    render(): React.ReactNode {
        if(localStorage) {
            return(
                <Joyride
                    steps={this.state.steps}
                    stepIndex={this.state.progress.step}
                    callback={this.handleJoyrideCallback}
                    continuous={true}
                    showSkipButton={true}
                    //run={!this.state.progress.hasSkipped && !this.state.progress.isFinished}
                    run={false}
                    styles={{
                        options: {
                            primaryColor: "var(--primary-color)"
                        }
                    }}
                />
            )
        }
        
        return null;
    }
}