import React from 'react';
import FetchEditor from './FetchEditor';

export default class Editor extends React.Component {
    editorRef:React.RefObject<FetchEditor>
    
    constructor(props:any) {
        super(props);
        this.editorRef = React.createRef();
        this.save = this.save.bind(this);
        this.clear = this.clear.bind(this);
        this.getExcerpt = this.getExcerpt.bind(this);
    }

    clear() {
        this.editorRef.current?.clear();
    }

    async save() {
        return await this.editorRef.current?.save()
    }

    getExcerpt() : string {
        const excerpt = this.editorRef.current?.getExcerpt();
        if(Array.isArray(excerpt)) {
            return excerpt[0];
        }

        else {
            return excerpt;
        }
    }
    
    render() {
        return(<FetchEditor ref={this.editorRef}/>)
    }
}