import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Account from '../API/Account';
import {theme} from './assets/js/theme';

type TermsProps = {

}

type TermsState = {
    isAuthenticated:boolean,
    username: string
}

export default class Terms extends React.Component<TermsProps, TermsState> {

    constructor(props:TermsProps) {
        super(props);
        this.state = {
            isAuthenticated: false,
            username: ""
        }
    }

    componentDidMount(): void {
        Account.WhoAmI().then(whoAmI => {
            this.setState({
                ...this.state,
                isAuthenticated: whoAmI.roles.length > 0,
                username: whoAmI.username
            }, () => {
                theme.init();
            });
        });
    }

    render(): React.ReactNode {
        return(
            <HelmetProvider>
                <Helmet>
                    <link rel="stylesheet" href="../pages/assets/css/plugins.css" />
                    <link rel="stylesheet" href="../pages/assets/css/style.css" />
                    <link rel="stylesheet" href="../pages/assets/css/colors/fetch.css" />
                    <script src="/pages/assets/js/typeanimation.js"></script>
                </Helmet>
                <div className="content-wrapper">
                <header className="wrapper bg-soft-primary">
                        <nav className="navbar navbar-expand-lg classNameic transparent navbar-light" style={{padding: '20px 0'}}>
                        <div className="container flex-lg-row flex-nowrap align-items-center">
                            <div className="navbar-brand w-100">
                            <a href="/">
                                <img src="/pages/assets/img/logo-dark.png" srcSet="/pages/assets/img/logo-dark@2x.png 2x" alt="" style={{width: '150px'}} />
                            </a>
                            </div>
                            <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
                            <div className="offcanvas-header d-lg-none">
                                <h3 className="text-white fs-30 mb-0">Fetch</h3>
                                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close" />
                            </div>
                                {this.state.isAuthenticated 
                                        ? 
                                            <div className="nav-collapse-links">
                                                <a className="nav-collapse-link" id="header-sub-settings" href="/settings">{this.state.username}</a>
                                                <a className="nav-collapse-link" href="/feed">Go to app</a>
                                            </div>
                                        :
                                        
                                        <div className="nav-collapse-links">
                                            <a className="nav-collapse-link" id="header-sub-signin" href="/login">Log in</a>
                                            <a className="nav-collapse-link" id="header-sub-signup" href="/register">Sign up</a>
                                            <a className="nav-collapse-link" href="/feed">Go to app</a>
                                        </div>
                                }
                            
                            </div>
                            <div className="navbar-other ms-lg-4">
                            {this.state.isAuthenticated 
                                        ? 
                                            <ul className="navbar-nav flex-row align-items-center ms-auto">
                                                <li className="nav-item d-none d-md-block">
                                                    <a id="header-main-signin" href="/feed" className="btn btn-primary btn-sm rounded">{this.state.username}</a>
                                                </li>
                                                <li className="nav-item d-none d-md-block">
                                                    <a id="header-main-open-app" href="/feed" className="btn btn-sm btn-secondary rounded">Open app</a>
                                                </li>
                                                <li className="nav-item d-lg-none">
                                                    <button className="hamburger offcanvas-nav-btn"><span /></button>
                                                </li>
                                            </ul>
                                        :
                                        
                                        <ul className="navbar-nav flex-row align-items-center ms-auto">
                                            <li className="nav-item d-none d-md-block">
                                                <a id="header-main-signin" href="/login" className="btn btn-sm rounded">Log in</a>
                                            </li>
                                            <li className="nav-item d-none d-md-block">
                                                <a id="header-main-signup" href="/register" className="btn btn-sm btn-primary rounded">Sign up</a>
                                            </li>
                                            <li className="nav-item d-none d-md-block">
                                                <a id="header-main-open-app" href="/feed" className="btn btn-sm btn-secondary rounded">Open app</a>
                                            </li>
                                            <li className="nav-item d-lg-none">
                                                <button className="hamburger offcanvas-nav-btn"><span /></button>
                                            </li>
                                        </ul>
                                }
                                
                            </div>
                        </div>
                        </nav>
                    </header>
                {/* /header */}
                <section className="wrapper bg-soft-primary">
                    <div className="container pt-10 pb-12 pt-md-14 pb-md-16 text-center">
                    <div className="row">
                        <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mx-auto">
                        <h1 className="display-1 mb-3">Terms and Conditions</h1>
                        {/* /nav */}
                        </div>
                        {/* /column */}
                    </div>
                    {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
                {/* /section */}
                <div className="container">
                    <div className="row gx-0">
                    <aside className="col-xl-3 sidebar sticky-sidebar mt-md-0 py-16 d-none d-xl-block">
                        <div className="widget">
                        <nav id="sidebar-nav">
                            <ul className="list-unstyled text-reset">
                            <li><a className="nav-link scroll active" href="#terms-conditions">1. Terms &amp; Conditions</a></li>
                            <li><a className="nav-link scroll" href="#privacy-policy">2. Privacy Policy</a></li>
                            </ul>
                        </nav>
                        {/* /nav */}
                        </div>
                        {/* /.widget */}
                    </aside>
                    {/* /column */}
                    <div className="col-xl-8">
                        <section id="terms-conditions" className="wrapper pt-16">
                        <div className="card">
                            <div className="card-body p-10">
                            <h2 className="mb-3">1. Terms and Conditions</h2>
                            <p>The terms of service for the Fetch Platform and the information contained within can be found below. The information of these terms is distributed free of charge both on this page as well as electronically before signing up. The terms apply to the entire platform. If you have questions regarding these terms please e-mail us at <a href="mailto:info@fetch.team">info@fetch.team</a>.</p>
                            <p>Fetch is registered with the Chamber of Commerce (Kamer van Koophandel) under the following number: 84958006. Fetch is located at Oude Poststraat 42, 9401 JS Assen, Netherlands.</p>
                            <p>In these terms you are considered a <b>user</b> when you have, in any way, engaged with Fetch's platform or services.</p>
                            <h5>General terms</h5>
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                <li><span><i className="uil uil-check" /></span><span>Fetch reserves its right to change the terms and conditions of its platform at all times. The latest version of the terms are always applicable to the platform. Any deviations will only be considered valid when they are agreed upon by fetch and the other party in writing.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Fetch exclusively distributes information, any content found on the platform generated either by Fetch or its users cannot be construed as advice of any kind.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Any strategic information like trading strategies, targets, portfolios or market analysis are to be considered for educational use only.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Fetch is not a personal or financial advisor. Fetch does not provide financial, personal or investment advice.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Within the platform the user may be redirected to third party tools and resources. The use of these third party entities are entirely at the users risk.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Fetch is not a personal or financial advisor. Fetch does not provide financial, personal or investment advice.</span></li>
                            </ul>
                            <br />
                            <h5>Subscriptions</h5>
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                <li><span><i className="uil uil-check" /></span><span>In order to access all functionality and content on the platform, the user must first enter into an agreement with Fetch, this is considered the subscription.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>The terms of the subscription, including the subscription period will be agreed during the application procedure. The subscription will commence on the date as agreed upon during the application procedure.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Users can choose to pay for their subscription on a monthly or annual basis. In both cases the total sum for that initial period is payed up front by the user.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>At the end of the subscription period the subscription will be renewew automatically for another period of equal length (either a month or a year). The total cost will remain the same as the initial cost of the subscription. If the costs do vary, Fetch will inform the user without renewing their subscription automatically.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>The user can cancel their subscription at any time. If the cancellation is performed before the renewal date the subscription will not be automatically renewed.</span></li>
                            </ul>
                            <br />
                            <h5>Prices</h5>
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                <li><span><i className="uil uil-check" /></span><span>All communicated prices by Fetch are in EUR and are including applicable VAT, possible expenses or any taxes or levies imposed by the relevant authorities.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>The price for a subscription chosen by the user will be stated during the application procedure.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>subscription prices may change, when they do Fetch will inform its users and ask them to indicate whether their subsription can be renewed under the new price. No rights ban be derived from historic prices.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>At the end of the subscription period the subscription will be renewew automatically for another period of equal length (either a month or a year). The total cost will remain the same as the initial cost of the subscription. If the costs do vary, Fetch will inform the user without renewing their subscription automatically.</span></li>
                            </ul>
                            <h5>Payment and collection</h5>
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                <li><span><i className="uil uil-check" /></span><span>The user must provide a valid method of payment to access all content of the platform. Fetch will debit the amount for the use of the subscription to the given payment method on a monthly or yearly basis</span></li>
                                <li><span><i className="uil uil-check" /></span><span>The user can pay for the subscription via the payment services offered by Fetch via its platform.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>If the user has a due payment which has not been payed the user will be sent a reminder to pay their charges in full. If payment is not recieved after this reminder the user will be in default. If the user is in default Fetch is entitled to charge statutory (commercial) interest. The interest on the owed sum of money is calculated from the moment the payment is due until the moment that the owed amount is payed in full.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Fetch reserves its right to (temporarily) block the user from accessing features and content bestowed onto them through their subscription.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>If the user is in default, Fetch has its right to charge any extrajudicial costs. The collection fees are calculated on the basis of the invoice amount, with a minimum of EUR 40 excluding VAT.</span></li>
                            </ul>
                            <br />
                            <h5>Account</h5>
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                <li><span><i className="uil uil-check" /></span><span>In order to access certain components of the platform a user account is required.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>The user is responsible for correctly identifying themselves on the Fetch platform. This includes information such as an e-mail address as well as payment information and a strong password.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>It is recommended that the user keeps their account details and password confidential. The user is responsible for all activities on their account after logging in. These terms will always apply unless the owner of the account can indicate that their account is compromised.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>To access Fetch a user must have a high quality internet connection. The user is responsible for arranging and maintaining this requirement.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>The user can delete their account at any time through the user settings page. This will constitute a termination or suspension of the users payment obligations to Fetch.</span></li>
                            </ul>
                            <br />
                            <h5>Content</h5>
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                <li><span><i className="uil uil-check" /></span><span>Content generated by Fetch can be distinguished from user generated content by the Fetch flag displayed on the content.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Users are solely respnonsible for the accuracy and completeness of their content. Fetch is not responsible for any communication between any users.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>The user is allowed to upload content to the platform. The content uploaded to the platform is subject to the following conditions:</span></li>
                                <li><span><i className="uil uil-check" /></span><span>The content uploaded is non offensive, harmful, discriminative or illegal in any way;</span></li>
                                <li><span><i className="uil uil-check" /></span><span>The content uploaded cannot contain any incorrect, misleading or persuasive information;</span></li>
                                <li><span><i className="uil uil-check" /></span><span>The content uploaded is in compliance with these terms and all applicable laws and regulation;</span></li>
                                <li><span><i className="uil uil-check" /></span><span>The content uploaded cannot violate any intellectual property right;</span></li>
                                <li><span><i className="uil uil-check" /></span><span>The content uploaded cannot be for any purpose for which the platform is clearly not intended.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Fetch retains the right but no obligation to review any content for compliance with these terms. At Fetch's sole descretion Fetch may delete or suspend any account in violation of this article. Fetch will notify the user of any removal via e-mail.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Fetch is under no obligation to store, back-up, retrive or return any information shared by the user on the platform.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>The user is requested, but not obligated, to report any content that is illegal or violates these terms via the content flagging system.</span></li>
                            </ul>
                            <br />
                            <h5>Restrictions</h5>
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                <li><span><i className="uil uil-check" /></span><span>Fetch reserves its right to terminate the users subscription and exclude the user from any use of the platform by blocking or delete the users account without being obliged to refund any amounts alreadde collected or pay any damagesm if there is, in our descretion, a valid reason t do so. This applies in any case, but not exclusively if the user:</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Violates these terms.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Attempt or allow to attempt, to de-compile, reverse compile, disassaemble, reverse engineer, try to obtains the source code, use viruses, Trojan horses, worms, bots, or otherwise user software or technical tools that can damage the platform;</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Fail to meet the payment obligations under the agreed upon subscription;</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Misuse the platform in any other way.</span></li>
                            </ul>
                            <br />
                            <h5>Availability and maintenance</h5>
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                <li><span><i className="uil uil-check" /></span><span>Fetch is responsible for maintaining and facilitating a 24 hours a day, 7 days a week availibility of the platform. Fetch does not have influence over the availability of its constituent hosting services and cannot guarantee the platform will always work uninterrupted.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Fetch reserves its right to make changes to the platform at any given time. This includes but is not limited to the addition, mutation or removal of features and functionalities on the platform.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Fetch does not guarantee that the platform and its servies are completely error free. Users a requested, but not obligated, to inform Fetch of any error or malfunction by e-mail.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Fetch makes use of third party software like Azure to host its services and is no way responsible for changes in these services.</span></li>
                            </ul>
                            <h5>Force Majeure</h5>
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                <li><span><i className="uil uil-check" /></span><span>In the event of force majeure, Fetch may suspend its obligations with respect to the platform or services. If this period exceeds 2 ininterrupted months or when it has become clear that the period will exceed a duration of the more than 2 months, Fetch may termine its service without being liable to pay any compensation to the user.</span></li>
                            </ul>
                            <br />
                            <h5>Intellectual property</h5>
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                <li><span><i className="uil uil-check" /></span><span>Fetch are the exclusive owners of all existing and future intellectual property, such as copyright, trademarks, design rights, patents, source codes and know-how, which rest on its platform and services or are the fruits of the use of its platform.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>By sharing content on Fetch's platform, the user grant Fetch a non-exclusive license to use any data or information included in the users contribution. The license is free of charge, irrevocable, worldwide, without limitation, sub-licensable and trasnferable and therby offers no limtiation of use by Fetch whatsoever. It is explicietly allowed to use any contribution for the benefit of Fetch's services in the broadest sense. Fetch hereby accept such a license in advance.</span></li>
                            </ul>
                            <br />
                            <h5>Confidentiality and privacy</h5>
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                <li><span><i className="uil uil-check" /></span><span>Unless there is a legal or statutory obligation to disclose, Fetch will keep all information about the user confidential.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Fetch protects the users personal data in accordance with the European General Data Protection Regulation. For more information, please read our Privacy Policy.</span></li>
                            </ul>
                            <br />
                            <h5>Liability and indemnification</h5>
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                <li><span><i className="uil uil-check" /></span><span>Fetch is not liable for any damage or other adverse consequences resulting from the use or inaccessibility of (information on) its website, Platform and its Services. All actions the user takes on the basis of our Website, the Platform and/or Services are for the users own account and risk.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>The user should be aware that with investing, both a positive and a negative result is possible. Fetch does not guarantee that the user will achieve certain results. Fetch only provides general information for educational purposes. Fetch will in no way be responsible for the users investments results. The user is fully responsible for their own actions, and can never hold Fetch liable for any damages arising from investment loss. </span></li>
                                <li><span><i className="uil uil-check" /></span><span>Fetch is not liable for any damages in case of force majeure.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Fetch is only liable for direct damages that are unequivocally caused by an attributable shortcoming from its side. Fetch is not liable for indirect damages or missed income.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Fetch's maximum liability is under all circumstances limited to the users yearly Subscription fee or 12x the users monthly Subscription fee. </span></li>
                                <li><span><i className="uil uil-check" /></span><span>The limitation period of all claims and defenses against Fetch is 1 year after the user became or should have become aware of the damages.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>The limitations set out in this Article do not apply if damage is the result of an intentional act or gross negligence from Fetch's side.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>The user will indemnify and keep Fetch indemnified for all claims by third parties as, but not limited to an intellectual property rights infringement, fines, costs, damages, etc. relating to any use of Fetch's Platform or Services.</span></li>
                            </ul>
                            <br />
                            <h5>Third parties</h5>
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                <li><span><i className="uil uil-check" /></span><span>Fetch reserves the right to employ third parties to partially perform our duties, if Fetch is of the opinion that this is necessary for the due exercise of its Platform or Services. Sections 7:404 of the Dutch Civil Code (DCC) (performance of service by a specific person), 7:407 paragraph 2 DCC (joint liability) and 7:409 DCC (death of a particularly assigned service provider) are not applicable.</span></li>
                            </ul>
                            <br />
                            <h5>Miscellaneous</h5>
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                <li><span><i className="uil uil-check" /></span><span>The invalidity or unenforceability of any provision of these Terms will not affect the validity or enforceability of any other provision of these Terms. Any such invalid or unenforceable provision will be replaced by a provision that is considered to be valid and enforceable and which' interpretation will be as close as possible to the intent of the invalid provision.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>The user is not allowed to assign or transfer any rights and obligations on account of Fetch's Platform, Services or these Terms without prior written approval of Fetch.</span></li>
                            </ul>
                            <br />
                            <h5>Applicable Law</h5>
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                <li><span><i className="uil uil-check" /></span><span>Dutch law exclusively applies to these terms.</span></li>
                                <li><span><i className="uil uil-check" /></span><span>The Court of Amsterdam, the Netherlands has jurisdiction to hear all disputes or claims ensuing from these Terms. The user may, within one month after Fetch has invoked the jurisdiction of this court, choose the court that is competent by law.
                                    the user can also use the ODR (Online Dispute Resolution) platform. This platform offers a simple, effective, quick and inexpensive out-of-court solution to disputes arising from online transactions. For more information see: <a href="http://ec.europa.eu/odr">http://ec.europa.eu/odr</a>.</span></li>
                            </ul>
                            </div>
                            {/*/.card-body */}
                        </div>
                        {/*/.card */}
                        </section>
                        <section id="privacy-policy" className="wrapper pt-16">
                        <div className="card">
                            <div className="card-body p-10">
                            <h2 className="mb-3">2. Privacy Policy</h2>
                            <p>As part of Fetch's activities. it may store and process personal data relating to you, the user. In this statement, Fetch explains what personal data it collects and uses. Why it does so, and what the users right are. This privacey statement is applicable to all Fetch's processing activities.</p>
                            <h5>Purpose</h5>
                            <p>Fetch processes the users personal data for different purposes. Fetch processed the users personal data if the user contacts Fetch, if the user purchases a subscription to Fetch's services or if the user create an account.</p>
                            <h5>Legal grounds</h5>
                            <p>Fetch processes the users personal data on the basis of different legal grounds. Fetch tries to ask for the users permissions as much as possible. Fetch processes personal data of the user in order to execute its agreements with the user or to make the necessary preparations.</p>
                            <h5>What personal data does Fetch process</h5>
                            <p>Because Fetch values the privacy of the users, it wants be as transparent as possible about the data it processed, for what purposes and on waht legal grounds.</p>
                            <h6>Contact details</h6>
                            <p>If the user contacts Fetch it may process the following data belonging to the user:</p>
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                <li><span><i className="uil uil-check" /></span><span>Full name including first- last and any middle names;</span></li>
                                <li><span><i className="uil uil-check" /></span><span>E-mail addresses;</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Any other personal data the user has provided on their own initiative.</span></li>
                            </ul>
                            <p>The purpose for processing this data is to answer the users questions, to provide the user with support and to provide the user with information concerning Fetch's services.</p>
                            <p>Fetch processes the users data in order to be able to execute its agreements with the user or to make the necessary preparations to do.</p>
                            <h6>User account</h6>
                            <p>When the user created an account on the platform, Fetch may process the following data belonging to the user:</p>
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                <li><span><i className="uil uil-check" /></span><span>Account credentials</span></li>
                                <li><span><i className="uil uil-check" /></span><span>E-mail address;</span></li>
                                <li><span><i className="uil uil-check" /></span><span>IP address.</span></li>
                            </ul>
                            <p>The purpose of this processing is to allow the user to easily order Fetch's paid subscriptions services online and to allow the user to view their order history. Fetch processed the users data in order to execute its agreements with the user or to make the necessary preparations to do.</p>
                            <h6>Subscription</h6>
                            <p>When the user purchases a paid subscription, Fetch may process the following data belonging to the user:</p>
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                <li><span><i className="uil uil-check" /></span><span>Full name including first- last and any middle names;</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Address;</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Telephone number;</span></li>
                                <li><span><i className="uil uil-check" /></span><span>E-mail address;</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Customer number;</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Payment information;</span></li>
                                <li><span><i className="uil uil-check" /></span><span>Order history;</span></li>
                                <li><span><i className="uil uil-check" /></span><span>And any other personal data the user provides on their own initiative.</span></li>
                            </ul>
                            <p>The purpose of this processing is able to deliver Fetch's paid services to the user, send its invoices to the user and collect their payments, keep its records and send the user commercial communications. Fetch processed the users data in order to execute its agreements with the user or to make the necessary preparations to do.</p>
                            <h6>Miscellaneous</h6>
                            <p>In addition to the above purposes, Fetch may also use all personal data relating to the user to the extent reasonably necessay to fulful its administrative and legal obligations and to assert or defend claims. These processing activities take place with a view to Fetch's legitimate interest or because Fetch are legally obliged to do so. If the user fails to provide any data Fetch requests from them, Fetch may not be able to provide its services to the user.</p>
                            <h5>Providing data to third parties</h5>
                            <p>Fetch will share the users personal data with third parties as little as possible. For the purpose of Fetch's business operations, it uses the services of third parties. In some cases, Fetch will provide these parties with the users personal data. However, Fetch will never provide more data to those third parties than they need to carry out Fetch's assignments.When the user takes out a subscription with Fetch, payment is handled by PayPro B.V. To enable PayPro to perform a transaction, Fetch provides it with the necessary data. Any additional personal data collected by PayPro, during the payment process or during your usage of the platform, is held by these parties. Fetch has no influence on the personal data collected by these parties. As independent data controllers, they have their own privacy statements which can be found at <a href="https://www.paypro.nl/privacy-statement-eu/">https://www.paypro.nl/privacy-statement-eu/</a>. Finally, Fetch may be legally obliged to provide data to third parties, such as public authorities including the tax services, or courts.</p>
                            <h5>Data retention</h5>
                            <p>Fetch will not retain the users personal data any longer than necessary for the stated purposes.</p>
                            <h5>Security measures</h5>
                            <p>Fetch have taken appropriate measures in order to protect the users personal data against unlawful processing. In addition to state-of-the-art technical security measures, Fetch require all its staff who have access to personal data to agree to a confidentiality agreement.</p>
                            <h5>Your rights</h5>
                            <p>During the period that Fetch processes the users personal data, the users has the right to ask Fetch for an overview of the personal data it process about the user. If this overview shows that data is incorrect, incomplete or no longer relevant, the user can ask Fetch to correct or remove the data. The user also has the right to object to Fetch's processing of personal data and, if Fetch have asked for the users consent, to withdraw the users consent to the processing. For a complete overview of the users rights, please visit the website of the Dutch Data Protection Authority, where you can also lodge complaints about Fetch's processing of the users personal data.</p>
                            <h5>Changes to the privacy statement</h5>
                            <p>Fetch may amend this privacy statement at any time. The last adjustment was made on 21 of February 2023.</p>
                            <h5>Questions and complaints</h5>
                            <p>If the user has any questions or complaints concerning privacy please e-mail us at <a href="mailto:info@Fetch.team">info@fetch.team</a></p>
                            </div>
                            {/*/.card-body */}
                        </div>
                        {/*/.card */}
                        </section>
                    </div>
                    {/* /column */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container */}
                </div>
                {/* /.content-wrapper */}
                <footer className="bg-light">
                <div className="container py-13 py-md-15">
                    <div className="row gy-6 gy-lg-0">
                    <div className="col-md-4 col-lg-3">
                        <div className="widget">
                        <img className="mb-4" src="/pages/assets/img/logo-dark.png" srcSet="/pages/assets/img/logo-dark@2x.png 2x" alt="" style={{width: '150px'}} />
                        <p className="mb-4">© 2023 Fetch.team <br className="d-none d-lg-block" />All rights reserved.</p>
                        <nav className="nav social ">
                            <a href="https://twitter.com/fetch_team" target="_blank"><i className="uil uil-twitter" /></a>
                            <a href="https://www.instagram.com/fetch.team/" target="_blank"><i className="uil uil-instagram" /></a>
                            <a href="https://www.youtube.com/channel/UC2tTJ2YS1Y_PyrDoLkRyK2A" target="_blank"><i className="uil uil-youtube" /></a>
                            <a href="https://www.reddit.com/user/FetchTeam" target="_blank"><i className="uil-reddit-alien-alt" /></a>
                        </nav>
                        {/* /.social */}
                        </div>
                        {/* /.widget */}
                    </div>
                    {/* /column */}
                    <div className="col-md-4 col-lg-3">
                        <div className="widget">
                        <h4 className="widget-title  mb-3">Get in Touch</h4>
                        <address>Oude Poststraat 42<br />Assen<br />The Netherlands</address>
                        <a href="mailto:info@fetch.team" className="link-body">info@fetch.team</a>
                        </div>
                        {/* /.widget */}
                    </div>
                    {/* /column */}
                    <div className="col-md-4 col-lg-3">
                        <div className="widget">
                        <h4 className="widget-title  mb-3">Learn More</h4>
                        <ul className="list-unstyled text-reset mb-0">
                            <li><a href="/feed">App</a></li>
                            {/* <li><a href="#">Our Story</a></li>
                    <li><a href="#">Projects</a></li> */}
                            <li><a href="/terms#terms-conditions">Terms of Use</a></li>
                            <li><a href="/terms#privacy-policy">Privacy Policy</a></li>
                        </ul>
                        </div>
                        {/* /.widget */}
                    </div>
                    </div>
                    {/*/.row */}
                </div>
                {/* /.container */}
                </footer>
                <div className="progress-wrap">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
                </div>
            </HelmetProvider>
        )
    }
}