import { Avatar } from '@mui/material';
import { red } from '@mui/material/colors';
import React from 'react';
import { AuthorDTO } from '../API/models/datatransferobjects';
import { router } from '../App';
import AuthorPopover from '../Content/Author/AuthorPopover';

type AuthorAvatarProps = {
    author: AuthorDTO
}

export default class AuthorAvatar extends React.Component<AuthorAvatarProps> {
    constructor(props:AuthorAvatarProps) {
        super(props);
        this.gotoProfile = this.gotoProfile.bind(this);
    }

    gotoProfile() {
        router.navigate(`/profile/${this.props.author.name}`);
    }

    render(): React.ReactNode {
        return(
            <Avatar onClick={this.gotoProfile} src={this.props.author.profileImageUrl.length > 0 ? this.props.author.profileImageUrl : undefined} sx={{ bgcolor: red[500] }} aria-label="recipe">
                {this.props.author.name[0]}
            </Avatar>
        )
    }
}