import React, { ChangeEvent } from 'react';

type HiddenImageInputProps = {
    onChange:Function
}

type HiddenImageInputState = {
    image: string | null,
    imageBase64: string | null
};

export default class HiddenImageInput extends React.Component<HiddenImageInputProps, HiddenImageInputState> {
    fileRef:React.RefObject<HTMLInputElement>

    constructor(props: HiddenImageInputProps) {
        super(props);

        this.state = {
            image: null,
            imageBase64: null,
        };

        this.fileRef = React.createRef();

        this.getBase64 = this.getBase64.bind(this);
        this.fileChanged = this.fileChanged.bind(this);
        
    }

    getBase64() {
        return this.state.imageBase64;
    }

    fileChanged(event:ChangeEvent<HTMLInputElement>) {
        if(event.target.files != null) {
             const file = event.target.files[0];
             if(file) {
                 const image = window.URL.createObjectURL(file);
                 if(image != null) {
                     this.setState({...this.state, image: image});
                 }
 
                 const reader = new FileReader();
                 reader.onload = () => {
                     const imageBase64 = (reader.result as string).split(';base64,')[1];
                     this.setState({...this.state, imageBase64: imageBase64 }, () => {
                        this.props.onChange(imageBase64);
                     });
                 }
                 reader.onerror = (error) => {
                     console.error(error);
                 }
                 reader.readAsDataURL(file);
             }
         }
     }

    open() {
        this.fileRef.current?.click();
    }
    
    render() {
        return(
            <input ref={this.fileRef} onChange={this.fileChanged} type="file" hidden accept="image/*" />
        )
    }
}