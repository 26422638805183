import { Box, Button, Card, CardContent, Stack, Typography } from '@mui/material';
import React from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { router } from '../App';
import Confetti from 'react-confetti'


export default class SubscriptionSucces extends React.Component {

    goToHome() {
        router.navigate("/")
    }

    render(): React.ReactNode {
        return(
            <Stack alignItems={"center"}>
                <ConfettiExplosion colors={[
                    "#df37a5",
                    "#2d46b9",
                    "#1e3163"
                ]} />
                <Confetti
                    colors={[
                        "#df37a5",
                        "#2d46b9",
                        "#1e3163"
                    ]}
                    numberOfPieces={100}
                    drawShape={ctx => {
                        ctx.beginPath()
                        ctx.lineTo(24.9, 39.8);
                        ctx.lineTo(24.5, 10.9);
                        ctx.lineTo(42.5, 10.8);
                        ctx.lineTo(42.4, 16.3);
                        ctx.lineTo(30.8, 17.0);
                        ctx.lineTo(29.7, 23.7);
                        ctx.lineTo(37.5, 23.9);
                        ctx.lineTo(37.1, 28.2);
                        ctx.lineTo(28.4, 28.3);
                        ctx.lineTo(29.0, 40.0);
                        ctx.lineTo(25.0, 39.9);
                        ctx.closePath();
                        ctx.fill();
                        ctx.stroke();
                    }}
                    />
                <Card>
                    <CardContent>
                        <Stack gap="12px" alignItems="center">
                            <Typography variant='h3'>Great success!</Typography>
                            <Typography variant='body1'>Welcome to the Fetch team!</Typography>
                            <Button onClick={this.goToHome} variant='contained'>Start Learning</Button>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>   
        )
    }
}