import { Stack, TextField, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';
import Comment from '../../API/Comment';
import { ROLES_CREATE_COMMENT } from '../../App';
import LoadableButton from '../../Controls/LoadableButton';
import Spacer from '../../Helpers/Spacer';
import AuthorizeView from '../../Layout/AuthorizeView';
import CommentDisclaimer from './CommentDisclaimer';
import { WhoAmIResponse } from '../../API/models/account';

const MAX_COMMENT_LENGTH = 10000;

type CreateCommentProps = {
    contentId:string,
    onCommentPosted:Function,
    parentCommentId?:string,
    whoAmI: WhoAmIResponse
};

type CreateCommentState = {
    comment:string,
    loading:boolean
};

export default class CreateComment extends React.Component<CreateCommentProps, CreateCommentState> {
    constructor(props:CreateCommentProps) {
        super(props);

        this.state = {
            comment: "",
            loading: false
        };

        this.onCommentChange = this.onCommentChange.bind(this);
        this.postComment = this.postComment.bind(this);
    }

    onCommentChange(event:ChangeEvent<HTMLInputElement>) {
        if(event.target.value.length <= MAX_COMMENT_LENGTH) {
            this.setState({...this.state, comment: event.target.value});
        }
    }

    postComment() {
        this.setState({...this.state, loading: true}, () => {
            Comment.Create(this.state.comment, this.props.contentId, this.props.parentCommentId).then(response => {
                Comment.View(response.id).then(comment => {
                    this.setState({...this.state, comment: ""}, () => {
                        this.setState({...this.state, loading: false});
                        this.props.onCommentPosted(comment);
                    });
                }).catch(error => {
                    console.log(error);
                });
            }).catch(error => {
                console.log(error);
            });
        });
    }

    render() {
        return(
            <AuthorizeView whoAmI={this.props.whoAmI} roles={ROLES_CREATE_COMMENT}>
                <Stack className='comment-box' gap={"12px"}>
                    <TextField
                        style={{backgroundColor: "#ffffff", border: "unset" }}
                        id="outlined-multiline-flexible"
                        multiline
                        fullWidth
                        rows={4}
                        value={this.state.comment}
                        onChange={this.onCommentChange}
                        placeholder="Write a comment..."
                    /> 
                    {
                        this.state.comment.length > 0 && (
                            <CommentDisclaimer />
                        )
                    }
                    <Stack direction={"row"} gap="8px">
                        <LoadableButton loading={this.state.loading} hasConfetti onClick={this.postComment} variant='contained'>Post comment</LoadableButton>
                        <Spacer />
                        <Typography style={{textAlign: "right"}} variant='subtitle1'>{this.state.comment.length}/{MAX_COMMENT_LENGTH}</Typography> 
                    </Stack>
                </Stack>
            </AuthorizeView>
        )
    }
}