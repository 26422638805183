import React from 'react';
import { Box, Stack, Card, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import EngineeringIcon from '@mui/icons-material/Engineering';

export default class Placeholder extends React.Component {
    render() {
        return(
            <Card style={{width: "100%"}}>
                <Stack style={{padding: "64px 0"}} spacing={3}>
                    <Stack direction="row" style={{justifyContent: "center", alignItems: "center"}}>
                        <EngineeringIcon sx={{fontSize: "72pt", color: "var(--secondary-color)"}}  />
                    </Stack>
                    <Typography>
                        This component has not yet been implemented.<br/>
                        Create a component and update the router in <code>App.tsx</code>.
                    </Typography>
                    <Box>
                        <Button component={Link} to="/" variant="contained">Go back to the feed</Button>
                    </Box>
                </Stack>
            </Card>
        );
    }
}