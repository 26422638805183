import { Card, Stack, Typography, Box, Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

export default class NotFound extends React.Component {
    render() {
        return(
            <Card style={{width: "100%"}}>
                <Stack style={{padding: "64px 0"}} spacing={3}>
                    <Stack direction="row" style={{justifyContent: "center", alignItems: "center"}}>
                        <SentimentVeryDissatisfiedIcon sx={{fontSize: "72pt", color: "var(--secondary-color)"}}  />
                    </Stack>
                    <Typography>
                        The page you requested can not be found
                    </Typography>
                    <Box>
                        <Button component={Link} to="/" variant="contained">Go back to the feed</Button>
                    </Box>
                </Stack>
            </Card>
        )
    }
}