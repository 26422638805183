import { Alert, Stack, Typography, Button, Grid, Avatar, Card, CardContent, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import React from "react";
import { router } from "../App";


import EditIcon from '@mui/icons-material/Edit';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import PollIcon from '@mui/icons-material/Poll';
import QuizIcon from '@mui/icons-material/Quiz';
import Subscription from "../API/Subscription";

type GetSubscriptionProps = {

}

type GetSubscriptionState = {
    error?:string
}

const listItemStyle: React.CSSProperties = {backgroundColor: "var(--primary-color)"};

export default class GetSubscription extends React.Component<GetSubscriptionProps, GetSubscriptionState> {
    constructor(props: GetSubscriptionProps) {
        super(props);
        this.state = {};
        this.goToHome = this.goToHome.bind(this);
        this.goToCheckout = this.goToCheckout.bind(this);
    }

    componentDidMount(): void {
        // const token = Data.GetAccessToken();
        // if(!token) {
        //     router.navigate("/");
        // }
    }

    goToHome() {
        router.navigate("/");
    }

    goToCheckout() {
        Subscription.CreatePayment("FETCH_MONTHLY");
    }
    
    render(): React.ReactNode {
        return(
            <Stack gap="12px" sx={{padding: "12px"}}>
                {this.state.error && (
                    <Alert severity="error">{this.state.error}</Alert>
                )}
                <Card sx={{flexGrow: 1, maxWidth: "768px"}}>
                    <CardContent>
                        <Stack gap="8px" alignItems="center">
                            <Typography variant="h4" sx={{textAlign: "center"}}>Fetch Premium</Typography>
                            <Stack direction="row" gap="8px">
                                <Typography variant="h5">&euro;50,- / month</Typography>
                            </Stack>
                            <Typography variant="body1" sx={{textAlign: "justify"}}>
                                With Fetch premium you gain access to our market analysis while their relevancy 
                                is at their peak. Never miss out on trades again. Additionally you gain access 
                                to our community features allowing you to interact with the community!
                            </Typography>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <ListItem>
                                        <ListItemAvatar>
                                        <Avatar style={listItemStyle}>
                                            <QueryStatsIcon />
                                        </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Analysis" secondary="View analysis during their relevancy and gain important insights." />
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ListItem>
                                        <ListItemAvatar>
                                        <Avatar style={listItemStyle}>
                                            <QuizIcon />
                                        </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Questions" secondary="Aks the community any questions enrich your market knowledge today." />
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ListItem>
                                        <ListItemAvatar>
                                        <Avatar style={listItemStyle}>
                                            <AutoStoriesIcon />
                                        </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Theories" secondary="Post, prove and debunk theories with your fellow trader. Test ideas and become smarter." />
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ListItem>
                                        <ListItemAvatar>
                                        <Avatar style={listItemStyle}>
                                            <PollIcon />
                                        </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Polls" secondary="Need a quick opinion? Ask your community members with a poll." />
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ListItem>
                                        <ListItemAvatar>
                                        <Avatar style={listItemStyle}>
                                            <EditIcon />
                                        </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Editor" secondary="Create all content with the Fetch editor. Allowing you to create Tradingview analysis and more." />
                                    </ListItem>
                                </Grid>
                            </Grid>
                        </Stack>
                    </CardContent>
                </Card>
                <Stack gap="12px">
                    <Button onClick={this.goToCheckout} variant="contained">Checkout</Button>
                    <Button onClick={this.goToHome} variant="outlined">Skip</Button>
                </Stack>
            </Stack>
        )
    }
}