import { Box, Stack, Card, Typography, Grid } from '@mui/material';
import React from 'react';
import { FeedItemDTO } from '../../API/models/datatransferobjects';
import FeedItemCard from './FeedItemCard';
import PostCardShadow from '../Post/PostCardShadow';
import FeedController from '../../API/FeedController';
import CircularProgress from '@mui/material/CircularProgress';
import { WhoAmIResponse } from '../../API/models/account';
import { withParams } from '../../Helpers/ReactRouterHelper';
import FeedFilter, { Filter } from './FeedFilter';
import FeedAssets from './FeedAssets';
import NewFeedPost from './NewFeedPost';
import Joyride, { Step } from 'react-joyride';
import FeedCta from './FeedCta';

export enum FeedFilterValues { All, Post, Theory, Question, Poll }

type FeedProps = {
    filter: FeedFilterValues, // filter on content type (posts, questions, theories, polls).
    loaderData: WhoAmIResponse
}

type FeedState = {
    items: FeedItemDTO[],
    currentPage:number,
    loadingNewItems:boolean,
    infiniteScrollEnabled: boolean,
    filter?:Filter | undefined,
    nothingFound: boolean,
    steps: Step[]
}

const PAGE_SIZE: number = 10;

class Feed extends React.Component<FeedProps, FeedState> {  
    constructor(props:FeedProps) {
        super(props);
        this.state = {
            items: [],
            currentPage: 0,
            loadingNewItems: true,
            infiniteScrollEnabled: true,
            nothingFound: false,
            steps: [
                {
                    target: '.test-class',
                    content: 'Here is the first try'
                }
            ]
        }
        this.onScroll = this.onScroll.bind(this);
        this.onFeedFilterChange = this.onFeedFilterChange.bind(this);
    }

    fetchPage(page: number): Promise<FeedItemDTO[]> {
        if(this.state.filter) {
            return FeedController.GetFilteredPost(page, this.state.filter);
        }
        switch (this.props.filter) {
            case FeedFilterValues.All: return FeedController.GetPage(page);
            case FeedFilterValues.Post: return FeedController.GetPosts(page);
            case FeedFilterValues.Question: return FeedController.GetQuestions(page);
            case FeedFilterValues.Theory: return FeedController.GetTheories(page);
            case FeedFilterValues.Poll: return FeedController.GetPolls(page);
            default: throw Error('Feed filter not supported.')
        }
    }

    componentDidMount(): void {

        this.fetchPage(this.state.currentPage).then(response => {
            this.setState({...this.state, items: response}, () => {
                this.setState({...this.state, loadingNewItems: false, nothingFound: false, infiniteScrollEnabled: response.length === PAGE_SIZE});
            });
        });

        
        window.addEventListener('scroll', this.onScroll);
    }

    componentDidUpdate(prevProps: Readonly<FeedProps>, prevState: Readonly<FeedState>, snapshot?: any): void {
        if (prevProps.filter !== this.props.filter) {
            this.setState({...this.state, items: [], loadingNewItems: true, nothingFound: false}, () => {
                this.fetchPage(0).then(response => {
                    this.setState({...this.state, items: response, currentPage: 0}, () => {
                        this.setState({...this.state, loadingNewItems: false, infiniteScrollEnabled: response.length === PAGE_SIZE});
                    });
                });
            });
        }
    }

    onScroll() {
        if(this.state.infiniteScrollEnabled && !this.state.loadingNewItems) {
            const rect = document.getElementsByTagName('body')[0].getBoundingClientRect();
            const scrollPositionBottomOffScreen = window.scrollY + window.innerHeight;
            const heightRemainingUntilEndOfPage = rect.height - scrollPositionBottomOffScreen;
            const loadThresholdInPixels = 600;
            if(heightRemainingUntilEndOfPage < loadThresholdInPixels) {
                this.setState({...this.state, loadingNewItems: true, nothingFound : false}, () => {
                    const newPage = this.state.currentPage + 1;
                    this.fetchPage(newPage).then(response => {
                        const items = this.state.items;
                        for(let item of response) {
                            items.push(item);
                        }

                        this.setState({...this.state, currentPage: newPage, items: items}, () => {
                            this.setState({...this.state, loadingNewItems: false, infiniteScrollEnabled: response.length === PAGE_SIZE});
                        });
                    });
                });
            }
        }
    }

    renderLoading() {
        return [0, 1, 2].map((_, i) => {
            return (
                <Grid key={`${_}-${i}`} item sm={12}>
                    <PostCardShadow  />
                </Grid>
            )
        });
    }

    renderFeed() {
        return this.state.items.map((post, i) => {
            return (
                <Grid key={`${post.id}-${i}-${post.identifier}`} item sm={12}>
                    <FeedItemCard whoAmI={this.props.loaderData} item={post}/>
                </Grid>
            )
        })
    }

    onFeedFilterChange(filter:Filter) {
        this.setState({...this.state, loadingNewItems: true, nothingFound: false, filter: filter}, () => {
            const page = 0;
            FeedController.GetFilteredPost(page, filter).then(items => {
                if(items.length == 0) {
                    this.setState({...this.state, nothingFound: true, loadingNewItems: false, infiniteScrollEnabled: false, items: []});
                }
                else {
                    this.setState({...this.state, currentPage: page, items: items}, () => {
                        this.setState({...this.state, loadingNewItems: false, infiniteScrollEnabled: items.length === PAGE_SIZE});
                    });
                }
            });
        })
    }

    render() {
        return (
            <Grid container spacing={2} direction={{xs: 'column-reverse', lg: 'row'}}>
                <Grid item xs={12} lg={8} xl={9}>
                    <Grid container gap={"24px"}>
                        {this.props.loaderData.roles.length > 0 && (
                            <Grid className='test' item sm={12}>
                                <NewFeedPost />
                            </Grid>
                        )}
                        <Grid className='onboarding-start' item sm={12}>
                            <FeedFilter OnFeedFilterChange={this.onFeedFilterChange} />
                        </Grid>
                        {
                            this.state.nothingFound && (
                                <Grid item sm={12}>
                                    <Card>
                                        <Typography variant='h6'>Nothing was found...</Typography>
                                        <Typography variant='body1'>
                                            Nothing for this query was found. Perhaps <a href='/new'>you'd like to make some content?</a>
                                        </Typography>
                                    </Card>
                                </Grid>
                            )
                        }
                        {
                            this.state.items && !this.state.nothingFound && this.state.items.length > 0
                                ? this.renderFeed()
                                : this.renderLoading()
                        }
                        {
                            this.state.loadingNewItems && !this.state.nothingFound && (
                                this.renderLoading()
                            )
                        }
                        {
                            this.state.loadingNewItems && !this.state.nothingFound && (
                                <Grid item sm={12}>
                                    <Box sx={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                        <CircularProgress />
                                    </Box>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
                <Box component={Grid} item xs={12} lg={4} xl={3} 
                    sx={{
                        position: {xs: 'relative', lg: 'sticky'},
                        alignSelf: {xs: 'auto', lg: 'flex-start'},
                        height: {xs: 'auto'},
                        top: {xs: 0, lg: 'none'}
                    }}
                    display={{md: 'none', sm: 'none', xs: 'none', lg: 'block', xl: 'block'}}
                >
                    <FeedCta />
                </Box>
            </Grid>
        )
    }
}

export default withParams(Feed);