import React from 'react';

import { Box, Button, Card, Divider, Stack, Link } from '@mui/material';


import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ArgumentState, ViewArgumentResult, ViewTheoryResult } from '../../API/models/theory';
import AuthorReputation from '../Author/AuthorReputation';

import {FormatDate} from '../../Helpers/Formatters';
import FetchViewer from '../Viewer/FetchViewer';
import CreateComment from '../Comment/CreateComment';
import { ViewCommentResult } from '../../API/models/comments';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import CommentTag from '../../Controls/CommentTag';
import VoteTag from '../../Controls/VoteTag';
import ChildComment from '../Question/ChildComment';
import Spacer from '../../Helpers/Spacer';

import {leftSidebarStyle} from '../Question/ViewQuestion';
import FeedItemCardMenu from '../Feed/FeedItemCardMenu';
import AuthorPopover from '../Author/AuthorPopover';
import Vote from '../../API/Vote';
import { WhoAmIResponse } from '../../API/models/account';
import { withParams } from '../../Helpers/ReactRouterHelper';

type ViewArgumentProps = {
    theory: ViewTheoryResult
    argument:ViewArgumentResult,
    loaderData: WhoAmIResponse
}

type ViewArgumentState = {
    argument: ViewArgumentResult,
    isCommentBoxOpen: boolean,
    showMoreComments: boolean,
    menuAnchor?:Element,
    isMenuOpen:boolean,
    error?: string,
    isAuthor: boolean,
}

const cardStyle: React.CSSProperties = {
    width: "100%"
}

class ViewArgument extends React.Component<ViewArgumentProps, ViewArgumentState> {
    viewerRef:React.RefObject<FetchViewer>;

    constructor(props:ViewArgumentProps) {
        super(props);

        this.state = {
            isCommentBoxOpen: false,
            argument: this.props.argument,
            showMoreComments: false,
            isMenuOpen: false,
            isAuthor: false
        };

        this.viewerRef = React.createRef();

        this.openContextMenu = this.openContextMenu.bind(this);
        this.closeContextMenu = this.closeContextMenu.bind(this);

        this.openCommentBox = this.openCommentBox.bind(this);
        this.onCommentPosted = this.onCommentPosted.bind(this);
        this.toggleComments = this.toggleComments.bind(this);
        this.showComments = this.showComments.bind(this);

        this.upVoteArgument = this.upVoteArgument.bind(this);
        this.downVoteArgument = this.downVoteArgument.bind(this);
    }

    componentDidMount(): void {
        const isAuthor = this.props.argument.author.id === this.props.loaderData.id;
            this.setState({...this.state, isAuthor: isAuthor }, () => {
                if(this.viewerRef.current) {
                    this.viewerRef.current.setContent(JSON.parse(this.state.argument.content));
                }
            });

        
    }

    openCommentBox() {
        this.setState({...this.state, isCommentBoxOpen: true});
    }

    closeCommentBox() {
        this.setState({...this.state, isCommentBoxOpen: false});
    }

    onCommentPosted(comment:ViewCommentResult) {
        let argument = this.state.argument;
        argument.numberOfComments += 1;
        argument.comments.unshift(comment);
        this.setState({...this.state, argument: argument, isCommentBoxOpen: false});
    }

    toggleComments() {
        this.setState({...this.state, showMoreComments: !this.state.showMoreComments});
    }

    showComments() {
        const elements:JSX.Element[] = [];
        const limit = this.state.showMoreComments 
            ? this.state.argument.comments.length 
            : this.state.argument.comments.length < 5 
                ? this.state.argument.comments.length 
                : 5;

        for(let i = 0; i < limit; i++) {
            elements.push(<ChildComment key={this.state.argument.comments[i].commentId} comment={this.state.argument.comments[i]} />)
        }

        return elements;
    }

    openContextMenu(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            ...this.state,
            isMenuOpen: true,
            menuAnchor: event.currentTarget
        });
    }

    closeContextMenu() {
        this.setState({
            ...this.state,
            isMenuOpen: false
        });
    }

    upVoteArgument() {
        Vote.Upvote(this.state.argument.argumentId).then(() => {
            let argument = this.state.argument;
            argument.votes += 1;
            this.setState({...this.state, argument: argument});
        }).catch(error => {
            console.log(error);
            this.setState({...this.state, error: error});
        });
    }

    downVoteArgument() {
        Vote.Downvote(this.state.argument.argumentId).then(() => {
            let argument = this.state.argument;
            argument.votes -= 1;
            this.setState({...this.state, argument: argument});
        }).catch(error => {
            console.log(error);
            this.setState({...this.state, error: error});
        });
    }

    render() {
        return (
            <Stack gap={"24px"} sx={{width: "100%"}}>
                <Card style={cardStyle}>
                    <CardContent style={{textAlign: "left"}}>
                        <Stack sx={{marginLeft: "-12px"}} direction="row">
                            <Stack gap={"6px"} alignItems="center" justifyItems="center" alignContent="center" style={leftSidebarStyle}>
                                {this.state.isAuthor && 
                                    <Box sx={{height: 72, width: 32}}></Box>
                                }
                                {!this.state.isAuthor && <IconButton onClick={this.upVoteArgument}>
                                    <KeyboardArrowUpIcon />
                                </IconButton>}
                                <Typography>{this.state.argument.votes}</Typography>
                                {!this.state.isAuthor && <IconButton onClick={this.downVoteArgument}>
                                    <KeyboardArrowDownIcon />
                                </IconButton>}
                                {
                                    this.props.argument.state === ArgumentState.ConsensusAgreed &&
                                    <CheckIcon style={{color: "green", fontWeight: "bold"}} />
                                }
                                {
                                    this.props.argument.state === ArgumentState.ConsensusDisagreed &&
                                    <CloseIcon style={{color: "red", fontWeight: "bold"}} />
                                }
                            </Stack>
                            <Stack gap={"18px"} style={{flexGrow: 1}}>
                                <Stack gap={"12px"} style={{width: "100%"}} direction="row">
                                    <AuthorPopover whoAmI={this.props.loaderData} author={this.state.argument.author}>
                                        <Avatar src={this.state.argument.author.profileImageUrl.length > 0 ? this.state.argument.author.profileImageUrl : undefined} sx={{ bgcolor: red[500] }} aria-label="recipe">
                                            {this.state.argument.author.name[0]}
                                        </Avatar>
                                    </AuthorPopover>
                                    
                                    <Box>
                                        <div style={{textAlign: "left", fontWeight: "bold"}}>{this.state.argument.author.name}</div>
                                        <div style={{textAlign: "left"}}><AuthorReputation author={this.state.argument.author}/></div>
                                    </Box>
                                    <Spacer />
                                    <Box>
                                        <IconButton onClick={this.openContextMenu} sx={{marginLeft: "auto", flexGrow: 1}}><MoreVertIcon/></IconButton>
                                    </Box>
                                </Stack>
                                <Stack spacing={2}>
                                    <Stack direction="row" spacing={2}>
                                        <Typography variant="body2" color="text.secondary">
                                            {FormatDate(new Date(this.state.argument.date))}
                                        </Typography>
                                    </Stack>
                                    <FetchViewer ref={this.viewerRef} />
                                </Stack>
                                {
                                    this.state.isCommentBoxOpen && (
                                        <div>
                                            <CreateComment whoAmI={this.props.loaderData} onCommentPosted={this.onCommentPosted} contentId={this.state.argument.argumentId}/>
                                        </div>
                                    )
                                }
                                {
                                    this.state.argument.comments.length > 0 && (
                                        <Stack spacing={1}>
                                            <Divider />
                                            <Stack style={{flexGrow: 1}} spacing={1} divider={<Divider />}>
                                                {this.showComments()}
                                            </Stack>
                                            <Divider />
                                            {this.state.argument.comments.length > 5 && (
                                                <Box>
                                                    <Link onClick={this.toggleComments}><Typography variant="body2" style={{fontSize: "80%", display: "inline"}}>{this.state.showMoreComments ? "Hide" : "Show"} {this.state.argument.comments.length - 5} more comments</Typography></Link>
                                                </Box>
                                            )}
                                        </Stack>
                                    )
                                }
                                <Stack direction="row" gap={"12px"} style={{alignItems: "center"}}>
                                    <VoteTag votes={this.state.argument.votes} />
                                    <CommentTag comments={this.state.argument.numberOfComments} />
                                    <Spacer />
                                    <Button onClick={this.openCommentBox} variant='outlined' style={{marginLeft: "auto"}}>Comment</Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
                <FeedItemCardMenu whoAmI={this.props.loaderData} hasFavorited={this.state.argument.hasFavorited} contentId={this.state.argument.argumentId} author={this.state.argument.author} isHidden={false} onClose={this.closeContextMenu} open={this.state.isMenuOpen} anchor={this.state.menuAnchor} />

                {/* <CreateComment onCommentPosted={this.onCommentPosted} contentId={this.props.answer.answerId} />
                <BasicCommentList comments={this.props.answer.comments} contentId={this.props.answer.answerId} /> */}
            </Stack>
        ); 
    }
}

export default withParams(ViewArgument);