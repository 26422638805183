import { Box, Card, Dialog, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import DataFeedAPI from '../API/DataFeedAPI';
import { Symbol } from '../API/models/market';

import AddIcon from '@mui/icons-material/Add';
import CreateSymbol from './CreateSymbol';


const colDefinitions: GridColDef[] = [
    {field: "name", headerName: "Name"},
    {field: "fullName", headerName: "Full name"},
    {field: "description", headerName: "Description"},
    {field: "exchange", headerName: "Exchange"},
    {field: "type", headerName: "Type"},
    {field: "minMov", headerName: "Minimum movement"},
    {field: "priceScale", headerName: "Price scale"},
    {field: "volumePrecision", headerName: "Volume precision"},
]

type SymbolsProps = {

}

type SymbolsState = {
    rows:Symbol[],
    isNewDialogOpen:boolean
}

export default class Symbols extends React.Component<SymbolsProps, SymbolsState> {
    constructor(props:SymbolsProps) {
        super(props);
        this.state = {
            rows: [],
            isNewDialogOpen: false
        };

        this.openNewDialog = this.openNewDialog.bind(this);
        this.closeNewDialog = this.closeNewDialog.bind(this);
        this.onSymbolCreate = this.onSymbolCreate.bind(this);
    }

    componentDidMount(): void {
        DataFeedAPI.GetSymbols().then(symbols => {
            this.setState({...this.state, rows:symbols});
        });
    }

    openNewDialog() {
        this.setState({...this.state, isNewDialogOpen: true});
    }

    closeNewDialog() {
        this.setState({...this.state, isNewDialogOpen: false});
    }

    onSymbolCreate(newSymbol:Symbol) {
        const symbols = this.state.rows;
        symbols.push(newSymbol);
        this.setState({...this.state, rows: symbols});
    }

    render() {
        return(
            <Box>
                <Card sx={{m: 2, p: 2, flexGrow: 1}}>
                    <Stack gap={"12px"}>
                        <Typography variant='h5'>Symbols</Typography>
                        <Stack gap="12px">
                            <IconButton onClick={this.openNewDialog}>
                                <AddIcon />
                            </IconButton>
                        </Stack>
                        <div style={{height: "500px"}}>
                            <DataGrid
                                sx={{height: "500px"}}
                                columns={colDefinitions}
                                rows={this.state.rows}
                                pageSize={50}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                checkboxSelection
                            />
                        </div>
                    </Stack>
                </Card>
                <Dialog onClose={this.closeNewDialog} open={this.state.isNewDialogOpen}>
                    <DialogTitle>Create a new symbol</DialogTitle>
                    <CreateSymbol onCreate={this.onSymbolCreate}/>
                </Dialog>
            </Box>
        )
    }
}