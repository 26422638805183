import React from 'react';
import './charting_library/charting_library';
import './datafeeds/udf/dist/bundle'
import { generateRandomUUID } from '../../Helpers/IdHelper';

import DataFeed from '../../DataFeed/DataFeed';

/* eslint-disable */
export default class TVChart extends React.Component {
    id;
    widget;
    tvChartParameters;
    constructor(props) {
        super(props);
        this.saveChart = this.saveChart.bind(this);
        this.id = generateRandomUUID();
        this.tvChartParameters = {
            interval: this.props.interval,
            container: "TVChart-" + this.id,
            //	BEWARE: no trailing slash is expected in feed URL
            datafeed: new DataFeed(),
            library_path: "/charting_library/",
            locale: "en",
            client_id: 'tradingview.com',
            user_id: 'public_user_id',
            theme: "Light"
        }

        this.state = {
            range: {
                from: 0,
                to: 0
            }
        }

        this.tvChartParameters.symbol = props.symbol ? props.symbol : "44";
        this.tvChartParameters.interval = props.interval ? props.interval : "1D";
        this.tvChartParameters.enabled_features = props.enabledFeatures ? props.enabledFeatures : [];
        this.tvChartParameters.disabled_features = props.disabledFeatures ? props.disabledFeatures : [];
        this.onChartReady = this.onChartReady.bind(this);
        this.onChartMove = this.onChartMove.bind(this);
        this.loadChart = this.loadChart.bind(this);
    }

    saveChart() {
        this.widget.save(state => {
            this.props.onSave(state);
        });
    }

    loadChart(state, range) {
        this.widget.onChartReady(() => {
            this.widget.activeChart().onDataLoaded().subscribe(null, () => {
                this.widget.activeChart().setVisibleRange(range);
            })
            this.widget.load(state);
        });     
    }

    setSymbol(symbol) {
        this.widget.setSymbol(symbol, "1D", () => {});
    }

    getData() {
        return new Promise((resolve, reject) => {
            this.widget.save(state => {
                resolve({state: state, range: this.state.range});
            });

            reject("Something went wrong");
        })
    }

    render() {
        return (
            <div id={ "TVChart-" + this.id }></div>
        );
    }

    onChartReady() {
        this.widget.activeChart().onVisibleRangeChanged().subscribe(null, this.onChartMove);
    }

    onChartMove(range) {
        this.setState({...this.state, range: {
            from : range.from, to: range.to
        }});
    }

    componentDidMount() {
        const widget = new TradingView.widget(this.tvChartParameters);
        this.widget = widget;
        this.widget.onChartReady(this.onChartReady);
    }
}

/* eslint-enable */