import { Autocomplete, TextField } from '@mui/material';

import React from 'react';

const testTags = [
    'Trading Tier List',
    'BTC',
    'COIN',
    'DOGE',
    'ETH',
    'HEX',
    'LONG',
    'NEWS',
    'PEPE',
    'S&P500',
    'SHORT',
    'SOL',
    'TA',
    'UNI',
    'XRP',
];

const TagInputTextFieldStyle:React.CSSProperties = {
    borderBottom: "none"
}

type TagInputProps = {
    onChange:Function
}

export default class TagInput extends React.Component<TagInputProps> {
    constructor(props:TagInputProps) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event:any, value:string[]) {
        this.props.onChange(value);
    }

    render() {
        return(
            <Autocomplete onChange={this.onChange} multiple options={testTags} renderInput={(params) => (
                <TextField style={TagInputTextFieldStyle} {...params} variant="outlined" size="small" label="Tags" placeholder="Select tags" />
            )}></Autocomplete>
        );
    }
}