import ReactGA from 'react-ga4';

enum EventCategory {
    BUTTONCLICK = 'button_click',
    LINKCLICK = 'link',
    CREATE = 'create',
}

// Recommended events
const sendPageView = (path: string, title: string) => ReactGA.send({ hitType: 'pageview', page: path, title: title })

// custom events
const buttonClick = (action: string) => ReactGA.event(
    { 
        category: EventCategory.BUTTONCLICK, 
        action: action, 
    }
)

const createClick = (action: string) => ReactGA.event(
    {
        category: EventCategory.CREATE,
        action: action,
        
    }
)

const linkClick = (action: string) => ReactGA.event(
    { 
        category: EventCategory.LINKCLICK, 
        action: action, 
    }
)



const events = {
    buttonClick: (label: string) =>  buttonClick(label),
    createClick: (label: string) => createClick(label),
    linkClick: (label: string) => linkClick(label),
    sendPageView: (path: string, title: string) => sendPageView(path, title),
}

export default events;