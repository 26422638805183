import { Stack, Typography } from '@mui/material';
import React from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { withParams } from '../Helpers/ReactRouterHelper';

type ErrorParams = {
    error:string
}

type ErrorProps = {
    params:ErrorParams
}

class Error extends React.Component<ErrorProps> {
    render() {
        return(
            <Stack gap={"12px"} alignItems="center" justifyContent="center">
                <ErrorOutlineIcon sx={{fontSize: "48px"}} />
                <Typography variant='h4'>{this.props.params.error}</Typography>
                <Typography variant='body1'>If the issue persist, please contact customer support.</Typography>
            </Stack>
        )
    }
}

export default withParams(Error);