import { Box } from '@mui/material';
import React from 'react';
import { withParams } from '../../Helpers/ReactRouterHelper';
import TVChart from '../Charts/TVChart';

type ViewSymbolParams = {
    symbol:string
}

type ViewSymbolProps = {
    params:ViewSymbolParams
}

type ViewSymbolState = {

}

class ViewSymbol extends React.Component<ViewSymbolProps, ViewSymbolState> {
    render() {
        return(
            <Box sx={{width: "100%"}}>
                <TVChart symbol={this.props.params.symbol} />
            </Box>
        );
    }
}

export default withParams(ViewSymbol);