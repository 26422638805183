import { Stack, Box, Typography, TextField, Alert, Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Account from '../API/Account';
import { router } from '../App';
import { withParams } from '../Helpers/ReactRouterHelper';

type ConfirmResetPasswordParams = {
    token: string
}

type ConfirmResetPasswordProps = {
    params: ConfirmResetPasswordParams
}

type ConfirmResetPasswordState = {
    password: string,
    confirmPassword: string,
    succeeded: boolean
}

class ConfirmResetPassword extends React.Component<ConfirmResetPasswordProps, ConfirmResetPasswordState> {
    constructor(props: ConfirmResetPasswordProps) {
        super(props);

        this.state = {
            password: "",
            confirmPassword: "",
            succeeded: false
        };

        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onConfirmPasswordChange = this.onConfirmPasswordChange.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    componentDidMount(): void {
        Account.IsPasswordResetTokenValid(this.props.params.token).then(isValid => {
            if(!isValid) {
                router.navigate("/forgot/password");
            }
        }).catch(error => {
            
        });
    }

    onPasswordChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, password: event.currentTarget.value});
    }

    onConfirmPasswordChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, confirmPassword: event.currentTarget.value});
    }

    resetPassword() {
        Account.CompletePasswordReset(this.state.password, this.state.confirmPassword, this.props.params.token).then(() => {
            this.setState({...this.state, succeeded: true}, () => {
                setTimeout(() => {
                    router.navigate("/login");
                }, 2000);
            });
        });
    }

    render() {
        return(
            <Stack gap={5}>
                {this.state.succeeded && 
                    <Alert severity='success'>Updated password!</Alert>
                }
                <Box style={{textAlign: "center"}}>
                    <Typography variant="h6">Reset your password</Typography>
                </Box>
                <Stack gap={2}>
                    <TextField value={this.state.password} size="small" onChange={this.onPasswordChange} variant="filled" fullWidth id='password' placeholder='Password' label='Password' type="password" />
                    <TextField value={this.state.confirmPassword} size="small" onChange={this.onConfirmPasswordChange} variant="filled" fullWidth id='confirm-password' placeholder='Confirm password' label='Confirm Password' type="password" />
                </Stack>
                <Stack gap={2}>
                    <Button type="submit" variant='contained' onClick={this.resetPassword} fullWidth>Reset password</Button>
                </Stack>
            </Stack>
        )
    }
}

export default withParams(ConfirmResetPassword);