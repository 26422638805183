import { Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Typography, Box, Button } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import ga4events from "../events";

import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/Upload';

import AppsIcon from '@mui/icons-material/Apps';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import PollIcon from '@mui/icons-material/Poll';
import QuizIcon from '@mui/icons-material/Quiz';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StarIcon from '@mui/icons-material/Star';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

import SettingsIcon from '@mui/icons-material/Settings';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';


import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ROLES_FREE, ROLES_MANAGE_APP, ROLES_VIEW_POST, router } from "../App";
import Authentication from "../API/Authentication";
import { hasRole } from "../Helpers/AuthHelper";
import NotificationController from "../API/NotificationController";
import { WhoAmIResponse } from "../API/models/account";

type MainMenuProps = {
    whoAmI: WhoAmIResponse
}

type MainMenuState = {
    showCreate:boolean,
    showPosts:boolean,
    isLoggedIn:boolean,
    isAdmin:boolean,
    notificationCount:number
}

const menuHeaderStyle = {
    textAlign: "left", m: 2
}

const menuItemStyle = {
    m: 1
}

export default class MainMenu extends React.Component<MainMenuProps, MainMenuState> {
    constructor(props:MainMenuProps) {
        super(props);

        this.state = {
            showCreate: false,
            showPosts: false,
            isLoggedIn: false,
            isAdmin: false,
            notificationCount: 0
        };

        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidMount(): void {
        const isAdmin = hasRole(this.props.whoAmI, ROLES_MANAGE_APP);
        const showCreate = hasRole(this.props.whoAmI, ROLES_FREE);
        const showPosts = hasRole(this.props.whoAmI, ROLES_VIEW_POST);
        this.setState({
            ...this.state, 
            showCreate: showCreate,
            showPosts: showPosts,
            isLoggedIn: this.props.whoAmI.id.length > 0,
            isAdmin: isAdmin
        }, () => {
            if(this.props.whoAmI.id.length > 0) {
                NotificationController.GetCount().then(notificationCount => {
                    this.setState({...this.state, notificationCount: notificationCount});
                });
            }
        });
    }

    login() {
        router.navigate("/login");
    }

    logout() {
        Authentication.Logout();
        this.setState({...this.state, isLoggedIn: false}, () => {
            router.navigate("/login");
        });
    }

    render() {
        return(
            <MenuList className="test-class">
                {
                    this.state.showCreate && 
                    <NavLink to="/new">
                        <div style={{padding: "8px"}}>
                            <Button onClick={()=> ga4events.buttonClick('New Post')} fullWidth variant="contained" startIcon={<EditIcon fontSize="small"/>}>
                                New Post
                            </Button>
                        </div>
                    </NavLink>
                }
                <Typography variant="body1" sx={menuHeaderStyle} color="text.primary">
                        Browse
                </Typography>
                <NavLink to="/" onClick={()=> ga4events.linkClick('To Feed')}>
                    <MenuItem sx={menuItemStyle}>
                        <ListItemIcon>
                            <AppsIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText sx={menuHeaderStyle} >All</ListItemText>
                    </MenuItem>
                </NavLink>
                {this.state.showPosts && 
                    <NavLink to="/posts" onClick={()=> ga4events.linkClick('To posts')}>
                        <MenuItem sx={menuItemStyle}>
                            <ListItemIcon>
                                <NewspaperIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText sx={menuHeaderStyle}>Posts</ListItemText>
                        </MenuItem>
                    </NavLink>
                }
                <NavLink to="/questions">
                    <MenuItem sx={menuItemStyle} onClick={()=> ga4events.linkClick('To Questions')}>
                        <ListItemIcon>
                            <QuizIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText sx={menuHeaderStyle}>Questions</ListItemText>
                    </MenuItem>
                </NavLink>
                <NavLink to="/theories" onClick={()=> ga4events.linkClick('To Theories')}>
                    <MenuItem sx={menuItemStyle}>
                        <ListItemIcon>
                            <AutoStoriesIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText sx={menuHeaderStyle}>Theories</ListItemText>
                    </MenuItem>
                </NavLink>
                <NavLink to="/polls" onClick={()=> ga4events.linkClick('To polls')}>
                    <MenuItem sx={menuItemStyle}>
                        <ListItemIcon>
                            <PollIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText sx={menuHeaderStyle}>Polls</ListItemText>
                    </MenuItem>
                </NavLink>
                <Divider />
                {this.state.showCreate &&
                <Box>
                    <Typography variant="body1" sx={menuHeaderStyle} color="text.primary">
                        Create
                    </Typography>
                    
                    <NavLink to="/symbols" onClick={()=> ga4events.linkClick('To Symbols')}>
                        <MenuItem sx={menuItemStyle}>
                            <ListItemIcon>
                                <CurrencyBitcoinIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText sx={menuHeaderStyle}>Symbols</ListItemText>
                        </MenuItem>
                    </NavLink>
                    {this.state.isAdmin && 
                        <NavLink to="/import" onClick={()=> ga4events.linkClick('To Import')}>
                            <MenuItem sx={menuItemStyle}>
                                <ListItemIcon>
                                    <UploadIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText sx={menuHeaderStyle}>Import</ListItemText>
                            </MenuItem>
                        </NavLink> }
                    {this.state.isAdmin && 
                        <NavLink to="/upgrade-user" onClick={()=> ga4events.linkClick('To Upgrade User')}>
                            <MenuItem sx={menuItemStyle}>
                                <ListItemIcon>
                                    <FollowTheSignsIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText sx={menuHeaderStyle}>Upgrade user</ListItemText>
                            </MenuItem>
                        </NavLink> }
                    <Divider />
                </Box>
                }
                
                {this.state.isLoggedIn && 
                    <Box>
                        <Typography variant="body1" sx={menuHeaderStyle} color="text.primary">
                                Account
                        </Typography>
                        <NavLink to="/profile" onClick={()=> ga4events.linkClick('To Profile')}>
                            <MenuItem sx={menuItemStyle}>
                                <ListItemIcon>
                                    <AccountCircleIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText sx={menuHeaderStyle}>Profile</ListItemText>
                            </MenuItem>
                        </NavLink>
                        <NavLink to="/favorites" onClick={()=> ga4events.linkClick('To Favorites')}>
                            <MenuItem sx={menuItemStyle}>
                                <ListItemIcon>
                                    <StarIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText sx={menuHeaderStyle}>Favorites</ListItemText>
                            </MenuItem>
                        </NavLink>
                        {this.state.isLoggedIn && (
                            <NavLink to="/notifications" onClick={()=> ga4events.linkClick('To Notifications')}>
                                <MenuItem sx={menuItemStyle}>
                                    <ListItemIcon>
                                        <ChatBubbleIcon fontSize="small" />
                                    </ListItemIcon>
                                    
                                    <ListItemText sx={menuHeaderStyle}>Notifications</ListItemText>
                                    {this.state.notificationCount > 0 && (
                                        <Typography variant="body2" color="text.secondary">
                                            {this.state.notificationCount}
                                        </Typography>
                                    )}
                                </MenuItem>
                            </NavLink>
                        )}
                        
                        <Divider />
                    </Box>
                }
                
                <Typography variant="body1" sx={menuHeaderStyle} color="text.primary">
                        Other
                </Typography>
                {this.state.isLoggedIn && (
                    <NavLink to="/settings" onClick={()=> ga4events.linkClick('To Settings')}>
                        <MenuItem sx={menuItemStyle}>
                            <ListItemIcon>
                                <SettingsIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText sx={menuHeaderStyle}>Settings</ListItemText>
                        </MenuItem>
                    </NavLink>
                )}
                <NavLink to="/portfolio" onClick={()=> ga4events.linkClick('To Portfolio')}>
                    <MenuItem sx={menuItemStyle}>
                        <ListItemIcon>
                            <AccountBalanceWalletIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText sx={menuHeaderStyle}>Portfolio</ListItemText>
                    </MenuItem>
                </NavLink>
                <NavLink to="/whale-tracker" onClick={()=> ga4events.linkClick('To Whale Tracker')}>
                    <MenuItem sx={menuItemStyle}>
                        <ListItemIcon>
                            <QueryStatsIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText sx={menuHeaderStyle}>Whale Tracker</ListItemText>
                    </MenuItem>
                </NavLink>
                <NavLink to="/indicators" onClick={()=> ga4events.linkClick('To Indicators')}>
                    <MenuItem sx={menuItemStyle}>
                        <ListItemIcon>
                            <AutoGraphIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText sx={menuHeaderStyle}>Indicators</ListItemText>
                    </MenuItem>
                </NavLink>
                <MenuItem onClick={this.state.isLoggedIn ? this.logout : this.login} sx={menuItemStyle}>
                    <ListItemIcon>
                    {this.state.isLoggedIn 
                    ? <ArrowBackIcon fontSize="small" /> 
                    : <ArrowForwardIcon fontSize="small" /> }
                        
                    </ListItemIcon>
                    <ListItemText sx={menuHeaderStyle}>{this.state.isLoggedIn ? "Logout" : "Login"}</ListItemText>
                </MenuItem>
            </MenuList>
        );
    }
}