import Data from "./Data";
import { NotificationDTO } from "./models/datatransferobjects";

export const notificationRoute = "/Notifications";

export default abstract class NotificationController {


    public static async GetNotifications(page:number) : Promise<NotificationDTO[]> {
        return new Promise<NotificationDTO[]>((resolve, reject) => {
            Data.Get<NotificationDTO[]>(`${notificationRoute}/${page}`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async GetUnread() : Promise<NotificationDTO[]> {
        return new Promise<NotificationDTO[]>((resolve, reject) => {
            Data.Get<NotificationDTO[]>(`${notificationRoute}/Unread`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async GetRead() : Promise<NotificationDTO[]> {
        return new Promise<NotificationDTO[]>((resolve, reject) => {
            Data.Get<NotificationDTO[]>(`${notificationRoute}/Read`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async GetCount() : Promise<number> {
        return new Promise<number>((resolve, reject) => {
            Data.Get<number>(`${notificationRoute}/Count`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }
}