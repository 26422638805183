import { Box } from '@mui/material';
import React, { ChangeEvent } from 'react';
import AddIcon from '@mui/icons-material/Add';

type ImageInputProps = {

};

type ImageInputState = {
    image: string | null,
    imageBase64: string | null,
    hover: boolean
};

const ImageInputWithImageStyle:React.CSSProperties = {
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}

const ImageInputStyle:React.CSSProperties = {
    borderRadius: "6px",
    borderStyle: "dashed",
    borderWidth: "2px",
    borderColor: "#6f6d71",
    padding: "12px",
    height: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
};

const ImageInputHoverStyle:React.CSSProperties = {
    cursor: "pointer",
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
};

export default class ImageInput extends React.Component<ImageInputProps, ImageInputState> {
    fileRef:React.RefObject<HTMLInputElement>;

    constructor(props:ImageInputProps) {
        super(props);
        this.fileRef = React.createRef();
        this.openFile = this.openFile.bind(this);
        this.toggleHover = this.toggleHover.bind(this);
        this.fileChanged = this.fileChanged.bind(this);
        this.state = {
            image: null,
            hover: false,
            imageBase64: null,
        };

        this.getBase64 = this.getBase64.bind(this);
    }

    getBase64() {
        return this.state.imageBase64;
    }

    fileChanged(event:ChangeEvent<HTMLInputElement>) {
       if(event.target.files != null) {
            const file = event.target.files[0];
            if(file) {
                const image = window.URL.createObjectURL(file);
                if(image != null) {
                    this.setState({...this.state, image: image});
                }

                const reader = new FileReader();
                reader.onload = () => {
                    const imageBase64 = (reader.result as string).split(';base64,')[1];
                    this.setState({...this.state, imageBase64: imageBase64 });
                }
                reader.onerror = (error) => {
                    console.error(error);
                }
                reader.readAsDataURL(file);
            }
        }
    }

    openFile() {
        this.fileRef.current?.click();
    }

    toggleHover(isToggled:boolean) {
        this.setState({...this.state, hover: isToggled});
    }
    
    render() {
        if(this.state.image) {
            return(
                <Box style={this.state.hover ? {...ImageInputWithImageStyle, ...ImageInputHoverStyle} : ImageInputWithImageStyle} onClick={this.openFile} onMouseEnter={() => {this.toggleHover(true)}} onMouseLeave={() => {this.toggleHover(false)}}>
                    <input hidden ref={this.fileRef} onChange={this.fileChanged} type="file" accept="image/*" />
                    <img title="preview" alt='preview' style={{height: "auto", width: "100%"}} src={this.state.image} />
                </Box>
            )
        }

        else {
            return(
                <Box style={this.state.hover ? {...ImageInputStyle, ...ImageInputHoverStyle, backgroundColor:" #f3edf7"} : ImageInputStyle} onClick={this.openFile} onMouseEnter={() => {this.toggleHover(true)}} onMouseLeave={() => {this.toggleHover(false)}}>
                    <input hidden ref={this.fileRef} onChange={this.fileChanged} type="file" accept="image/*" />
                    <AddIcon />
                </Box>
            );
        }
    }
}