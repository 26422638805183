import { Avatar, Box, Card, CardContent, CardHeader, CircularProgress, Collapse, Popover, Stack, Typography } from '@mui/material';
import React from 'react';
import { red } from '@mui/material/colors';
import { AuthorDTO } from '../../API/models/datatransferobjects';
import { ProfileResult, WhoAmIResponse } from '../../API/models/account';
import FeedController from '../../API/FeedController';
import FeedItemCard from '../Feed/FeedItemCard';
import PostCardShadow from '../Post/PostCardShadow';
import { router } from '../../App';
import AuthorReputation from './AuthorReputation';

type AuthorPopoverProps = {
    children? : JSX.Element | JSX.Element[],
    author: AuthorDTO,
    whoAmI: WhoAmIResponse
}

type AuthorPopoverState = {
    isOpen:boolean,
    anchor?:Element,
    firstOpen:boolean
    profile?:ProfileResult
}

export default class AuthorPopover extends React.Component<AuthorPopoverProps, AuthorPopoverState> {
    constructor(props:AuthorPopoverProps) {
        super(props);
        this.onEnter = this.onEnter.bind(this);
        this.onLeave = this.onLeave.bind(this);
        this.gotoProfile = this.gotoProfile.bind(this);

        this.state = {
            isOpen: false,
            firstOpen: false
        }
    }

    onEnter(event:React.SyntheticEvent) {
        this.setState({...this.state, isOpen: true, anchor: event.currentTarget}, () => {
            if(!this.state.firstOpen) {
                FeedController.GetProfile(this.props.author.name).then(profile => {
                    this.setState({...this.state, profile: profile, firstOpen: true});
                });
            }
        });
    }

    onLeave(event:React.SyntheticEvent) {
        this.setState({...this.state, isOpen: false});
    }

    gotoProfile() {
        router.navigate(`/profile/${this.props.author.name}`);
    }

    render() {
        return(
            <Box onClick={this.onEnter} onMouseLeave={this.onLeave}>
                {this.props.children}
                {this.state.anchor && (
                    <Popover 
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        anchorEl={this.state.anchor} 
                        onClose={this.onLeave} 
                        open={this.state.isOpen}
                        sx={{maxHeight: '50vh', maxWidth: "75vw"}}
                    >
                        <Collapse in={this.state.isOpen} unmountOnExit>
                            <Box sx={{p: 1}}>
                                <Stack gap={"12px"}>
                                    <Card>
                                        <CardHeader 
                                            avatar={
                                                <Avatar src={this.props.author.profileImageUrl.length > 0 ? this.props.author.profileImageUrl : undefined} onClick={this.gotoProfile} sx={{ bgcolor: red[500] }} aria-label="recipe">
                                                    {this.props.author.name[0]}
                                                </Avatar>}
                                                title={<div style={{textAlign: "left", fontWeight: "bold"}}>{this.props.author.name}</div>}
                                                subheader={<div style={{textAlign: "left"}}><AuthorReputation author={this.props.author}/></div>}
                                        />
                                        <CardContent>
                                            {this.state.profile ? 
                                            this.state.profile.bio.length > 0 ?
                                            <Typography variant='body1'>
                                                {this.state.profile.bio}
                                            </Typography> : <Typography variant='body1'>This user has no bio...</Typography> : <CircularProgress />}
                                        </CardContent>
                                    </Card>
                                    {this.state.profile ? this.state.profile.items.map((post, i) => {
                                        return <FeedItemCard whoAmI={this.props.whoAmI} key={i} item={post} hasHover={false}/>
                                    }) : [0, 1, 3].map((n, i) => {
                                        return <PostCardShadow key={i} />
                                    })}
                                </Stack>
                            </Box>
                        </Collapse>
                    </Popover>
                )}
            </Box>
        )
    }
}