import { _PROTOCOL, _HOST, _PORT } from "./Data";
import Data from './Data';

export default abstract class Subscription {

    public static async CreatePayment(product_lookup_key:string) : Promise<void> {
        const token = Data.GetAccessToken();
        return new Promise((resolve, reject) => {
            fetch(`${_PROTOCOL()}${_HOST()}:${_PORT()}/create-checkout-session/${product_lookup_key}`, {
                method: "GET",
                headers: {
                    ...token && { "Authorization": `Bearer ${token}`}
                }
            }).then(response => {
                if(response.status == 200) {
                    response.text().then(location => {
                        window.location.href = location;
                    });
                }
                else{
                    reject("Something went wrong!");
                }
            }).catch(error => {
                console.log(error);
            })
        });
    }

    public static async CreatePortalSession() : Promise<void> {
        const token = Data.GetAccessToken();
        return new Promise((resolve, reject) => {
            fetch(`${_PROTOCOL()}${_HOST()}:${_PORT()}/create-portal-session`, {
                method: "GET",
                headers: {
                    ...token && { "Authorization": `Bearer ${token}`}
                }
            }).then(response => {
                if(response.status == 200) {
                    response.text().then(location => {
                        window.location.href = location;
                    });
                }
                else{
                    reject("Something went wrong!");
                }
            }).catch(error => {
                console.log(error);
            })
        });
    }

    public static async CheckStripe() : Promise<boolean> {
        const token = Data.GetAccessToken();
        return new Promise((resolve, reject) => {
            fetch(`${_PROTOCOL()}${_HOST()}:${_PORT()}/check-stripe`, {
                method: "GET",
                headers: {
                    ...token && { "Authorization": `Bearer ${token}`}
                }
            }).then(response => {
                if(response.status == 200) {
                    response.text().then(isManageable => {
                        resolve(isManageable === "true");
                    });
                }
                else{
                    reject("Something went wrong!");
                }
            }).catch(error => {
                console.log(error);
            })
        });
    }

    public static async AddSubscription(email:string, endDate:Date) : Promise<void> {
        const token = Data.GetAccessToken();
        return new Promise((resolve, reject) => {
            fetch(`${_PROTOCOL()}${_HOST()}:${_PORT()}/Subscription/Add?email=${email}&subscriptionUntil=${endDate.toISOString()}`, {
                method: "GET",
                headers: {
                    ...token && { "Authorization": `Bearer ${token}`}
                }
            }).then(response => {
                if(response.status == 200) {
                    resolve()
                }
                else{
                    reject("Something went wrong!");
                }
            }).catch(error => {
                reject(error);
                console.log(error);
            })
        });
    }
}