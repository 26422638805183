import { Button, Card, CardActions, CardContent, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { hasRole } from '../../Helpers/AuthHelper';
import { ROLES_CREATE_POST } from '../../App';
import ga4events from '../../events';

import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

import PsychologyIcon from '@mui/icons-material/Psychology';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

import BoltIcon from '@mui/icons-material/Bolt';
import PollIcon from '@mui/icons-material/Poll';
import MoodIcon from '@mui/icons-material/Mood';
import { withParams } from '../../Helpers/ReactRouterHelper';
import { WhoAmIResponse } from '../../API/models/account';

type NewPostState = {
    canCreatePost: boolean
}

type NewPostProps = {
    loaderData: WhoAmIResponse
}

const smColumnSize = 12;
const mdColumnSize = 12;
const lgColumnSizeDefault = 4;

const cardStyle = {
    height: "100%",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column" as 'column'
};

class NewPost extends React.Component<NewPostProps, NewPostState> {

    constructor(props:NewPostProps) {
        super(props);
        this.state = {
            canCreatePost: false
        };
    }

    componentDidMount(): void {
        const showCreate = hasRole(this.props.loaderData, ROLES_CREATE_POST);
        this.setState({
            ...this.state, 
            canCreatePost: showCreate
        });
    }

    render(): React.ReactNode {
        return(
            <Grid container spacing={2}>
                {this.state.canCreatePost && 
                    <Grid item sm={smColumnSize} style={{flexGrow: 1}}>
                        <Paper sx={{p: 2}}>
                            <Stack direction={'row'} spacing={2} alignItems="center" justifyContent="space-between">
                                <Typography variant='h6'>Create a post</Typography>
                                <Button onClick={() => ga4events.createClick('Post')} href='/new/post' variant="contained">Create</Button>
                            </Stack>
                        </Paper>
                    </Grid>
                }
                <Grid item sm={smColumnSize} md={mdColumnSize} lg={lgColumnSizeDefault} style={{flexGrow: 1}}>
                    <Card style={cardStyle}>
                        {/* <CardMedia
                            sx={{ height: 140 }}
                            image="/static/images/cards/contemplative-reptile.jpg"
                            title="green iguana"
                        /> */}
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Question
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Ask your community about anything. 
                            </Typography>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                    <ListItemIcon>
                                        <AutoGraphIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Request analysis for assets" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                    <ListItemIcon>
                                        <FactCheckIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Get clarity about technical analysis" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                    <ListItemIcon>
                                        <CurrencyExchangeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Get explanations about the economy" />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </CardContent>
                        <CardActions>
                            <Button onClick={() => ga4events.createClick('Question')} href='/new/question' fullWidth variant="contained">Create</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item sm={smColumnSize} md={mdColumnSize} lg={lgColumnSizeDefault} style={{flexGrow: 1}}>
                    <Card style={cardStyle}>
                        {/* <CardMedia
                            sx={{ height: 140 }}
                            image="/static/images/cards/contemplative-reptile.jpg"
                            title="green iguana"
                        /> */}
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Theory
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Share theories and get feedback.
                            </Typography>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                    <ListItemIcon>
                                        <PsychologyIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Share your thoughts on the state of the market" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                    <ListItemIcon>
                                        <AssignmentTurnedInIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Test your ideas" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                    <ListItemIcon>
                                        <QuestionAnswerIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Get feedback and discuss with the community" />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </CardContent>
                        <CardActions>
                            <Button onClick={() => ga4events.createClick('Theory')} href='/new/theory' fullWidth variant="contained">Create</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item sm={smColumnSize} md={mdColumnSize} lg={lgColumnSizeDefault} style={{flexGrow: 1}}>
                    <Card style={cardStyle}>
                        {/* <CardMedia
                            sx={{ height: 140 }}
                            image="/static/images/cards/contemplative-reptile.jpg"
                            title="green iguana"
                        /> */}
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Poll
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Quickly gather information with your community
                            </Typography>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                    <ListItemIcon>
                                        <BoltIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Get results quickly" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                    <ListItemIcon>
                                        <PollIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Poll the state of the market" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                    <ListItemIcon>
                                        <MoodIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Simple and easy" />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </CardContent>
                        <CardActions>
                            <Button onClick={() => ga4events.createClick('Poll')} href='/new/poll' fullWidth variant="contained">Create</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}

export default withParams(NewPost);