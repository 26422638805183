import React from 'react';
import Account from '../API/Account';
import Authentication from '../API/Authentication';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Button, TextField, Typography, Container, Grid, } from '@mui/material';
import { styled, } from '@mui/material/styles';
import { router } from '../App';

import heroBackgroundImage from '../Static/assets/img/landingpage/landingpageOnboardImg.jpg';
import LandingPageReviews from './LandingPageReviews';
import LandingPageOnboardStepSection from './LandingPageOnboardStepSection';
import LandingPageOnboardLogicSection from './LandingPageOnboardLogicSection';
import LandingPageFooter from './LandingPageFooter';
import ga4events from "../events";

type RegisterProps = {

}

type RegisterState = {
    email:string,
    error?:string
}

// Custom styles
const FullWidthContainer = styled(Container)({
    // use width 100% on all screen sizes
    width: '100%',
    maxWidth: '100% !important',
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  });
  
  const StyledSection = styled('section')({
    margin: 0,
  });

  const HeroSection = styled(StyledSection)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh', // Full viewport height
    background: `url(${heroBackgroundImage}) no-repeat center center / cover`,
    color: '#fff',
    '& .logo-section': {
      marginTop: '3vh !important',
      left: '50px',
      position: 'absolute',
      top: '0',
      display: 'flex',
      margin: '0 auto',
      maxWidth: '30em',
      maxHeight: '30em',
    
      '& .image-container':{
        '& img': {
          padding: '10px',
        }, 
        '&::after': {
          borderLeft: '1px solid #fff',
          content: '""',
          height: '100%',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
        },
      },
      '& .logo-text-container': {
        padding: '10px',
        '& h1': {
          fontSize: '2.5rem',
        },
      },
    },
    '& .hero-content': {
      marginTop: '20vh',
      width: '100%',
      padding: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        maxWidth: '80vw', // Increase the max width of the hero section
        padding: theme.spacing(4),
        marginBottom: theme.spacing(4),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      },
      
      '& .title': {
        maxWidth: '80% !important',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('sm')]: {
          marginLeft: 0,
          marginRight: 0,
          maxWidth: '70% !important',
        },
        '& h1': {
          marginBottom: theme.spacing(2),
          fontSize: '2.5rem',
          [theme.breakpoints.up('sm')]: {
            fontSize: '4rem',
          },
        },
      },
      '& form': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        backgroundColor: 'rgba(255, 255, 255, 0.9)', // Semi-transparent background
        borderRadius: theme.spacing(2),
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Box shadow for depth
        maxWidth: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('sm')]: {
          maxWidth: '400px', // Reduce the max width of the form on larger screens
          padding: theme.spacing(4),
          marginLeft: 0,
          marginRight: 0,
        },
        '& .MuiTextField-root': {
          width: '100%',
          marginBottom: theme.spacing(2),
        },
        '& .MuiButton-root': {
          width: '100%',
          maxWidth: '300px',
          padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
          borderRadius: theme.spacing(3),
          fontSize: '1rem',
          fontWeight: 'bold',
        },
      },
    },
    '& .trustpilot-reviews': {
      width: '100%',
      marginTop: '15vh',
      alignItems: 'center',

    },
  }));

  
  
  

export default class Register extends React.Component<RegisterProps, RegisterState> {
    constructor(props:RegisterProps) {
        super(props);

        this.state = {
            email: "",
        }
        this.onEmailChanged = this.onEmailChanged.bind(this);
        this.onRegister = this.onRegister.bind(this);
    }

    onEmailChanged(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, email: event.currentTarget.value});
    }
    
    onRegister() {
        ga4events.buttonClick('register_at_landing_page_hero_section');
        Account.Register(this.state.email).then(() => {
            try { Authentication.Logout() } catch { }
            router.navigate("/register/confirm");
        });
    }

    render() {
        return (
          <HelmetProvider>
            <Helmet>
              <script async src="https://www.googletagmanager.com/gtag/js?id=G-HC1EQ1GX01"></script>
              <script src="/pages/assets/js/gtag.js"></script>
              <script src="https://www.googleoptimize.com/optimize.js?id=OPT-T88LPH3"></script>
            </Helmet>
            <FullWidthContainer disableGutters>
              {/* Hero Section */}
              <HeroSection>
                <div className="logo-section">
                  <div className="image-container">
                    <img height={50} src="logo.png" alt="Fetch Team Logo" />
                  </div>
                  <div className="logo-text-container">
                    <Typography variant="h1">Fetch Team</Typography>
                  </div>
                </div>
                <div className="hero-content">
                  <div className="title">
                      <Typography variant="h6">Not your average Trading Group</Typography>
                      <Typography style={{ fontSize: '4rem' }} variant="h1">Learn how to trade crypto and stocks</Typography>
                  </div>
                  <form>
                    <TextField onChange={this.onEmailChanged} label="Enter your email" variant="outlined" />
                    <Button id={'hero-register-button'} onClick={this.onRegister} variant="contained" style={{backgroundColor: "#1a73e8"}}>
                      Register for free
                    </Button>
                  </form>
                </div>
                <div className='trustpilot-reviews'>
                  <LandingPageReviews />
                </div>
            </HeroSection>
                
            {/* Steps Section */}
            <LandingPageOnboardStepSection/>
      
            {/* Logic Section */}
            <LandingPageOnboardLogicSection/>

            <LandingPageFooter/>
            
          </FullWidthContainer >

          </HelmetProvider>
        );
    }
}