import React, { ChangeEvent } from 'react';

type NakedTextInputProps = {
    title:string,
    placeholder:string,
    value?:string,
    onChange?: Function
}

type NakedTextInputState = {
    value?:string
}

const NakedInputStyle:React.CSSProperties = {
    height: "32px",
    width: "100%",
    padding: "4px 0px 5px",
    backgroundColor: "transparent",
    border: "none",
    fontSize: "16px"
}

export default class NakedTextInput extends React.Component<NakedTextInputProps, NakedTextInputState> {
    constructor(props:NakedTextInputProps) {
        super(props);
        this.textChanged = this.textChanged.bind(this);
        this.state = {
            value: this.props.value ?? ""
        };
    }

    textChanged(event:ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, value: event.target.value});
         if(this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    }

    update() {
        this.setState({...this.state, value: this.props.value ?? this.state.value});
    }
    
    render() {
        return(
            <input style={NakedInputStyle} placeholder={this.props.placeholder} onChange={this.textChanged} title={this.props.title} type="text" value={this.state.value}  />
        )
    }
}