import React from 'react';
import Post from '../../API/Post';
import ga4events from '../../events';
import { withParams } from '../../Helpers/ReactRouterHelper';

import { Box, Card, Stack } from '@mui/material';

import {InlineShareButtons} from 'sharethis-reactjs';


import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ViewQuestionResult } from '../../API/models/question';
import AuthorReputation from '../Author/AuthorReputation';
import Tags from '../Feed/Tag';
import Spacer from '../../Helpers/Spacer';


import {FormatDate} from '../../Helpers/Formatters';
import FetchViewer from '../Viewer/FetchViewer';
import ViewContentShadow from '../Post/ViewContentShadow';
import Question from '../../API/Question';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Editor from '../Editor/Editor';
import ViewAnswer from './ViewAnswer';
import Vote from '../../API/Vote';
import FeedItemCardMenu from '../Feed/FeedItemCardMenu';
import LoadableButton from '../../Controls/LoadableButton';
import AuthorizeView from '../../Layout/AuthorizeView';
import { ROLES_FREE, router } from '../../App';
import AuthorAvatar from '../../Account/AuthorAvatar';
import { WhoAmIResponse } from '../../API/models/account';
import { Helmet, HelmetProvider } from 'react-helmet-async';


type ViewQuestionParams = {
    identifier:string,
    seoFriendlyUrl:string
}

type ViewQuestionProps = {
    params: ViewQuestionParams,
    loaderData: WhoAmIResponse
}

type ViewQuestionState = {
    question: ViewQuestionResult,
    content: any,
    loading: boolean,
    menuAnchor?:Element,
    isMenuOpen:boolean,
    postingAnswer:boolean
}

const cardStyle: React.CSSProperties = {
    width: "100%"
}

export const leftSidebarStyle: React.CSSProperties = {
    padding: "0 4px 0 6px",
    marginTop: "-14px",
}

export const cardHeaderStyle: React.CSSProperties = {

}

export const cardBottomStyle: React.CSSProperties = {
    padding: "18px 38px 0px 38px",
    alignContent: "center",
    alignItems: "center",
    width: "100%"
}

class ViewQuestion extends React.Component<ViewQuestionProps, ViewQuestionState> {
    viewerRef:React.RefObject<FetchViewer>;
    editorRef: React.RefObject<Editor>
    
    answerRefs: { [key: string]: React.RefObject<ViewAnswer> };

    constructor(props:ViewQuestionProps) {
        super(props);
        this.state = {
            question: { 
                id: "", 
                title: "", 
                author: {
                    id: "",
                    name: "", 
                    reputation: 0, 
                    achievements: { 
                        gold: 0, 
                        silver: 0, 
                        bronze: 0 
                    },
                    profileImageUrl:  ""
                }, 
                date: new Date(), 
                content: "{}", 
                tags: [],
                votes: 0,
                answers: [],
                userVote: 0,
                hasFavorited: false
            },
            content: {},
            loading: true,
            isMenuOpen: false,
            postingAnswer: false
        }

        this.viewerRef = React.createRef();
        this.editorRef = React.createRef();

        this.answerRefs = {};

        this.openContextMenu = this.openContextMenu.bind(this);
        this.closeContextMenu = this.closeContextMenu.bind(this);
        this.postAnswer = this.postAnswer.bind(this);
        this.upVote = this.upVote.bind(this);
        this.downVote = this.downVote.bind(this);
        this.onAcceptedAnswer = this.onAcceptedAnswer.bind(this);
    }

    componentDidMount(): void {
        ga4events.sendPageView(window.location.pathname, 'View question');
        Question.ViewQuestion(this.props.params.identifier, this.props.params.seoFriendlyUrl).then(question => {
            this.setState({...this.state, loading: false}, () => {
                const content = JSON.parse(question.content);
                if(this.viewerRef.current) {
                    this.setState({...this.state, question: question, content: content}, () => {
                        if(this.viewerRef.current) {
                            this.answerRefs = {};
                            for(const answer of question.answers) {
                                this.answerRefs[answer.answerId] = React.createRef();
                            }
                            this.viewerRef.current.setContent(content);
                            this.setState({...this.state, loading: false});
                        }
                    });
                }
            });
        });
    }

    postAnswer() {
        this.setState({...this.state, postingAnswer: true}, () => {
            this.editorRef.current?.save().then(content => {
                Question.CreateAnswer(this.state.question.id, JSON.stringify(content), "Answer preview").then(answer => {
                    Question.ViewQuestion(this.props.params.identifier, this.props.params.seoFriendlyUrl).then(question => {
                        const content = JSON.parse(question.content);
                        if(this.viewerRef.current) {
                            this.setState({...this.state, question: question, content: content}, () => {
                                if(this.viewerRef.current) {
                                    this.viewerRef.current.setContent(content);
                                    this.answerRefs = {};
                                    for(const answer of question.answers) {
                                        this.answerRefs[answer.answerId] = React.createRef();
                                    }
                                    this.setState({...this.state, loading: false, postingAnswer: false}, () => {
                                        this.editorRef.current?.clear();
                                    });
                                }
                            });
                        }
                    });
                });
            }).catch(error => {
                console.log(error);
            });
        });
    }

    upVote() {
        Vote.Upvote(this.state.question.id).then(() => {
            const votes = this.state.question.userVote === 0 ? this.state.question.votes + 1 : this.state.question.votes;
            this.setState({...this.state, question: {...this.state.question, userVote: 1, votes:votes}})
        });
    }

    downVote() {
        Vote.Downvote(this.state.question.id).then(() => {
            const votes = this.state.question.userVote === 0 ? this.state.question.votes - 1 : this.state.question.votes;

            this.setState({...this.state, question: {...this.state.question, userVote: -1, votes: votes}})
        });
    }

    openContextMenu(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            ...this.state,
            isMenuOpen: true,
            menuAnchor: event.currentTarget
        });
    }

    closeContextMenu() {
        this.setState({
            ...this.state,
            isMenuOpen: false
        });
    }

    onAcceptedAnswer(id:string) {
        let question = this.state.question;
        for(let answer of question.answers) {
            const ref = this.answerRefs[answer.answerId] as React.RefObject<ViewAnswer>;
            ref.current?.setAcceptation(answer.answerId === id);
        }

        this.setState({...this.state, question: question});
    }

    onPostDeleted() {
        router.navigate("/");
    }

    render() {
        if (this.state.loading)
        {
            return <ViewContentShadow />
        }
        else 
        {
            return (
                <HelmetProvider>
                    <Helmet>
                        <meta name='title' property="og:title" content={this.state.question.title}/>
                        <meta name='type' property="og:type" content="article"/>
                        <meta name='description' property="og:description" content="View this question on Fetch.team!" />
                        <meta name='url' property="og:url" content={window.location.href} />
                        <meta name="twitter:card" property='twitter:card' content="summary_large_image" />
                        <meta name='published' property='article:published_time' content={this.state.question.date.toString()}/>
                        <meta name='modified' property='article:modified_time' content={this.state.question.date.toString()}/>
                        <meta name='author' property='article:author' content={this.state.question.author.name}/>
                        <meta name='tags' property='article:tag ' content={this.state.question.tags.toString()}/>
                        <meta name='section' property='article:section ' content='Financial education'/>
                        <meta name='sitename' property='og:site_name ' content='Fetch.team'/>
                    </Helmet>
                    <Stack gap={"24px"} sx={{width: "100%"}}>
                        <Card style={cardStyle}>
                            <CardContent style={{textAlign: "left"}}>
                                <Stack sx={{marginLeft: "-12px"}} direction="row">
                                    <Stack gap={"6px"} alignItems="center" justifyItems="center" alignContent="center" style={leftSidebarStyle}>
                                        <IconButton onClick={this.upVote}>
                                            <KeyboardArrowUpIcon style={{...(this.state.question.userVote === 1) && {color: "var(--primary-color)"}}} />
                                        </IconButton>
                                        <Typography>{this.state.question.votes}</Typography>
                                        <IconButton onClick={this.downVote}>
                                            <KeyboardArrowDownIcon style={{...(this.state.question.userVote === -1) && {color: "var(--primary-color)"}}} />
                                        </IconButton>
                                    </Stack>
                                    <Stack gap={"18px"} flexGrow={1}>
                                        <Stack gap={"12px"} direction="row" style={cardHeaderStyle}>
                                            <AuthorAvatar author={this.state.question.author} />
                                            <Box>
                                                <div style={{textAlign: "left", fontWeight: "bold"}}>{this.state.question.author.name}</div>
                                                <div style={{textAlign: "left"}}><AuthorReputation author={this.state.question.author}/></div>
                                            </Box>
                                            <Spacer />
                                            <Box>
                                                <Stack direction="row">
                                                    <Tags tags={this.state.question.tags}/>
                                                    <IconButton onClick={this.openContextMenu}>
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                </Stack>
                                            </Box>
                                        </Stack>
                                        <Stack>
                                            <Typography variant="h5" color="text.secondary">
                                                {this.state.question.title}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {FormatDate(new Date(this.state.question.date))}
                                            </Typography>
                                        </Stack>
                                        <FetchViewer ref={this.viewerRef} />
                                        <div className="sharethis-container">
                                            <InlineShareButtons
                                                config={{
                                                    alignment: 'center',  // alignment of buttons (left, center, right)
                                                    color: 'social',      // set the color of buttons (social, white)
                                                    enabled: true,        // show/hide buttons (true, false)
                                                    font_size: 16,        // font size for the buttons
                                                    labels: 'cta',        // button labels (cta, counts, null)
                                                    language: 'en',       // which language to use (see LANGUAGES)
                                                    networks: [           // which networks to include (see SHARING NETWORKS)
                                                    'whatsapp',
                                                    'reddit',
                                                    'twitter',
                                                    'facebook',
                                                    'messenger',
                                                    ],
                                                    padding: 12,          // padding within buttons (INTEGER)
                                                    radius: 4,            // the corner radius on each button (INTEGER)
                                                    show_total: true,
                                                    size: 40,             // the size of each button (INTEGER)


                                                    // OPTIONAL PARAMETERS

                                                    // min_count: 10,                    // (threshold for total share count to be displayed)
                                                    // url: 'https://www.sharethis.com', // (defaults to current url)
                                                    // image: 'https://bit.ly/2CMhCMC',  // (defaults to og:image or twitter:image)
                                                    // description: 'custom text',       // (defaults to og:description or twitter:description)
                                                    // title: 'custom title',            // (defaults to og:title or twitter:title)
                                                    // message: 'custom email text',     // (only for email sharing)
                                                    // subject: 'custom email subject',  // (only for email sharing)
                                                    // username: 'custom twitter handle' // (only for twitter sharing)
                                                }}
                                            />
                                        </div>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>
                        <AuthorizeView whoAmI={this.props.loaderData} roles={ROLES_FREE}>
                            <Card>
                                <CardHeader subheader={<div style={{textAlign: "left"}}>Add an answer</div>}></CardHeader>
                                <CardContent>
                                    <Editor ref={this.editorRef} />
                                </CardContent>
                                <CardActions sx={{margin: "12px"}}>
                                    <LoadableButton hasConfetti loading={this.state.postingAnswer} onClick={this.postAnswer} style={{marginLeft: "auto"}} variant='contained'>
                                        Post answer
                                    </LoadableButton>
                                </CardActions>
                            </Card>
                        </AuthorizeView>
                        <Box>
                            {this.state.question.answers.map((answer, i) => {
                                return (
                                    <ViewAnswer whoAmI={this.props.loaderData} ref={this.answerRefs[answer.answerId]} key={answer.answerId} answer={answer} question={this.state.question} onAccepted={this.onAcceptedAnswer} />
                                )
                            })}
                        </Box>
                        <FeedItemCardMenu whoAmI={this.props.loaderData} onDelete={this.onPostDeleted} hasFavorited={this.state.question.hasFavorited} contentId={this.state.question.id} author={this.state.question.author} isHidden={false} onClose={this.closeContextMenu} open={this.state.isMenuOpen} anchor={this.state.menuAnchor} />
                    </Stack>
                </HelmetProvider>
                
            ); 
        }
    }
}

export default withParams(ViewQuestion);