import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import React from 'react';
import Account from '../API/Account';
import { setCookie } from '../API/resources/cookies';
import { router } from '../App';
import Data from '../API/Data';
import { Box } from '@mui/material';

type GoogleTapSignInProps = {
    button:boolean
}

type GoogleTapSignInState = {
    hasToken: boolean,
    tokenCache?: string | undefined,
    enterUsername: boolean,
    newUsername: string
}

export default class GoogleTapSignIn extends React.Component<GoogleTapSignInProps, GoogleTapSignInState> {
    constructor(props:GoogleTapSignInProps) {
        super(props);
        this.state = {
            hasToken: false,
            enterUsername: false,
            newUsername: ""
        };
    }

    onGoogleLoginSuccess(response:CredentialResponse) {
        if(!response.credential) return;
        Account.GoogleSignInPreflight(response.credential).then(accountExists => {
            console.log(accountExists);
            if(accountExists) {
                if(!response.credential) return;
                Account.GoogleSignIn(response.credential).then(response => {
                    setCookie("auth", JSON.stringify(response), response.expiresOn);
                    router.navigate("/");
                });
            }

            else {
                this.setState({...this.state, tokenCache: response.credential, enterUsername: true});
            }
        });
    }

    componentDidMount(): void {
        const token = Data.GetAccessToken();
        if(token) {
            this.setState({...this.state, hasToken: true});
        }
        else {
            this.setState({...this.state, hasToken: false});
        }
    }

    onGoogleLoginError() {

    }

    signInGoogle() {
        if(this.state.tokenCache == null) return;

        Account.GoogleSignIn(this.state.tokenCache, this.state.newUsername).then(response => {
            setCookie("auth", JSON.stringify(response), response.expiresOn);
            this.setState({...this.state, hasToken: true}, () => {
                router.navigate("/");
            });
        });
    }

    render(): React.ReactNode {
        if(this.props.button) {
            return(
                <GoogleLogin 
                    onSuccess={this.onGoogleLoginSuccess}
                    onError={this.onGoogleLoginError}
                    shape='circle'
                    width={"100%"}
                />
            )
        }

        else if (!this.state.hasToken) {
            return(
                <Box display={'none'}>
                    <GoogleLogin 
                        onSuccess={this.onGoogleLoginSuccess}
                        onError={this.onGoogleLoginError}
                        useOneTap
                    />
                </Box>
                
            )
        }

        return null;
    }
}