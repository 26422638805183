import React from 'react';
import { WhoAmIResponse } from '../API/models/account';

type AuthorizeViewProps = {
    roles:string[],
    children: JSX.Element | JSX.Element[],
    whoAmI: WhoAmIResponse
};

type AuthorizeViewState = {
    isAuthorized:boolean
}

export default class AuthorizeView extends React.Component<AuthorizeViewProps, AuthorizeViewState> {
    constructor(props:AuthorizeViewProps) {
        super(props);
        this.state = {
            isAuthorized: false
        };
    }
    
    componentDidMount(): void {
        const filteredArray = this.props.roles.filter(value => this.props.whoAmI.roles.includes(value));
        this.setState({...this.state, isAuthorized: filteredArray.length > 0});
    }
    
    render() {
        if(this.state.isAuthorized) {
            return(
                <div>{this.props.children}</div>
            )
        }
        else {
            return(null);
        }
    }
}