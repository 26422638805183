import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Stack } from "@mui/material";

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import CodeIcon from '@mui/icons-material/Code';

export default class FetchEditorBlockType extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            block: "left"
        };

        this.handleBlockChange = this.handleBlockChange.bind(this);
        this.onBlockSelect = this.onBlockSelect.bind(this);
    }

    onBlockSelect(event) {
        this.handleBlockChange(event, event.target.value);
    }

    handleBlockChange(event, newBlock) {
        if(newBlock) {
            if(this.props.onChange) {
                this.props.onChange(newBlock);
            }
            
            this.setState({...this.state, block: newBlock});
        }

        else {
            if(this.props.onChange) {
                if(this.state.block) {
                    this.props.onChange(this.state.block);
                    this.setState({...this.state, block: null});
                }
            }
        }
    }

    render() {
        return (
            <Stack spacing={2} direction="row">
                <FormControl>
                    <Select size="small" defaultValue={"blockqoute"} onChange={this.onBlockSelect}>
                        <MenuItem value={"blockqoute"}>Normal</MenuItem>
                        <MenuItem value={"header-one"}>Heading 1</MenuItem>
                        <MenuItem value={"header-two"}>Heading 2</MenuItem>
                        <MenuItem value={"header-three"}>Heading 3</MenuItem>
                    </Select>
                </FormControl>
                <ToggleButtonGroup exclusive value={this.state.block} onChange={this.handleBlockChange}>
                    <ToggleButton size="small" value="unordered-list-item" aria-label="unordered list item">
                        <FormatListBulletedIcon fontSize="small"/>
                    </ToggleButton>
                    <ToggleButton size="small" value="ordered-list-item" aria-label="ordered list item">
                        <FormatListNumberedIcon fontSize="small"/>
                    </ToggleButton>
                    <ToggleButton size="small" value="code-block" aria-label="code block">
                        <CodeIcon fontSize="small"/>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>
        );
    }
}