import * as React from 'react';
import { styled, } from '@mui/material/styles';

type YoutubeEmbedEmbedProps = {

}

const YoutubeEmbed = styled('section')(({ theme }) => ({
    padding: theme.spacing(4),
    '.video-responsive': {
        overFlow: 'hidden',
        position: 'relative',
        height: '40vh',
        width: '60vw',

        [theme.breakpoints.up('sm')]: {
            height: '60vh',
            width: '60vw',
        
        },
        marginLeft: 'auto',
        marginRight: 'auto',

       

        '& iframe': {
            left: 0,
            top: 0,
            height: '40vh',

            [theme.breakpoints.up('sm')]: {
                height: '60vh',
                width: '60vw',
            
            },
            position: 'middle',
        }

      }
      
  }));


export default class  LandingPageYoutubeEmbed extends React.Component<YoutubeEmbedEmbedProps> {
    constructor(props:YoutubeEmbedEmbedProps) {
        super(props);

    }

    render(){
        return (
            <YoutubeEmbed>
                <div className="video-responsive">
                    <iframe 
                        id='landingpage-youtube-embed'
                        className='iframe'
                        src="https://www.youtube.com/embed/76T73PpSYS8" 
                        title="YouTube video player" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;fullscreen;" 
                    >

                    </iframe>
                </div>

            </YoutubeEmbed>
        )
    }
        
    
}