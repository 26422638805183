import { Button, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import DataFeedAPI from '../API/DataFeedAPI';

type CreateSymbolProps = {
    onCreate:Function
}

type CreateSymbolState = {
    name: string,
    fullName: string,
    description: string,
    exchange: string,
    type: string,
    minMov: number,
    priceScale: number,
    hasNoVolume: boolean,
    volumePrecision: number,
    alphavantageSymbol:string
}

export default class CreateSymbol extends React.Component<CreateSymbolProps, CreateSymbolState> {
    constructor(props:CreateSymbolProps) {
        super(props);
        this.state = {
            name: "TST",
            fullName: "Test symbol",
            description: "Description",
            exchange: "Fetch",
            type: "crypto",
            minMov: 1,
            priceScale: 100,
            hasNoVolume: true,
            volumePrecision: 0,
            alphavantageSymbol: ""
        }

        this.onNameChange = this.onNameChange.bind(this);
        this.onFullNameChange = this.onFullNameChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.onExchangeChange = this.onExchangeChange.bind(this);
        this.onTypeChange = this.onTypeChange.bind(this);
        this.onMinMovChange = this.onMinMovChange.bind(this);
        this.onPriceScaleChange = this.onPriceScaleChange.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onAlphavantageSymbolChanged = this.onAlphavantageSymbolChanged.bind(this);
    }

    onNameChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, name: event.target.value});
    }

    onFullNameChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, fullName: event.target.value});
    }

    onDescriptionChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, description: event.target.value});
    }

    onExchangeChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, exchange: event.target.value});
    }

    onTypeChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, type: event.target.value});
    }

    onMinMovChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, minMov: +event.target.value});
    }

    onPriceScaleChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, priceScale: +event.target.value});
    }

    onAlphavantageSymbolChanged(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, alphavantageSymbol: event.target.value});
    }

    onCreate() {
        DataFeedAPI.CreateSymbol({
            name: this.state.name,
            fullName : this.state.fullName,
            description: this.state.description,
            exchange: this.state.exchange,
            hasNoVolume: false,
            id: 0,
            minMov: this.state.minMov,
            priceScale: this.state.priceScale,
            type: this.state.type,
            volumePrecision: this.state.volumePrecision,
            alphaVantageSymbol: this.state.alphavantageSymbol
        }).then(newSymbol => {
            this.props.onCreate(newSymbol);
        });
    }

    render() {
        return(
            <Stack gap="12px" sx={{p : "12px"}}>
                <TextField fullWidth label="Name" variant='outlined' value={this.state.name} onChange={this.onNameChange}></TextField>
                <TextField fullWidth label="Full name" variant='outlined' value={this.state.fullName} onChange={this.onFullNameChange}></TextField>
                <TextField fullWidth label="Description" variant='outlined' value={this.state.description} onChange={this.onDescriptionChange} multiline></TextField>
                <TextField fullWidth label="Exchange" variant='outlined' value={this.state.exchange} onChange={this.onExchangeChange}></TextField>
                <TextField fullWidth label="Minimum movement" variant='outlined' value={this.state.minMov} onChange={this.onMinMovChange}></TextField>
                <TextField fullWidth label="Price scale" variant='outlined' value={this.state.priceScale} onChange={this.onPriceScaleChange}></TextField>
                <TextField fullWidth label="Alpha vantage symbol" variant='outlined' value={this.state.alphavantageSymbol} onChange={this.onAlphavantageSymbolChanged}></TextField>
                <Button fullWidth onClick={this.onCreate} variant="contained">Create</Button>
            </Stack>
        )
    }
}