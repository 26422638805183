import React from 'react';

import { styled } from '@mui/material/styles';

import { Button, Stack, CardActions, CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import rsiStratImage from '../../Static/assets/img/indicators/rsi-buy-signal.png';
import atrStratImage from '../../Static/assets/img/indicators/atr.png';
import fetchTrendsStratImage from '../../Static/assets/img/indicators/fetch-trends.png';



import { Helmet, HelmetProvider } from 'react-helmet-async';

type IndicatorsState = {
    
}

const cardStyle = {
    height: "100%",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column" as 'column'
};

class WhaleTracker extends React.Component <{}, IndicatorsState> {
    constructor(props:any) {
        super(props);
        this.state = {
        }
        this.toIndicator = this.toIndicator.bind(this)
    }

    toIndicator(name:string){
        if (name === 'fetch trends') window.open('https://www.tradingview.com/script/n8Yx6vYG-Fetch-Trends/') 
        if (name === 'fetch buy and hold strategy') window.open('https://www.tradingview.com/script/jSG6gryP-Fetch-Buy-And-Hold-Strategy/')
        if (name === 'fetch atr + ma strategy') window.open('https://www.tradingview.com/script/bka1NUXf-Fetch-ATR-MA-Strategy/')
    }
    
    render() {        
        return (
            <HelmetProvider>
                <Helmet>
                    <meta name='title' property="og:title" content='portfolio'/>
                    <meta name='type' property="og:type" content="portfolio"/>
                    {/* <meta name='image' property="og:image" content={this.state.post.thumbnail} /> */}
                    <meta name='description' property="og:description" content="View this post on Fetch.team!" />
                    <meta name='url' property="og:url" content={window.location.href} />
                    <meta name="twitter:card" property='twitter:card' content="summary_large_image" />
                    <meta name='section' property='article:section ' content='Financial education'/>
                    <meta name='sitename' property='og:site_name ' content='Fetch.team'/>
                </Helmet>
                <Stack gap={"24px"} sx={{width: "100%"}}>
                    <div className='blur-container'>
                        <div className="indicators-container-inner">
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Card style={cardStyle}>
                                        <CardActionArea>
                                            <CardMedia component="img" height="300"  image={rsiStratImage}></CardMedia>
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" textAlign={"left"} component="div">Fetch Buy And Hold Strategy</Typography>
                                                <Typography variant="body2" textAlign={"left"} color="text.secondary">
                                                    Find long term buys on the chart effortlesly with Fetch Buy And Hold Strategy.
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button size="small" color="primary" onClick={()=>this.toIndicator('fetch buy and hold strategy')}>
                                                    Add indicator to TradingView
                                                </Button>
                                            </CardActions>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card style={cardStyle}>
                                        <CardActionArea>
                                            <CardMedia component="img" height="300" image={fetchTrendsStratImage}></CardMedia>
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" textAlign={"left"} component="div">Fetch Trends</Typography>
                                                <Typography variant="body2" textAlign={"left"} color="text.secondary">
                                                    Know what the trend of the market is in one second using Fetch Trends.
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button size="small" color="primary" onClick={()=>this.toIndicator('fetch trends')}>
                                                    Add indicator to TradingView
                                                </Button>
                                            </CardActions>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                <Card style={cardStyle}>
                                        <CardActionArea>
                                            <CardMedia component="img" height="300" image={atrStratImage}></CardMedia>
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" textAlign={"left"} component="div">Fetch ATR + MA Strategy</Typography>
                                                <Typography variant="body2" textAlign={"left"} color="text.secondary">
                                                    Fetch ATR + MA Strategy allows you to spot mid- to long term swing trades, or provides you with the option to spot long term DCA targets.
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button size="small" color="primary" onClick={()=>this.toIndicator('fetch atr + ma strategy')}>
                                                    Add indicator to TradingView
                                                </Button>
                                            </CardActions>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Stack>
            </HelmetProvider>
        ); 
    }
}


export default WhaleTracker;