import { Box, Stack, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import TagInput from '../../../Controls/TagInput';
import { ISideBar, sidebarPaddingStyle } from './EditorSideBar';

type EditorSidebarTheoryProps = {

}

type EditorSidebarTheoryState = {
    title:string,
    tags: string[],
}

export default class EditorSideBarTheory extends React.Component<EditorSidebarTheoryProps, EditorSidebarTheoryState> implements ISideBar {
    constructor(props:EditorSidebarTheoryProps) {
        super(props);
        this.state = {
            title: "",
            tags: [],
        };

        this.save = this.save.bind(this);
        this.onTagsChanged = this.onTagsChanged.bind(this);
        this.titleChanged = this.titleChanged.bind(this);
    }
    
    save() {
        return {...this.state};
    }


    onTagsChanged(tags:string[]) {
        this.setState({...this.state, tags: tags});
    }

    titleChanged(title:ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, title: title.target.value});
    }

    render() {
        return(
            <Stack>
                <Box style={sidebarPaddingStyle}>
                    <TextField onChange={this.titleChanged} fullWidth label="Title" variant="outlined" size="small" />
                </Box>
                <Box style={sidebarPaddingStyle}>
                    <TagInput onChange={this.onTagsChanged} />
                </Box>
            </Stack>
        );
    }
}