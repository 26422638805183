import * as React from 'react';
import Account from '../API/Account';
import Authentication from '../API/Authentication';
import { router } from '../App';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { Typography, Grid, Button, TextField} from '@mui/material';
import { styled, } from '@mui/material/styles';

import LandingPageYoutubeEmbed from './LandingPageYoutubeEmbed';

type RegisterProps = {

}

type RegisterState = {
    email:string,
    error?:string
}


const LogicSection = styled('section')(({ theme }) => ({
    padding: theme.spacing(4),
    textAlign: 'center',
    backgroundColor: '#f9f9f9',
    marginTop: '10vh !important',
    [theme.breakpoints.up('sm')]: {
        marginTop: '3vh !important',
    
    },

    '& video': {
      maxWidth: '100%',
    },
    '& h2': {
      fontSize: '2rem',
      marginBottom: theme.spacing(2),
    },
    '& p': {
      color: '#666',
    },

    '& .ul-container': {
        maxWidth: '60%',
        marginLeft: 'auto !important',
        marginRight: 'auto !important',
        marginTop: '3vh !important',
        marginBottom: '3vh !important',

        '& ul': {
            listStyleType: 'none',
            textAlign: 'left',
            marginTop: 0,
            marginBottom: 0,

            '& li': {
                '& svg': {
                    padding: '0.3rem',
                },
                '& h6': {
                    marginBottom: '0.7rem',
                    marginLeft: '2.2rem',
                    // padding: '0.5rem',
                }
            }

        }  
    },
    '& form': {
        marginTop: '3vh !important', // Push form to the bottom
        positition: ' relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        backgroundColor: 'rgba(255, 255, 255, 0.9)', // Semi-transparent background
        borderRadius: theme.spacing(2),
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Box shadow for depth
        maxWidth: '80%',
        marginLeft: 'auto !important',
        marginRight: 'auto !important',
        [theme.breakpoints.up('sm')]: {
          marginTop: '10vh !important',
          maxWidth: '400px', // Reduce the max width of the form on larger screens
          padding: theme.spacing(4),
        },
        '& .MuiTextField-root': {
          width: '100%',
          marginBottom: theme.spacing(2),
        },
        '& .MuiButton-root': {
          width: '100%',
          maxWidth: '300px',
          padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
          borderRadius: theme.spacing(3),
          fontSize: '1rem',
          fontWeight: 'bold',
        },
      },
  }));


export default class  LandingPageOnboardLogicSection extends React.Component<RegisterProps, RegisterState> {
    constructor(props:RegisterProps) {
        super(props);

        this.state = {
            email: "",
        }
        this.onEmailChanged = this.onEmailChanged.bind(this);
        this.onRegister = this.onRegister.bind(this);
    }

    onEmailChanged(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, email: event.currentTarget.value});
    }
    
    onRegister() {
        Account.Register(this.state.email).then(() => {
            try { Authentication.Logout() } catch { }
            router.navigate("/register/confirm");
        });
    }

    render(){
        return (
            
                <LogicSection>
                    <Typography variant="h2">Are you having trouble making money in the market?</Typography>
                    <Grid container spacing={4} className='ul-container'>
                        <Grid item xs={12} sm={6}>
                            <ul className="">
                                <li><CheckCircleIcon style={{position: 'absolute', color: '#df37a5'}} /><Typography variant='h6'>Are you scared of making trades?</Typography></li>
                                <li><CheckCircleIcon style={{position: 'absolute', color: '#df37a5'}} /><Typography variant='h6'>Is your portfolio still down after a couple of years?</Typography></li>
                                <li><CheckCircleIcon style={{position: 'absolute', color: '#df37a5'}} /><Typography variant='h6'>Are you done with fake gurus?</Typography></li>
                                <li><CheckCircleIcon style={{position: 'absolute', color: '#df37a5'}} /><Typography variant='h6'>Is the information online too overwelming?</Typography></li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ul className="">
                                <li><CheckCircleIcon style={{position: 'absolute', color: '#df37a5'}} /><Typography variant='h6'>Do you feel like you're not smart enough?</Typography></li>
                                <li><CheckCircleIcon style={{position: 'absolute', color: '#df37a5'}} /><Typography variant='h6'>Do you want to learn profitable technical analysis?</Typography></li>
                                <li><CheckCircleIcon style={{position: 'absolute', color: '#df37a5'}} /><Typography variant='h6'>Do you feel like today is not the right time to start?</Typography></li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Typography variant='body1' style={{fontSize: 18}}>If so, then Fetch can help you transform yourself from a trader losing money, to a profitable trader.</Typography>
                    <LandingPageYoutubeEmbed />
                </LogicSection>
            
        )
    }
        
    
}