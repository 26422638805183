import { Box } from '@mui/material';
import React from 'react';
import HiddenImageInput from '../../../Controls/HiddenImageInput';


export default class FetchEditorImageBlock extends React.Component {
    imageInputRef
    constructor(props) {
        super(props);

        const hasLink = this.props.data !== undefined && this.props.data !== null;
        this.state = {
            hasLink: hasLink,
            src: hasLink ? this.props.data.src : "",
            isDialogOpen: false
        };

        this.imageInputRef = React.createRef();
        this.getData = this.getData.bind(this);
        this.onFileChanged = this.onFileChanged.bind(this);
    }

    componentDidMount() {
        if(!this.state.hasLink && !this.stateisDialogOpen) {
            this.setState({...this.state, isDialogOpen: true}, () => {
                this.imageInputRef.current?.open();
            });
        }
    }

    getData() {
        return {
            "src": this.state.src
        };
    }

    onFileChanged(base64) {
        this.setState({...this.state, src: `data:image/png;base64,${base64}`, hasLink: true, isDialogOpen: false});
    }

    render() {
        return(
            <Box>
                {this.state.hasLink ? <img style={{width: "100%"}} src={this.state.src} alt='hidden' /> : <div></div> }
                <HiddenImageInput onChange={this.onFileChanged} ref={this.imageInputRef} />
            </Box>
        )
    }
}