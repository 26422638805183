import React from 'react'; // we need this to make JSX compile
import DesktopLayout from './DesktopLayout';
import MobileLayout from './MobileLayout';
import { WhoAmIResponse } from '../API/models/account';
import { withParams } from '../Helpers/ReactRouterHelper';

type MainLayoutState = {
    isMobile:boolean
}

type MainLayoutProps = {
    children? : JSX.Element | JSX.Element[],
    loaderData: WhoAmIResponse
}

class MainLayout extends React.Component<MainLayoutProps, MainLayoutState> {
    constructor(props:MainLayoutProps) {
        super(props);
        this.state = {isMobile: window.innerWidth < 768}
        this.resize = this.resize.bind(this);
    }

    componentDidMount(): void {
        window.addEventListener('resize', this.resize);
    }

    resize() {
        const width = window.innerWidth;
        if(width < 768 && !this.state.isMobile) {
            this.setState({...this.state, isMobile: true});
        }

        else if(width > 768 && this.state.isMobile) {
            this.setState({...this.state, isMobile: false});
        }
    }
    
    render() {
        if(this.state.isMobile) {
            return(<MobileLayout title="Feed" whoAmI={this.props.loaderData}>
                {this.props.children}
            </MobileLayout>);
        }
        else {
            return(<DesktopLayout whoAmI={this.props.loaderData}>
                {this.props.children}
            </DesktopLayout>);
        }
    }
}

export default withParams(MainLayout);