export function FormatDate(date: Date): string {
    //navigator.language
    return date.toLocaleDateString("en-US", { weekday:"long", year:"numeric", month:"short", day:"numeric"})
}

export function AbbreviateNumber(num:number): string {
    if(num < 1000) {
        return num.toString();
    }

    else if(num < 10000) {
        const numString = (num / 1000).toFixed(1).toString();
        if(numString[numString.length - 1] === "0" && numString.indexOf(".") >= 0) {
            return numString.slice(0, numString.indexOf(".")) + "K";
        }
        else {  
            return`${numString}K`;
        }
    }

    else if(num < 1000000) {
        const numString = (num / 1000).toFixed(0).toString();
        return`${numString}K`;
    }

    else {
        const numString = (num / 1000000).toFixed(1).toString();
        if(numString[numString.length - 1] === "0" && numString.indexOf(".") >= 0) {
            return numString.slice(0, numString.indexOf(".")) + "M";
        }
        else {  
            return`${numString}M`;
        }
    }

}

export function momentDurationToString(momentDuration: moment.Duration): string {

    //Get Days and subtract from duration
    const days = momentDuration.days();
    momentDuration.subtract(days, 'days');

    if(days > 0) {
        const hours = momentDuration.hours();
        if(hours > 0) {
            return `${days} day${days > 1 ? 's' : ''} and ${hours} hour${hours > 1 ? 's' : ''} left`;
        }

        else {
            return `${days} day${days > 1 ? 's' : ''} left`;
        }
    }

    //Get hours and subtract from duration
    const hours = momentDuration.hours() + days * 24;
    momentDuration.subtract(hours, 'hours');

    if (hours >= 1) {
        return `${hours} hour` + (hours !== 1 ? 's' : '') + ' left';
    }

    //Get Minutes and subtract from duration
    const minutes = momentDuration.minutes();
    momentDuration.subtract(minutes, 'minutes');

    if (minutes >= 1) {
        return `${minutes} minute` + (minutes !== 1 ? 's' : '') + ' left';
    }

    //Get seconds
    const seconds = momentDuration.seconds();

    return `${seconds} seconds left`;
}

export function GetDecimalPlaces(value:number) {
    if(!value) {
        return 0;
    }
    
    if(Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0;
}

export function isNumber(str:string) {
    const x = parseFloat(str);
    return !isNaN(x);
}

export function isInt(value:any) {
    var x = parseFloat(value);
    return !isNaN(value) && (x | 0) === x;
}

export function FormatSeoFriendlyUrl(url:string) : string {
    let alpha = url.replace(/[^a-zA-Z]+/g, '');
    return alpha.replace(" ", "-");
}