import React from 'react';

import { Box, Stack, Card, CardMedia, Typography, Avatar, Button, Divider, Dialog } from '@mui/material';
import logo from './logo.png';
import cardHeader from './header.png';
import { withParams } from '../../Helpers/ReactRouterHelper';
import { WhoAmIResponse } from '../../API/models/account';
import { red } from '@mui/material/colors';
import NewPost from '../Create/NewPost';
import { hasRole } from '../../Helpers/AuthHelper';
import { ROLES_VIEW_POST } from '../../App';


type FeedCtaProps = {
    loaderData: WhoAmIResponse
}

type FeedCtaState = {
    isDialogOpen: boolean
}

export class FeedCta extends React.Component<FeedCtaProps, FeedCtaState> {

    constructor(props:FeedCtaProps) {
        super(props);

        this.state = {
            isDialogOpen: false
        };

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }

    openDialog() {
        this.setState({...this.state, isDialogOpen: true});
    }

    closeDialog() {
        this.setState({...this.state, isDialogOpen: false});
    }

    render(): React.ReactNode {
        return(
            <Stack spacing={2}>
                {!hasRole(this.props.loaderData, ROLES_VIEW_POST) && (
                    <Card style={{padding: "12px"}}>
                        <Stack spacing={2}>
                            <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                <img width={40} height={40} src={logo} />
                                <Box>
                                    <Typography variant='body1' align='left'>Fetch Premium</Typography>
                                    <Typography variant='caption' align='left'>Get the best content as soon as it arrives</Typography>
                                </Box>
                            </Stack>
                            <Button href='/register/subscription' fullWidth variant='contained'>Try now</Button>
                        </Stack>
                    </Card>
                )}
                <Card>
                    <CardMedia
                        sx={{height: 80}}
                        image={cardHeader}
                    />
                    <Stack style={{padding: "12px"}} spacing={2}>
                        <Stack spacing={1} direction={'row'} alignItems={'center'}>
                            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                {this.props.loaderData.username[0]}
                            </Avatar>
                            <Typography variant='body1'>Feed</Typography>
                        </Stack>
                        <Typography variant='caption'>
                            Check out the latest content from us and our community members
                        </Typography>
                        <Divider />
                        <Button onClick={this.openDialog} fullWidth variant='contained'>Create Post</Button>
                    </Stack>
                </Card>
                <Dialog maxWidth={'lg'} onClose={this.closeDialog} open={this.state.isDialogOpen}>
                    <Box style={{padding: "12px"}}>
                        <NewPost />
                    </Box>
                </Dialog>
            </Stack>
        )
    }
}


export default withParams(FeedCta);