import { Box, Popover, Stack, Typography } from "@mui/material";
import React from "react";
import { tagStyle } from "../Theme/Theme";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import Vote from "../API/Vote";
import Comment from "../API/Comment";

import ConfettiExplosion from 'react-confetti-explosion';

type LikeButtonProps = {
    likes:number,
    hasLiked:boolean,

    contentId?:string,
    commentId?:string
}

type LikeButtonState = {
    hasLiked:boolean,
    hasVoted:boolean,
    hasError:boolean,
    error?:string,
    popOverAnchor?: Element,
    likes:number,
    showConfetti: boolean
}

export default class LikeTag extends React.Component<LikeButtonProps, LikeButtonState> {

    constructor(props:LikeButtonProps) {
        super(props);
        this.state = {
            hasLiked:this.props.hasLiked,
            hasVoted: false,
            hasError: false,
            likes: this.props.likes,
            showConfetti: false
        };

        this.onVote = this.onVote.bind(this);
        this.closePopOver = this.closePopOver.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<LikeButtonProps>, prevState: Readonly<LikeButtonState>, snapshot?: any): void {
        if(this.props.hasLiked !== prevProps.hasLiked) {
            this.setState({...this.state, hasLiked: this.props.hasLiked});
        }

        if(this.props.likes !== prevProps.likes) {
            this.setState({...this.state, likes: this.props.likes});
        }
    }

    onVote(event: any) {
        if(this.props.contentId && !this.state.hasLiked) {
            Vote.Upvote(this.props.contentId).then(() => {
                this.setState({...this.state, hasLiked: true, likes: this.state.likes + 1, hasVoted: true, popOverAnchor: event.target, showConfetti: true}, () => {
                    setTimeout(() => {
                        this.setState({...this.state, showConfetti: false});
                    }, 2200);
                    
                });
            });
        }
        else if(this.props.contentId && this.state.hasLiked) {
            Vote.ClearVote(this.props.contentId).then(() => {
                this.setState({...this.state, hasLiked: false, likes: this.state.likes - 1, hasVoted: false, popOverAnchor: event.target});
            });
        }

        else if(this.props.commentId && !this.state.hasLiked) {
            Comment.Like(this.props.commentId).then(() => {
                this.setState({...this.state, hasLiked: true, likes: this.state.likes + 1, hasVoted: true, popOverAnchor: event.target, showConfetti: true}, () => {
                    setTimeout(() => {
                        this.setState({...this.state, showConfetti: false});
                    }, 2200);
                    
                });
            }).catch(error => {
                this.setState({...this.state, error: error.title, popOverAnchor: event.target, hasError: true});
            });
        }

        else if(this.props.commentId && this.state.hasLiked) {
            Comment.Unlike(this.props.commentId).then(() => {
                this.setState({...this.state, hasLiked: false, likes: this.state.likes - 1, hasVoted: false, popOverAnchor: event.target});
            });
        }
    }
    
    closePopOver() {
        this.setState({...this.state, hasError: false});
    }

    render() {
        let classes = "pointer";
        if(this.state.hasVoted) {
            classes += " voted";
        }

        if(this.state.error) {
            classes += " error";
        }
        return(
            <Box>
                {this.state.showConfetti && <ConfettiExplosion 
                        force={0.2}
                        duration={2200}
                        width={300}
                        particleCount={30}
                        colors={[
                            "#df37a5",
                            "#2d46b9",
                            "#1e3163"
                        ]}
                    />}
                <Box className={classes} onClick={this.onVote} style={{...tagStyle, ...(this.state.hasLiked && {backgroundColor: "#df37a5"})}}>
                    <Stack direction="row" spacing={1}>
                        <ThumbUpIcon fontSize='small' color='primary' style={this.state.hasLiked ? {color: "#fff"} : {}}/> 
                        <Typography variant="body2" color="text.primary" style={this.state.hasLiked ? {color: "#fff"} : {}}>
                            {this.state.likes}
                        </Typography>
                    </Stack>
                </Box>
                <Popover
                    id="like-error"
                    open={this.state.hasError}
                    anchorEl={this.state.popOverAnchor}
                    onClose={this.closePopOver}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Typography sx={{ 
                        p: 2,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center" 
                    }}><ErrorOutlineIcon />{this.state.error}</Typography>
                </Popover>
            </Box>
            
        )
    }
}