import { Button, Stack, TextField, Box, Typography } from '@mui/material';
import React from 'react';
import Account from '../API/Account';
import Data from '../API/Data';
import { Link } from 'react-router-dom';
import { router } from '../App';
import Authentication from '../API/Authentication';


type RegisterProps = {

}

type RegisterState = {
    email:string,
    error?:string
}

export default class Register extends React.Component<RegisterProps, RegisterState> {
    constructor(props:RegisterProps) {
        super(props);

        this.state = {
            email: "",
        }
        this.onEmailChanged = this.onEmailChanged.bind(this);
        this.onRegister = this.onRegister.bind(this);
    }

    onEmailChanged(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, email: event.currentTarget.value});
    }
    
    onRegister() {
        Account.Register(this.state.email).then(() => {
            try { Authentication.Logout() } catch { }
            router.navigate("/register/confirm");
        });
    }

    render() {
        return(
            <Stack gap={5}>
                <Box>
                    <Typography variant="h6">Create your account</Typography>
                </Box>
                <Stack gap={2}>
                    <TextField onChange={this.onEmailChanged} variant="filled" size="small" fullWidth id='e-mail' placeholder='E-mail' label="E-mail"/>
                </Stack>
                <Stack gap={2}>
                    <Button onClick={this.onRegister} variant='contained' fullWidth>Send confirmation link</Button>
                    <Button component={Link} to={"/login"} variant='outlined' fullWidth>Already have an account? Sign in</Button>
                </Stack>
            </Stack>
        )
    }
}