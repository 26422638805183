import React from 'react';
import Post from '../../API/Post';
import ga4events from '../../events';
import { withParams } from '../../Helpers/ReactRouterHelper';

import { Box, Card, Stack } from '@mui/material';

import {InlineShareButtons} from 'sharethis-reactjs';


import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ViewPostResult } from '../../API/models/post';
import AuthorReputation from '../Author/AuthorReputation';
import Tags from '../Feed/Tag';

import {defaultElevation} from "../../Theme/Theme";
import {FormatDate} from '../../Helpers/Formatters';
import FetchViewer from '../Viewer/FetchViewer';
import CreateComment from '../Comment/CreateComment';
import BasicCommentList from '../Comment/BasicCommentList';
import { ViewCommentResult } from '../../API/models/comments';
import ViewContentShadow from './ViewContentShadow';
import FeedItemCardMenu from '../Feed/FeedItemCardMenu';
import CommentTag from '../../Controls/CommentTag';
import LikeTag from '../../Controls/LikeTag';
import { ROLES_VIEW_POST, router } from '../../App';

import AuthorAvatar from '../../Account/AuthorAvatar';
import { WhoAmIResponse } from '../../API/models/account';
import { Helmet, HelmetProvider } from 'react-helmet-async';


type ViewPostParams = {
    identifier:string,
    seoFriendlyUrl:string
}

type ViewPostProps = {
    loaderData: WhoAmIResponse,
    params: ViewPostParams
}

type ViewPostState = {
    post: ViewPostResult,
    content: any,
    loading: boolean,
    menuAnchor?:Element,
    isMenuOpen:boolean
}

const cardStyle: React.CSSProperties = {
    width: "100%"
}

class ViewPost extends React.Component<ViewPostProps, ViewPostState> {
    editorRef:React.RefObject<FetchViewer>;

    constructor(props:ViewPostProps) {
        super(props);
        this.state = {
            post: { 
                id: "", 
                title: "", 
                author: { 
                    id: "",
                    name: "", 
                    reputation: 0, 
                    achievements: { 
                        gold: 0, 
                        silver: 0, 
                        bronze: 0 
                    },
                    profileImageUrl:  ""
                }, 
                date: new Date(), 
                content: "{}", 
                tags: [], 
                likes: 0, 
                numberOfComments: 0, 
                comments: [],
                thumbnail: "",
                hasFavorited: false,
                hasLiked: false
            },
            content: {},
            loading: true,
            isMenuOpen: false
        }

        this.editorRef = React.createRef();

        this.openContextMenu = this.openContextMenu.bind(this);
        this.closeContextMenu = this.closeContextMenu.bind(this);
        this.onCommentPosted = this.onCommentPosted.bind(this);
    }

    componentDidMount(): void {
        ga4events.sendPageView(window.location.pathname, 'View Post');
        Post.ViewPost(this.props.params.identifier, this.props.params.seoFriendlyUrl).then(post => {
            const isAllowed = (ROLES_VIEW_POST.filter(value => this.props.loaderData.roles.includes(value))).length > 0;
            if(!isAllowed) {
                router.navigate("/upgrade-now")
            }

            this.setState({...this.state, loading: false}, () => {
                const content = JSON.parse(post.content);
                if(this.editorRef.current) {
                    this.setState({...this.state, post: post, content: content}, () => {
                        if(this.editorRef.current) {
                            this.editorRef.current.setContent(content);
                            this.setState({...this.state, loading: false});
                        }
                    });
                }
            });
        })
    }

    openContextMenu(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            ...this.state,
            isMenuOpen: true,
            menuAnchor: event.currentTarget
        });
    }

    closeContextMenu() {
        this.setState({
            ...this.state,
            isMenuOpen: false
        });
    }

    onCommentPosted(comment:ViewCommentResult, callback:Function) {
        /*
            Look up how to load a complete comment from the generated DTO. 
            Currently the DTO is lacking in properties to display it in the comment.
        */
        const post = this.state.post;
        post.comments.unshift(comment);
        this.setState({...this.state, post: post}, () => {
            if(callback) {
                callback();
            }
        });
    }

    onPostDeleted() {
        router.navigate("/");
    }

    render() {
        if (this.state.loading)
        {
            return (
                <ViewContentShadow />
            ) 
            
        }
        else 
        {
            return (
                <HelmetProvider>
                    <Helmet>
                        <meta name='title' property="og:title" content={this.state.post.title}/>
                        <meta name='type' property="og:type" content="article"/>
                        <meta name='image' property="og:image" content={this.state.post.thumbnail} />
                        <meta name='description' property="og:description" content="View this post on Fetch.team!" />
                        <meta name='url' property="og:url" content={window.location.href} />
                        <meta name="twitter:card" property='twitter:card' content="summary_large_image" />
                        <meta name='published' property='article:published_time' content={this.state.post.date.toString()}/>
                        <meta name='modified' property='article:modified_time' content={this.state.post.date.toString()}/>
                        <meta name='author' property='article:author' content={this.state.post.author.name}/>
                        <meta name='tags' property='article:tag ' content={this.state.post.tags.toString()}/>
                        <meta name='section' property='article:section ' content='Financial education'/>
                        <meta name='sitename' property='og:site_name ' content='Fetch.team'/>
                    </Helmet>
                    <Stack gap={"24px"} sx={{width: "100%"}}>
                        <Card style={cardStyle}>
                            <CardHeader
                                avatar={
                                    <AuthorAvatar author={this.state.post.author} />
                                }
                                title={<div style={{textAlign: "left", fontWeight: "bold"}}>{this.state.post.author.name}</div>}
                                subheader={<div style={{textAlign: "left"}}><AuthorReputation author={this.state.post.author}/></div>}
                                action={<Box>
                                    <Stack direction="row">
                                        <Tags tags={this.state.post.tags}/>
                                        <IconButton onClick={this.openContextMenu}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Stack>
                                </Box>}
                            />
                            {
                                this.state.post.thumbnail && 
                                    <CardMedia
                                        component="img"
                                        height="300"
                                        image={this.state.post.thumbnail}
                                        alt="Paella dish"
                                    />
                            }
                            <CardContent style={{textAlign: "left" }}>
                                <Typography variant="h5" color="text.secondary">
                                    {this.state.post.title}
                                </Typography>
                                <Stack spacing={2}>
                                    <Stack direction="row" spacing={2}>
                                        <Typography variant="body2" color="text.secondary">
                                            {FormatDate(new Date(this.state.post.date))}
                                        </Typography>
                                    </Stack>
                                    <FetchViewer ref={this.editorRef} />
                                    <div className="sharethis-container">
                                        <InlineShareButtons
                                            config={{
                                                alignment: 'center',  // alignment of buttons (left, center, right)
                                                color: 'social',      // set the color of buttons (social, white)
                                                enabled: true,        // show/hide buttons (true, false)
                                                font_size: 16,        // font size for the buttons
                                                labels: 'cta',        // button labels (cta, counts, null)
                                                language: 'en',       // which language to use (see LANGUAGES)
                                                networks: [           // which networks to include (see SHARING NETWORKS)
                                                'whatsapp',
                                                'reddit',
                                                'twitter',
                                                'facebook',
                                                'messenger',
                                                ],
                                                padding: 12,          // padding within buttons (INTEGER)
                                                radius: 4,            // the corner radius on each button (INTEGER)
                                                show_total: true,
                                                size: 40,             // the size of each button (INTEGER)


                                                // OPTIONAL PARAMETERS

                                                // min_count: 10,                    // (threshold for total share count to be displayed)
                                                // url: 'https://www.sharethis.com', // (defaults to current url)
                                                // image: 'https://bit.ly/2CMhCMC',  // (defaults to og:image or twitter:image)
                                                // description: 'custom text',       // (defaults to og:description or twitter:description)
                                                // title: 'custom title',            // (defaults to og:title or twitter:title)
                                                // message: 'custom email text',     // (only for email sharing)
                                                // subject: 'custom email subject',  // (only for email sharing)
                                                // username: 'custom twitter handle' // (only for twitter sharing)
                                            }}
                                        />
                                    </div>
                                </Stack>
                            </CardContent>
                            <CardActions sx={{p: "0 16px 16px 16px", gap: "8px"}}>
                                <LikeTag likes={this.state.post.likes} hasLiked={this.state.post.hasLiked} contentId={this.state.post.id} />
                                <CommentTag comments={this.state.post.numberOfComments} />
                            </CardActions>
                        </Card>
                        <Card style={{ flexGrow: 1}} elevation={defaultElevation}>
                            <CardContent style={{textAlign: "left"}}>
                                <CreateComment whoAmI={this.props.loaderData} onCommentPosted={this.onCommentPosted} contentId={this.state.post.id} />
                            </CardContent>
                        </Card>
                        <BasicCommentList whoAmI={this.props.loaderData} comments={this.state.post.comments} contentId={this.state.post.id} />
                        <FeedItemCardMenu whoAmI={this.props.loaderData} onDelete={this.onPostDeleted} hasFavorited={this.state.post.hasFavorited} contentId={this.state.post.id} author={this.state.post.author} isHidden={false} onClose={this.closeContextMenu} open={this.state.isMenuOpen} anchor={this.state.menuAnchor} />
                    </Stack>
                </HelmetProvider>
            ); 
        }
    }
}

export default withParams(ViewPost);