import { Stack } from '@mui/material';
import React from 'react';
import { AuthorDTO } from '../../API/models/datatransferobjects';

import {AbbreviateNumber} from "../../Helpers/Formatters";

type AuthorReputationProps = {
    author:AuthorDTO
}

const achievementStyle:React.CSSProperties = {
    width: "10px",
    height: "10px",
    borderRadius: "100%",
    backgroundColor: "#ff0000",
}

const badgeContainerStyle: React.CSSProperties = {
    alignItems: "center"
}

export default class AuthorReputation extends React.Component<AuthorReputationProps> {

    render() {
        return(
            <Stack direction="row" spacing={2}>

                <div>
                    {
                        AbbreviateNumber(this.props.author.reputation)
                    }
                </div>

                {
                    this.props.author.achievements.gold > 0 && (
                        <Stack direction="row" spacing={1} style={badgeContainerStyle}>
                            <div style={{...achievementStyle, backgroundColor: "#ffcc01"}}></div>
                            <div>{this.props.author.achievements.gold}</div>
                        </Stack>
                    )
                }

                {
                    this.props.author.achievements.silver > 0 && (
                        <Stack direction="row" spacing={1} style={badgeContainerStyle}>
                            <div style={{...achievementStyle, backgroundColor: "#b4b8bc"}}></div>
                            <div>{this.props.author.achievements.silver}</div>
                        </Stack>
                    )
                }

                {
                    this.props.author.achievements.bronze > 0 && (
                        <Stack direction="row" spacing={1} style={badgeContainerStyle}>
                            <div style={{...achievementStyle, backgroundColor: "#d1a684"}}></div>
                            <div>{this.props.author.achievements.bronze}</div>
                        </Stack>
                    )
                }

            </Stack>
        )
    }
}