import Data from "./Data";
import { PostDTO } from "./models/datatransferobjects";
import { CreatePostRequest, UpdatePostRequest, ViewPostResult } from "./models/post";

export const postRoute = "/Post";

export default abstract class Post {

    public static async GetPost(postId:number) : Promise<PostDTO> {
        return new Promise<PostDTO>((resolve, reject) => {
            Data.Get<PostDTO>(`${postRoute}/${postId}`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        })
    }

    public static async ViewPost(identifier:string, seoFriendlyUrl:string) : Promise<ViewPostResult> {
        return new Promise<ViewPostResult>((resolve, reject) => {
            Data.Get<ViewPostResult>(`${postRoute}/${identifier}/${seoFriendlyUrl}`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        })
    }

    public static async CreatePost(title:string, content:string, contentPreview:string, tags:string[], image:any) : Promise<PostDTO> {
        return new Promise<PostDTO>((resolve, reject) => {
            const payload = {title:title, content:content, contentPreview:contentPreview, tags:tags, image:image};
            Data.Post<CreatePostRequest, PostDTO>(postRoute, payload).then(post => {
                resolve(post);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async UpdatePost(postId:string, title:string, content:string, contentPreview:string, tags:string[]) : Promise<PostDTO> {
        return new Promise<PostDTO>((resolve, reject) => {
            Data.Put<UpdatePostRequest, PostDTO>(`${postRoute}/${postId}`, {title:title, content:content, contentPreview:contentPreview, tags:tags}).then(post => {
                resolve(post);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async DeletePost(postId:string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Data.Delete(`${postRoute}/${postId}`).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }
}