import { Box, Button, Stack, Paper, Typography, Divider, TextField, CircularProgress, Alert } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Bar, Symbol } from "../API/models/market";

import * as Papa from 'papaparse';
import { GetDecimalPlaces, isInt } from '../Helpers/Formatters';
import DataFeedAPI from '../API/DataFeedAPI';
import moment from 'moment';
import TabPanel from '../Controls/Tabs';

type ImportProps = {

}

type ImportState = {
    importColumns?: GridColDef[],
    importRows?:{[k: string]: any}[],

    exportColumns?: GridColDef[],
    exportRows?:{[k: string]: any}[],

    selectedTab: number,
    selectedOpenField:string,
    selectedHighField:string,
    selectedLowField:string,
    selectedCloseField:string,
    selectedVolumeField:string,
    selectedDateField:string,
    symbolFullname:string,
    symbolName:string,
    symbolDescription:string,
    symbolExchange:string,

    minimumMovement:number,
    priceScale:number,
    volumePrecision:number,
    alphaVantageSymbol:string,
    symbolType:string,

    loadingImport:boolean,
    succeeded: boolean
}

export default class Import extends React.Component<ImportProps, ImportState> {
    constructor(props:ImportProps) {
        super(props);
        this.onFileChange = this.onFileChange.bind(this);
        this.parseCSV = this.parseCSV.bind(this);
        this.state = {
            selectedTab: 0,
            symbolName: "",
            symbolFullname: "",
            symbolDescription: "",
            symbolExchange: "Fetch",

            minimumMovement: 0,
            priceScale: 0,
            volumePrecision: 0,

            selectedCloseField: "Close",
            selectedHighField: "High",
            selectedDateField: "Date",
            selectedLowField: "Low",
            selectedOpenField: "Open",
            selectedVolumeField: "Volume",
            alphaVantageSymbol: "",
            symbolType: "crypto",

            loadingImport: false,
            succeeded: false
        }

        this.onTabChange = this.onTabChange.bind(this);
        this.onSelectedDateChange = this.onSelectedDateChange.bind(this);

        this.onSelectedOpenChange = this.onSelectedOpenChange.bind(this);
        this.onSelectedHighChange = this.onSelectedHighChange.bind(this);
        this.onSelectedLowChange = this.onSelectedLowChange.bind(this);
        this.onSelectedCloseChange = this.onSelectedCloseChange.bind(this);
        this.onSelectedVolumeChange = this.onSelectedVolumeChange.bind(this);

        this.updateMapping = this.updateMapping.bind(this);
        this.onMap = this.onMap.bind(this);
        this.onImport = this.onImport.bind(this);

        this.onSymbolNameChange = this.onSymbolNameChange.bind(this);
        this.onSymbolDescriptionChange = this.onSymbolDescriptionChange.bind(this);
        this.onFullnameChange = this.onFullnameChange.bind(this);
        this.onSymbolExchangeChange = this.onSymbolExchangeChange.bind(this);
        this.onAlphaVantageSymbolChange = this.onAlphaVantageSymbolChange.bind(this);
        this.onSymbolTypeChange = this.onSymbolTypeChange.bind(this);

        this.startImport = this.startImport.bind(this);
    }

    parseCSV(csv:any) {
        const colDefinitions: GridColDef[] = [];
        const rows = [];
        for(const column of csv.data[0]) {
            colDefinitions.push({field: column, headerName: column});
        }

        colDefinitions.push({field: "id", headerName: "ID"});

        for(let i = 1; i < csv.data.length; i++) {
            let row: {[k: string]: any} = {};
            row["id"] = i;
            for(let j = 0; j < csv.data[i].length; j++) {
                    row[colDefinitions[j].field] = csv.data[i][j];
            }

            rows.push(row);
        }

        this.setState({
            ...this.state, 
            importColumns: colDefinitions, 
            importRows: rows,
            selectedDateField: "",
            selectedOpenField: "",
            selectedHighField: "",
            selectedLowField: "",
            selectedCloseField: "",
            selectedVolumeField: ""
        });
    }

    onFileChange(event:any) {
        const fileInput = document.getElementById('csv') as HTMLInputElement;

        if(fileInput && fileInput.files) {
            Papa.parse(fileInput.files[0], {
                complete: results => {
                    this.parseCSV(results);
                }
            })
        }
    }

    updateMapping() {
        let colDefinitions: GridColDef[] = [];
        const rows:{[k: string]: any}[]  = [];
        if(!this.state.importRows || !this.state.importColumns) {
            return;
        }

        colDefinitions = [
            {field: "id", headerName: "ID"},
            {field: this.state.selectedDateField, headerName: "Date"},
            {field: this.state.selectedOpenField, headerName: "Open"},
            {field: this.state.selectedHighField, headerName: "High"},
            {field: this.state.selectedLowField, headerName: "Low"},
            {field: this.state.selectedCloseField, headerName: "Close"},
            {field: this.state.selectedVolumeField, headerName: "Volume"},
        ]

        for(const importRow of this.state.importRows) {
            let row: {[k: string]: any} = {};
            row["id"] = importRow["id"];
            row[this.state.selectedDateField] = importRow[this.state.selectedDateField];
            row[this.state.selectedOpenField] = importRow[this.state.selectedOpenField];
            row[this.state.selectedHighField] = importRow[this.state.selectedHighField];
            row[this.state.selectedLowField] = importRow[this.state.selectedLowField];
            row[this.state.selectedCloseField] = importRow[this.state.selectedCloseField];
            row[this.state.selectedVolumeField] = importRow[this.state.selectedVolumeField];

            rows.push(row);
        }

        this.setState({...this.state, exportColumns: colDefinitions, exportRows: rows});
    }

    onTabChange(event: React.SyntheticEvent | undefined, newValue: number) {
        this.setState({...this.state, selectedTab: newValue}, () => {
            this.updateMapping();
            if(newValue === 2) {
                this.calculatePricingFormat();
            }
        });
    }

    onSelectedDateChange(event: SelectChangeEvent) {
        this.setState({...this.state, selectedDateField: event.target.value as string}, () => {
            this.updateMapping();
        });
    }

    onSelectedOpenChange(event: SelectChangeEvent) {
        this.setState({...this.state, selectedOpenField: event.target.value as string}, () => {
            this.updateMapping();
        });
    }

    onSelectedHighChange(event: SelectChangeEvent) {
        this.setState({...this.state, selectedHighField: event.target.value as string}, () => {
            this.updateMapping();
        });
    }

    onSelectedLowChange(event: SelectChangeEvent) {
        this.setState({...this.state, selectedLowField: event.target.value as string}, () => {
            this.updateMapping();
        });
    }

    onSelectedCloseChange(event: SelectChangeEvent) {
        this.setState({...this.state, selectedCloseField: event.target.value as string}, () => {
            this.updateMapping();
        });
    }

    onSelectedVolumeChange(event: SelectChangeEvent) {
        this.setState({...this.state, selectedVolumeField: event.target.value as string}, () => {
            this.updateMapping();
        });
    }

    onMap() {
        this.onTabChange(undefined, 1);
    }

    onImport() {
        this.onTabChange(undefined, 2);
        this.calculatePricingFormat();
    }

    onFullnameChange(event:ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, symbolFullname: event.target.value, symbolDescription: event.target.value});
    }

    onSymbolNameChange(event:ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, symbolName: event.target.value, alphaVantageSymbol: event.target.value});
    }

    onSymbolDescriptionChange(event:ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, symbolDescription: event.target.value});
    }

    onSymbolExchangeChange(event:ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, symbolExchange: event.target.value});
    }

    onAlphaVantageSymbolChange(event:ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, alphaVantageSymbol: event.target.value});
    }

    onSymbolTypeChange(event:ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, symbolType: event.target.value});
    }

    calculatePricingFormat() {
        let mostCloseDecimals = 0;
        let mostVolumeDecimals = 0;
        if(this.state.exportRows) {
            for(const row of this.state.exportRows) {
                const price = +row[this.state.selectedCloseField];
                const volume = +row[this.state.selectedVolumeField]
                const closeDecimals = GetDecimalPlaces(price);
                const volumeDecimals = GetDecimalPlaces(volume);
                if(closeDecimals > mostCloseDecimals) {
                    mostCloseDecimals = closeDecimals;
                }

                if(volumeDecimals > mostVolumeDecimals) {
                    mostVolumeDecimals = volumeDecimals;
                }
            }
        }
        
        const volumePrecision = mostVolumeDecimals;
        const priceScale = Math.pow(10, mostCloseDecimals);
        const minimumMovement = +((1 / priceScale) * 100).toFixed(mostCloseDecimals);
        this.setState({...this.state, minimumMovement: minimumMovement, priceScale: priceScale, volumePrecision: volumePrecision});    
    }

    startImport() {
        const symbol:Symbol = {
            name: this.state.symbolName,
            fullName: this.state.symbolFullname,
            description: this.state.symbolDescription,
            exchange: this.state.symbolExchange,
            hasNoVolume: false,
            id: 0,
            minMov: this.state.minimumMovement,
            priceScale: this.state.priceScale,
            type: this.state.symbolType,
            volumePrecision: this.state.volumePrecision,
            alphaVantageSymbol: this.state.alphaVantageSymbol
        };

        this.setState({...this.state, loadingImport: true}, () => {
            DataFeedAPI.CreateSymbol(symbol).then(newSymbol => {
                const symbolId = newSymbol.id;
                const bars:Bar[] = [];
                if(this.state.exportRows) {
                    for(const row of this.state.exportRows) {
                        const date = Math.floor(moment(row[this.state.selectedDateField]).toDate().getTime()) / 1000;
                        if(isInt(date)) {
                            bars.push({
                                open: +row[this.state.selectedOpenField],
                                close: +row[this.state.selectedCloseField],
                                high: +row[this.state.selectedHighField],
                                low: +row[this.state.selectedLowField],
                                volume: +row[this.state.selectedVolumeField],
                                time: date
                            });
                        }
                        else {
                            console.log(`Caught invalid candle with date ${date}`);
                        }
                    }
                    
                    DataFeedAPI.InsertBars(symbolId, 1, bars).then(() => {
                        this.setState({...this.state, succeeded: true, loadingImport: false});
                    })
                }
            });
        });
    }

    render() {
        return(
            <Paper sx={{width: "100%"}}>
                <Tabs style={{justifyContent: "center"}} value={this.state.selectedTab} onChange={this.onTabChange} aria-label="basic tabs example">
                    <Tab label="Data" id={`import-tab-1`}/>
                    <Tab disabled={!(this.state.importColumns && this.state.importRows)} label="Mapping" id={`import-tab-2`}/>
                    <Tab disabled={!(this.state.exportColumns && this.state.exportRows)} label="Import" id={`import-tab-3`}/>
                </Tabs>
                <TabPanel value={this.state.selectedTab} index={0}>
                    <Button
                    variant="contained"
                    component="label"
                    >
                    Upload File
                    <input
                        id='csv'
                        type="file"
                        onChange={this.onFileChange}
                        hidden
                        accept=".csv"
                    />
                    </Button>
                    {this.state.importColumns && this.state.importRows && (
                        <Box>
                            <div style={{ height: '400px', width: '100%' }}>
                                <DataGrid
                                    rows={this.state.importRows}
                                    columns={this.state.importColumns}
                                    pageSize={50}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    checkboxSelection
                                />
                            </div>
                            <Button variant='contained' onClick={this.onMap} fullWidth>Map</Button>
                        </Box>
                    )}
                    
                </TabPanel>
                <TabPanel value={this.state.selectedTab} index={1}>
                    <Stack gap={"12px"}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Date</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.selectedDateField}
                            label="Date"
                            onChange={this.onSelectedDateChange}
                            >
                                {this.state.importColumns?.map((column, i) => {
                                    return(
                                        <MenuItem key={column.field} value={column.field}>{column.headerName}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Open</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.selectedOpenField}
                            label="Open"
                            onChange={this.onSelectedOpenChange}
                            >
                                {this.state.importColumns?.map((column, i) => {
                                    return(
                                        <MenuItem key={column.field} value={column.field}>{column.headerName}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">High</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.selectedHighField}
                            label="High"
                            onChange={this.onSelectedHighChange}
                            >
                                {this.state.importColumns?.map((column, i) => {
                                    return(
                                        <MenuItem key={column.field} value={column.field}>{column.headerName}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Low</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.selectedLowField}
                            label="Low"
                            onChange={this.onSelectedLowChange}
                            >
                                {this.state.importColumns?.map((column, i) => {
                                    return(
                                        <MenuItem key={column.field} value={column.field}>{column.headerName}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Close</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.selectedCloseField}
                            label="Close"
                            onChange={this.onSelectedCloseChange}
                            >
                                {this.state.importColumns?.map((column, i) => {
                                    return(
                                        <MenuItem key={column.field} value={column.field}>{column.headerName}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Volume</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.selectedVolumeField}
                            label="Volume"
                            onChange={this.onSelectedVolumeChange}
                            >
                                {this.state.importColumns?.map((column, i) => {
                                    return(
                                        <MenuItem key={column.field} value={column.field}>{column.headerName}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        {this.state.exportColumns && this.state.exportRows && (
                            <Button variant='contained' onClick={this.onImport} fullWidth>Import</Button>
                        )}
                    </Stack>
                </TabPanel>
                <TabPanel value={this.state.selectedTab} index={2}>
                    {this.state.exportColumns && this.state.exportRows && (
                        <Stack spacing={2}>
                            <Typography variant='h5'>Metadata</Typography>
                            <Divider />
                            <TextField value={this.state.symbolName} onChange={this.onSymbolNameChange} fullWidth label="Symbol name" variant="outlined" />
                            <TextField value={this.state.symbolFullname} onChange={this.onFullnameChange} fullWidth label="Full name" variant="outlined" />
                            <TextField value={this.state.alphaVantageSymbol} onChange={this.onAlphaVantageSymbolChange} fullWidth label="Alpha vantage symbol" variant="outlined"/>
                            <TextField value={this.state.symbolDescription} onChange={this.onSymbolDescriptionChange} fullWidth label="Description" variant="outlined"/>
                            <TextField value={this.state.symbolExchange} onChange={this.onSymbolExchangeChange} fullWidth label="Exchange" variant="outlined" multiline maxRows={4}/>
                            <TextField value={this.state.symbolType} onChange={this.onSymbolTypeChange} fullWidth label="Type" variant="outlined"/>

                            <Typography variant='h5'>Data view</Typography>
                            <Divider />
                            <div style={{ height: '400px', width: '100%' }}>
                                <DataGrid
                                    rows={this.state.exportRows}
                                    columns={this.state.exportColumns}
                                    pageSize={50}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    checkboxSelection
                                />
                            </div>
                            <Typography variant='h5'>Formatting</Typography>
                            <Divider />
                            <Stack direction="column">
                                <Typography variant='body1'>MinMov: {this.state.minimumMovement}</Typography>
                                <Typography variant='body1'>PriceScale: {this.state.priceScale}</Typography>
                                <Typography variant='body1'>VolumePrecision: {this.state.volumePrecision}</Typography>
                            </Stack>
                            {this.state.loadingImport && (
                                <Stack direction={"row"} gap="12px">
                                    <CircularProgress color='inherit' />
                                    <Typography variant='subtitle1'>Importing data to database, this may take a while...</Typography>
                                </Stack>
                            )}
                            {this.state.succeeded && 
                                <Alert severity='success'>Import succeeded</Alert>}
                            <Button onClick={this.startImport} variant='contained'>Import</Button>
                            
                        </Stack>
                    )}
                </TabPanel>
            </Paper>
        );
    }
}