import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import { Stack } from '@mui/material';

export default class FetchEditorTextFormat extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            format: [],
            isBold: false,
            isItalic: false,
            isUnderline: false
        };

        this.handleFormatChange = this.handleFormatChange.bind(this);
    }

    handleFormatChange(event, newFormat) {
        event.preventDefault();
        const intersectionA = newFormat.filter(x => !this.state.format.includes(x));
        const intersectionB = this.state.format.filter(x => !newFormat.includes(x));
        const changes = intersectionA.concat(intersectionB);

        if(this.props.onChange) {
            for(const change of changes) {
                this.props.onChange(change);
            }
        }
        
        if(newFormat) {            
            this.setState({...this.state, format: newFormat});
        }

        else {
            this.setState({...this.state, format: []});
        }
    }

    render() {
        return (
            <Stack sx={{height: "40px"}}>
                <ToggleButtonGroup sx={{flexGrow: 1}} value={this.state.format} onChange={this.handleFormatChange}>
                    <ToggleButton size='small' value="BOLD" aria-label="bold">
                        <FormatBoldIcon fontSize="small"/>
                    </ToggleButton>
                    <ToggleButton size='small' value="ITALIC" aria-label="italic">
                        <FormatItalicIcon fontSize="small"/>
                    </ToggleButton>
                    <ToggleButton size='small' value="UNDERLINED" aria-label="underlined">
                        <FormatUnderlinedIcon fontSize="small"/>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>
        );
    }
}