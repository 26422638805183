import { Avatar, Box, Card, Dialog, Stack, TextField } from '@mui/material';
import React from 'react';
import { withParams } from '../../Helpers/ReactRouterHelper';
import { WhoAmIResponse } from '../../API/models/account';
import Account from '../../API/Account';
import { red } from '@mui/material/colors';
import NewPost from '../Create/NewPost';

type NewFeedPostProps = {
    loaderData: WhoAmIResponse
}

type NewFeedPostState = {
    profileImageUrl: string,
    isDialogOpen: boolean
}

class NewFeedPost extends React.Component<NewFeedPostProps, NewFeedPostState> {
    constructor(props:NewFeedPostProps) {
        super(props);

        this.state = {
            profileImageUrl: "",
            isDialogOpen: false
        };

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }

    componentDidMount(): void {
        if(this.props.loaderData.roles.length > 0) {
            Account.GetProfileImageUrl().then(profileImageUrl => {
                this.setState({...this.state, profileImageUrl: profileImageUrl});
            });
        }
    }

    openDialog() {
        this.setState({...this.state, isDialogOpen: true});
    }

    closeDialog() {
        this.setState({...this.state, isDialogOpen: false});
    }

    render(): React.ReactNode {
        if(this.props.loaderData.roles.length == 0) {
            return null;
        }

        return(
            <Box>
                <Card style={{padding: "8px"}}>
                    <Stack spacing={2} direction={'row'} alignItems={'center'}>
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            {this.props.loaderData.username[0]}
                        </Avatar>
                        <TextField onClick={this.openDialog} style={{flexGrow: 1}} fullWidth size='small' placeholder='Create Post'/>
                    </Stack>
                </Card>
                <Dialog maxWidth={'lg'} onClose={this.closeDialog} open={this.state.isDialogOpen}>
                    <Box style={{padding: "12px"}}>
                        <NewPost />
                    </Box>
                </Dialog>
            </Box>
        )
    }
}

export default withParams(NewFeedPost);