import { Box, Stack, TextField, Divider } from '@mui/material';
import React, { ChangeEvent } from 'react';
import TagInput from '../../../Controls/TagInput';
import { ISideBar } from './EditorSideBar';
import { sidebarPaddingStyle } from './EditorSideBar';

type EditorSidebarPollProps = {

}

type EditorSidebarPollState = {
    title:string,
    question: string,
    option1:string,
    option2:string,
    option3:string,
    option4:string,
    tags:string[]
}

export default class EditorSideBarPoll extends React.Component<EditorSidebarPollProps, EditorSidebarPollState> implements ISideBar {
    constructor(props:EditorSidebarPollProps) {
        super(props);

        this.state = {
            title: "",
            question: "",
            option1: "",
            option2: "",
            option3: "",
            option4: "",
            tags: []
        }

        this.save = this.save.bind(this);
        this.onTagsChanged = this.onTagsChanged.bind(this);
        this.onTitleChanged = this.onTitleChanged.bind(this);
        this.onPollQuestionChanged = this.onPollQuestionChanged.bind(this);
        this.onOption1Changed = this.onOption1Changed.bind(this);
        this.onOption2Changed = this.onOption2Changed.bind(this);
        this.onOption3Changed = this.onOption3Changed.bind(this);
        this.onOption4Changed = this.onOption4Changed.bind(this);
    }
    
    save() {
        return {...this.state};
    }

    onTagsChanged(tags:string[]) {
        this.setState({...this.state, tags: tags});
    }

    onTitleChanged(title: ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, title: title.target.value});
    }

    onPollQuestionChanged(question: ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, question: question.target.value});
    }

    onOption1Changed(option: ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, option1: option.target.value});
    }

    onOption2Changed(option: ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, option2: option.target.value});
    }

    onOption3Changed(option: ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, option3: option.target.value});
    }

    onOption4Changed(option: ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, option4: option.target.value});
    }

    render() {
        return(
            <Stack>
                <Stack gap={2}>
                    <Box style={sidebarPaddingStyle}>
                        <Stack gap={2}>
                            <TextField fullWidth variant="outlined" size="small" label="Title" onChange={this.onTitleChanged} />
                            <TextField fullWidth variant="outlined" size="small" label="Poll question" onChange={this.onPollQuestionChanged} />
                        </Stack>
                    </Box>
                    <Divider />
                    <Box>
                        <Box style={sidebarPaddingStyle}>
                            <TextField fullWidth variant="outlined" size="small" label="Option 1" onChange={this.onOption1Changed} />
                        </Box>
                        <Box style={sidebarPaddingStyle}>
                            <TextField fullWidth variant="outlined" size="small" label="Option 2" onChange={this.onOption2Changed} />
                        </Box>
                        <Box style={sidebarPaddingStyle}>
                            <TextField fullWidth variant="outlined" size="small" label="Option 3 (Optional)" onChange={this.onOption3Changed} />
                        </Box>
                        <Box style={sidebarPaddingStyle}>
                            <TextField fullWidth variant="outlined" size="small" label="Option 4 (Optional)" onChange={this.onOption4Changed} />
                        </Box>
                    </Box>
                    <Divider />
                    <Box style={sidebarPaddingStyle}>
                        <TagInput onChange={this.onTagsChanged} />
                    </Box>
                </Stack>
            </Stack>
        );
    }
}