
import { Box, Dialog, DialogContent, DialogTitle, ListItemIcon, ListItemText, Menu, MenuItem, Stack } from '@mui/material';
import React from 'react';
import Content from '../../API/Content';
import { AuthorDTO } from '../../API/models/datatransferobjects';

import StarIcon from '@mui/icons-material/Star';
import FlagIcon from '@mui/icons-material/Flag';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import PushPinIcon from '@mui/icons-material/PushPin';
import Post from '../../API/Post';
import DatePicker from '../../Controls/DatePicker';
import Button from '@mui/material/Button';

import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import Typography from '@mui/material/Typography';
import { ROLE_ADMIN_OVERLORD, router } from '../../App';
import { WhoAmIResponse } from '../../API/models/account';

type FeedItemCardMenuProps = {
    open: boolean,
    anchor?:Element,
    onClose:Function,
    contentId:string,
    author:AuthorDTO,
    isHidden:boolean,
    isMine?:boolean,
    hasFavorited:boolean,
    onDelete?: Function,
    whoAmI: WhoAmIResponse
};

type FeedItemCardMenuState = {
    isPinDialogOpen:boolean,
    isFlagDialogOpen:boolean,
    dateToPin:Date,
    isOwner: boolean,
    pinSucceeded: boolean,
    flagSucceeded: boolean,
    isAdmin:boolean,
    hasFavorited:boolean
}

export default class FeedItemCardMenu extends React.Component<FeedItemCardMenuProps, FeedItemCardMenuState> {
    constructor(props:FeedItemCardMenuProps) {
        super(props);
        this.state = {
            isOwner: false,
            isPinDialogOpen: false,
            isFlagDialogOpen: false,
            dateToPin: new Date(),
            pinSucceeded: false,
            flagSucceeded: false,
            isAdmin: false,
            hasFavorited: this.props.hasFavorited
        };

        this.favorite = this.favorite.bind(this);
        this.unfavorite = this.unfavorite.bind(this);
        this.delete = this.delete.bind(this);
        this.flag = this.flag.bind(this);
        this.hide = this.hide.bind(this);
        this.unhide = this.unhide.bind(this);

        this.closePinDialog = this.closePinDialog.bind(this);
        this.openPinDialog = this.openPinDialog.bind(this);

        this.closeFlagDialog = this.closeFlagDialog.bind(this);
        this.openFlagDialog = this.openFlagDialog.bind(this);

        this.onPinDateChange = this.onPinDateChange.bind(this);
        this.onPin = this.onPin.bind(this);
        this.gotoProfile = this.gotoProfile.bind(this);
    }

    componentDidMount(): void {
        const isAdmin = this.props.whoAmI.roles.includes(ROLE_ADMIN_OVERLORD);
        const isMine = this.props.author.id === this.props.whoAmI.id;
        this.setState({...this.state, isAdmin: isAdmin, isOwner: isMine});
    }

    favorite() {
        Content.Favorite(this.props.contentId).then(() => {
            this.setState({...this.state, hasFavorited: true}, () => {
                this.props.onClose();
            });
        });
    }

    unfavorite() {
        Content.Unfavorite(this.props.contentId).then(() => {
            this.setState({...this.state, hasFavorited: false}, () => {
                this.props.onClose();
            });
        });
    }

    flag() {
        Content.Flag(this.props.contentId).then(() => {
            this.props.onClose();
            this.setState({...this.state, flagSucceeded: true}, () => {
                setTimeout(this.closeFlagDialog, 750);
            });
        });
    }

    hide() {
        Content.Hide(this.props.contentId).then(() => {
            this.props.onClose();
        });
    }

    unhide() {
        Content.Unhide(this.props.contentId).then(() => {
            this.props.onClose();
        });
    }

    delete() {
        Post.DeletePost(this.props.contentId).then(() => {
            this.props.onClose();
            if(this.props.onDelete !== undefined) {
                this.props.onDelete();
            }
        });
    }

    openPinDialog() {
        this.setState({...this.state, isPinDialogOpen: true});

    }

    closePinDialog() {
        this.setState({...this.state, isPinDialogOpen: false, pinSucceeded: false});
    }

    openFlagDialog() {
        this.setState({...this.state, isFlagDialogOpen: true});

    }

    closeFlagDialog() {
        this.setState({...this.state, isFlagDialogOpen: false, flagSucceeded: false});
    }

    onPinDateChange(value:Date) {
        this.setState({...this.state, dateToPin: value});
    }

    onPin() {
        Content.Pin(this.props.contentId, this.state.dateToPin).then(() => {
            this.setState({...this.setState, pinSucceeded: true}, () => {
                setTimeout(this.closePinDialog, 750);
            });
        });
    }

    gotoProfile() {
        router.navigate(`/profile/${this.props.author.name}`);
    }

    render() {
        return(
            <Box>
                <Menu open={this.props.open} anchorEl={this.props.anchor} onClose={() => this.props.onClose()}>
                    <MenuItem onClick={this.state.hasFavorited ? this.unfavorite : this.favorite}>
                        <ListItemIcon>
                            <StarIcon color={this.state.hasFavorited ? "primary" : "action"} fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{this.state.hasFavorited ? "Unfavorite" : "Favorite"}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={this.gotoProfile}>
                        <ListItemIcon>
                            <PersonIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Go to profile</ListItemText>
                    </MenuItem>
                    {!this.state.isOwner && (
                        <MenuItem onClick={this.openFlagDialog}>
                            <ListItemIcon>
                                <FlagIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Flag</ListItemText>
                        </MenuItem>
                    )}
                    {this.state.isAdmin && (
                        <MenuItem onClick={this.openPinDialog}>
                            <ListItemIcon>
                                <PushPinIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Pin</ListItemText>
                        </MenuItem>
                    )}

                    {this.state.isAdmin && (
                        <MenuItem onClick={this.props.isHidden ? this.unhide : this.hide}>
                            <ListItemIcon>
                                {this.props.isHidden ? <VisibilityIcon fontSize="small"/> : <VisibilityOffIcon fontSize="small"/>}
                            </ListItemIcon>
                            <ListItemText>{this.props.isHidden ? "Unhide" : "Hide"}</ListItemText>
                        </MenuItem>
                    )}
                    {this.state.isOwner && (
                        <MenuItem onClick={this.delete}>
                            <ListItemIcon>
                                <DeleteIcon fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                    )}
                </Menu>
                <Dialog onClose={this.closePinDialog} open={this.state.isPinDialogOpen}>
                    <DialogTitle>Pin this item</DialogTitle>
                    <DialogContent>
                        <Stack gap="12px">
                            <DatePicker onChange={this.onPinDateChange} label="Date to pin" />
                            <Button variant='contained' onClick={this.onPin} fullWidth endIcon={this.state.pinSucceeded ? <CheckIcon/> : null}>Pin</Button>
                        </Stack>
                    </DialogContent>
                </Dialog>
                <Dialog onClose={this.closeFlagDialog} open={this.state.isFlagDialogOpen}>
                    <DialogTitle>Flag this item</DialogTitle>
                    <DialogContent>
                        <Stack gap="12px">
                            <Typography variant='h3'>Are you sure?</Typography>
                            <Typography variant='body1'>
                                Flagging the content of another user will alert the moderation team to a possible transgression of the community guidelines.
                            </Typography>
                            <Typography variant='body1'>
                                If this content is not in infringement of the community guidelines or if there is no feasible reasons for flagging this content you may risk a ban from the platform.
                            </Typography>
                            <Button 
                                style={ this.state.flagSucceeded ? {backgroundColor: "var(--success-color)"} : {backgroundColor: "var(--error-color)"}} variant='contained' onClick={this.flag} 
                                fullWidth 
                                endIcon={this.state.flagSucceeded ? <CheckIcon/> : <WarningIcon/>}>
                                    Flag
                            </Button>
                        </Stack>
                    </DialogContent>
                </Dialog>
            </Box>
        )
    }
}
