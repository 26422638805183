import { Alert, Box, Button, Link, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import Account from '../API/Account';
import Data from '../API/Data';
import { setCookie } from '../API/resources/cookies';
import { router } from '../App';
import { withParams } from '../Helpers/ReactRouterHelper';

type ConfirmRegistrationParams = {
    token:string
}

type ConfirmRegistrationProps = {
    params:ConfirmRegistrationParams
}

type ConfirmRegistrationState = {
    success:boolean,
    error?:string,
    username:string,
    password:string,
    confirmPassword:string
}

class ConfirmRegistration extends React.Component<ConfirmRegistrationProps, ConfirmRegistrationState> {
    constructor(props: ConfirmRegistrationProps) {
        super(props);
        this.state = {
            success: false,
            username: "",
            password: "",
            confirmPassword: ""
        };

        this.onConfirm = this.onConfirm.bind(this);
        this.onUsernameChanged = this.onUsernameChanged.bind(this);
        this.onPasswordChanged = this.onPasswordChanged.bind(this);
        this.onConfirmPasswordChanged = this.onConfirmPasswordChanged.bind(this);
    }

    //TODO Check if user is logged in. 
    componentDidMount(): void {
        const token = Data.GetAccessToken();
        if(token) {
            router.navigate("/feed");
        }
    }

    onUsernameChanged(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, username: event.target.value});
    }

    onPasswordChanged(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, password: event.target.value});
    }

    onConfirmPasswordChanged(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, confirmPassword: event.target.value});
    }

    onConfirm(event:any) {
        event.preventDefault();
        if(this.state.password != this.state.confirmPassword) {
            this.setState({...this.state, error: "Password don't match"});
            return;
        }

        if(this.state.username.length == 0) {
            this.setState({...this.state, error: "Username cannot be empty"});
            return;
        }

        Account.CompleteRegistration(this.state.username, this.state.password, this.state.confirmPassword, this.props.params.token).then(login => {
            setCookie("auth", JSON.stringify(login), login.expiresOn);
            router.navigate("/register/subscription");
        }).catch(error => {
            console.error(error);
            this.setState({...this.state, error: error});
        });
    }
    
    render(): React.ReactNode {
        return(
            <form action='#' onSubmit={this.onConfirm}>
                {this.state.error && (
                    <Alert severity="error">{this.state.error}</Alert>
                )}
                <Stack gap={5}>
                    <Box style={{textAlign: "center"}}>
                        <Typography variant="h6">Confirm your registration</Typography>
                    </Box>
                    <Stack gap={2}>
                        <TextField size="small" onChange={this.onUsernameChanged} variant="filled" fullWidth id='username' placeholder='Username' label="Username"/>
                        <TextField size="small" onChange={this.onPasswordChanged} variant="filled" fullWidth id='password' placeholder='Password' label='Password' type="password" />
                        <TextField size="small" onChange={this.onConfirmPasswordChanged} variant="filled" fullWidth id='confirm-password' placeholder='Confirm Password' label='Confirm Password' type="password" />
                    </Stack>
                    <Stack gap={2}>
                        <Button type="submit" variant='contained' fullWidth>Confirm</Button>
                        {/* <Button component={Link} to={"/register"} variant='outlined' fullWidth>Don't have an account? Sign up</Button> */}
                        {/* <Button component={Link} to={"/forgot-password"} variant='outlined' fullWidth>Forgot your password?</Button> */}
                    </Stack>
                </Stack>
            </form>
        )
    }
}


export default withParams(ConfirmRegistration);