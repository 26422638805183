import { Box, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React from 'react';
import { ViewCommentResult } from '../../API/models/comments';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Comment from '../../API/Comment';
import Spacer from '../../Helpers/Spacer';
import { Link } from 'react-router-dom';

type ChildCommentProps = {
    comment:ViewCommentResult
}

type ChildCommentState = {
    comment:ViewCommentResult
    showMenuButton: boolean;
    showMenu:boolean,
    menuAnchor?:Element
}


export default class ChildComment extends React.Component<ChildCommentProps, ChildCommentState> {
    constructor(props:ChildCommentProps) {
        super(props);

        this.state = {
            comment: this.props.comment,
            showMenu: false,
            showMenuButton: false
        };

        this.showMenuButton = this.showMenuButton.bind(this);
        this.hideMenuButton = this.hideMenuButton.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.vote = this.vote.bind(this);
    }

    showMenuButton() {
       this.setState({...this.state, showMenuButton: true});
    }

    hideMenuButton() {
        this.setState({...this.state, showMenuButton: false});
    }

    openMenu(event:React.MouseEvent<HTMLElement>) {
        this.setState({...this.state, showMenu: true, menuAnchor: event.currentTarget});
    }

    closeMenu() {
        this.setState({...this.state, showMenu: false});
    }

    vote() {
        if(this.state.comment.hasLiked) {
            Comment.Unlike(this.props.comment.commentId).then(() => {
                this.setState({...this.state, comment: {
                    ...this.state.comment,
                    hasLiked: false,
                    likes: this.state.comment.likes - 1
                }});
            });
        }
        else {
            Comment.Like(this.props.comment.commentId).then(() => {
                this.setState({...this.state, comment: {
                    ...this.state.comment,
                    hasLiked: true,
                    likes: this.state.comment.likes + 1
                }});
            });
        }
    }

    render() {
        return(
            <Stack onMouseEnter={this.showMenuButton} onMouseLeave={this.hideMenuButton}>
                <Stack direction={"row"} sx={{justifyContent: "flex-start" }}>
                    <Stack direction="row" gap={"12px"}>
                        {/* <div style={{backgroundColor: "#ddd", minWidth: "4px", borderRadius: "100px"}}></div> */}
                        {/* <Typography variant="body2">{this.state.comment.likes}</Typography> */}
                        <Typography variant="body2" style={{opacity: 0.65, fontSize: "85%"}}>
                            {this.state.comment.likes}
                        </Typography>
                        <Typography variant="body2" style={{fontSize: "85%"}}>
                            {this.state.comment.comment}
                            &nbsp;–&nbsp;
                            <Link style={{color: "var(--primary-color)"}} to={`/profile/${this.state.comment.author.name}`}>{this.state.comment.author.name}</Link>
                            <span style={{opacity: 0.65}}>
                                &nbsp;
                                {new Date(this.state.comment.dateCreated).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}
                            </span>
                        </Typography>
                    </Stack>
                    <Spacer />
                    {
                        this.state.showMenuButton && 
                            <Box style={{position: "relative"}}>
                                <IconButton onClick={this.openMenu} sx={{position: "absolute", marginTop: "-8px", marginLeft: "-28px"}}>
                                    <MoreVertIcon sx={{fontSize: "20px"}} />
                                </IconButton>
                            </Box>
                    }
                </Stack>
                <Box style={{position: "relative"}}>
                    {
                        this.state.menuAnchor && 
                            <Menu open={this.state.showMenu} onClose={this.closeMenu} anchorEl={this.state.menuAnchor}>
                                <MenuItem onClick={this.vote}>{this.state.comment.hasLiked ? "Unlike" : "Like"}</MenuItem>
                                <MenuItem onClick={this.closeMenu}>Flag</MenuItem>
                            </Menu>
                    }
                </Box>
            </Stack>
        );
    }
}