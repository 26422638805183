import { Button, Stack, TextField, Box, Typography, Alert } from '@mui/material';
import React from 'react';
import Data from '../API/Data';
import { Link } from 'react-router-dom';
import { withParams } from '../Helpers/ReactRouterHelper';
import Account from '../API/Account';
import { router } from '../App';

type ResetPasswordProps = {
}

type ResetPasswordState = {
    email:string,
    succeeded:boolean,
    error?:string
}

export default class ResetPassword extends React.Component<ResetPasswordProps, ResetPasswordState> {
    constructor(props:ResetPasswordProps) {
        super(props);

        this.state = {
            succeeded: false,
            email:""
        }

        this.onUsernameChanged = this.onUsernameChanged.bind(this);
        this.onResetPassword = this.onResetPassword.bind(this);
    }

    onUsernameChanged(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, email: event.currentTarget.value});
    }
    
    onResetPassword() {
        Account.ResetPassword(this.state.email).then(() => {
            this.setState({...this.state, succeeded: true}, () => {
                setTimeout(() => {
                    router.navigate("/");
                }, 2000);
            })
        }).catch(error => {
            console.error(error);
            this.setState({...this.state, error: error.title});
        });
    }

    render() {
        return(
            <Stack gap={5}>
                {
                    this.state.succeeded 
                        && <Alert severity='success'>Reset link sent.</Alert>
                }
                <Typography variant="h6">Reset your password</Typography>
                <Box>
                    <TextField variant="filled" size="small" onChange={this.onUsernameChanged} 
                        fullWidth id='e-mail' placeholder='E-mail' label="E-mail"/>
                </Box>
                <Stack gap={2}>
                    <Button onClick={this.onResetPassword} variant='contained' fullWidth>Send reset link</Button>
                    <Button component={Link} to={"/login"} variant='outlined' fullWidth>Login</Button>
                </Stack>
            </Stack>
        )
    }
}