import { Button, Card, CardActions, CardContent, CardHeader, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { ViewCommentResult } from '../../API/models/comments';
import { defaultElevation } from '../../Theme/Theme';
import AuthorReputation from '../Author/AuthorReputation';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreateComment from './CreateComment';
import LikeTag from '../../Controls/LikeTag';
import CommentContextMenu from './CommentContextMenu';
import { ROLES_CREATE_COMMENT, router } from '../../App';
import AuthorizeView from '../../Layout/AuthorizeView';
import AuthorAvatar from '../../Account/AuthorAvatar';
import Spacer from '../../Helpers/Spacer';
import { WhoAmIResponse } from '../../API/models/account';

type BasicCommentProps = {
    comment:ViewCommentResult,
    contentId:string,
    commentDepth:number,
    whoAmI: WhoAmIResponse
};

type BasicCommentState = {
    isCommentBoxOpen: boolean,
    comment:ViewCommentResult,
    isContextMenuOpen:boolean,
    menuAnchor?:Element
}

const parentCommentStyle: React.CSSProperties = {
    flexGrow: 1
}  

const childCommentStyle: React.CSSProperties = {
    flexGrow: 1,
}

export default class BasicComment extends React.Component<BasicCommentProps, BasicCommentState> {
    constructor(props:BasicCommentProps) {
        super(props);

        this.state = {
            isCommentBoxOpen: false,
            comment: this.props.comment,
            isContextMenuOpen: false
        };

        this.openChildCommentBox = this.openChildCommentBox.bind(this);
        this.closeChildCommentBox = this.closeChildCommentBox.bind(this);
        this.onCommentPosted = this.onCommentPosted.bind(this);

        this.openContextMenu = this.openContextMenu.bind(this);
        this.closeContextMenu = this.closeContextMenu.bind(this);
        this.gotoProfile = this.gotoProfile.bind(this);
    }

    openChildCommentBox() {
        this.setState({...this.state, isCommentBoxOpen: true});
    }

    closeChildCommentBox() {
        this.setState({...this.state, isCommentBoxOpen: false});
    }

    onCommentPosted(comment:ViewCommentResult, parentCommentId?:string) {
        const thisComment = this.state.comment;
        thisComment.comments.push(comment);
        this.setState({...this.state, comment: thisComment, isCommentBoxOpen: false});
    }

    openContextMenu(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            ...this.state,
            isContextMenuOpen: true,
            menuAnchor: event.currentTarget
        });
    }

    closeContextMenu() {
        this.setState({...this.state, isContextMenuOpen: false});
    }

    gotoProfile() {
        router.navigate(`/profile/${this.state.comment.author.name}`);
    }

    render() {
        return(
            <Stack style={this.props.commentDepth === 1 ? parentCommentStyle : childCommentStyle}>
                <Card style={{flexGrow: 1}} elevation={this.props.commentDepth === 1 ? defaultElevation : 0}>
                    <CardHeader
                        className='clickable-header'
                        avatar={
                            <AuthorAvatar author={this.state.comment.author} />
                        }
                        title={<div onClick={this.gotoProfile} style={{textAlign: "left", fontWeight: "bold"}}>{this.state.comment.author.name}</div>}
                        subheader={<div style={{textAlign: "left"}}><AuthorReputation author={this.state.comment.author}/></div>}
                        action={<IconButton onClick={this.openContextMenu}><MoreVertIcon/></IconButton>}
                    />
                    <CardContent style={{textAlign: "left", padding: "0px 18px"}}>
                        <Typography variant="body2" color="text.secondary">
                            {this.state.comment.comment}
                        </Typography>
                    </CardContent>
                    <CardActions sx={{p: "16px", gap: "8px"}}>
                        <LikeTag likes={this.state.comment.likes} hasLiked={this.state.comment.hasLiked} commentId={this.state.comment.commentId} />
                        <Spacer />
                        {
                            this.props.commentDepth < 3 && (
                                <AuthorizeView whoAmI={this.props.whoAmI} roles={ROLES_CREATE_COMMENT}>
                                    <Button 
                                        onClick={this.state.isCommentBoxOpen ? this.closeChildCommentBox :this.openChildCommentBox} 
                                        style={{marginLeft: "auto"}} 
                                        variant='outlined'>
                                        {this.state.isCommentBoxOpen ? "Cancel" : "Comment"}
                                    </Button>
                                </AuthorizeView>
                            )
                        }                         
                    </CardActions>
                    {
                        this.state.isCommentBoxOpen && (
                            <div>
                                <CreateComment whoAmI={this.props.whoAmI} onCommentPosted={this.onCommentPosted} contentId={this.props.contentId} parentCommentId={this.state.comment.commentId} />
                            </div>
                        )
                    }
                    {
                        this.state.comment.comments.length > 0 && this.props.commentDepth < 4 && (
                            <Stack direction="row">
                                <div style={{backgroundColor: "#ddd", minWidth: "4px", margin: "16px", borderRadius: "100px"}}></div>
                                <Stack style={{flexGrow: 1}} spacing={2}>
                                    {this.state.comment.comments.map((childComment, i) => {
                                        return(
                                            <BasicComment
                                                whoAmI={this.props.whoAmI}
                                                contentId={this.props.contentId} 
                                                key={i} 
                                                comment={childComment} 
                                                commentDepth={this.props.commentDepth + 1} />
                                        )
                                    })}
                                </Stack>

                            </Stack>
                        )
                    }
                </Card>
                <CommentContextMenu anchor={this.state.menuAnchor} onClose={this.closeContextMenu} content={this.props.comment} open={this.state.isContextMenuOpen} />
            </Stack>
        );
    }
}