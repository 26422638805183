import { Box } from "@mui/system";
import React from "react";

export default class FetchViewerYoutubeBlock extends React.Component {
    constructor(props) {
        super(props);
        this.data = JSON.parse(this.props.data);
    }

    render() {
        return(
            <Box>
                <iframe width="560" height="315" src={`https://www.youtube.com/embed/${this.data.video}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </Box>
        )
    }
}