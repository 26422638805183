import React from 'react';
import {Editor, EditorState, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';


export default class FetchViewerTextBlock extends React.Component {
    constructor(props) {
        super(props);
        if(this.props.data) {
            this.data = JSON.parse(this.props.data);
            const rawData = convertFromRaw(this.data);
            //let contentState = ContentState.createFromBlockArray(this.data.blocks, this.data.entityMap);
            let editorState = EditorState.createWithContent(rawData);
            this.state = {
                editorState:  editorState
            };
        }

        else {
            this.state = {
                editorState:  EditorState.createEmpty()
            };
        }
    }

    render() {
        return(<Editor editorState={this.state.editorState} readOnly={true} />)
    }
}