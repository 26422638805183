import React, {useEffect} from 'react';
import './App.css';
import './Whale.css';
import './Indicators.css'
import MainLayout from './Layout/MainLayout';
import { ThemeProvider } from '@emotion/react';
import { theme } from './Theme/Theme';
import {
  createBrowserRouter,
  RouterProvider} from "react-router-dom";
import Placeholder from "./Content/Placeholder";
import Feed, { FeedFilterValues } from './Content/Feed/Feed';
import AccountSettings from "./Content/Account/Settings";
import Notifications from "./Content/Account/Notifications";
import Favorites from './Content/Feed/Favorites';

import Portfolio from './Content/Portfolio/Portfolio';
import WhaleTracker from './Content/WhaleTracker/Whaletracker';
import Indicators from './Content/Indicators/Indicators';

import EditorLayout from './Layout/EditorLayout';
import ViewPost from './Content/Post/ViewPost';
import ViewPoll from './Content/Poll/ViewPoll';
import ViewQuestion from './Content/Question/ViewQuestion';
import SimpleLayout from './Layout/SimpleLayout';
import Login from './Account/Login';
import Register from './Account/Register';
import LandingPageOnboard from './LandingPage/LandingPageOnboard'
import ResetPassword from './Account/ResetPassword';
import PremiumRequired from './Account/PremiumRequired';
import Authorize from './Layout/Authorize';
import ViewTheory from './Content/Theory/ViewTheory';
import Profile from './Content/Account/Profile';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Import from './Data/Import';
import Symbols from './Data/Symbols';
import BrowseSymbols from './Content/Symbols/BrowseSymbols';
import ViewSymbol from './Content/Symbols/ViewSymbol';
import NotFound from './Layout/NotFound';
import ConfirmRegistration from './Account/ConfirmRegistration';
import GetSubscription from './Account/GetSubscription';
import { loadStripe } from '@stripe/stripe-js';
import SubscriptionSucces from './Account/SubscriptionSuccess';
import SubscriptionFailed from './Account/SubscriptionFailed';
import ConfirmEmail from './Account/ConfirmEmail';
import ConfirmResetPassword from './Account/ConfirmResetPassword';
import Error from './Layout/Error';
import ConfirmEmailChange from './Account/ConfirmEmailChange';
import UpgradeUser from './Account/UpgradeUser';
import NewPost from './Content/Create/NewPost';
import { defaultLoader } from './Helpers/ReactRouterHelper';
import Index from './Static';
import Terms from './Static/terms';
import CookieAlert from './Static/cookieAlert';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { getCookie } from './API/resources/cookies';
import ReactGA from "react-ga4";
import GoogleTapSignIn from './Static/googleTapSignIn';
import Onboarding from './Static/onBoarding';

ReactGA.initialize('G-HC1EQ1GX01');

export const ROLE_ANON = "Anonymous";
export const ROLE_FREE = "User";
export const ROLE_CUSTOMER = "Customer";
export const ROLE_PROMOTED = "Promoted";
export const ROLE_RANKED = "Ranked";
export const ROLE_CONTRIBUTOR = "Contributor";
export const ROLE_ADMIN_OVERLORD = "Administrator";

export const ROLES_FREE = [ROLE_FREE];
export const ROLES_CREATE_COMMENT = [ROLE_FREE, ROLE_CUSTOMER, ROLE_PROMOTED, ROLE_RANKED, ROLE_CONTRIBUTOR, ROLE_ADMIN_OVERLORD];
export const ROLES_CREATE_POST = [ROLE_CUSTOMER, ROLE_PROMOTED, ROLE_RANKED, ROLE_CONTRIBUTOR, ROLE_ADMIN_OVERLORD];
export const ROLES_MANAGE_APP = [ROLE_CONTRIBUTOR, ROLE_ADMIN_OVERLORD];
export const ROLES_VIEW_POST = [ROLE_CUSTOMER, ROLE_PROMOTED, ROLE_RANKED, ROLE_CONTRIBUTOR, ROLE_ADMIN_OVERLORD];

export const stripePromise = loadStripe('pk_test_51MdvBSKhX1xBvQqljvYoJTU2CZCFOfIvXXIs44jhcZI79thQAiUj5Ow25qGbVrodQsLxyLDOb5OZfgWFQABWCwkl00z37gMtHI');

export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout><Feed filter={FeedFilterValues.All}/></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/onboard",
    element: <LandingPageOnboard />,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/terms",
    element: <Terms />,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/new",
    element: <Authorize redirectToLogin={false} roles={ROLES_FREE}><MainLayout><NewPost /></MainLayout></Authorize>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/new/:contentType",
    element: <Authorize redirectToLogin={false} roles={ROLES_FREE}><EditorLayout/></Authorize>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/import",
    element: <Authorize redirectToLogin={true} roles={ROLES_MANAGE_APP}><MainLayout><Import /></MainLayout></Authorize>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/symbols",
    element: <Authorize redirectToLogin={true} roles={ROLES_MANAGE_APP}><MainLayout><Symbols /></MainLayout></Authorize>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/browse-symbol",
    element: <MainLayout><BrowseSymbols /></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/symbol/:symbol",
    element: <MainLayout><ViewSymbol /></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/posts",
    element: <MainLayout><Feed filter={FeedFilterValues.Post}/></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/post/:identifier/:seoFriendlyUrl",
    element: <MainLayout><ViewPost /></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/question/:identifier/:seoFriendlyUrl",
    element: <MainLayout><ViewQuestion /></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/theory/:identifier/:seoFriendlyUrl",
    element: <MainLayout><ViewTheory /></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/poll/:identifier/:seoFriendlyUrl",
    element: <MainLayout><ViewPoll /></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/questions",
    element: <MainLayout><Feed filter={FeedFilterValues.Question}/></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/theories",
    element: <MainLayout><Feed filter={FeedFilterValues.Theory}/></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/polls",
    element: <MainLayout><Feed filter={FeedFilterValues.Poll}/></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/profile",
    element: <MainLayout><Profile /></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/profile/:username",
    element: <MainLayout><Profile /></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/favorites",
    element: <MainLayout><Favorites /></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/notifications",
    element: <MainLayout><Notifications /></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/settings",
    element: <MainLayout><AccountSettings /></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/logout",
    element: <MainLayout><Placeholder /></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/login",
    element: <SimpleLayout><Login /></SimpleLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/login/:error",
    element: <SimpleLayout><Login /></SimpleLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/register",
    element: <SimpleLayout><Register /></SimpleLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "register/confirm",
    element: <SimpleLayout><ConfirmEmail /></SimpleLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "register/confirm/:token",
    element: <SimpleLayout><ConfirmRegistration /></SimpleLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/register/subscription",
    element: <SimpleLayout showLogo={false} hideOverflow={false}><GetSubscription /></SimpleLayout>
  },
  {
    path: "/subscription/success",
    element: <SimpleLayout><SubscriptionSucces /></SimpleLayout>
  },
  {
    path: "/subscription/failed",
    element: <SimpleLayout><SubscriptionFailed /></SimpleLayout>
  },
  {
    path: "/forgot/password/:token",
    element: <SimpleLayout><ConfirmResetPassword /></SimpleLayout>
  },
  {
    path: "/forgot/password",
    element: <SimpleLayout><ResetPassword /></SimpleLayout>
  },
  {
    path: "/change/email/:token",
    element: <SimpleLayout><ConfirmEmailChange /></SimpleLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/error/:error",
    element: <SimpleLayout><Error/></SimpleLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/upgrade-now",
    element: <MainLayout><PremiumRequired /></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/upgrade-user",
    element: <MainLayout><UpgradeUser /></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/portfolio",
    element: <MainLayout><Portfolio /></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/whale-tracker",
    element: <MainLayout><WhaleTracker /></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "/indicators",
    element: <MainLayout><Indicators /></MainLayout>,
    loader: async ({params}) => {
      return defaultLoader(params);
    }
  },
  {
    path: "*",
    element: <MainLayout><NotFound /></MainLayout>
  }
], {});

function App() {

  useEffect(() => {
    const terrificCookie = getCookie("consentCookie");
  });

  return (
    <GoogleOAuthProvider clientId='867913239230-95eccasgl5o4ar1ras63dscp8eu88r9u.apps.googleusercontent.com'>
      <HelmetProvider>
      <Helmet>
      </Helmet>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider theme={theme}>
            <div className="App">
              <RouterProvider router={router} />
              <CookieAlert />
              <GoogleTapSignIn button={false} />
              <Onboarding />
            </div>
          </ThemeProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
