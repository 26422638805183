import React from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TextField } from '@mui/material';

type DatePickerProps = {
    label:string,
    onChange:Function
}

type DatePickerState = {
    width:number,
    value: Date
}

export default class DatePicker extends React.Component<DatePickerProps, DatePickerState> {
    constructor(props:DatePickerProps) {
        super(props);
        const width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        this.resize = this.resize.bind(this);
        this.onChange = this.onChange.bind(this);
        this.state = {
            width: width,
            value: new Date()
        };

        window.addEventListener("resize", this.resize);
    }

    resize() {
        const width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        this.setState({...this.state, width: width});
    }

    onChange(value:Date | null) {
        if(value) {
            this.props.onChange(value);
            this.setState({...this.state, value: value});
        }
    }
    
    render() {
        if(this.state.width <= 768) {
            return(
                <MobileDatePicker 
                label={this.props.label}
                inputFormat="DD/MM/YYYY"
                value={this.state.value}
                onChange={this.onChange}
                renderInput={(params) => <TextField style={{margin: "8px 0px"}} {...params} />}
                />
            );
        }
        else {
            return(
                <DesktopDatePicker 
                    label={this.props.label}
                    inputFormat="DD/MM/YYYY"
                    value={this.state.value}
                    onChange={this.onChange}
                    renderInput={(params) => <TextField style={{margin: "8px 0px"}} {...params} />}
                />
            );
        }
    }
}