import * as React from 'react';
import { styled, } from '@mui/material/styles';
import { Typography, Grid, Button, TextField} from '@mui/material';

import Account from '../API/Account';
import Authentication from '../API/Authentication';
import { router } from '../App';

import InstagramIcon from '@mui/icons-material/Instagram';
import RedditIcon from '@mui/icons-material/Reddit';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ga4events from "../events";


type FooterProps = {

}


type RegisterState = {
    email:string,
}


const Footer = styled('section')(({ theme }) => ({
    backgroundImage: `url("/pages/assets/img/photos/bg23.png")`,
    paddingTop: theme.spacing(6),
    height: '30vh',
    '& .footer-container': {
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        '& .socials': {
            ' & li': {
                listStyleType: 'none',
            }
        },
        '& form': {
            positition: 'relative',
            border: '2px solid rgba(255, 255, 255)',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: theme.spacing(2),
            background: 'transparent',
            backdropFilter: 'blur(70px)',
            borderRadius: theme.spacing(2),
            // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Box shadow for depth
            maxWidth: '80%',
            marginLeft: 'auto !important',
            [theme.breakpoints.up('sm')]: {
              maxWidth: '400px', // Reduce the max width of the form on larger screens
              padding: theme.spacing(4),
              marginLeft: 'auto !important',
              marginRight: 'auto !important',
            },
            '& .MuiTextField-root': {
              width: '100%',
              marginBottom: theme.spacing(2),
            },
            '& .MuiButton-root': {
              width: '100%',
              maxWidth: '300px',
              padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
              borderRadius: theme.spacing(3),
              fontSize: '1rem',
              fontWeight: 'bold',
            },
          },
    }


  }));


export default class  LandingPageFooter extends React.Component<FooterProps, RegisterState> {
    constructor(props:FooterProps) {
        super(props);

        this.state = {
            email: "",
        }
        this.onEmailChanged = this.onEmailChanged.bind(this);
        this.onRegister = this.onRegister.bind(this);
    }

    onEmailChanged(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, email: event.currentTarget.value});
    }
    
    onRegister() {
        ga4events.buttonClick('register_at_landing_page_hero_section');
        Account.Register(this.state.email).then(() => {
            try { Authentication.Logout() } catch { }
            router.navigate("/register/confirm");
        });
    }

    render(){
        return (
            <Footer>

                <Grid container spacing={4} className='footer-container'>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='h6'>Are you ready to make money?</Typography>
                        <form>
                            <TextField onChange={this.onEmailChanged} label="Enter your email" variant="outlined" />
                            <Button id={'register-footer'} onClick={this.onRegister} variant="contained" style={{backgroundColor: "#1a73e8"}}>
                                Register for free
                            </Button>
                        </form>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='h6'>Follow us on social media</Typography>
                        <ul className="socials">
                            <li><a href="https://twitter.com/fetch_team" target="_blank"><TwitterIcon/></a></li>
                            <li><a href="https://www.instagram.com/fetch.team/" target="_blank"><InstagramIcon/></a></li>
                            <li><a href="https://www.reddit.com/user/FetchTeam" target="_blank"><RedditIcon/></a></li>
                            <li><a href="https://www.youtube.com/channel/UC2tTJ2YS1Y_PyrDoLkRyK2A" target="_blank"><YouTubeIcon/></a></li>
                        </ul>
                    </Grid>
                </Grid>
                    

            </Footer>
        )
    }
        
    
}