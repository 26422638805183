import React from 'react';
import {FormControl, Card, CardContent, Grid, Slider, ListItemIcon, MenuItem, Skeleton, Select, InputLabel, Box, Dialog, DialogContent, Stack, TextField, Typography, Button, DialogActions } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import * as Papa from 'papaparse';

import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ResponsiveChart from '../../Charts/ResponsiveChart';

import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

export default class FetchEditorChartJsBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 0,
            hasDataSource : false,
            importColumns: [], 
            importRows: [],
            selectedDateField: "",
            selectedValueField: "",
            selectedChartType: "",
            chartTitle: "",
            chartHeight: 400,
            chartOptions: undefined,
            chartData: undefined,
            fileName: ""
        };

        this.onFileChange = this.onFileChange.bind(this);
        this.onChartTypeChange = this.onChartTypeChange.bind(this);
        this.onSelectedDataFieldChangeChange = this.onSelectedDataFieldChangeChange.bind(this);
        this.onSelectedValueFieldChange = this.onSelectedValueFieldChange.bind(this);
        this.updateMapping = this.updateMapping.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.onChartTitleChange = this.onChartTitleChange.bind(this);
        this.onChartHeightChange = this.onChartHeightChange.bind(this);

        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    onTabChange(event, newValue) {
        this.setState({...this.state, selectedTab: newValue}, () => {
        });
    }

    onFileChange(event) {
        const fileInput = document.getElementById('csv');

        if(fileInput && fileInput.files) {
            console.log(fileInput.files[0]);
            this.setState({...this.state, fileName: fileInput.files[0].name}, () => {
                Papa.parse(fileInput.files[0], {
                    complete: results => {
                        this.parseCSV(results);
                    }
                });
            });
        }
    }

    onChartTypeChange(event) {
        this.setState({...this.state, selectedChartType: event.target.value}, () => {
            this.updateMapping();
        });
    }

    onSelectedDataFieldChangeChange(event) {
        this.setState({...this.state, selectedDateField: event.target.value}, () => {
            this.updateMapping();
        });
    }

    onSelectedValueFieldChange(event) {
        this.setState({...this.state, selectedValueField: event.target.value}, () => {
            this.updateMapping();
        });
    }

    onChartTitleChange(event) {
        this.setState({...this.state, chartTitle: event.target.value});
    }

    onChartHeightChange(event, newValue) {
        this.setState({...this.state, chartHeight: newValue});
    }

    updateMapping() {
        if(this.state.selectedChartType === "line") {
            const xAxis = this.state.selectedDateField;
            const yAxis = this.state.selectedValueField;
            const xAxisData = [];
            const yAxisDAta = [];
            
            for(const row of this.state.importRows) {
                xAxisData.push(row[xAxis]);
                yAxisDAta.push(row[yAxis]);
            }

            const data = [
                {
                    x: xAxisData,
                    y: yAxisDAta,
                    type: "scatter",
                    mode: "lines+markers",
                    marker: {color: '#df37a5'}
                }
            ];

            this.setState({...this.state, chartData: data});
        }
        
        else if(this.state.selectedChartType === "bar") {
            const xAxis = this.state.selectedDateField;
            const xAxisData = [];
            const yAxisData = [];

            let data = {};

            for(const row of this.state.importRows) {
                const value = row[xAxis];
                if(value) {
                    if(data[row[xAxis]]) {
                        data[row[xAxis]] += 1;
                    }
    
                    else {
                        data[row[xAxis]] = 1;
                    }
                }
            }

            for(const row in data) {
                xAxisData.push(row);
                yAxisData.push(data[row]);
            }

            const chartData = {
                x: xAxisData,
                y: yAxisData,
                name: "Count",
                type: "bar",
                marker: {color: '#df37a5'}
            };

            this.setState({...this.state, chartData: [chartData]});
        }
    }

    parseCSV(csv) {
        const colDefinitions = [];
        const rows = [];
        for(const column of csv.data[0]) {
            colDefinitions.push({field: column.replace(" ", ""), headerName: column});
        }

        colDefinitions.push({field: "id", headerName: "ID"});

        for(let i = 1; i < csv.data.length; i++) {
            let row = {};
            row["id"] = i;
            for(let j = 0; j < csv.data[i].length; j++) {
                row[colDefinitions[j].field] = csv.data[i][j];
            }

            rows.push(row);
        }

        this.setState({
            ...this.state, 
            importColumns: colDefinitions, 
            importRows: rows,
            selectedDateField: "",
            selectedValueField: "",
            selectedTab: 1
        });
    }

    onClose() {
        this.setState({...this.state, op})
    }

    onCancel() {
        this.props.onDelete();
        this.setState({...this.state, hasDataSource: true});
    }

    onSave() {
        this.setState({...this.state, hasDataSource: true});
    }

    getData() {
        return {
            "title": this.state.chartTitle,
            "chartData": this.state.chartData,
            "height": this.state.chartHeight
        };
    }

    render() {
        return(
            <Box>
                <Dialog fullScreen open={!this.state.hasDataSource}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12} md={8} lg={9} xl={10}>
                                <Card sx={{height: "100%", backgroundColor: "#ffffff"}}>
                                    <CardContent>
                                        <Stack gap="12px">
                                            <Typography variant='h5'>Preview</Typography>
                                            {((this.state.selectedChartType === "line" || this.state.selectedChartType === "bar") && this.state.chartData !== undefined) ? (
                                                <ResponsiveChart height={this.state.chartHeight} data={this.state.chartData} title={this.state.chartTitle}/>
                                            ) : <Skeleton variant="rectangular" height={300} />}
                                        </Stack>
                                    </CardContent>
                                </Card>
                                
                            </Grid>
                            <Grid item sm={12} md={4} lg={3} xl={2}>
                                <Card sx={{height: "100%", backgroundColor: "#ffffff"}}>
                                    <CardContent>
                                        <Stack gap="12px">
                                            <Typography variant='h5'>Customize</Typography>
                                            <Button
                                                variant="contained"
                                                component="label"
                                                >
                                                Upload File
                                                <input
                                                    id='csv'
                                                    type="file"
                                                    onChange={this.onFileChange}
                                                    hidden
                                                    accept=".csv"
                                                />
                                            </Button>
                                            {this.state.fileName.length > 0 && <Typography variant='caption'>{this.state.fileName}</Typography>}
                                            <FormControl fullWidth margin='dense'>
                                                <InputLabel id="demo-simple-select-label">Chart Type</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={this.selectedChartType}
                                                label="Age"
                                                onChange={this.onChartTypeChange}
                                                >
                                                    <MenuItem value="line">
                                                        <ListItemIcon>
                                                            <ShowChartIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Line
                                                    </MenuItem>
                                                    <MenuItem value="bar">
                                                        <ListItemIcon>
                                                            <BarChartIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Bar
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                            
                                            {(this.state.selectedChartType === "line" || this.state.selectedChartType === "bar") && (
                                                <FormControl margin='dense'>
                                                    <InputLabel id="demo-simple-select-label">X axis</InputLabel>
                                                    <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={this.state.selectedDateField}
                                                    label="X axis"
                                                    onChange={this.onSelectedDataFieldChangeChange}
                                                    >
                                                        {this.state.importColumns?.map((column, i) => {
                                                            return(
                                                                <MenuItem key={column.field} value={column.field}>{column.headerName}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            )}   
                                        
                                        
                                            {this.state.selectedChartType === "line" && (
                                                <FormControl margin='dense'>
                                                    <InputLabel id="demo-simple-select-label">Y axis</InputLabel>
                                                    <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={this.state.selectedValueField}
                                                    label="Y axis"
                                                    onChange={this.onSelectedValueFieldChange}
                                                    >
                                                        {this.state.importColumns?.map((column, i) => {
                                                            return(
                                                                <MenuItem key={column.field} value={column.field}>{column.headerName}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            )}

                                            {(this.state.selectedChartType === "line" || this.state.selectedChartType === "bar") && (
                                                <TextField value={this.state.chartTitle} onChange={this.onChartTitleChange} id="chart-title" margin='dense' label="Title" variant="outlined" />
                                            )}
                                            <InputLabel id="chart-height-label">Chart height</InputLabel>
                                            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                                <UnfoldLessIcon />
                                                <Slider min={100} max={800} aria-label="Chart Height" value={this.state.chartHeight} onChange={this.onChartHeightChange} />
                                                <UnfoldMoreIcon />
                                            </Stack>
                                        </Stack>
                                    </CardContent>
                                </Card>
                                
                            </Grid>
                            <Grid item xs={12}>
                                <Card sx={{backgroundColor: "#ffffff"}}>
                                    <CardContent>
                                        <Stack gap="12px">
                                            <Typography variant='h6'>Data</Typography>
                                            <div style={{ height: '400px', width: '100%' }}>
                                                <DataGrid
                                                    rows={this.state.importRows}
                                                    columns={this.state.importColumns}
                                                    pageSize={50}
                                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                />
                                            </div>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='filled' onClick={this.onCancel}>Cancel</Button>
                        <Button disabled={this.state.chartData === undefined} variant='contained' onClick={this.onSave}>Save</Button>
                    </DialogActions>
                </Dialog>
                {(this.state.selectedChartType === "line" || this.state.selectedChartType === "bar") && this.state.chartData !== undefined && (
                    <ResponsiveChart height={this.state.chartHeight} data={this.state.chartData} title={this.state.chartTitle}/>
                )}
            </Box>
        )
    }
}