import { useParams, useLoaderData } from "react-router-dom";
import Account from "../API/Account";
import { WhoAmIResponse } from "../API/models/account";
import React from 'react';
import { setCookie } from "../API/resources/cookies";

export function withParams(Component:any) {
    return (props:any) => <Component {...props} params={useParams()} loaderData={useLoaderData()} />;
}

export async function defaultLoader(params: any): Promise<WhoAmIResponse> {
    scrollToTop();
    return new Promise<WhoAmIResponse>((resolve, reject) => {
        Account.WhoAmI().then(whoAmI => {
            if(whoAmI.shouldRefreshToken) {
                Account.RefreshToken().then(loginResponse => {
                    setCookie("auth", JSON.stringify(loginResponse), loginResponse.expiresOn);
                    resolve(whoAmI);
                }).catch(error => {
                    reject(error);
                });
            }
            else {
                resolve(whoAmI);
            }
        }).catch(error => {
            reject(error);
        });
    });
}

export function scrollToTop() {
    window.scrollTo(0,0);
}