import React from 'react';
import FetchViewerBlock from './Blocks/FetchViewerBlock';
import { Stack } from "@mui/material";

export class ViewerBlock {
    id;
    type;
    data;

    constructor(id, type, data) {
        this.id = id;
        this.type = type;
        this.data = data;
    }
}

export default class FetchViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {blocks: []};
    }

    setContent(content) {
        if(content && Array.isArray(content)) {
            
            let blocks = [];
            
            for(const block of content) {
                blocks.push(new ViewerBlock(block.id, block.type, block.data));
            }

            this.setState({...this.state, blocks: blocks, blockReference: blocks[0].reference});
        }
    }

    render() {
        return(
            <Stack spacing={3}>
                {this.state.blocks.map((object, i) => {
                    return(
                        <FetchViewerBlock key={i} type={object.type} id={object.id} data={object.data} />
                    )
                })}
            </Stack>
        );
    }
}