import { Box, Button, Menu, MenuItem } from '@mui/material';
import React from 'react';
import Authentication from '../../API/Authentication';
import Data from '../../API/Data';
import { WhoAmIResponse } from '../../API/models/account';
import { router } from '../../App';


type UserMenuProps = {
    whoAmI: WhoAmIResponse
}

type UserMenuState = {
    isAuthenticated:boolean,
    isMenuOpen:boolean,
    menuAnchor?: Element
}

export default class UserMenu extends React.Component<UserMenuProps, UserMenuState> {
    constructor(props:UserMenuProps) {
        super(props);

        this.state = {
            isAuthenticated: false,
            isMenuOpen: false
        }

        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.goToProfile = this.goToProfile.bind(this);
        this.logout = this.logout.bind(this);
        this.login = this.login.bind(this);
    }

    componentDidMount(): void {
        const token = Data.GetAccessToken();
        if(token) {
            this.setState({...this.state, isAuthenticated: true});
        }
    }

    openMenu(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({...this.state, isMenuOpen: true, menuAnchor: event.currentTarget});
    }

    closeMenu() {
        this.setState({...this.state, isMenuOpen: false});
    }

    goToProfile() {
        router.navigate("/profile");
    }

    login() {
        router.navigate("/login");
    }

    logout() {
        Authentication.Logout();
        this.setState({...this.state, isAuthenticated: false}, () => {
            router.navigate("/login");
        });
    }

    render() {
        if(this.state.isAuthenticated && this.props.whoAmI) {
            return(
                <Box>
                    <Button onClick={this.openMenu} id='user-menu-button' variant='contained'>{this.props.whoAmI.username}</Button>
                    <Menu
                        id="user-menu-button"
                        anchorEl={this.state.menuAnchor}
                        open={this.state.isMenuOpen}
                        onClose={this.closeMenu}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={this.goToProfile}>Profile</MenuItem>
                        <MenuItem onClick={this.logout}>Logout</MenuItem>
                    </Menu>
                </Box>
            );
        }

        else {
            return (<Button variant='contained' onClick={this.login}>Login</Button>);
        }
    }
}