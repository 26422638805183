import { Box, Dialog, DialogContent, Stack, TextField, Typography, Button, Alert } from '@mui/material';
import React from 'react';

export default class FetchEditorYoutubeBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasLink: false,
            videoId: "",
            linkValue: "",
            error: undefined
        }

        this.onLinkSubmit = this.onLinkSubmit.bind(this);
        this.onLinkValueChange = this.onLinkValueChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    onLinkValueChange(event) {
        this.setState({...this.state, linkValue: event.target.value});
    }

    onLinkSubmit(event) {
        try {
            const url = new URL(this.state.linkValue);
            const videoId = url.searchParams.get("v");
            if(videoId) {
                this.setState({...this.state, videoId: videoId, hasLink: true});
            }
            else {
                this.setState({...this.state, error: "The Youtube link was invalid, please insert a valid YouTube link"});
            }
        }
        catch(error) {
            this.setState({...this.state, error: "Invalid URL please insert a valid YouTube URL"});
        }
    }

    onCancel() {
        this.props.onDelete();
        this.setState({...this.state, hasLink: true});
    }

    handleFocus() {
        this.props.onFocus(this.state.editorState, this.props.id);
    }

    getData() {
        return {
            "video": this.state.videoId
        };
    }

    getRawText() {
        return this.state.linkValue;
    }

    render() {
        //https://www.youtube.com/watch?v=1belhsR__LQ
        return(
            <Box>
                <iframe width="560" height="315" src={`https://www.youtube.com/embed/${this.state.videoId}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <Dialog open={!this.state.hasLink}>
                    <DialogContent>
                        <Stack gap={"12px"}>
                            <Typography variant='h5'>Add a video</Typography>
                            <Typography variant='body1'>Embed a youtube video by inserting a link below</Typography>
                            <TextField onChange={this.onLinkValueChange} value={this.state.linkValue} variant='filled' label="Youtube link" placeholder='Insert a youtube link' fullWidth></TextField>
                            {this.state.error && (
                                <Alert severity="error">{this.state.error}</Alert>
                            )}
                            <Stack direction="row" gap={"8px"}>
                                <Button onClick={this.onLinkSubmit} color='primary' variant='contained' fullWidth>Insert</Button>
                                <Button onClick={this.onCancel} color='secondary' variant='contained' fullWidth>Cancel</Button>
                            </Stack>
                        </Stack>
                    </DialogContent>
                </Dialog>
            </Box>
        )
    }
}