import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { CardActionArea, Button, CardActions } from '@mui/material';


import Rating from '@mui/material/Rating';

const reviews = [
    // {
    //   id: 1,
    //   name: 'Danny',
    //   reviewTitle: 'Learn the world of crypto',
    //   review: "the makers of fetsch take you into the world of crypto. \
    //   By reading, analyzing, answering questions and following discussions, this can help you make the next move in the crypto market. \
    //   are you coming along or will your investment fail. \
    //   it has mainly helped me with gaining insights and understanding the crypto market." ,
    //   url: 'https://www.trustpilot.com/reviews/64bb9d7672d489a0490a63f8',
    //   date: 'March 12, 2023',
    //   stars: 5,
    // },
    {
      id: 7,
      name: 'Marija Mihaljević',
      reviewTitle: 'Hex and ETH',
      review: 'I am personally into Hex and ETH, great site for constant updates about these coins',
      url: 'https://www.trustpilot.com/reviews/64c54a4bc254e5bee8e34153',
      date: 'July 29, 2023',
      image: 'https://user-images.trustpilot.com/63cfc6e48059b4001232e748/73x73.png',
      stars: 5,
    },
    {
      id: 2,
      name: 'Ijsbrand',
      reviewTitle: 'Perfect for a human approach to crypto',
      review: 'Fetch is a great place for getting mental clarity and recieving a good overview of the lastest developments in crypto. No over the top delusional praising, but instead clear analysis and very human approach to the subject. All the posts are well explained and clearly presented. The creators have a top focus on sharing opinions and ideas about what is happening/going to happen in the crypto market, so users can learn how to best invest their wealth on their own terms. If you are looking to start in crypto there is no better place to begin!',
      url: 'https://www.trustpilot.com/reviews/64bbb4212568d64c8d2d0d3d',
      date: 'July 22, 2023',
      stars: 4,
    },
    {
      id: 3,
      name: 'Niek Boekel',
      reviewTitle: 'One-stop-shop',
      review: "I have not only had the pleasure of interacting with the Fetch platform and community members, but also the people behind the platform.\
      The combined expertise of the founders was initially quite intimidating to me, as I had no experience with crypto whatsoever. After a couple of months of working with them and the platform, I can say my knowledge regarding crypto as well as my admiration for their team members and their supportive attitudes increased exponentially! \
      One-stop-shop if you breathe crypto and aren't afraid to debate on the subject. \
      Niek Boekel",
      url: 'https://www.trustpilot.com/reviews/64baa84fcedb28e8494232f3',
      date: 'July 21, 2023',
      stars: 5,
    },
    {
      id: 4,
      name: 'Jesper Grim',
      reviewTitle: 'Great experience!',
      review: "From design, to analyses. Everything looks really good and makes sence. If I don’t understand something Fetch.Team has a wiki or I can just ask in the post / poll \
      Would recommend Fetch.Team to everyone starting with crypto or investing, and even if you are quite expierenced it is still worth to have a look at their analyses!" ,
      url: 'https://www.trustpilot.com/reviews/64af0405a5e1608484a79479',
      date: 'May 16, 2023',
      stars: 5,
    },
    {
      id: 5,
      name: 'Klant',
      reviewTitle: 'Learn to think like a crypto investor',
      review: 'Finally a crypto platform that focuses on improving the skills of the investor, not randomly dishing out advice but thoroughly clarifying their reasoning behind strategies. Big up for the Fetch team for making this happen!',
      url: 'https://www.trustpilot.com/reviews/64aefe0522a5e9e449900c25',
      date: 'July 12, 2023',
      stars: 5,
    },
    {
      id: 6,
      name: 'Nico Arling',
      reviewTitle: 'Good site',
      review: 'The site looks logical and is clear.Good analysis!',
      url: 'https://www.trustpilot.com/users/64bacfeae81dfc0012f46bef',
      date: 'July 21, 2023',
      image: 'https://user-images.trustpilot.com/64bacfeae81dfc0012f46bef/73x73.png',
      stars: 4,
    },
  ]


export default function LandingPageReviews() {

    const getName = (name:string) => {
        // Get the first letter of the first name and the first letter of the last name if the last name exists
        let nameArray = name.split(" ");
        let firstName = nameArray[0];
        let lastName = nameArray.length > 1 ? nameArray[1] : "";
        let firstLetter = firstName.charAt(0);
        let secondLetter = lastName.charAt(0);
        return firstLetter + secondLetter;

    }  

    const openReview = (url:string) => {
      window.open(url, "_blank", "noreferrer");
    }



  return (
    <Box
      sx={{
        // marginTop: 20,
        padding: 10,
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: 250,
          height: 200,
        },
      }}
    >
        {reviews.map((review) => {
            return (
                <Card sx={{opacity:0.9}}>
                    <CardActionArea onClick={()=>openReview(review.url)}>
                      <CardHeader
                          sx={{opacity:1}}
                          avatar={review.image? <Avatar src={review.image}></Avatar> :<Avatar sx={{ bgcolor: '#df37a5' }}>{getName(review.name)}</Avatar>}
                          title={review.reviewTitle}
                      >
                      </CardHeader>
                      <CardContent>
                          <Typography noWrap variant="body2">{review.review}</Typography>
                          <Box display="flex" justifyContent="center" alignItems="center" p={1}>
                              <Rating name="stars" value={review.stars} max={5} readOnly/>
                          </Box>
                      </CardContent>          
                    </CardActionArea>
                    <CardActions>
                      <Button onClick={()=>openReview(review.url)} size="small" color="primary">
                        CLick for review
                      </Button>
                    </CardActions>
                </Card>
            )
        })}      
    </Box>
  );
}