import { Box, Stack, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import TagInput from '../../../Controls/TagInput';
import ImageInput from '../../../Controls/ImageInput';
import { ISideBar } from './EditorSideBar';
import { sidebarPaddingStyle } from './EditorSideBar';


type EditorSideBarPostProps = {

}

type EditorSideBarPostState = {
    title:string,
    tags: string[]
}

export default class EditorSideBarPost extends React.Component<EditorSideBarPostProps, EditorSideBarPostState> implements ISideBar {
    imageRef:React.RefObject<ImageInput>
    constructor(props:EditorSideBarPostProps) {
        super(props);
        this.state = {
            title: "",
            tags: []
        };

        this.save = this.save.bind(this);
        this.onTagsChanged = this.onTagsChanged.bind(this);
        this.titleChanged = this.titleChanged.bind(this);
        this.imageRef = React.createRef();
    }
    
    save() {
        return {...this.state, image: this.imageRef.current?.getBase64()};
    }


    onTagsChanged(tags:string[]) {
        this.setState({...this.state, tags: tags});
    }

    titleChanged(title:ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, title: title.target.value});
    }

    render() {
        return(
            <Stack>
                <Box style={sidebarPaddingStyle}>
                    <TextField fullWidth variant="outlined" size="small" label="Title" onChange={this.titleChanged} />
                </Box>
                <Box style={sidebarPaddingStyle}>
                    <TagInput onChange={this.onTagsChanged} />
                </Box>
                <Box style={sidebarPaddingStyle}>
                    <ImageInput ref={this.imageRef} />
                </Box>
            </Stack>
        );
    }
}