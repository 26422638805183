import React from 'react';
import {Editor, EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';

export default class FetchEditorTextBlock extends React.Component {
    data;
    editorRef;
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            editorState:  EditorState.createEmpty()
        };
        
        this.editorRef = React.createRef();

        this.handleFocus = this.handleFocus.bind(this);
        this.editorChange = this.editorChange.bind(this);
        this.getEditor = this.getEditor.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getData = this.getData.bind(this);
        this.getRawText = this.getRawText.bind(this);
    }

    componentDidMount() {
        if(this.props.data) {
            this.data = this.props.data;
            const rawData = convertFromRaw(this.data);
            //let contentState = ContentState.createFromBlockArray(this.data.blocks, this.data.entityMap);
            let editorState = EditorState.createWithContent(rawData);
            this.setState({
                ...this.state,
                id: this.props.id,
                editorState:  editorState
            });
        }

        else {
            this.setState({
                ...this.state,
                id: this.props.id,
                editorState:  EditorState.createEmpty()
            });
        }
    }

    onChange(newState) {
        this.setState({...this.state, editorState: newState});
    }

    handleFocus() {
        this.props.onFocus(this.state.editorState, this.props.id);
    }

    editorChange(editorState) {
        this.setState({...this.state, editorState: editorState}, () => {
            this.editorRef.current?.focus();
        });        
    }

    getEditor() {
        return this.state.editorState;
    }

    getData() {
        return convertToRaw(this.state.editorState.getCurrentContent());
    }

    getRawText() {
        return this.state.editorState.getCurrentContent().getPlainText("<br/><br/>");
    }

    handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            this.onChange(newState);
            return 'handled';
        }

        return 'not-handled';
    }

    render() {
        return(<Editor ref={this.editorRef} editorState={this.state.editorState} onChange={this.onChange} onFocus={this.handleFocus} />);
    }
}