import React from 'react';

import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Card, CardContent, Stack, Typography } from '@mui/material';

export default class SubscriptionFailed extends React.Component {
    render(): React.ReactNode {
        return(
            <Card>
                <CardContent>
                    <Stack gap="12px" alignItems="center">
                        <SentimentVeryDissatisfiedIcon sx={{ fontSize: 120, color: "var(--primary-color)" }} />
                        <Typography variant='h3'>VIEZE KANKER!</Typography>
                        <Typography variant='body1'>Something went wrong during the payment process.</Typography>
                    </Stack>   
                </CardContent>
            </Card>
        )
    }
}