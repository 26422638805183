import { AppBar, Box, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import React from 'react';
import UserMenu from './Header/UserMenu';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { router } from '../App';
import EditorSideBar from '../Content/Editor/Sidebar/EditorSideBar';
import Poll from "../API/Poll";
import Post from '../API/Post';
import Question from '../API/Question';
import Theory from '../API/Theory';
import Editor from '../Content/Editor/Editor';
import { ParseErrors } from '../Helpers/ErrorHelper';
import { ContentType } from './EditorLayout';
import { WhoAmIResponse } from '../API/models/account';
import { withParams } from '../Helpers/ReactRouterHelper';

type MobileEditorProps = {
    contentType: ContentType,
    whoAmI: WhoAmIResponse
}

class MobileEditorLayout extends React.Component<MobileEditorProps> {
    editorRef:React.RefObject<Editor>
    metdataRef:React.RefObject<EditorSideBar>
    constructor(props:MobileEditorProps) {
        super(props);
        this.metdataRef = React.createRef();
        this.editorRef = React.createRef();
        this.goToHome = this.goToHome.bind(this);
        this.save = this.save.bind(this);
        this.savePost = this.savePost.bind(this);
        this.savePoll = this.savePoll.bind(this);
        this.saveQuestion = this.saveQuestion.bind(this);
        this.saveTheory = this.saveTheory.bind(this);
    }

    goToHome() {
        router.navigate("/feed")
    }

    async save(metadata:any, callBack:Function) {
        let excerpt = this.editorRef.current?.getExcerpt();
        this.editorRef.current?.save().then(editorData => {
            if(metadata.contentType === "post") {
                this.savePost(metadata, editorData, excerpt ?? metadata.contentType).then(() => {
                    callBack();
                });
            }

            else if(metadata.contentType === "question") {
                this.saveQuestion(metadata, editorData, excerpt ?? metadata.contentType).then(() => {
                    callBack();
                });
            }

            else if(metadata.contentType === "theory") {
                this.saveTheory(metadata, editorData, excerpt ?? metadata.contentType).then(() => {
                    callBack();
                });
            }

            else if(metadata.contentType === "poll") {
                this.savePoll(metadata, editorData, excerpt ?? metadata.contentType).then(() => {
                    callBack();
                });
            }
        });
    }

    async savePost(metadata:any, editorData:any, excerpt:string) {
        await Post.CreatePost(metadata.title, JSON.stringify(editorData), excerpt, metadata.tags, metadata.image)
            .then(post => {
                router.navigate(`/post/${post.identifier}/${post.title.replace(" ", "-")}`);
            })
            .catch(errors => {
                const errorList = ParseErrors(errors);
                this.setState({...this.state, errors: errorList});
            });
    }

    async saveQuestion(metadata:any, editorData:any, excerpt:string) {
        Question.CreateQuestion(metadata.title, JSON.stringify(editorData), excerpt, metadata.tags)
            .then(question => {
                router.navigate(`/question/${question.identifier}/${question.title.replace(" ", "-")}`);
            })
            .catch(errors => {
                const errorList = ParseErrors(errors);
                this.setState({...this.state, errors: errorList});
            });
    }

    async saveTheory(metadata:any, editorData:any, excerpt:string) {
        await Theory.CreateTheory(metadata.title, JSON.stringify(editorData), excerpt, metadata.tags)
            .then(theory => {
                router.navigate(`/theory/${theory.identifier}/${theory.title.replace(" ", "-")}`);
            })
            .catch(errors => {
                const errorList = ParseErrors(errors);
                this.setState({...this.state, errors: errorList});
            });
        
    }

    async savePoll(metadata:any, editorData:any, excerpt: string) {
        await Poll.CreatePoll(metadata.title, JSON.stringify(editorData), excerpt, metadata.tags, 
        metadata.question, metadata.option1, metadata.option2, metadata.option3, metadata.option4)
            .then(poll => {
                router.navigate(`/poll/${poll.identifier}/${poll.title.replace(" ", "-")}`);
            })
            .catch(errors => {
                const errorList = ParseErrors(errors);
                this.setState({...this.state, errors: errorList});
            });
    }

    render() {
        return(
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color='primary'>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={this.goToHome}
                    >
                        <ArrowBackIosNewIcon />
                    </IconButton>
                    <Typography onClick={this.goToHome} variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Fetch
                    </Typography>
                    <UserMenu whoAmI={this.props.whoAmI} />
                </Toolbar>
            </AppBar>
            <Stack gap="12px">
                <EditorSideBar contentType={this.props.contentType} onSave={this.save} ref={this.metdataRef}/>
                <Editor ref={this.editorRef}/>
            </Stack>
        </Box>
        )
    }
}

export default withParams(MobileEditorLayout);