import { Avatar, Box, Card, CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';
import FeedController from '../../API/FeedController';
import { ProfileResult, WhoAmIResponse } from '../../API/models/account';
import FeedItemCard from '../Feed/FeedItemCard';
import { withParams } from '../../Helpers/ReactRouterHelper';

type ProfileParams = {
    username:string
};

type ProfileProps = {
    loaderData: WhoAmIResponse,
    params:ProfileParams
}

type ProfileState = {
    profile?: ProfileResult
}

class Profile extends React.Component<ProfileProps, ProfileState> {
    constructor(props:ProfileProps) {
        super(props);
        this.state = {
            profile: undefined
        };
    }
    
    componentDidMount(): void {
        if(this.props.params.username) {
            FeedController.GetProfile(this.props.params.username).then(profile => {
                this.setState({...this.state, profile: profile});
            });
        }

        else {
            FeedController.GetProfile(this.props.loaderData.username).then(profile => {
                this.setState({...this.state, profile: profile});
            });
        }
    }

    render() {
        if(this.state.profile) {
            return(
                <Stack gap="24px" style={{width: "100%"}}>
                    <Card sx={{padding: "12px"}} elevation={2}>
                        <Box style={{display: "flex", justifyContent: "center", margin: "12px", alignItems: "center"}}>
                            {this.state.profile.profileImageUrl && this.state.profile.profileImageUrl.length === 0
                                ? <Avatar sx={{width: 64, height: 64}}>{this.state.profile.name[0]}</Avatar>
                                : <Avatar sx={{width: 64, height: 64}} src={this.state.profile.profileImageUrl} />
                            }
                        </Box>
                        <Typography variant="h4">{this.state.profile.name}</Typography>
                        {/* <Stack direction={"row"} gap={"6px"} alignItems="center" justifyContent="center">
                            <Typography>{this.state.profile.reputation}</Typography>
                            <Box sx={{...medalStyle, backgroundColor: "#ffcc01"}}></Box>
                            <Typography>{this.state.profile.goldAwards}</Typography>
                            <Box sx={{...medalStyle, backgroundColor: "#b4b8bc"}}></Box>
                            <Typography>{this.state.profile.silverAwards}</Typography>
                            <Box sx={{...medalStyle, backgroundColor: "#d1a684"}}></Box>
                            <Typography>{this.state.profile.bronzeAwards}</Typography>
                        </Stack> */}
                        <Typography variant="body1">{this.state.profile.bio}</Typography>
                    </Card>
                    {this.state.profile.items.map((feedItem, i) => {
                        return <FeedItemCard whoAmI={this.props.loaderData} key={i} item={feedItem}  />
                    })}
                </Stack>
                
            )
        }

        else {
            return(
                <Card sx={{width: "100%", height: "40vh"}} elevation={2}>
                    <Box sx={{ display: 'flex', alignContent: "center", alignItems: "center", justifyContent: "center", justifyItems: "center" }}>
                        <CircularProgress sx={{marginTop: "18vh"}} />
                    </Box>
                </Card>
            );
        }
    }
}

export default withParams(Profile);