import { error } from "console";
import Data from "./Data";
import { ProfileResult } from "./models/account";
import { FeedItemDTO } from "./models/datatransferobjects";
import { Filter } from "../Content/Feed/FeedFilter";

export const feedRoute = "/Feed";

export default abstract class FeedController {
    public static async GetPage(page:number) : Promise<FeedItemDTO[]> {
        return new Promise<FeedItemDTO[]>((resolve, reject) => {
            Data.Get<FeedItemDTO[]>(`${feedRoute}/${page}`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async GetPosts(page:number) : Promise<FeedItemDTO[]> {
        return new Promise<FeedItemDTO[]>((resolve, reject) => {
            Data.Get<FeedItemDTO[]>(`${feedRoute}/${page}/Filter/Posts`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async GetQuestions(page:number) : Promise<FeedItemDTO[]> {
        return new Promise<FeedItemDTO[]>((resolve, reject) => {
            Data.Get<FeedItemDTO[]>(`${feedRoute}/${page}/Filter/Questions`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async GetTheories(page:number) : Promise<FeedItemDTO[]> {
        return new Promise<FeedItemDTO[]>((resolve, reject) => {
            Data.Get<FeedItemDTO[]>(`${feedRoute}/${page}/Filter/Theories`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async GetPolls(page:number) : Promise<FeedItemDTO[]> {
        return new Promise<FeedItemDTO[]>((resolve, reject) => {
            Data.Get<FeedItemDTO[]>(`${feedRoute}/${page}/Filter/Polls`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async GetFilteredPost(page:number, filter:Filter) : Promise<FeedItemDTO[]> {
        return new Promise<FeedItemDTO[]>((resolve, reject) => {
            const tags = filter.symbols ?? [];
            let url = `${feedRoute}/${page}/Tags/${filter.type}`;
            for(let i = 0; i < tags.length; i++) {
                if(i == 0) {
                    url += `?tags=${tags[i].name}`;
                }

                else {
                    url += `&tags=${tags[i].name}`;
                }
            }

            Data.Get<FeedItemDTO[]>(url).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        })
    }

    public static async GetFavorites(page:number) : Promise<FeedItemDTO[]> {
        return new Promise<FeedItemDTO[]>((resolve, reject) => {
            Data.Get<FeedItemDTO[]>(`${feedRoute}/Favorites/${page}`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async GetProfile(username: string): Promise<ProfileResult> {
        return new Promise<ProfileResult>((resolve, reject) => {
            Data.Get<ProfileResult>(`${feedRoute}/Profile/${username}`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async GetPostPreviews(): Promise<FeedItemDTO[]> {
        return new Promise<FeedItemDTO[]>((resolve, reject) => {
            Data.Get<FeedItemDTO[]>(`${feedRoute}/Preview/Posts`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async GetHomePreviews(): Promise<FeedItemDTO[]> {
        return new Promise<FeedItemDTO[]>((resolve, reject) => {
            Data.Get<FeedItemDTO[]>(`${feedRoute}/Preview/Home`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public static async GetPostPreview(identifier:string) : Promise<FeedItemDTO> {
        return new Promise<FeedItemDTO>((resolve, reject) => {
            Data.Get<FeedItemDTO>(`${feedRoute}/Preview/Post/${identifier}`).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }

    // public static async IsContentMine(contentId:string) {
    //     return new Promise<boolean>((resolve, reject) => {
    //         Data.Get<boolean>(`${feedRoute}/IsMine/${contentId}`).then(response => {
    //             resolve(response);
    //         }).catch(error => {
    //             reject(error);
    //         });
    //     });
    // }
}